import { Cell } from './Cell'
import { Feature } from './Feature'
import {
  MAX_WORD_LENGTH,
  MASK_PORT,
  MASK_ROAD,
  MASK_AIRPORT,
} from '../../constants/settings'

type Props = {
  showEmpty: boolean
  extraDetail?: number
}

export const Features = ({ showEmpty, extraDetail }: Props) => {
  const icons = ['plane', 'ship', 'truck']
  const masks = [MASK_AIRPORT, MASK_PORT, MASK_ROAD]

  const featureStatus = (n: number | undefined, i: number) => {
    if (n === undefined) {
      return undefined
    } else if (n === (MASK_AIRPORT | MASK_PORT | MASK_ROAD)) {
      return 'correct'
    } else if ((n & masks[i]) === masks[i]) {
      return 'present'
    }
    return 'absent'
  }

  return (
    <div className="contents">
      {icons.map((icon, i) =>
        showEmpty ? (
          <Cell key={i} />
        ) : (
          <Feature
            key={i}
            status={featureStatus(extraDetail, i)}
            icon={icon}
            position={MAX_WORD_LENGTH + i}
          />
        )
      )}
    </div>
  )
}
