import { WORDS } from '../constants/wordlist'
import { VALID_GUESSES } from '../constants/validGuesses'

const wordList = WORDS.map((w) => {
  return w[0]
})
const guessList = VALID_GUESSES.map((w) => {
  return w[0]
})

export const isWordInWordList = (word: string) => {
  return (
    wordList.includes(word.toLowerCase()) ||
    guessList.includes(word.toLowerCase())
  )
}

export const isWinningWord = (word: string) => {
  return solution === word
}

export const getWordOfDay = () => {
  // January 1, 2022 Game Epoch
  const epochMs = new Date('January 1, 2022 00:00:00').valueOf()
  const now = Date.now()
  const msInDay = 86400000
  const index = Math.floor((now - epochMs) / msInDay)
  const nextday = (index + 1) * msInDay + epochMs

  return {
    solution: WORDS[index % WORDS.length][0].toUpperCase(),
    solutionLocation: WORDS[index % WORDS.length][2],
    solutionIndex: index,
    tomorrow: nextday,
  }
}

export const getFeatures = (word: string) => {
  const guessIndex = guessList.indexOf(word.toLowerCase())
  if (guessIndex !== -1) {
    return VALID_GUESSES[guessIndex][1]
  }

  const wordIndex = wordList.indexOf(word.toLowerCase())
  if (wordIndex !== -1) {
    return WORDS[wordIndex][1]
  }

  return undefined
}

export const { solution, solutionLocation, solutionIndex, tomorrow } =
  getWordOfDay()
