export const WORDS: [string, number, string][] = [
  ['usbli', 7, 'Bellingham'],
  ['clpmc', 7, 'Puerto Montt'],
  ['seume', 7, 'Umea'],
  ['esadt', 7, 'Alcaudete de la Jaya'],
  ['khkos', 7, 'Kampong Saom'],
  ['gbray', 7, 'Rothesay, Isle of Bute'],
  ['idpdg', 7, 'Padang'],
  ['dztlm', 7, 'Tlemcen'],
  ['plbzg', 7, 'Bydgoszcz'],
  ['itnap', 7, 'Napoli'],
  ['cnsnz', 7, 'Shenzhen'],
  ['usdkk', 7, 'Dunkirk'],
  ['brssz', 7, 'Santos'],
  ['bekjk', 7, 'Kortrijk'],
  ['itvce', 7, 'Venezia'],
  ['isbol', 7, 'Bolungarvik'],
  ['fisvl', 7, 'Nyslott (Savonlinna)'],
  ['dersk', 7, 'Rostock'],
  ['noadn', 7, 'Andenes'],
  ['cardl', 7, 'Riviere-du-Loup'],
  ['debmr', 7, 'Baltrum'],
  ['debre', 7, 'Bremen'],
  ['nohft', 7, 'Hammerfest'],
  ['phsji', 7, 'San Jose Apt, Mindoro'],
  ['nzkbz', 7, 'Kaikoura'],
  ['pkgwd', 7, 'Gwadar'],
  ['lkkct', 7, 'Koggala'],
  ['novds', 7, 'Vadso'],
  ['hrlsz', 7, 'Mali Losinj'],
  ['audrw', 7, 'Darwin'],
  ['phlgp', 7, 'Legaspi Apt, Luzon'],
  ['nzgis', 7, 'Gisborne'],
  ['mykud', 7, 'Kudat, Sabah'],
  ['gwoxb', 7, 'Bissau'],
  ['fioul', 7, 'Uleaborg (Oulu)'],
  ['sevby', 7, 'Visby'],
  ['ausyd', 7, 'Sydney'],
  ['casjb', 7, 'Saint-John'],
  ['nlein', 7, 'Eindhoven'],
  ['nossj', 7, 'Sandnessjoen'],
  ['trtzx', 7, 'Trabzon'],
  ['segot', 7, 'Goteborg'],
  ['sesoo', 7, 'Soderhamn'],
  ['itolb', 7, 'Olbia'],
  ['deber', 7, 'Berlin'],
  ['tndje', 7, 'Djerba'],
  ['delgo', 7, 'Langeoog'],
  ['uspae', 7, 'Everett'],
  ['tzdar', 7, 'Dar es Salaam'],
  ['brbel', 7, 'Belem'],
  ['sehuv', 7, 'Hudiksvall'],
  ['chbsl', 7, 'Basel'],
  ['usoth', 7, 'North Bend'],
  ['mxzlo', 7, 'Manzanillo'],
  ['mekot', 7, 'Kotor'],
  ['sesto', 7, 'Stockholm'],
  ['inbdq', 7, 'Vadodara'],
  ['iqbgw', 7, 'Baghdad'],
  ['esgij', 7, 'Gijon'],
  ['norvk', 7, 'Rorvik'],
  ['defra', 7, 'Frankfurt am Main'],
  ['idtjq', 7, 'Tanjung Pandan'],
  ['sgchg', 7, 'Changi'],
  ['denoe', 7, 'Norddeich'],
  ['caows', 7, 'Owen Sound'],
  ['fipor', 7, 'Bjorneborg (Pori)'],
  ['nzwre', 7, 'Whangarei'],
  ['notrd', 7, 'Trondheim'],
  ['dedrs', 7, 'Dresden'],
  ['yemyn', 7, 'Mareb'],
  ['fivaa', 7, 'Vaasa (Vasa)'],
  ['armdq', 7, 'Mar del Plata'],
  ['iewat', 7, 'Waterford'],
  ['inbom', 7, 'Mumbai (ex Bombay)'],
  ['dkrke', 7, 'Roskilde'],
  ['eszaz', 7, 'Zaragoza'],
  ['hrspu', 7, 'Split'],
  ['rocnd', 7, 'Constanta'],
  ['fruro', 7, 'Rouen'],
  ['pkjiw', 7, 'Jiwani'],
  ['nobnn', 7, 'Bronnoysund'],
  ['clari', 7, 'Arica'],
  ['jpukb', 7, 'Kobe'],
  ['nlmst', 7, 'Maastricht'],
  ['cabjo', 7, 'Baie-Johan-Beetz'],
  ['sjlyr', 7, 'Longyearbyen'],
  ['jpkkj', 7, 'Kitakyushu'],
  ['casat', 7, 'Saint-Anthony'],
  ['dekae', 7, 'Karlsruhe'],
  ['clzud', 7, 'Ancud'],
  ['invtz', 7, 'Visakhapatnam'],
  ['nohau', 7, 'Haugesund'],
  ['aedxb', 7, 'Dubai'],
  ['rukuf', 7, 'Samara'],
  ['tldil', 7, 'Dili'],
  ['auwhl', 7, 'Welshpool'],
  ['denue', 7, 'Nurnberg'],
  ['demhg', 7, 'Mannheim'],
  ['gbleq', 7, "Land's End"],
  ['ussba', 7, 'Santa Barbara'],
  ['usufg', 7, 'Chesapeake'],
  ['ingau', 7, 'Gauhati (Panidi)'],
  ['dzgha', 7, 'Ghardaia'],
  ['clcnr', 7, 'Chanaral'],
  ['aroyo', 7, 'Tres Arroyos'],
  ['casca', 7, 'Saint-Catharines'],
  ['hrosi', 7, 'Osijek'],
  ['inbhu', 7, 'Bhavnagar'],
  ['auons', 7, 'Onslow'],
  ['frnte', 7, 'Nantes'],
  ['pheni', 7, 'El Nido/Puerto Princesa'],
  ['usenw', 7, 'Kenosha'],
  ['itaoi', 7, 'Ancona'],
  ['iegwy', 7, 'Galway'],
  ['fitku', 7, 'Turku (Abo)'],
  ['dexfw', 7, 'Finkenwerder'],
  ['cftog', 7, 'Togo'],
  ['gbncl', 7, 'Newcastle upon Tyne'],
  ['delbc', 7, 'Lubeck'],
  ['senrk', 7, 'Norrkoping'],
  ['camfd', 7, 'Meaford'],
  ['usoxr', 7, 'Ventura-Oxnard Apt'],
  ['usfll', 7, 'Fort Lauderdale'],
  ['iebyt', 7, 'Bantry'],
  ['usbwb', 7, 'Brownsburg'],
  ['nomol', 7, 'Molde'],
  ['usjmc', 7, 'Sausalito'],
  ['sevvk', 7, 'Vastervik'],
  ['delew', 7, 'Lemwerder'],
  ['aucbr', 7, 'Canberra'],
  ['bzbgk', 7, 'Big Creek'],
  ['uscec', 7, 'Crescent City'],
  ['papty', 7, 'Panama, Ciudad de'],
  ['dehaj', 7, 'Hannover'],
  ['deham', 7, 'Hamburg'],
  ['eslei', 7, 'Almeria'],
  ['esvit', 7, 'Vitoria-Gasteiz'],
  ['cnggz', 7, 'Guangzhou'],
  ['dkode', 7, 'Odense'],
  ['copuu', 7, 'Puerto Asis'],
  ['frrhe', 7, 'Reims'],
  ['idsrg', 7, 'Semarang'],
  ['augoa', 7, 'Goolwa'],
  ['fitku', 7, 'Abo (Turku)'],
  ['nlgrq', 7, 'Groningen'],
  ['jpfms', 7, 'Fukushima, Miyazaki'],
  ['krsuk', 7, 'Samcheok'],
  ['frlle', 7, 'Lille'],
  ['usbcg', 7, 'Boca Grande'],
  ['deeme', 7, 'Emden'],
  ['phnop', 7, 'Mactan Island Apt'],
  ['cnhyn', 7, 'Huangyan'],
  ['canwp', 7, 'Argentia'],
  ['frjlp', 7, 'Juan-les-Pins'],
  ['mxlap', 7, 'La Paz'],
  ['dksgd', 7, 'Sonderborg'],
  ['copcr', 7, 'Puerto Carreno'],
  ['senyo', 7, 'Nykoping'],
  ['idpum', 7, 'Pomalaa, Sulawesi'],
  ['fipor', 7, 'Pori (Bjorneborg)'],
  ['caeta', 7, 'East Angus'],
  ['nolkn', 7, 'Leknes'],
  ['cispy', 7, 'San-Pedro'],
  ['briar', 7, 'Itacoatiara'],
  ['tmkrw', 7, 'Turkmenbashi'],
  ['decgn', 7, 'Koln'],
  ['esbcn', 7, 'Barcelona'],
  ['nllid', 7, 'Leiden'],
  ['lkcmb', 7, 'Colombo'],
  ['usmsy', 7, 'New Orleans'],
  ['vndqt', 7, 'Dung Quat'],
  ['frcfr', 7, 'Caen'],
  ['itbri', 7, 'Bari'],
  ['essio', 7, 'Casasimarro'],
  ['deess', 7, 'Essen'],
  ['sesft', 7, 'Skelleftea'],
  ['crglf', 7, 'Golfito'],
  ['gbbbp', 7, 'Bembridge'],
  ['idsub', 7, 'Surabaya'],
  ['aubne', 7, 'Brisbane'],
  ['ermsw', 7, 'Massawa (Mitsiwa)'],
  ['inccj', 7, 'Kozhikode (ex Calicut)'],
  ['kmajn', 7, 'Anjouan Apt'],
  ['usdbq', 7, 'Dubuque'],
  ['ruled', 7, 'Saint Petersburg (ex Leningrad)'],
  ['nznsn', 7, 'Nelson'],
  ['selav', 7, 'Landvetter'],
  ['nofro', 7, 'Floro'],
  ['clpnt', 7, 'Puerto Natales'],
  ['pthor', 7, 'Horta'],
  ['usdvn', 7, 'Davenport'],
  ['uscnm', 7, 'Carlsbad'],
  ['dosdq', 7, 'Santo Domingo'],
  ['nlven', 7, 'Venlo'],
  ['nztiu', 7, 'Timaru'],
  ['estar', 7, 'Tarragona'],
  ['filpp', 7, 'Lappeenranta (Villmanstrand)'],
  ['uslwm', 7, 'Lawrence'],
  ['notos', 7, 'Tromso'],
  ['caovr', 7, 'Oliver'],
  ['nllwr', 7, 'Leeuwarden'],
  ['frarr', 7, 'Arras'],
  ['usmkg', 7, 'Muskegon'],
  ['uanlv', 7, 'Mykolaiv'],
  ['brqrz', 7, 'Resende'],
  ['noalf', 7, 'Alta'],
  ['vnvca', 7, 'Can Tho'],
  ['aeauh', 7, 'Abu Dhabi'],
  ['inixy', 7, 'Kandla'],
  ['jptyo', 7, 'Tokyo'],
  ['casim', 7, 'Imperial'],
  ['dedui', 7, 'Duisburg'],
  ['cnsxj', 7, 'Shanshan'],
  ['fivaa', 7, 'Vasa (Vaasa)'],
  ['nobjf', 7, 'Batsfjord'],
  ['itreg', 7, 'Reggio di Calabria'],
  ['awbus', 7, 'Bushiribana'],
  ['glgoh', 7, 'Nuuk (Godthaab)'],
  ['deflf', 7, 'Flensburg'],
  ['instv', 7, 'Surat'],
  ['usotm', 7, 'Ottumwa'],
  ['krcha', 7, 'Jeju'],
  ['cmgou', 7, 'Garoua'],
  ['tntun', 7, 'Tunis'],
  ['nznpl', 7, 'New Plymouth'],
  ['clwca', 7, 'Castro'],
  ['castv', 7, 'Stephenville'],
  ['clzal', 7, 'Valdivia'],
  ['dzalg', 7, 'Alger (Algiers)'],
  ['arros', 7, 'Rosario'],
  ['itrmi', 7, 'Rimini'],
  ['clcrr', 7, 'Corral'],
  ['cumar', 7, 'Mariel'],
  ['novao', 7, 'Vardo'],
  ['aeshj', 7, 'Sharjah'],
  ['botdd', 7, 'Trinidad'],
  ['frmzm', 7, 'Metz'],
  ['hrrjk', 7, 'Rijeka'],
  ['eeure', 7, 'Kuressaare'],
  ['irbuz', 7, 'Bushehr'],
  ['sesdl', 7, 'Sundsvall'],
  ['deaah', 7, 'Aachen'],
  ['gragt', 7, 'Agio Theodoroi'],
  ['gwbqe', 7, 'Bubaque'],
  ['ussan', 7, 'San Diego'],
  ['tredo', 7, 'Edremit'],
  ['usoxr', 7, 'Oxnard-Ventura Apt'],
  ['frleh', 7, 'Le Havre'],
  ['aukrb', 7, 'Karumba'],
  ['noosy', 7, 'Namsos'],
  ['nobgo', 7, 'Bergen'],
  ['itcag', 7, 'Cagliari'],
  ['uaods', 7, 'Odesa'],
  ['vnsgn', 7, 'Ho Chi Minh City'],
  ['mehno', 7, 'Hercegnovi'],
  ['usewr', 7, 'Newark'],
  ['fikem', 7, 'Kemi/Tornea (Kemi/Tornio)'],
  ['nlens', 7, 'Enschede'],
  ['cltlx', 7, 'Talca'],
  ['erasa', 7, 'Assab'],
  ['hrzad', 7, 'Zadar'],
  ['auadl', 7, 'Adelaide'],
  ['fimik', 7, 'Mikkeli (Sankt Michel)'],
  ['borib', 7, 'Riberalta'],
  ['idlah', 7, 'Labuha, Molucas'],
  ['tnsfa', 7, 'Sfax'],
  ['frmel', 7, 'Melun'],
  ['crsjo', 7, 'San Jose'],
  ['sernb', 7, 'Ronneby'],
  ['auhba', 7, 'Hobart'],
  ['nomjf', 7, 'Mosjoen'],
  ['zacpt', 7, 'Cape Town'],
  ['usric', 7, 'Richmond'],
  ['gpmsb', 7, 'Marigot'],
  ['caycb', 7, 'Cambridge Bay'],
  ['itgoa', 7, 'Genova'],
  ['frsxb', 7, 'Strasbourg'],
  ['trist', 7, 'Istanbul'],
  ['cnjrg', 7, 'Jurong'],
  ['iqnjf', 7, 'Najaf'],
  ['frlso', 7, "Les Sables-d'Olonne"],
  ['gfqkr', 7, 'Kourou'],
  ['usugn', 7, 'Waukegan'],
  ['mqfdf', 7, 'Fort-de-France'],
  ['cursl', 7, 'Sagua la Grande'],
  ['debrv', 7, 'Bremerhaven'],
  ['gbppw', 7, 'Papa Westray'],
  ['frvaf', 7, 'Valence'],
  ['becrl', 7, 'Charleroi'],
  ['eslcg', 7, 'La Coruna (A Coruna)'],
  ['noskn', 7, 'Stokmarknes'],
  ['uszsp', 7, 'Stephen'],
  ['usmes', 7, 'Minneapolis'],
  ['fijoe', 7, 'Joensuu'],
  ['thbkk', 7, 'Bangkok'],
  ['usjkf', 7, 'Jakolof Bay'],
  ['itpsr', 7, 'Pescara'],
  ['essdr', 7, 'Santander'],
  ['dewvn', 7, 'Wilhelmshaven'],
  ['brfen', 7, 'Fernando de Noronha'],
  ['dkcph', 7, 'Kobenhavn'],
  ['begne', 7, 'Gent (Ghent)'],
  ['pkorw', 7, 'Ormara'],
  ['caope', 7, 'Port Hope'],
  ['bebru', 7, 'Brussel (Bruxelles)'],
  ['clpuq', 7, 'Punta Arenas'],
  ['bopur', 7, 'Puerto Rico'],
  ['itjlo', 7, 'Iesolo'],
  ['frlio', 7, 'Lyon'],
  ['sekrf', 7, 'Kramfors'],
  ['sevst', 7, 'Vasteras'],
  ['inixm', 7, 'Madurai'],
  ['bgvid', 7, 'Vidin'],
  ['nofde', 7, 'Forde'],
  ['fioul', 7, 'Oulu (Uleaborg)'],
  ['mgnos', 7, 'Nosy-Be'],
  ['debon', 7, 'Bonn'],
  ['fikuo', 7, 'Kuopio'],
  ['irmrx', 7, 'Bandar-e Mah Shahr'],
  ['sekid', 7, 'Kristianstad'],
  ['idlsw', 7, 'Lhokseumawe'],
  ['clvap', 7, 'Valparaiso'],
  ['noaes', 7, 'Alesund'],
  ['usorf', 7, 'Norfolk'],
  ['derci', 7, 'Rechlin'],
  ['nzgmn', 7, 'Greymouth'],
  ['pkkhi', 7, 'Karachi'],
  ['uscge', 7, 'Cambridge'],
  ['copya', 7, 'Puerto Boyaca'],
  ['usspt', 7, 'South Saint Paul'],
  ['graxd', 7, 'Alexandroupolis'],
  ['aupis', 7, 'Phillip Island'],
  ['nlams', 7, 'Amsterdam'],
  ['ushlm', 7, 'Holland'],
  ['rumow', 7, 'Moskva'],
  ['inixj', 7, 'Jammu'],
  ['fijyv', 7, 'Jyvaskyla'],
  ['nztrg', 7, 'Tauranga'],
  ['yesye', 7, 'Sadah'],
  ['uscvg', 7, 'Cincinnati'],
  ['seoer', 7, 'Ornskoldsvik'],
  ['usgoh', 7, 'Goshen'],
  ['ussky', 7, 'Sandusky, Erie'],
  ['noboo', 7, 'Bodo'],
  ['ptlis', 7, 'Lisboa'],
  ['gfslm', 7, 'Saint-Laurent-du-Maroni'],
  ['trbdm', 7, 'Bandirma'],
  ['incok', 7, 'Cochin'],
  ['nlhov', 7, 'Hoogeveen'],
  ['defdh', 7, 'Friedrichshafen'],
  ['arsfn', 7, 'Santa Fe'],
  ['trtek', 7, 'Tekirdag'],
  ['nlwsc', 7, 'Winschoten'],
  ['sethn', 7, 'Trollhattan'],
  ['clzos', 7, 'Osorno'],
  ['ushif', 7, 'Hill Air Force Base'],
  ['dedtm', 7, 'Dortmund'],
  ['dekel', 7, 'Kiel'],
  ['fikem', 7, 'Kemi/Tornio (Kemi/Tornea)'],
  ['arsns', 7, 'San Nicolas de los Arroyos'],
  ['tnbiz', 7, 'Bizerte'],
  ['usnyc', 7, 'New York'],
  ['jpyok', 7, 'Yokohama'],
  ['usrkd', 7, 'Rockland'],
  ['nzwsz', 7, 'Westport'],
  ['ecocc', 7, 'Coca'],
  ['kygec', 7, 'Georgetown, Grand Cayman'],
  ['esvlc', 7, 'Valencia'],
  ['inpny', 7, 'Pondicherry'],
  ['mybki', 7, 'Kota Kinabalu, Sabah'],
  ['dewib', 7, 'Wiesbaden'],
  ['brjur', 7, 'Juruti'],
  ['frbes', 7, 'Brest'],
  ['nomqn', 7, 'Mo i Rana'],
  ['usmkc', 7, 'Kansas City'],
  ['lrmlw', 7, 'Monrovia'],
  ['noosl', 7, 'Oslo'],
  ['nohvg', 7, 'Honningsvag'],
  ['krpus', 7, 'Busan'],
  ['usmem', 7, 'Memphis'],
  ['uslui', 7, 'Louisville'],
  ['nzwlg', 7, 'Wellington'],
  ['usure', 7, 'Laurel'],
  ['bebru', 7, 'Bruxelles (Brussel)'],
  ['dehas', 7, 'Hassfurt'],
  ['zadur', 7, 'Durban'],
  ['ushou', 7, 'Houston'],
  ['mxepm', 7, 'Empalme'],
  ['fisvl', 7, 'Savonlinna (Nyslott)'],
  ['usjvl', 7, 'Janesville'],
  ['saynb', 7, 'Yanbu al-Bahr'],
  ['esmah', 7, 'Mahon, Menorca'],
  ['sbxya', 7, 'Yandina, Russell Island'],
  ['cania', 7, 'Niagara Falls'],
  ['usbro', 7, 'Brownsville'],
  ['brnvt', 7, 'Navegantes'],
  ['pgkrx', 7, 'Karkar Is'],
  ['egdam', 7, 'Dumyat (Damietta)'],
  ['nzakl', 7, 'Auckland'],
  ['espmi', 7, 'Palma de Mallorca'],
  ['filpp', 7, 'Villmanstrand (Lappeenranta)'],
  ['cafre', 7, 'Fredericton'],
  ['seklr', 7, 'Kalmar'],
  ['nonvk', 7, 'Narvik'],
  ['mtflo', 7, 'Floriana'],
  ['nofan', 7, 'Farsund'],
  ['deage', 7, 'Wangerooge'],
  ['gbboh', 7, 'Bournemouth'],
  ['mybwh', 7, 'Bagan Luar (Butterworth)'],
  ['ruyts', 7, 'Yartsevo'],
  ['usliz', 7, 'Limestone'],
  ['idcbn', 7, 'Cirebon (Tjeribon)'],
  ['usntd', 7, 'Port Hueneme'],
  ['cagan', 7, 'Gander'],
  ['brbps', 7, 'Porto Seguro'],
  ['brbvs', 7, 'Breves'],
  ['trizm', 7, 'Ä°zmir'],
  ['nzwag', 7, 'Wanganui'],
  ['idjkt', 7, 'Jakarta, Java'],
  ['noske', 7, 'Skien'],
  ['ussck', 7, 'Stockton'],
  ['sadmm', 7, 'Ad Dammam'],
  ['krchf', 7, 'Jinhae'],
  ['auper', 7, 'Perth'],
  ['mxver', 7, 'Veracruz'],
  ['lvlpx', 7, 'Liepaja'],
  ['usevv', 7, 'Evansville'],
  ['fimik', 7, 'Sankt Michel (Mikkeli)'],
  ['nosoj', 7, 'Sorkjosen'],
  ['vnvut', 7, 'Vung Tau'],
  ['mxtij', 7, 'Tijuana'],
  ['dkebj', 7, 'Esbjerg'],
  ['cayzs', 7, 'Coral Harbour'],
  ['frval', 7, 'Valenciennes'],
  ['usowb', 7, 'Owensboro'],
  ['usstp', 7, 'Saint Paul'],
  ['aumel', 7, 'Melbourne'],
  ['metiv', 7, 'Tivat'],
  ['iltlv', 7, 'Tel Aviv-Yafo'],
  ['nosvg', 7, 'Stavanger'],
  ['frbod', 7, 'Bordeaux'],
  ['fivrk', 7, 'Varkaus'],
  ['usrac', 7, 'Racine'],
  ['bgrdu', 7, 'Ruse'],
  ['mxcdd', 7, 'Ciudad Madero'],
  ['dkaal', 7, 'Aalborg'],
  ['vnuih', 7, 'Qui Nhon'],
  ['usbex', 7, 'Bethlehem'],
  ['krsel', 7, 'Seoul'],
  ['esbio', 7, 'Bilbao'],
  ['essvq', 7, 'Sevilla'],
  ['cntnj', 7, 'Tianjin'],
  ['bnbwn', 7, 'Bandar Seri Begawan'],
  ['gbsnw', 7, 'Saint Andrews'],
  ['cuhav', 7, 'La Habana'],
  ['paonx', 7, 'Colon'],
  ['zaels', 7, 'East London'],
  ['mylgk', 7, 'Langkawi'],
  ['tnsus', 7, 'Sousse'],
  ['grkva', 7, 'Kavala'],
  ['sehad', 7, 'Halmstad'],
  ['borbq', 7, 'Rurrenabaque'],
  ['irbkm', 7, 'Bandar Khomeini'],
  ['nlley', 7, 'Lelystad'],
  ['idbtw', 7, 'Batulicin'],
  ['canai', 7, 'Nain'],
  ['usdet', 7, 'Detroit'],
  ['usgqq', 7, 'Galion'],
  ['noksu', 7, 'Kristiansund'],
  ['pkpsi', 7, 'Pasni'],
  ['inmaa', 7, 'Chennai (ex Madras)'],
  ['krusn', 7, 'Ulsan'],
  ['nomeh', 7, 'Mehamn'],
  ['phges', 7, 'General Santos'],
  ['arbue', 7, 'Buenos Aires'],
  ['aondd', 7, 'Sumbe'],
  ['nokkn', 7, 'Kirkenes'],
  ['aubuc', 7, 'Burketown'],
  ['caydh', 7, 'Daniels Harbour'],
  ['uspah', 7, 'Paducah'],
  ['depef', 7, 'Peenemunde'],
  ['zajoh', 7, "Port Saint John's"],
  ['cnsgh', 7, 'Shanghai'],
  ['denod', 7, 'Norden'],
  ['frhon', 7, 'Honfleur'],
  ['denrd', 7, 'Norderney'],
  ['gbbrs', 7, 'Bristol'],
  ['uspie', 7, 'Saint Petersburg'],
  ['ptopo', 7, 'Porto'],
  ['nosvj', 7, 'Svolvar'],
  ['usstl', 7, 'Saint Louis'],
  ['uspef', 7, 'Port Everglades'],
  ['esala', 7, 'Villarreal de Alava'],
  ['mzmzb', 7, 'Mocimboa da Praia'],
  ['krkuv', 7, 'Gunsan'],
  ['firvn', 7, 'Rovaniemi'],
  ['dedus', 7, 'Dusseldorf'],
  ['cahop', 7, 'Hopedale'],
  ['krkpo', 7, 'Pohang'],
  ['esmln', 7, 'Melilla'],
  ['vcbqu', 7, 'Port Elizabeth, Bequia'],
  ['twtyn', 7, 'Taoyuan'],
  ['iddjj', 7, 'Jayapura, Irian Jaya'],
  ['uslgb', 7, 'Long Beach'],
  ['rupvs', 7, 'Provideniya, Bukhta'],
  ['clccp', 7, 'Concepcion'],
  ['eslpa', 7, 'Las Palmas de Gran Canaria'],
  ['descn', 7, 'Saarbrucken'],
  ['rumcx', 7, 'Makhachkala'],
  ['beanr', 7, 'Antwerpen'],
  ['cuqco', 7, 'Colon'],
  ['semma', 7, 'Malmo'],
  ['trayt', 7, 'Antalya'],
  ['ushnl', 7, 'Honolulu'],
  ['hrdbv', 7, 'Dubrovnik'],
  ['seldk', 7, 'Lidkoping'],
  ['nlrtm', 7, 'Rotterdam'],
  ['ruokt', 7, "Oktyabr'skiy"],
  ['paboc', 7, 'Bocas del Toro'],
  ['demsr', 7, 'Munster'],
  ['dkaar', 7, 'Aarhus'],
  ['esalc', 7, 'Alicante'],
  ['notrf', 7, 'Sandefjord'],
  ['cnnin', 7, 'Nanning'],
  ['idcxp', 7, 'Cilacap (Tjilatjap)'],
  ['ruaer', 7, 'Sochi'],
  ['gblba', 7, 'Leeds'],
  ['usinx', 7, 'Mosinee'],
  ['ptsie', 7, 'Sines'],
  ['ptpdl', 7, 'Ponta Delgada'],
  ['uscle', 7, 'Cleveland'],
  ['ieche', 7, 'Cahirciveen'],
  ['clkna', 7, 'Vina del Mar'],
  ['brslz', 7, 'Sao Luis'],
  ['iecfn', 7, 'Donegal'],
  ['nzthh', 7, 'Taharoa'],
  ['aumky', 7, 'Mackay'],
  ['ptfao', 7, 'Faro'],
  ['nostj', 7, 'Stjordal'],
  ['ptfnc', 7, 'Funchal, Madeira'],
  ['hrpuy', 7, 'Pula'],
  ['caarb', 7, 'Arctic Bay'],
  ['usgrb', 7, 'Green Bay'],
]
