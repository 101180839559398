import { Cell } from '../grid/Cell'
import { Feature } from '../grid/Feature'
import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const InfoModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="How to play" isOpen={isOpen} handleClose={handleClose}>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Guess the UNLOCODE in 6 tries.
      </p>
      <p>&nbsp;</p>
      <p className="font-bold text-sm text-gray-500 dark:text-gray-300">
        The solution will be a UNLOCODE flagged as having an Airport, Port{' '}
        <u>
          <i>and</i>
        </u>{' '}
        Road Terminal.
      </p>
      <p className="font-bold text-sm text-gray-500 dark:text-gray-300">
        Otherwise we'll be here forever. &#128514;
      </p>
      <p>&nbsp;</p>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        After each guess, the color of the tiles will change to show how close
        your guess was to the UNLOCODE.
      </p>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Whether the UNLOCODE has an Airport, Port, or Road Terminal is also
        shown.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="A" status="correct" />
        <Cell value="U" status="absent" />
        <Cell value="P" status="absent" />
        <Cell value="B" status="absent" />
        <Cell value="T" status="absent" />
        <Feature icon="plane" status="absent" />
        <Feature icon="ship" status="present" />
        <Feature icon="truck" status="present" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        The letter A is in the UNLOCODE and in the correct spot.
      </p>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        The location has a Port and Road Terminal, but has no Airport.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="C" status="absent" />
        <Cell value="N" status="absent" />
        <Cell value="S" status="present" />
        <Cell value="N" status="absent" />
        <Cell value="Z" status="absent" />
        <Feature icon="plane" status="correct" />
        <Feature icon="ship" status="correct" />
        <Feature icon="truck" status="correct" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        The letter S is in the UNLOCODE but in the wrong spot.
      </p>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        The location has an Airport, Port and Road Terminal.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="D" status="absent" />
        <Cell value="E" status="absent" />
        <Cell value="T" status="absent" />
        <Cell value="X" status="absent" />
        <Cell value="L" status="absent" />
        <Feature icon="plane" status="present" />
        <Feature icon="ship" status="absent" />
        <Feature icon="truck" status="absent" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        The letters D, E, T, X and L are not in the UNLOCODE.
      </p>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        The location has an Airport, but no Port or Road Terminal.
      </p>
    </BaseModal>
  )
}
