import { getGuessStatuses } from '../../lib/statuses'
import { Cell } from './Cell'
import { Features } from './Features'
import { getFeatures } from '../../lib/words'

type Props = {
  guess: string
  isRevealing?: boolean
}

export const CompletedRow = ({ guess, isRevealing }: Props) => {
  const statuses = getGuessStatuses(guess)
  const features = getFeatures(guess)

  return (
    <div className="flex justify-center mb-1">
      {guess.split('').map((letter, i) => (
        <Cell
          key={i}
          value={letter}
          status={statuses[i]}
          position={i}
          isRevealing={isRevealing}
          isCompleted
        />
      ))}
      <Features showEmpty={false} extraDetail={features} />
    </div>
  )
}
