import { FeatureStatus } from '../../lib/statuses'
import classnames from 'classnames'
import { REVEAL_TIME_MS } from '../../constants/settings'
import PlaneLineIcon from 'remixicon-react/PlaneLineIcon'
import ShipLineIcon from 'remixicon-react/ShipLineIcon'
import TruckLineIcon from 'remixicon-react/TruckLineIcon'

type Props = {
  value?: string
  status?: FeatureStatus
  isRevealing?: boolean
  isCompleted?: boolean
  position?: number
  icon: string
}

export const Feature = ({
  value,
  status,
  isRevealing,
  isCompleted,
  position = 1,
  icon,
}: Props) => {
  const isFilled = value && !isCompleted
  const shouldReveal = isRevealing && isCompleted
  const animationDelay = `${position * REVEAL_TIME_MS}ms`

  const classes = classnames(
    'w-14 h-14 border-solid border-2 flex items-center justify-center mx-0.5 text-4xl font-bold rounded dark:text-white',
    {
      'bg-white dark:bg-slate-900 border-slate-200 dark:border-slate-600 text-slate-300 dark:text-slate-700':
        !status,
      'border-black dark:border-slate-100': value && !status,
      'absent shadowed bg-slate-400 dark:bg-slate-700 text-white border-slate-400 dark:border-slate-700':
        status === 'absent',
      'correct shadowed bg-green-500 text-white border-green-500 dark:bg-green-500 dark:text-white dark:border-green-500':
        status === 'correct',
      'present shadowed bg-yellow-500 text-white border-yellow-500 dark:bg-yellow-500 dark:text-white dark:border-yellow-500':
        status === 'present',
      'cell-fill-animation': isFilled,
      'cell-reveal': shouldReveal,
    }
  )

  return (
    <div className={classes} style={{ animationDelay }}>
      <div className="letter-container" style={{ animationDelay }}>
        {icon === 'plane' && <PlaneLineIcon />}
        {icon === 'ship' && <ShipLineIcon />}
        {icon === 'truck' && <TruckLineIcon />}
      </div>
    </div>
  )
}
