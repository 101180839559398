export const VALID_GUESSES: [string, number][] = [
  ['adalv', 6],
  ['aeabu', 1],
  ['aeamu', 1],
  ['aearp', 1],
  ['aeajm', 3],
  ['aeaan', 4],
  ['aedhf', 6],
  ['aefjr', 5],
  ['aejaz', 1],
  ['aeajp', 3],
  ['aeruw', 1],
  ['aedas', 1],
  ['aedbp', 3],
  ['aedwc', 6],
  ['aeend', 1],
  ['aefat', 1],
  ['aefrp', 1],
  ['aehzp', 1],
  ['aehsn', 3],
  ['aehtl', 1],
  ['aejea', 1],
  ['aeqwe', 3],
  ['aejed', 1],
  ['aeklb', 3],
  ['aeklf', 1],
  ['aemkh', 3],
  ['aekhl', 1],
  ['aemrp', 1],
  ['aemsa', 1],
  ['aemzd', 1],
  ['aenhd', 4],
  ['aerfa', 1],
  ['aemub', 1],
  ['aembs', 1],
  ['aeamf', 1],
  ['aepra', 1],
  ['aerkp', 1],
  ['aermc', 1],
  ['aerkt', 5],
  ['aerwp', 1],
  ['aesid', 1],
  ['aeduj', 1],
  ['aeulr', 1],
  ['aeqiw', 5],
  ['aeyas', 1],
  ['aezur', 1],
  ['afbin', 6],
  ['afbst', 4],
  ['afcbn', 6],
  ['afccn', 4],
  ['afdaz', 4],
  ['afdhd', 1],
  ['affbd', 4],
  ['affah', 4],
  ['afgrg', 4],
  ['afgzi', 4],
  ['afhea', 4],
  ['afjaa', 4],
  ['afkbl', 6],
  ['afkdh', 6],
  ['afkht', 4],
  ['afkwh', 4],
  ['afund', 4],
  ['afkur', 4],
  ['afmmz', 4],
  ['afmzr', 4],
  ['afimz', 4],
  ['aflqn', 4],
  ['afqlt', 3],
  ['afsbf', 4],
  ['afsga', 4],
  ['aftqn', 4],
  ['aftii', 4],
  ['afrkh', 1],
  ['afurn', 4],
  ['afurz', 4],
  ['afzaj', 4],
  ['aganu', 1],
  ['agbbq', 1],
  ['agfam', 1],
  ['agphm', 1],
  ['agsjo', 1],
  ['aiaxa', 4],
  ['aiblp', 1],
  ['aimbb', 1],
  ['aisom', 1],
  ['aifor', 1],
  ['airoa', 1],
  ['aiwll', 5],
  ['albut', 1],
  ['aldrz', 1],
  ['alhmr', 1],
  ['alrom', 3],
  ['alsar', 1],
  ['alshg', 1],
  ['altia', 4],
  ['alvoa', 1],
  ['amlwn', 4],
  ['amjer', 1],
  ['amevn', 6],
  ['aoazz', 5],
  ['aoanl', 4],
  ['aobab', 1],
  ['aobdd', 1],
  ['aobdt', 1],
  ['aobug', 5],
  ['aocab', 5],
  ['aocff', 4],
  ['aocnz', 4],
  ['aoknp', 4],
  ['aocpo', 1],
  ['aocbt', 4],
  ['aocav', 4],
  ['aopgi', 4],
  ['aocui', 1],
  ['aocti', 4],
  ['aodal', 1],
  ['aodrc', 4],
  ['aodgr', 1],
  ['aodue', 4],
  ['aoetn', 1],
  ['aofby', 1],
  ['aoflt', 1],
  ['aogim', 1],
  ['aogir', 1],
  ['aogpo', 1],
  ['aonov', 4],
  ['aojmb', 4],
  ['aokti', 1],
  ['aosvp', 4],
  ['aokot', 1],
  ['aolaa', 1],
  ['aolie', 1],
  ['aolob', 1],
  ['aolbt', 1],
  ['aolad', 5],
  ['aoual', 4],
  ['aosdd', 4],
  ['aoluc', 3],
  ['aoluo', 4],
  ['aolbz', 4],
  ['aoggc', 4],
  ['aolzm', 4],
  ['aossy', 4],
  ['aomeg', 4],
  ['aospp', 4],
  ['aondf', 4],
  ['aonrq', 4],
  ['aoarz', 4],
  ['aonto', 1],
  ['aomsz', 5],
  ['aogxg', 4],
  ['aonza', 4],
  ['aonqi', 3],
  ['aongv', 4],
  ['aovpe', 4],
  ['aopat', 1],
  ['aople', 3],
  ['aopbn', 1],
  ['aopsa', 1],
  ['aorsn', 1],
  ['aovhc', 4],
  ['aostl', 1],
  ['aosza', 5],
  ['aosoq', 1],
  ['aotak', 1],
  ['aougo', 4],
  ['aoceo', 4],
  ['aoxgn', 4],
  ['aqaba', 1],
  ['aqarc', 1],
  ['aqart', 1],
  ['aqapt', 1],
  ['aqbhn', 1],
  ['aqcas', 1],
  ['aqcfz', 1],
  ['aqdav', 1],
  ['aqddu', 1],
  ['aqesc', 1],
  ['aqesp', 1],
  ['aqohg', 1],
  ['aqgwl', 1],
  ['aqhly', 1],
  ['aqjbs', 1],
  ['aqjcp', 1],
  ['aqjub', 1],
  ['aqksg', 1],
  ['aqkhn', 1],
  ['aqlaw', 1],
  ['aqmrb', 1],
  ['aqmzu', 1],
  ['aqmaw', 1],
  ['aqmcm', 5],
  ['aqmir', 1],
  ['aqorc', 1],
  ['aqplm', 1],
  ['aqpfs', 1],
  ['aqpro', 1],
  ['aqrot', 1],
  ['aqsmt', 1],
  ['aqsgn', 1],
  ['aqsyw', 1],
  ['aqtnm', 4],
  ['aqvky', 1],
  ['aqwsa', 1],
  ['aqzgn', 1],
  ['argeo', 1],
  ['ararr', 6],
  ['aracf', 3],
  ['araso', 1],
  ['arbhi', 5],
  ['arbqs', 1],
  ['arbvc', 1],
  ['arcsa', 1],
  ['arclc', 1],
  ['arcvi', 5],
  ['arcmp', 1],
  ['arcdl', 1],
  ['arcpg', 4],
  ['arctc', 4],
  ['arcvh', 4],
  ['arcrr', 4],
  ['arcnt', 4],
  ['archo', 1],
  ['arcbs', 1],
  ['archl', 1],
  ['arhos', 4],
  ['arclx', 4],
  ['arcry', 1],
  ['arcct', 4],
  ['aroln', 4],
  ['arcol', 1],
  ['arcrd', 5],
  ['arcou', 1],
  ['arcoc', 4],
  ['ariqo', 1],
  ['arcsz', 4],
  ['arcnq', 5],
  ['arcua', 1],
  ['aruzu', 4],
  ['arcut', 4],
  ['arcor', 4],
  ['ardgu', 1],
  ['ardme', 1],
  ['ardsu', 3],
  ['arehl', 6],
  ['arfte', 4],
  ['aremx', 4],
  ['arepa', 4],
  ['arelo', 4],
  ['arzai', 4],
  ['areqs', 4],
  ['arfma', 5],
  ['arfrt', 4],
  ['argpo', 4],
  ['argnr', 6],
  ['arvgs', 4],
  ['arggs', 4],
  ['aroya', 5],
  ['arghu', 5],
  ['ariby', 1],
  ['arigr', 4],
  ['arbui', 1],
  ['arigb', 4],
  ['arinw', 3],
  ['araep', 4],
  ['arjls', 1],
  ['arjsm', 4],
  ['arjuj', 4],
  ['arjni', 4],
  ['arlcm', 4],
  ['arlpm', 1],
  ['arlpg', 5],
  ['arirj', 4],
  ['aring', 4],
  ['arlhs', 4],
  ['arlls', 4],
  ['arlps', 3],
  ['arlav', 1],
  ['arxmx', 4],
  ['arlcp', 4],
  ['arlmd', 4],
  ['arlcu', 1],
  ['arluj', 3],
  ['arlzg', 1],
  ['arlgs', 4],
  ['armqd', 4],
  ['armdz', 4],
  ['armdx', 4],
  ['arrlo', 4],
  ['areze', 4],
  ['armjr', 4],
  ['arnos', 1],
  ['armcs', 4],
  ['armrn', 6],
  ['arxos', 4],
  ['arnec', 5],
  ['arnqn', 4],
  ['arovi', 3],
  ['arqlv', 4],
  ['arpp9', 3],
  ['arpra', 5],
  ['araol', 4],
  ['arpeh', 4],
  ['arpmq', 4],
  ['arxmv', 4],
  ['arpcy', 1],
  ['arqpq', 4],
  ['arpss', 5],
  ['arprq', 4],
  ['arpal', 1],
  ['arpbg', 1],
  ['arpub', 1],
  ['arpud', 5],
  ['arpel', 1],
  ['arpgv', 1],
  ['arroc', 1],
  ['arpla', 1],
  ['arpmy', 5],
  ['arpnl', 1],
  ['arprs', 1],
  ['arpwa', 1],
  ['arpco', 3],
  ['arplo', 1],
  ['arpme', 1],
  ['arpqu', 1],
  ['arqbr', 3],
  ['arqqn', 1],
  ['arram', 1],
  ['arrwo', 3],
  ['arrcq', 6],
  ['arrsf', 1],
  ['arres', 4],
  ['arrds', 4],
  ['arric', 1],
  ['arriv', 1],
  ['arrfo', 3],
  ['arrcu', 4],
  ['arrgl', 5],
  ['arrga', 5],
  ['arrhd', 4],
  ['arroy', 4],
  ['arryo', 4],
  ['arsla', 4],
  ['arsal', 1],
  ['arsae', 1],
  ['aroes', 4],
  ['arsca', 1],
  ['arbrc', 4],
  ['arsfe', 1],
  ['arsig', 1],
  ['aruaq', 4],
  ['arula', 5],
  ['arslo', 1],
  ['arluq', 6],
  ['arsma', 1],
  ['arsmr', 1],
  ['arcpc', 4],
  ['arspd', 3],
  ['arxpd', 5],
  ['arafa', 4],
  ['arssj', 3],
  ['arssn', 1],
  ['arsan', 1],
  ['arrza', 5],
  ['arrsa', 4],
  ['arsta', 1],
  ['arsst', 4],
  ['arsde', 4],
  ['arsgv', 4],
  ['arszq', 4],
  ['artdl', 4],
  ['arttg', 4],
  ['arrel', 4],
  ['artuc', 5],
  ['artun', 1],
  ['arurd', 3],
  ['arush', 5],
  ['arvcf', 4],
  ['arvlo', 3],
  ['arvdm', 4],
  ['arvcn', 3],
  ['arvtt', 1],
  ['arvdr', 4],
  ['arvlg', 4],
  ['arvhe', 1],
  ['arvmd', 1],
  ['arvme', 6],
  ['arapz', 4],
  ['arzae', 1],
  ['asfti', 4],
  ['asofu', 4],
  ['asppg', 5],
  ['astav', 4],
  ['atfg7', 1],
  ['atawh', 3],
  ['atatn', 3],
  ['atsxa', 3],
  ['atbfs', 3],
  ['atbrz', 3],
  ['atboe', 3],
  ['atdwa', 3],
  ['atdkz', 1],
  ['aterr', 1],
  ['atnnn', 1],
  ['atdra', 1],
  ['atdro', 3],
  ['atdue', 3],
  ['atebr', 3],
  ['atewd', 1],
  ['atbri', 1],
  ['atero', 3],
  ['atdfc', 3],
  ['aterl', 3],
  ['atepd', 3],
  ['atfsu', 3],
  ['atlkn', 1],
  ['atfhr', 3],
  ['atfna', 3],
  ['atfps', 3],
  ['atrtv', 1],
  ['atga6', 1],
  ['atgdn', 3],
  ['atgrz', 6],
  ['atgfs', 3],
  ['atgrn', 3],
  ['atswh', 3],
  ['atgsn', 3],
  ['atdof', 1],
  ['athkm', 3],
  ['atltx', 3],
  ['atghh', 1],
  ['atitn', 1],
  ['atten', 1],
  ['atbnn', 1],
  ['atein', 1],
  ['athoh', 4],
  ['athrh', 6],
  ['atinn', 6],
  ['atzin', 1],
  ['atjps', 1],
  ['atzlf', 1],
  ['atjdd', 1],
  ['atkam', 3],
  ['atktt', 3],
  ['atzrf', 1],
  ['atklu', 6],
  ['atkfd', 3],
  ['atkmb', 3],
  ['atghj', 1],
  ['atlzd', 3],
  ['atlua', 3],
  ['atlts', 1],
  ['atieg', 1],
  ['atlnz', 6],
  ['atman', 3],
  ['atmab', 3],
  ['atmka', 1],
  ['atmkc', 3],
  ['atmbs', 1],
  ['atnt9', 3],
  ['atngg', 1],
  ['atnfn', 3],
  ['atnad', 3],
  ['atnd2', 1],
  ['atnuz', 3],
  ['atobu', 1],
  ['atxae', 3],
  ['atggu', 1],
  ['atosh', 3],
  ['atatk', 3],
  ['atpbu', 3],
  ['atpet', 1],
  ['atpas', 3],
  ['at7pz', 3],
  ['atrad', 3],
  ['atrhn', 3],
  ['atreg', 1],
  ['atetz', 1],
  ['atxxx', 1],
  ['atrhr', 1],
  ['atrrf', 1],
  ['atszg', 4],
  ['atsak', 3],
  ['atant', 4],
  ['atsgs', 3],
  ['atsmr', 3],
  ['atsk3', 1],
  ['atfbz', 1],
  ['atsuh', 1],
  ['atsvm', 3],
  ['atttl', 1],
  ['atsag', 1],
  ['atsmk', 1],
  ['atsft', 1],
  ['atspg', 1],
  ['atspz', 3],
  ['atstd', 3],
  ['atsss', 1],
  ['atsus', 1],
  ['atst6', 1],
  ['attam', 1],
  ['attkp', 3],
  ['attfs', 3],
  ['atths', 3],
  ['attmr', 3],
  ['atvel', 3],
  ['atvdd', 4],
  ['aterm', 3],
  ['atwse', 3],
  ['atweu', 1],
  ['atwdf', 3],
  ['atvie', 4],
  ['atwul', 3],
  ['atzng', 1],
  ['atzrs', 4],
  ['auabp', 1],
  ['aualp', 3],
  ['auabg', 4],
  ['auada', 4],
  ['auagw', 4],
  ['auwsy', 4],
  ['auair', 1],
  ['aualh', 5],
  ['auabx', 6],
  ['auald', 1],
  ['auaxl', 4],
  ['auasp', 4],
  ['auabh', 4],
  ['auayd', 4],
  ['auawn', 4],
  ['auamt', 4],
  ['auabl', 4],
  ['aurcn', 4],
  ['auamx', 4],
  ['auamr', 1],
  ['auado', 4],
  ['auanz', 4],
  ['auann', 1],
  ['auayl', 4],
  ['auapp', 1],
  ['auaxc', 4],
  ['auary', 4],
  ['auard', 1],
  ['augyl', 4],
  ['auagy', 4],
  ['auaua', 1],
  ['auarm', 4],
  ['audlp', 3],
  ['auaab', 4],
  ['auash', 1],
  ['aup6d', 3],
  ['auatt', 1],
  ['aurse', 4],
  ['auags', 3],
  ['auaud', 4],
  ['auauu', 4],
  ['auawp', 4],
  ['auali', 3],
  ['auavg', 4],
  ['auavv', 4],
  ['auayq', 4],
  ['auayr', 4],
  ['aubdd', 4],
  ['aubae', 1],
  ['aubsj', 4],
  ['aulcn', 4],
  ['aubqw', 4],
  ['aubaa', 1],
  ['aubah', 1],
  ['aubnk', 5],
  ['aubal', 1],
  ['aubzd', 4],
  ['auabm', 4],
  ['aulme', 1],
  ['aubyx', 4],
  ['aubwu', 4],
  ['aubnn', 1],
  ['aubci', 4],
  ['aubkp', 4],
  ['aubwb', 5],
  ['aubar', 3],
  ['aubvw', 4],
  ['aubhs', 4],
  ['aubrt', 5],
  ['aubch', 1],
  ['aubee', 4],
  ['aubyp', 1],
  ['aubdw', 4],
  ['aubeu', 4],
  ['aubbl', 1],
  ['aubel', 3],
  ['aubeo', 4],
  ['aube9', 1],
  ['aubvd', 1],
  ['aubdg', 6],
  ['aubfd', 1],
  ['aubke', 1],
  ['aubrr', 1],
  ['aubtx', 4],
  ['aubvz', 4],
  ['aubic', 1],
  ['aubcz', 4],
  ['aubbe', 4],
  ['aubiw', 4],
  ['aublo', 6],
  ['aubbg', 1],
  ['aubvi', 4],
  ['aubzp', 4],
  ['aublp', 1],
  ['aubkq', 4],
  ['autts', 3],
  ['aubov', 1],
  ['aublt', 4],
  ['aubny', 3],
  ['aubfc', 4],
  ['aubob', 1],
  ['augic', 4],
  ['aubls', 4],
  ['aubck', 4],
  ['aubdy', 1],
  ['auboo', 3],
  ['aubod', 1],
  ['aubot', 1],
  ['auvib', 1],
  ['aubox', 4],
  ['aubtb', 1],
  ['aubql', 4],
  ['aubou', 1],
  ['aubrk', 4],
  ['auzbo', 5],
  ['auboy', 1],
  ['aubmp', 4],
  ['aubby', 3],
  ['aubwq', 4],
  ['aubol', 1],
  ['aubpo', 3],
  ['aubrj', 4],
  ['aubht', 4],
  ['aubsa', 1],
  ['aubra', 4],
  ['auwtb', 4],
  ['aubmw', 1],
  ['aunsb', 1],
  ['aubhq', 4],
  ['aubme', 5],
  ['aubtd', 4],
  ['aubuv', 1],
  ['aubip', 4],
  ['aubul', 3],
  ['aubll', 1],
  ['aubwi', 1],
  ['aubuy', 5],
  ['aubdb', 5],
  ['aubwt', 5],
  ['aubyy', 1],
  ['aubus', 1],
  ['aucgv', 4],
  ['aucns', 5],
  ['aucud', 4],
  ['aucal', 1],
  ['aucmb', 1],
  ['aucdu', 4],
  ['aucfi', 4],
  ['aucml', 4],
  ['aucmp', 1],
  ['aucby', 4],
  ['aucap', 3],
  ['aucbi', 4],
  ['auccu', 1],
  ['audon', 1],
  ['aucqp', 1],
  ['auclt', 1],
  ['aucln', 1],
  ['aucpn', 1],
  ['aucbo', 1],
  ['aucar', 1],
  ['auclf', 1],
  ['aucry', 4],
  ['aucvq', 5],
  ['aucfp', 4],
  ['aucsi', 4],
  ['auchb', 1],
  ['auctr', 4],
  ['aucun', 1],
  ['aucvs', 1],
  ['auced', 4],
  ['auces', 4],
  ['auchv', 1],
  ['auctl', 4],
  ['aucxt', 4],
  ['aucha', 3],
  ['auche', 1],
  ['aucbc', 4],
  ['aucrh', 4],
  ['aullg', 4],
  ['auccl', 4],
  ['aucn6', 3],
  ['aucht', 1],
  ['aucxq', 4],
  ['auscl', 1],
  ['auclm', 3],
  ['auclr', 1],
  ['aucdn', 1],
  ['aucmq', 4],
  ['aucvc', 4],
  ['aucvn', 3],
  ['aucfh', 4],
  ['aucnj', 4],
  ['aucvl', 1],
  ['auczy', 4],
  ['aucaz', 4],
  ['aucti', 1],
  ['aucnc', 4],
  ['aucuq', 4],
  ['aucfs', 5],
  ['auxco', 4],
  ['aucst', 1],
  ['aucll', 1],
  ['aucrb', 4],
  ['aucie', 4],
  ['aukce', 4],
  ['aucmo', 1],
  ['aucde', 3],
  ['aucbx', 4],
  ['aucpd', 4],
  ['aucda', 4],
  ['auctn', 5],
  ['auclh', 4],
  ['aucoy', 4],
  ['aucob', 4],
  ['aucou', 1],
  ['aucom', 4],
  ['aucoo', 3],
  ['aucoj', 4],
  ['aucnb', 4],
  ['aucnw', 1],
  ['aucrj', 4],
  ['aucpr', 1],
  ['aucmd', 4],
  ['aucrl', 1],
  ['aucrs', 5],
  ['auodl', 4],
  ['aucba', 1],
  ['aucrv', 1],
  ['aucww', 4],
  ['aucyg', 4],
  ['aucop', 1],
  ['aucte', 1],
  ['auctt', 1],
  ['audil', 1],
  ['aucow', 1],
  ['aucwr', 4],
  ['auccw', 4],
  ['aucwt', 4],
  ['aucrf', 1],
  ['aucsd', 4],
  ['aucre', 1],
  ['aucki', 4],
  ['auc8r', 1],
  ['aucru', 1],
  ['aucdq', 4],
  ['aucug', 4],
  ['aucuy', 4],
  ['aucma', 4],
  ['audby', 4],
  ['audit', 1],
  ['audal', 1],
  ['audvr', 4],
  ['audyw', 4],
  ['audam', 1],
  ['audar', 1],
  ['aunlf', 4],
  ['audaa', 1],
  ['audaj', 4],
  ['audvp', 4],
  ['auddi', 4],
  ['audlv', 4],
  ['auddn', 4],
  ['audnm', 4],
  ['audnq', 4],
  ['audrb', 5],
  ['audpo', 5],
  ['audhi', 1],
  ['audym', 4],
  ['audia', 1],
  ['audrn', 4],
  ['audxd', 4],
  ['audkv', 4],
  ['audox', 4],
  ['audmd', 4],
  ['audrd', 4],
  ['audfp', 4],
  ['audry', 1],
  ['audbo', 4],
  ['audlk', 4],
  ['audnb', 4],
  ['audki', 4],
  ['audhd', 4],
  ['audrr', 4],
  ['audya', 4],
  ['auech', 4],
  ['auqdn', 5],
  ['auede', 1],
  ['auebh', 4],
  ['auedb', 1],
  ['auedr', 4],
  ['aueih', 4],
  ['auelc', 4],
  ['auele', 1],
  ['auelb', 1],
  ['auekd', 4],
  ['auelt', 1],
  ['auemd', 4],
  ['auenb', 4],
  ['auedd', 4],
  ['auerb', 4],
  ['auepr', 5],
  ['aumeb', 4],
  ['auetd', 4],
  ['aueuc', 4],
  ['auevd', 4],
  ['auevh', 4],
  ['auevl', 3],
  ['aupex', 1],
  ['auexm', 5],
  ['aufbn', 4],
  ['auflc', 4],
  ['aufin', 1],
  ['aufik', 4],
  ['aufly', 4],
  ['aufis', 3],
  ['aufls', 4],
  ['aufln', 1],
  ['aufrb', 4],
  ['aufos', 4],
  ['aufot', 4],
  ['aufsl', 4],
  ['aufld', 1],
  ['aufre', 5],
  ['aufwt', 1],
  ['augbp', 4],
  ['augbt', 3],
  ['augpn', 4],
  ['augsc', 4],
  ['augah', 4],
  ['augex', 5],
  ['augem', 1],
  ['augee', 5],
  ['augtt', 4],
  ['auget', 5],
  ['augbv', 4],
  ['auglt', 5],
  ['augll', 3],
  ['augli', 4],
  ['auglg', 4],
  ['auglm', 4],
  ['auool', 4],
  ['augly', 4],
  ['augoi', 1],
  ['augoo', 4],
  ['augor', 1],
  ['augdd', 4],
  ['augos', 4],
  ['augul', 4],
  ['augbl', 4],
  ['augov', 5],
  ['augoh', 1],
  ['augfn', 4],
  ['augts', 4],
  ['augth', 3],
  ['augrb', 1],
  ['augkl', 4],
  ['augtw', 1],
  ['augrh', 1],
  ['augrk', 1],
  ['augvp', 4],
  ['auggd', 4],
  ['augfe', 4],
  ['augrv', 1],
  ['augff', 4],
  ['augte', 5],
  ['augru', 1],
  ['augum', 1],
  ['auguh', 4],
  ['augyp', 4],
  ['auhah', 1],
  ['auhtt', 1],
  ['auhcq', 4],
  ['auhlt', 4],
  ['auhti', 4],
  ['auham', 1],
  ['auhwd', 1],
  ['auhpp', 1],
  ['auhyi', 1],
  ['auhas', 1],
  ['auhwk', 4],
  ['auhxx', 4],
  ['auhpt', 1],
  ['auhis', 4],
  ['auhip', 4],
  ['auhth', 1],
  ['auhbe', 1],
  ['auhat', 4],
  ['auhlv', 4],
  ['auhry', 4],
  ['auhfr', 1],
  ['auhmg', 4],
  ['auhrn', 4],
  ['auhvb', 4],
  ['auhig', 4],
  ['auhll', 4],
  ['auhnk', 5],
  ['auhdd', 1],
  ['auhih', 4],
  ['auhok', 4],
  ['auhot', 1],
  ['auhpe', 4],
  ['auhtu', 4],
  ['auhid', 4],
  ['auhsm', 4],
  ['auhgd', 4],
  ['auhub', 4],
  ['auiff', 4],
  ['auidk', 4],
  ['auigh', 4],
  ['auigf', 1],
  ['auinj', 4],
  ['auikp', 4],
  ['auinm', 4],
  ['auifl', 4],
  ['auivr', 4],
  ['auivw', 4],
  ['auips', 1],
  ['auiry', 1],
  ['auisi', 4],
  ['aujab', 4],
  ['aujov', 1],
  ['aujad', 4],
  ['aujar', 1],
  ['aujck', 4],
  ['aujun', 4],
  ['aujur', 4],
  ['aukax', 4],
  ['aukgi', 4],
  ['aukfg', 4],
  ['aukpp', 4],
  ['auubu', 4],
  ['aukds', 4],
  ['aukdb', 4],
  ['aukml', 4],
  ['aukan', 1],
  ['aukta', 4],
  ['aukni', 4],
  ['auktr', 5],
  ['aukay', 1],
  ['aukei', 1],
  ['aukps', 4],
  ['aukng', 1],
  ['aukra', 4],
  ['aukey', 1],
  ['aukbd', 4],
  ['aukib', 1],
  ['aukns', 4],
  ['aukgy', 4],
  ['aukbj', 4],
  ['aukcs', 4],
  ['aukgc', 5],
  ['augty', 1],
  ['aukit', 1],
  ['aukbb', 4],
  ['auklp', 1],
  ['aukoi', 1],
  ['aukoh', 4],
  ['aukkp', 4],
  ['aukqb', 4],
  ['aukwm', 4],
  ['aukug', 4],
  ['aukgr', 4],
  ['auknx', 4],
  ['aukur', 1],
  ['aukrd', 4],
  ['aukwi', 1],
  ['aulab', 1],
  ['aulyt', 4],
  ['aulel', 4],
  ['aulmq', 3],
  ['aulnh', 4],
  ['aulfp', 4],
  ['aulkd', 4],
  ['aulen', 3],
  ['aulan', 1],
  ['auldw', 4],
  ['aultb', 4],
  ['aulst', 5],
  ['auluu', 4],
  ['aulut', 4],
  ['aujml', 1],
  ['aulvo', 4],
  ['aulwh', 4],
  ['aulea', 4],
  ['aulgt', 1],
  ['aulgh', 4],
  ['auler', 4],
  ['aulno', 4],
  ['aulhg', 4],
  ['aulyd', 3],
  ['aulib', 4],
  ['aullp', 4],
  ['auldc', 4],
  ['aulow', 3],
  ['aulsy', 4],
  ['aulll', 4],
  ['aulzr', 4],
  ['auloc', 4],
  ['auirg', 4],
  ['auhap', 4],
  ['aulre', 4],
  ['audfe', 1],
  ['auldh', 5],
  ['auloa', 4],
  ['aultv', 4],
  ['auluc', 1],
  ['aulub', 1],
  ['aultp', 4],
  ['auubb', 4],
  ['aumnw', 4],
  ['aumvh', 4],
  ['aumhc', 4],
  ['aumqi', 1],
  ['aumrk', 1],
  ['aumiz', 4],
  ['aumtl', 4],
  ['auxmc', 4],
  ['aumqa', 4],
  ['aumng', 4],
  ['aumjp', 4],
  ['aumfp', 4],
  ['aumbb', 4],
  ['aumrg', 4],
  ['aumgv', 4],
  ['aumby', 1],
  ['aumxd', 4],
  ['aumrp', 4],
  ['aumcy', 4],
  ['aumqe', 4],
  ['aurre', 4],
  ['aumar', 3],
  ['aumbh', 5],
  ['ausad', 1],
  ['aukll', 1],
  ['aumcv', 4],
  ['aumkr', 4],
  ['aukah', 4],
  ['aumrs', 1],
  ['aumli', 1],
  ['aumim', 4],
  ['aumlv', 4],
  ['aurty', 4],
  ['aummm', 4],
  ['aumid', 3],
  ['aumql', 4],
  ['aumgt', 4],
  ['aumlr', 4],
  ['aumib', 1],
  ['aumil', 3],
  ['aumrl', 4],
  ['auxml', 4],
  ['aumin', 4],
  ['aumwy', 4],
  ['aumtq', 4],
  ['aumtp', 6],
  ['aumih', 4],
  ['aumxq', 4],
  ['aumiy', 4],
  ['aumjk', 4],
  ['auonr', 4],
  ['aumon', 1],
  ['aumnq', 4],
  ['aumwt', 4],
  ['aumll', 3],
  ['aumoo', 4],
  ['aumnp', 6],
  ['aumbw', 4],
  ['auoor', 4],
  ['aumok', 3],
  ['aumov', 4],
  ['aumwb', 4],
  ['aumdc', 3],
  ['aumrz', 4],
  ['aumet', 4],
  ['auoxy', 4],
  ['auong', 4],
  ['aumrt', 4],
  ['aumya', 4],
  ['aumbn', 4],
  ['aumbf', 4],
  ['aumkv', 4],
  ['aumfl', 4],
  ['aumgb', 6],
  ['aumge', 1],
  ['augsn', 4],
  ['aumhw', 1],
  ['aumhu', 4],
  ['aumho', 4],
  ['auisa', 4],
  ['auwme', 4],
  ['aummg', 6],
  ['aumtd', 4],
  ['aumsf', 4],
  ['aumnv', 4],
  ['aumou', 1],
  ['aumow', 3],
  ['aumuq', 4],
  ['audge', 4],
  ['aumup', 4],
  ['aumvk', 4],
  ['aumxu', 4],
  ['aumwa', 3],
  ['aumne', 4],
  ['aumyi', 4],
  ['aumvu', 4],
  ['auutb', 4],
  ['aumyo', 4],
  ['aunbr', 4],
  ['aunbh', 4],
  ['aunmr', 4],
  ['aunpp', 4],
  ['aunac', 4],
  ['aunaa', 4],
  ['aunra', 4],
  ['aunrg', 4],
  ['auneq', 3],
  ['aunmp', 4],
  ['aunlk', 3],
  ['auntl', 5],
  ['auzne', 4],
  ['aunry', 4],
  ['auvic', 3],
  ['aungh', 1],
  ['aurpm', 4],
  ['aunhu', 1],
  ['aunls', 4],
  ['aunif', 4],
  ['auniv', 1],
  ['aunkb', 4],
  ['aunsa', 4],
  ['aunsv', 4],
  ['auntn', 4],
  ['aunsm', 4],
  ['aunb2', 1],
  ['aunte', 1],
  ['aunoa', 4],
  ['aunll', 4],
  ['aunur', 4],
  ['aunub', 4],
  ['auutd', 4],
  ['aunyn', 4],
  ['auokf', 1],
  ['auoky', 4],
  ['auoba', 4],
  ['auopi', 4],
  ['auolp', 4],
  ['auodd', 4],
  ['auoag', 4],
  ['aurbs', 4],
  ['auokb', 4],
  ['auodr', 4],
  ['auoxo', 4],
  ['auors', 4],
  ['auosb', 1],
  ['auoyn', 4],
  ['aupag', 1],
  ['audsw', 3],
  ['aupmk', 5],
  ['aupal', 1],
  ['aupde', 4],
  ['aupbo', 4],
  ['auprd', 4],
  ['aupke', 4],
  ['aupdn', 4],
  ['aupce', 4],
  ['aupel', 3],
  ['aupen', 1],
  ['aupea', 4],
  ['aupey', 4],
  ['aupep', 4],
  ['aupic', 1],
  ['aupyt', 1],
  ['auphp', 1],
  ['aupsm', 1],
  ['aupwi', 1],
  ['aupae', 1],
  ['auptl', 1],
  ['aupau', 1],
  ['aupug', 5],
  ['aupby', 1],
  ['aupbt', 3],
  ['aupct', 1],
  ['auptd', 1],
  ['aupdi', 3],
  ['aupti', 5],
  ['aufry', 1],
  ['aupgi', 1],
  ['aut3p', 3],
  ['auphe', 5],
  ['auphj', 4],
  ['auphu', 1],
  ['aupjk', 3],
  ['aupkt', 4],
  ['aupkl', 5],
  ['aupky', 1],
  ['aupla', 1],
  ['auplo', 5],
  ['aupqq', 5],
  ['aupor', 1],
  ['aupbn', 3],
  ['auppi', 5],
  ['aupsy', 1],
  ['aupst', 1],
  ['aupte', 4],
  ['aupwl', 1],
  ['aupwr', 1],
  ['auptw', 1],
  ['auptj', 5],
  ['auphr', 3],
  ['auprc', 1],
  ['auprb', 1],
  ['auppp', 4],
  ['aupye', 1],
  ['aupyr', 3],
  ['auuee', 4],
  ['auulp', 4],
  ['auuir', 4],
  ['audcn', 4],
  ['auraf', 1],
  ['auram', 4],
  ['aurab', 1],
  ['aurmk', 4],
  ['aurev', 3],
  ['aurll', 1],
  ['aurds', 3],
  ['aurch', 4],
  ['aurcm', 5],
  ['aurdn', 3],
  ['aurob', 1],
  ['auroh', 4],
  ['aurrv', 4],
  ['aurbc', 4],
  ['aurok', 5],
  ['aurda', 4],
  ['auryp', 1],
  ['aurbu', 5],
  ['aurky', 4],
  ['aurma', 4],
  ['aurpb', 4],
  ['aurpv', 4],
  ['aursb', 4],
  ['aurlp', 4],
  ['aurnb', 3],
  ['aurts', 5],
  ['aurhl', 4],
  ['auroz', 3],
  ['aurtp', 4],
  ['ausbr', 4],
  ['ausgo', 4],
  ['auhls', 4],
  ['ausvm', 4],
  ['ausmt', 1],
  ['ausxe', 4],
  ['ausgt', 1],
  ['ausrm', 4],
  ['aunds', 4],
  ['aunso', 4],
  ['auscy', 3],
  ['au5ci', 1],
  ['aussh', 1],
  ['ausmr', 1],
  ['aushb', 1],
  ['auswb', 4],
  ['ausgp', 4],
  ['ausol', 1],
  ['ausht', 6],
  ['aujhq', 4],
  ['aussp', 4],
  ['ausix', 4],
  ['auska', 1],
  ['auskv', 1],
  ['ausky', 1],
  ['aushu', 4],
  ['ausio', 4],
  ['ausnb', 4],
  ['ausbn', 3],
  ['auzgl', 4],
  ['ausoi', 4],
  ['austr', 3],
  ['auswr', 1],
  ['ausqc', 4],
  ['aushq', 4],
  ['auspo', 3],
  ['auspb', 1],
  ['auscg', 4],
  ['auksv', 4],
  ['auzvg', 4],
  ['austg', 1],
  ['austa', 1],
  ['ausnh', 4],
  ['ausqp', 4],
  ['auswc', 4],
  ['austf', 4],
  ['ausrr', 4],
  ['ausrn', 5],
  ['austh', 4],
  ['aukby', 4],
  ['aussk', 4],
  ['ausyu', 4],
  ['ausfp', 4],
  ['ausuh', 3],
  ['auswh', 4],
  ['ausyl', 1],
  ['autbl', 4],
  ['autmw', 4],
  ['autxr', 4],
  ['autan', 4],
  ['auxtr', 4],
  ['autaq', 4],
  ['autro', 4],
  ['auxto', 4],
  ['audga', 1],
  ['autef', 4],
  ['autem', 6],
  ['autca', 4],
  ['autwn', 4],
  ['authg', 4],
  ['auxtg', 4],
  ['augur', 1],
  ['autdr', 4],
  ['authe', 1],
  ['aucth', 1],
  ['autis', 5],
  ['autyg', 4],
  ['autyb', 4],
  ['autbk', 4],
  ['autin', 4],
  ['autyp', 4],
  ['autcw', 4],
  ['autpr', 4],
  ['autol', 1],
  ['autrd', 3],
  ['autwb', 4],
  ['autor', 1],
  ['aulku', 1],
  ['autwp', 4],
  ['autob', 1],
  ['autsv', 5],
  ['autgn', 4],
  ['autrb', 1],
  ['autri', 5],
  ['autum', 4],
  ['autky', 4],
  ['auths', 3],
  ['auda5', 3],
  ['auula', 1],
  ['auult', 3],
  ['auumb', 1],
  ['auuda', 4],
  ['auurn', 1],
  ['auusl', 1],
  ['auvgh', 1],
  ['auvnr', 4],
  ['auvar', 1],
  ['auvcd', 4],
  ['auwga', 6],
  ['auwlc', 4],
  ['auwge', 4],
  ['auwla', 4],
  ['auwal', 1],
  ['auwnt', 1],
  ['auwgt', 4],
  ['auwsn', 3],
  ['auwkb', 4],
  ['auwrw', 4],
  ['auwmb', 5],
  ['auwaz', 5],
  ['auwlo', 4],
  ['auwtw', 1],
  ['auwau', 4],
  ['auwav', 4],
  ['auwan', 4],
  ['auwtn', 3],
  ['auwew', 4],
  ['auwei', 5],
  ['aukjh', 1],
  ['auwwp', 1],
  ['auwrb', 3],
  ['auwwy', 4],
  ['auwsj', 6],
  ['auwep', 1],
  ['auwsi', 1],
  ['auwya', 5],
  ['auwio', 4],
  ['auwlm', 4],
  ['auwun', 4],
  ['auwnd', 4],
  ['auwnr', 4],
  ['auwih', 1],
  ['auwin', 4],
  ['auvit', 1],
  ['auwnb', 3],
  ['auwit', 6],
  ['auwll', 4],
  ['auwol', 4],
  ['auwdi', 4],
  ['auwon', 4],
  ['auwot', 1],
  ['auwog', 4],
  ['auwwi', 4],
  ['auwoo', 3],
  ['auwbt', 1],
  ['auumr', 4],
  ['auwpk', 4],
  ['auwud', 4],
  ['auwyn', 5],
  ['auwny', 4],
  ['aukyi', 4],
  ['auylg', 4],
  ['auxmy', 4],
  ['auyba', 1],
  ['auyam', 1],
  ['auynn', 4],
  ['aukyb', 4],
  ['auyrr', 1],
  ['aukyf', 4],
  ['auokr', 4],
  ['auorr', 4],
  ['aunga', 4],
  ['auyue', 4],
  ['awaua', 5],
  ['awbar', 1],
  ['awdeu', 1],
  ['aworj', 1],
  ['awsnl', 1],
  ['axmhq', 5],
  ['axmhq', 5],
  ['azbak', 5],
  ['azkvd', 4],
  ['azgyd', 4],
  ['azkmz', 1],
  ['azqdg', 3],
  ['azshi', 3],
  ['azsuq', 3],
  ['babnx', 6],
  ['babir', 3],
  ['ba4ca', 3],
  ['badoj', 1],
  ['bafoj', 1],
  ['bajba', 1],
  ['bamag', 3],
  ['baomo', 4],
  ['banem', 1],
  ['basmt', 1],
  ['basjj', 6],
  ['batzl', 6],
  ['bavgs', 1],
  ['baznk', 3],
  ['bbbgi', 5],
  ['bbstm', 1],
  ['bdasj', 1],
  ['bdbzl', 5],
  ['bdbnp', 1],
  ['bdchl', 1],
  ['bdcgp', 5],
  ['bdcxb', 4],
  ['bdcla', 4],
  ['bddac', 4],
  ['bdird', 4],
  ['bdjsr', 4],
  ['bdkhl', 1],
  ['bdmgl', 1],
  ['bdmun', 1],
  ['bdnar', 3],
  ['bdpgn', 3],
  ['bdpay', 1],
  ['bdrjh', 4],
  ['bdrau', 4],
  ['bdspd', 4],
  ['bdsdw', 4],
  ['bdzhm', 4],
  ['bdsaj', 4],
  ['bdzyl', 4],
  ['bdtkr', 4],
  ['beaab', 3],
  ['beaal', 3],
  ['beadk', 3],
  ['beafn', 3],
  ['beabk', 1],
  ['beamy', 3],
  ['beaps', 3],
  ['beand', 3],
  ['beanb', 3],
  ['beanh', 3],
  ['beave', 3],
  ['beasr', 3],
  ['beahe', 3],
  ['beato', 3],
  ['beavg', 3],
  ['beapl', 3],
  ['beapt', 3],
  ['beaej', 3],
  ['beagt', 3],
  ['beasp', 3],
  ['beast', 3],
  ['beath', 3],
  ['be7pd', 3],
  ['beode', 3],
  ['beavs', 3],
  ['beavl', 3],
  ['bew5r', 3],
  ['bebsr', 3],
  ['bebai', 1],
  ['beble', 3],
  ['bebgk', 3],
  ['beboa', 3],
  ['bebat', 3],
  ['bebdu', 3],
  ['bebaz', 3],
  ['bebrm', 3],
  ['beber', 3],
  ['bebcr', 3],
  ['beblo', 3],
  ['beban', 3],
  ['bebcm', 3],
  ['bebrt', 3],
  ['berin', 3],
  ['bebnu', 3],
  ['bebtx', 3],
  ['bebve', 3],
  ['bebvo', 3],
  ['bebse', 3],
  ['bebbg', 3],
  ['bewht', 3],
  ['beblt', 3],
  ['bebhr', 3],
  ['beboc', 3],
  ['bebhe', 3],
  ['bebom', 3],
  ['beboo', 3],
  ['bebmb', 3],
  ['bebon', 3],
  ['bebsu', 3],
  ['bebo2', 3],
  ['bebvm', 3],
  ['bebre', 3],
  ['bebgn', 3],
  ['bebgo', 4],
  ['bebgs', 3],
  ['bebry', 3],
  ['bebhu', 3],
  ['bebud', 3],
  ['becln', 3],
  ['bekan', 3],
  ['becra', 3],
  ['becrq', 3],
  ['becta', 3],
  ['bectl', 3],
  ['becbq', 3],
  ['becom', 3],
  ['becou', 3],
  ['beccs', 3],
  ['bedkn', 3],
  ['bedpy', 3],
  ['bedpe', 3],
  ['bedez', 3],
  ['bednb', 3],
  ['beiai', 3],
  ['bedrw', 3],
  ['beddr', 3],
  ['bedse', 3],
  ['bedsl', 3],
  ['bedeg', 3],
  ['bedxn', 3],
  ['bedpk', 3],
  ['bedik', 3],
  ['bedls', 3],
  ['bedia', 3],
  ['bedel', 3],
  ['betrn', 3],
  ['bedgn', 3],
  ['beduf', 3],
  ['be9ir', 3],
  ['beeek', 3],
  ['beebz', 3],
  ['beeht', 3],
  ['beein', 3],
  ['beesd', 3],
  ['beeqe', 3],
  ['beeke', 3],
  ['beeks', 3],
  ['beebm', 3],
  ['beeam', 3],
  ['beeng', 3],
  ['bereq', 3],
  ['beere', 3],
  ['beefs', 3],
  ['bespi', 3],
  ['beeqm', 3],
  ['beemp', 3],
  ['beevm', 3],
  ['befcs', 3],
  ['befey', 3],
  ['befwi', 3],
  ['beflo', 3],
  ['beffx', 3],
  ['befma', 3],
  ['befln', 3],
  ['befvt', 3],
  ['befrm', 3],
  ['befnr', 3],
  ['begvr', 3],
  ['begel', 3],
  ['beglk', 3],
  ['begbh', 3],
  ['begnk', 3],
  ['begbu', 3],
  ['begea', 3],
  ['beghl', 3],
  ['begdn', 3],
  ['begde', 3],
  ['begos', 3],
  ['beggm', 3],
  ['begrb', 3],
  ['begbb', 3],
  ['begro', 3],
  ['behct', 3],
  ['behfb', 3],
  ['behlu', 3],
  ['behmb', 3],
  ['behmm', 3],
  ['beham', 3],
  ['behna', 3],
  ['behbe', 3],
  ['behcs', 3],
  ['behej', 3],
  ['behar', 3],
  ['behss', 3],
  ['behpd', 3],
  ['behtj', 3],
  ['behre', 3],
  ['behee', 3],
  ['behdk', 3],
  ['behkn', 3],
  ['behex', 3],
  ['behns', 3],
  ['behyb', 3],
  ['behet', 3],
  ['beher', 3],
  ['behmh', 3],
  ['behtm', 3],
  ['behrs', 3],
  ['bettj', 3],
  ['behve', 3],
  ['behbn', 3],
  ['behsd', 3],
  ['behol', 3],
  ['behga', 3],
  ['behgg', 3],
  ['behrp', 3],
  ['behux', 3],
  ['bewah', 3],
  ['behum', 3],
  ['behuy', 3],
  ['beige', 3],
  ['beisr', 3],
  ['beitr', 3],
  ['beitr', 3],
  ['beyrt', 3],
  ['beizg', 3],
  ['bejam', 3],
  ['bejav', 3],
  ['bejps', 3],
  ['bejak', 3],
  ['bekct', 3],
  ['bekai', 3],
  ['bekou', 3],
  ['bekmh', 3],
  ['bekan', 3],
  ['bekpb', 3],
  ['bekle', 3],
  ['bekhn', 3],
  ['beksn', 3],
  ['bekst', 4],
  ['bekde', 3],
  ['bekro', 3],
  ['bekve', 3],
  ['beksr', 3],
  ['bekns', 3],
  ['bekno', 6],
  ['bekek', 3],
  ['bekrn', 3],
  ['bekuu', 3],
  ['bekwa', 3],
  ['berar', 3],
  ['bebsi', 3],
  ['beluz', 3],
  ['belan', 3],
  ['belny', 3],
  ['belgm', 3],
  ['belds', 3],
  ['belgb', 3],
  ['belgo', 3],
  ['belnk', 3],
  ['belqa', 3],
  ['belpi', 3],
  ['belaw', 3],
  ['berou', 3],
  ['belrg', 3],
  ['belfe', 3],
  ['belbe', 3],
  ['belpg', 3],
  ['beles', 3],
  ['beleu', 3],
  ['belhk', 3],
  ['belie', 3],
  ['belil', 3],
  ['belsg', 3],
  ['belix', 3],
  ['belgg', 5],
  ['belbs', 3],
  ['belok', 3],
  ['belml', 3],
  ['belot', 3],
  ['beloz', 3],
  ['belum', 3],
  ['belui', 3],
  ['bemsk', 3],
  ['bemmn', 3],
  ['bemff', 3],
  ['bemsr', 3],
  ['beman', 3],
  ['bewbr', 1],
  ['bemld', 3],
  ['bemap', 3],
  ['bemly', 1],
  ['bemhn', 3],
  ['bemby', 3],
  ['bemrg', 3],
  ['beech', 3],
  ['bemee', 3],
  ['bemeh', 3],
  ['bemgm', 3],
  ['bemeg', 3],
  ['bemel', 3],
  ['beels', 3],
  ['bemen', 3],
  ['bemrl', 3],
  ['bemrd', 3],
  ['bemrm', 3],
  ['bemrk', 3],
  ['bemsp', 3],
  ['bemno', 3],
  ['bemew', 3],
  ['bemrb', 3],
  ['bemzk', 3],
  ['bemgn', 3],
  ['bemlo', 3],
  ['bemsj', 3],
  ['bemss', 3],
  ['bemqs', 3],
  ['bemsi', 3],
  ['bemgs', 3],
  ['bemro', 3],
  ['bemui', 3],
  ['benam', 3],
  ['benmc', 3],
  ['benvh', 3],
  ['benha', 3],
  ['benrt', 3],
  ['benrp', 3],
  ['benfe', 3],
  ['benhy', 3],
  ['benvl', 3],
  ['benil', 3],
  ['benie', 3],
  ['benyl', 3],
  ['benba', 3],
  ['bennv', 3],
  ['benvn', 3],
  ['beobr', 3],
  ['beoel', 3],
  ['beosg', 3],
  ['beoev', 3],
  ['beoqc', 3],
  ['beokg', 3],
  ['beoln', 3],
  ['beogs', 3],
  ['beobt', 3],
  ['beozl', 3],
  ['beoog', 3],
  ['beost', 3],
  ['beotl', 3],
  ['beokp', 3],
  ['beotk', 3],
  ['beohn', 3],
  ['beopi', 3],
  ['beorr', 3],
  ['beost', 3],
  ['beoth', 3],
  ['beoud', 3],
  ['beobg', 3],
  ['beode', 3],
  ['beoug', 3],
  ['beopy', 3],
  ['beole', 3],
  ['beove', 3],
  ['bepal', 3],
  ['bepml', 3],
  ['bepcq', 3],
  ['beply', 3],
  ['beptp', 3],
  ['bepkh', 3],
  ['bepme', 3],
  ['beplo', 3],
  ['bepcl', 3],
  ['bepts', 3],
  ['bepfe', 3],
  ['bepuu', 3],
  ['bepes', 3],
  ['beplb', 3],
  ['berci', 3],
  ['bervs', 3],
  ['berbx', 3],
  ['berek', 3],
  ['berge', 3],
  ['benry', 3],
  ['berti', 3],
  ['bercl', 3],
  ['berie', 3],
  ['berij', 3],
  ['bexxx', 1],
  ['berve', 3],
  ['berjt', 1],
  ['berbe', 3],
  ['berha', 3],
  ['beroe', 3],
  ['berqs', 3],
  ['beros', 3],
  ['berct', 3],
  ['berox', 3],
  ['berue', 3],
  ['berui', 3],
  ['besvg', 3],
  ['berus', 3],
  ['berpm', 3],
  ['beghi', 3],
  ['besls', 3],
  ['besso', 3],
  ['bevit', 3],
  ['beslj', 3],
  ['bespe', 3],
  ['beshr', 3],
  ['beshl', 3],
  ['beshb', 3],
  ['besce', 3],
  ['besne', 3],
  ['besct', 3],
  ['besgx', 3],
  ['besly', 3],
  ['bessn', 3],
  ['beses', 3],
  ['besen', 3],
  ['bezer', 3],
  ['beska', 3],
  ['besas', 3],
  ['besan', 3],
  ['behsl', 3],
  ['bejkp', 3],
  ['bemsj', 3],
  ['besjg', 3],
  ['besjr', 3],
  ['besjo', 3],
  ['besln', 3],
  ['besml', 3],
  ['bessk', 3],
  ['bessb', 3],
  ['besdt', 3],
  ['bespi', 3],
  ['besbg', 3],
  ['besgm', 3],
  ['bestt', 3],
  ['besbe', 3],
  ['besdp', 3],
  ['besvn', 3],
  ['beskr', 3],
  ['betms', 3],
  ['betse', 3],
  ['betfn', 3],
  ['betdk', 3],
  ['betge', 3],
  ['betgn', 3],
  ['belny', 3],
  ['beter', 3],
  ['betet', 3],
  ['betes', 3],
  ['bethe', 3],
  ['betrg', 3],
  ['betob', 1],
  ['bethu', 3],
  ['betil', 3],
  ['bethg', 3],
  ['betlk', 3],
  ['betlr', 3],
  ['betis', 3],
  ['betgl', 3],
  ['betsl', 1],
  ['betrn', 3],
  ['betbe', 3],
  ['betur', 3],
  ['beuit', 3],
  ['bevsb', 3],
  ['bevlx', 3],
  ['bevwz', 3],
  ['bevbg', 3],
  ['beveu', 3],
  ['bevzi', 3],
  ['bevsl', 3],
  ['bevvi', 3],
  ['bevsh', 3],
  ['bevil', 3],
  ['bevgl', 3],
  ['bevhn', 3],
  ['bewdm', 3],
  ['bewaa', 3],
  ['bewlm', 3],
  ['bewnd', 3],
  ['bewan', 3],
  ['bewcg', 3],
  ['bewar', 3],
  ['bebwn', 3],
  ['bewlt', 3],
  ['bewel', 3],
  ['bewer', 3],
  ['bewet', 3],
  ['bewev', 3],
  ['bewih', 3],
  ['bewir', 3],
  ['bewgl', 3],
  ['bewjg', 3],
  ['bewlb', 3],
  ['bewee', 3],
  ['bewth', 3],
  ['bewdg', 3],
  ['bewlp', 3],
  ['bewip', 3],
  ['beyvo', 3],
  ['bezbr', 3],
  ['bezvt', 3],
  ['bezvd', 3],
  ['bezee', 3],
  ['bezle', 3],
  ['bezel', 3],
  ['bezet', 3],
  ['bezgm', 3],
  ['beobl', 6],
  ['bezod', 3],
  ['bezrm', 3],
  ['bezun', 3],
  ['bezul', 3],
  ['beztl', 3],
  ['bezun', 3],
  ['bezwe', 3],
  ['bezwn', 3],
  ['bezwl', 3],
  ['beeet', 1],
  ['bfabd', 4],
  ['bfarl', 4],
  ['bfbnr', 4],
  ['bfboy', 4],
  ['bfbog', 4],
  ['bfbou', 4],
  ['bfdip', 4],
  ['bfdbg', 4],
  ['bfdji', 4],
  ['bfdor', 4],
  ['bfdgu', 4],
  ['bffng', 4],
  ['bfgao', 4],
  ['bfgog', 4],
  ['bfkan', 4],
  ['bfkay', 4],
  ['bfleo', 4],
  ['bfnou', 4],
  ['bfoua', 4],
  ['bfoug', 4],
  ['bfpam', 4],
  ['bfpup', 4],
  ['bfseb', 4],
  ['bftmq', 4],
  ['bfteg', 4],
  ['bftuq', 4],
  ['bfzab', 4],
  ['bfob2', 3],
  ['bgakh', 1],
  ['bgbal', 1],
  ['bgboj', 1],
  ['bgvt4', 3],
  ['bgbsl', 3],
  ['bggoz', 4],
  ['bggur', 3],
  ['bghkv', 4],
  ['bg4ig', 3],
  ['bgjam', 4],
  ['bgkuk', 3],
  ['bgkdg', 6],
  ['bgmab', 1],
  ['bgnes', 3],
  ['bgnpo', 3],
  ['bgokh', 3],
  ['bgpvn', 4],
  ['bgpdv', 4],
  ['bgpod', 1],
  ['bgxxx', 1],
  ['bgsls', 5],
  ['bgsof', 4],
  ['bgsom', 1],
  ['bgsoz', 3],
  ['bgszr', 6],
  ['bgs3w', 3],
  ['bgsvi', 1],
  ['bgtgv', 6],
  ['bgvar', 5],
  ['bgvaz', 1],
  ['bhahd', 3],
  ['bhgbq', 1],
  ['bhbah', 4],
  ['bhisa', 4],
  ['bhkbs', 1],
  ['bhmin', 1],
  ['bhsit', 1],
  ['bibjm', 4],
  ['bicbk', 3],
  ['bigid', 4],
  ['bikre', 4],
  ['bjcoo', 5],
  ['bjdja', 4],
  ['bjkdc', 4],
  ['bjnae', 4],
  ['bjpko', 4],
  ['bjptn', 3],
  ['bjsvf', 4],
  ['bjsem', 1],
  ['blsbh', 5],
  ['bmsev', 1],
  ['bmfpt', 1],
  ['bmbda', 5],
  ['bmhbi', 1],
  ['bmkwf', 3],
  ['bmsge', 1],
  ['bmsan', 1],
  ['bmsou', 1],
  ['bnkub', 5],
  ['bnlum', 1],
  ['bnmua', 1],
  ['bnser', 1],
  ['bntas', 1],
  ['boapb', 6],
  ['boasc', 6],
  ['bobvl', 6],
  ['bobjo', 6],
  ['bocam', 6],
  ['bocij', 6],
  ['bocbb', 6],
  ['bocep', 6],
  ['bocpb', 3],
  ['boguq', 3],
  ['bogya', 4],
  ['bobvk', 6],
  ['bohtj', 3],
  ['bolpb', 6],
  ['bomgd', 6],
  ['bomhw', 4],
  ['booru', 6],
  ['bopoi', 6],
  ['bopbu', 3],
  ['boptc', 3],
  ['boppr', 3],
  ['boqjr', 3],
  ['bopsz', 5],
  ['borey', 6],
  ['borbo', 6],
  ['bosrj', 6],
  ['bosnm', 4],
  ['bosng', 6],
  ['bosjv', 6],
  ['bosjb', 6],
  ['bosjs', 6],
  ['bomqk', 4],
  ['bosrd', 6],
  ['bosbl', 6],
  ['bosrz', 6],
  ['bosrb', 4],
  ['bosre', 6],
  ['botja', 6],
  ['bovah', 6],
  ['bovlm', 6],
  ['bovvi', 4],
  ['bobyc', 6],
  ['bqbon', 5],
  ['bqrin', 3],
  ['bqgot', 1],
  ['bqkra', 1],
  ['bqsab', 5],
  ['bqeux', 5],
  ['bratb', 3],
  ['brabt', 3],
  ['bradl', 3],
  ['brags', 3],
  ['bralq', 4],
  ['bralt', 4],
  ['bralh', 1],
  ['bramj', 4],
  ['bralp', 3],
  ['brafl', 4],
  ['bratm', 4],
  ['brabv', 6],
  ['brapy', 4],
  ['bralu', 1],
  ['bradr', 3],
  ['brant', 1],
  ['braps', 4],
  ['brapu', 4],
  ['braju', 5],
  ['brars', 4],
  ['braux', 4],
  ['brapq', 4],
  ['brapx', 4],
  ['braag', 4],
  ['braqa', 4],
  ['brarb', 1],
  ['braax', 4],
  ['braru', 6],
  ['brare', 3],
  ['brair', 4],
  ['braqm', 4],
  ['brabz', 6],
  ['braai', 4],
  ['braif', 4],
  ['bracx', 1],
  ['brbgx', 4],
  ['brbss', 4],
  ['brbun', 1],
  ['brbaz', 4],
  ['brbqq', 4],
  ['brbdc', 6],
  ['brbpg', 4],
  ['brrch', 1],
  ['brbar', 3],
  ['brbra', 4],
  ['brbrb', 6],
  ['brbat', 4],
  ['brbau', 4],
  ['brbhz', 4],
  ['brbgv', 4],
  ['brbnu', 5],
  ['brbvb', 4],
  ['brbcr', 6],
  ['brbob', 6],
  ['brqcj', 4],
  ['brbsb', 4],
  ['brbms', 4],
  ['brqab', 4],
  ['brcdo', 1],
  ['brcfo', 6],
  ['brccq', 4],
  ['brcdm', 3],
  ['brccl', 6],
  ['brclv', 4],
  ['brcbu', 3],
  ['brcmr', 6],
  ['brcmc', 5],
  ['brcpv', 4],
  ['brcpq', 4],
  ['brqcd', 4],
  ['brcgr', 4],
  ['brcbw', 4],
  ['brcaw', 4],
  ['brqjo', 4],
  ['brcgz', 6],
  ['brcnv', 4],
  ['brqcn', 4],
  ['brqns', 4],
  ['brcpi', 3],
  ['brcks', 4],
  ['brcaf', 4],
  ['brcrq', 4],
  ['brcln', 4],
  ['brcau', 4],
  ['brctp', 4],
  ['brcav', 3],
  ['brcac', 4],
  ['brcss', 4],
  ['brtlz', 4],
  ['brcxj', 4],
  ['brcea', 3],
  ['brcha', 6],
  ['brciz', 4],
  ['brcda', 3],
  ['brcsw', 4],
  ['brcdj', 6],
  ['brccd', 6],
  ['brcnf', 4],
  ['brcgh', 4],
  ['brcko', 4],
  ['brcmg', 5],
  ['brcqs', 4],
  ['brccm', 4],
  ['brczb', 4],
  ['brczs', 4],
  ['brcgb', 4],
  ['brcua', 3],
  ['brcwb', 6],
  ['brcpu', 4],
  ['brccx', 4],
  ['brdmt', 4],
  ['brdno', 4],
  ['brdia', 3],
  ['brdiv', 6],
  ['brdou', 4],
  ['brern', 4],
  ['brerm', 4],
  ['bresi', 4],
  ['bresa', 3],
  ['brfej', 4],
  ['brfec', 4],
  ['brflb', 4],
  ['brfln', 5],
  ['brfno', 1],
  ['brfor', 5],
  ['brigu', 4],
  ['brfrc', 4],
  ['brfbe', 4],
  ['brgig', 4],
  ['brgyn', 4],
  ['brgvr', 4],
  ['brqrp', 4],
  ['brgdp', 4],
  ['brqga', 4],
  ['brgjm', 6],
  ['brgua', 3],
  ['brgnm', 4],
  ['brqdl', 4],
  ['brgav', 6],
  ['brgta', 6],
  ['brgtb', 3],
  ['brgru', 4],
  ['brgms', 4],
  ['brgur', 6],
  ['briju', 4],
  ['brios', 5],
  ['bribb', 1],
  ['brimp', 4],
  ['bripn', 4],
  ['bripu', 4],
  ['bripg', 4],
  ['bripo', 3],
  ['brire', 4],
  ['briro', 3],
  ['brita', 6],
  ['brspb', 1],
  ['britb', 4],
  ['britj', 5],
  ['briur', 6],
  ['britz', 3],
  ['brioa', 3],
  ['briqi', 3],
  ['britq', 5],
  ['braub', 4],
  ['brite', 4],
  ['brjcr', 4],
  ['brjcm', 4],
  ['brjrv', 3],
  ['brjls', 4],
  ['brjna', 4],
  ['brjti', 4],
  ['brjeq', 4],
  ['brjpr', 4],
  ['brjpa', 5],
  ['brjcb', 4],
  ['brjoi', 4],
  ['brjua', 4],
  ['brjne', 6],
  ['brjia', 4],
  ['brjdf', 6],
  ['brjrn', 4],
  ['brlaj', 4],
  ['brlcu', 6],
  ['brqlb', 4],
  ['brldf', 3],
  ['brlep', 4],
  ['brlrs', 6],
  ['brlip', 4],
  ['brlvb', 4],
  ['brldb', 4],
  ['brloi', 4],
  ['brlbr', 4],
  ['brmai', 1],
  ['brmca', 6],
  ['brmcu', 1],
  ['brmea', 4],
  ['brmcz', 5],
  ['brmao', 5],
  ['brmnx', 4],
  ['brmab', 4],
  ['brmcn', 6],
  ['brmgf', 4],
  ['brmii', 4],
  ['brmbk', 4],
  ['brmbz', 4],
  ['brmqh', 4],
  ['brqmi', 4],
  ['brmte', 4],
  ['brmeu', 4],
  ['brqgf', 6],
  ['brmoc', 4],
  ['brmvf', 4],
  ['brmvs', 4],
  ['brmgu', 1],
  ['brnnu', 6],
  ['brnat', 5],
  ['brnql', 4],
  ['brntr', 5],
  ['brhar', 1],
  ['brnvu', 3],
  ['brnok', 4],
  ['brnvp', 4],
  ['brnho', 6],
  ['brobi', 4],
  ['broyk', 4],
  ['brorx', 4],
  ['brqoc', 4],
  ['brous', 4],
  ['brout', 3],
  ['brpmw', 4],
  ['brplu', 4],
  ['brpng', 5],
  ['brpvi', 4],
  ['brpnn', 3],
  ['brprj', 1],
  ['brpin', 4],
  ['brphb', 5],
  ['brpfb', 4],
  ['brpsw', 4],
  ['brpto', 4],
  ['brpav', 4],
  ['brpec', 1],
  ['brpen', 3],
  ['brpet', 5],
  ['brpnz', 4],
  ['brqpe', 4],
  ['brpcs', 4],
  ['brpbq', 4],
  ['brphi', 4],
  ['brqhb', 4],
  ['brpiv', 4],
  ['brpig', 4],
  ['brpom', 3],
  ['brpma', 1],
  ['brpou', 1],
  ['brpgz', 4],
  ['brpll', 4],
  ['brpmg', 4],
  ['brplc', 6],
  ['brpbx', 4],
  ['brpnb', 4],
  ['brpoi', 3],
  ['brpar', 6],
  ['brpoo', 4],
  ['brpdf', 4],
  ['brpdr', 4],
  ['brppe', 6],
  ['brpgg', 4],
  ['brpoa', 5],
  ['brptq', 4],
  ['brpbv', 4],
  ['brpvh', 5],
  ['brqui', 6],
  ['brrec', 5],
  ['brrdc', 6],
  ['brrel', 1],
  ['brrao', 4],
  ['brrbr', 4],
  ['brrio', 3],
  ['brrig', 5],
  ['brrvd', 4],
  ['brroo', 4],
  ['brsal', 3],
  ['brssa', 5],
  ['brslv', 1],
  ['brscs', 4],
  ['brsnz', 4],
  ['brsfv', 4],
  ['brria', 4],
  ['brsra', 4],
  ['brstz', 4],
  ['brsvp', 6],
  ['brsag', 6],
  ['brmcp', 1],
  ['brstm', 4],
  ['brqse', 4],
  ['brgel', 4],
  ['brsta', 3],
  ['brsdu', 4],
  ['brqsb', 4],
  ['brqcx', 4],
  ['brqsc', 4],
  ['brsjl', 4],
  ['brqsd', 4],
  ['brsjp', 4],
  ['brsjk', 4],
  ['brsqy', 4],
  ['brsqm', 4],
  ['brqhf', 4],
  ['brsap', 4],
  ['brsma', 6],
  ['brsei', 4],
  ['brsts', 3],
  ['brqhg', 4],
  ['brops', 4],
  ['brqbx', 4],
  ['brsod', 4],
  ['brsfk', 4],
  ['brsua', 1],
  ['brsfs', 1],
  ['brsxo', 6],
  ['brsji', 6],
  ['brsbj', 5],
  ['brsao', 6],
  ['brsso', 1],
  ['brsom', 1],
  ['brsvt', 3],
  ['brtbt', 4],
  ['brtpr', 3],
  ['brtrq', 4],
  ['brtff', 4],
  ['brtxf', 4],
  ['brtec', 4],
  ['brthe', 6],
  ['brqht', 6],
  ['brteu', 4],
  ['brtfl', 4],
  ['brtmo', 3],
  ['brtow', 4],
  ['brtsq', 4],
  ['brtrm', 1],
  ['brtfo', 3],
  ['brtmt', 4],
  ['brqid', 4],
  ['brroa', 3],
  ['brtub', 1],
  ['brtuz', 4],
  ['brtur', 4],
  ['brtut', 1],
  ['brubt', 4],
  ['bruba', 4],
  ['brudi', 4],
  ['brumu', 4],
  ['brurb', 4],
  ['brurg', 4],
  ['brvac', 6],
  ['brval', 4],
  ['brvag', 4],
  ['brvzc', 1],
  ['brvia', 6],
  ['brvdc', 1],
  ['brvlp', 4],
  ['brvha', 6],
  ['brvcp', 4],
  ['brvit', 6],
  ['brvca', 6],
  ['brvix', 1],
  ['brqvr', 4],
  ['brvot', 4],
  ['brxig', 4],
  ['bsasd', 5],
  ['bsatc', 6],
  ['bsccz', 5],
  ['bsfpo', 5],
  ['bsggt', 5],
  ['bsghb', 5],
  ['bstcb', 5],
  ['bsbim', 4],
  ['bsbkc', 1],
  ['bscel', 5],
  ['bscxy', 4],
  ['bscat', 4],
  ['bsclp', 1],
  ['bscbt', 1],
  ['bscox', 5],
  ['bscri', 5],
  ['bslgi', 4],
  ['bsdct', 4],
  ['bsele', 1],
  ['bsgoc', 1],
  ['bsgbi', 4],
  ['bsexu', 1],
  ['bsghc', 5],
  ['bsgsc', 1],
  ['bsgtc', 5],
  ['bshbi', 5],
  ['bsiga', 5],
  ['bshmc', 1],
  ['bscoc', 1],
  ['bsluc', 1],
  ['bsmay', 6],
  ['bsmhh', 5],
  ['bsmsk', 6],
  ['bsmat', 1],
  ['bsmyg', 5],
  ['bsnas', 5],
  ['bsnet', 6],
  ['bsnmc', 4],
  ['bselh', 4],
  ['bsoce', 1],
  ['bspid', 4],
  ['bspwn', 4],
  ['bsppo', 4],
  ['bsrsd', 5],
  ['bsrcy', 4],
  ['bssaq', 5],
  ['bszsa', 5],
  ['bswzy', 4],
  ['bstzn', 4],
  ['bssrp', 1],
  ['bsswl', 4],
  ['bsaxp', 4],
  ['bstym', 4],
  ['bssml', 5],
  ['bstbi', 5],
  ['bswkr', 5],
  ['bswtd', 5],
  ['btpbh', 4],
  ['bwduk', 1],
  ['bwfrw', 6],
  ['bwgbe', 6],
  ['bwgnz', 6],
  ['bwhuk', 4],
  ['bwjwa', 4],
  ['bwbbk', 4],
  ['bwkhw', 4],
  ['bwloq', 4],
  ['bwmah', 3],
  ['bwmub', 4],
  ['bworp', 4],
  ['bwsvt', 4],
  ['bwpkw', 4],
  ['bwswx', 4],
  ['bwsxn', 4],
  ['bwtby', 4],
  ['bwtld', 4],
  ['bygme', 4],
  ['bygna', 4],
  ['byklk', 3],
  ['bykyc', 3],
  ['bymsq', 4],
  ['bymvq', 4],
  ['bypik', 3],
  ['byrya', 3],
  ['bysnm', 3],
  ['byvaw', 3],
  ['byvtb', 4],
  ['bzbar', 1],
  ['bzbze', 5],
  ['bztza', 4],
  ['bzcuk', 4],
  ['bzcyc', 4],
  ['bzcol', 1],
  ['bzczh', 4],
  ['bzdga', 3],
  ['bzinb', 6],
  ['bzmze', 6],
  ['bzmdb', 4],
  ['bzorz', 4],
  ['bzplj', 4],
  ['bzpnd', 4],
  ['bzsqs', 4],
  ['bzspr', 4],
  ['bzstu', 4],
  ['bzsjx', 4],
  ['bzsvk', 4],
  ['cabst', 3],
  ['cahos', 6],
  ['caalk', 3],
  ['caagu', 3],
  ['caakv', 6],
  ['caaal', 1],
  ['caytf', 4],
  ['cayae', 4],
  ['caabl', 1],
  ['caamh', 1],
  ['cayey', 4],
  ['caanb', 1],
  ['cayax', 4],
  ['caanz', 3],
  ['caahl', 1],
  ['caari', 3],
  ['caynr', 4],
  ['caarc', 3],
  ['cayek', 5],
  ['cayaf', 4],
  ['cayza', 4],
  ['caasq', 1],
  ['caalv', 3],
  ['cayib', 4],
  ['cayat', 4],
  ['caypj', 4],
  ['caavo', 3],
  ['caavp', 3],
  ['cabad', 1],
  ['cabgt', 1],
  ['caybg', 4],
  ['cabco', 5],
  ['cabve', 3],
  ['cablk', 3],
  ['cabaa', 3],
  ['cabdr', 3],
  ['cabam', 1],
  ['cabct', 3],
  ['cantr', 1],
  ['caban', 3],
  ['cabth', 1],
  ['cabat', 5],
  ['cabbu', 3],
  ['cabyr', 3],
  ['cabay', 1],
  ['cabpo', 3],
  ['cabcv', 1],
  ['cabea', 3],
  ['cabeh', 1],
  ['cabdf', 3],
  ['cablc', 1],
  ['cabel', 1],
  ['caeek', 1],
  ['cablv', 3],
  ['cabll', 3],
  ['cabld', 1],
  ['caybv', 4],
  ['cabfr', 3],
  ['caytl', 4],
  ['caytz', 4],
  ['caybi', 6],
  ['cablh', 3],
  ['cabsk', 3],
  ['caybx', 4],
  ['cabby', 1],
  ['cablr', 1],
  ['caydv', 4],
  ['cabbb', 1],
  ['cabob', 3],
  ['caybo', 4],
  ['cayvb', 4],
  ['cabor', 3],
  ['caybn', 4],
  ['cabwd', 3],
  ['cabue', 3],
  ['cabc2', 3],
  ['cabni', 3],
  ['cabwv', 1],
  ['caybr', 6],
  ['cabrd', 3],
  ['cabrw', 1],
  ['cabri', 1],
  ['cabtt', 1],
  ['caydr', 4],
  ['caybt', 4],
  ['cabka', 1],
  ['cabck', 1],
  ['cazbm', 4],
  ['caybm', 4],
  ['cabkn', 1],
  ['cabrt', 5],
  ['cayzm', 4],
  ['cayvt', 4],
  ['cabua', 1],
  ['caybh', 4],
  ['cabuo', 3],
  ['cabui', 3],
  ['cabuk', 3],
  ['cabrl', 3],
  ['caypz', 4],
  ['cabus', 3],
  ['cabin', 1],
  ['caydb', 4],
  ['cabtf', 1],
  ['caykz', 6],
  ['cabec', 1],
  ['cacda', 6],
  ['cacal', 6],
  ['cayyc', 4],
  ['cacam', 5],
  ['cacbf', 3],
  ['cacom', 1],
  ['cacpb', 1],
  ['cacpt', 1],
  ['cacah', 3],
  ['cacac', 1],
  ['cacsi', 1],
  ['cacdo', 5],
  ['cacpy', 3],
  ['caycj', 4],
  ['cacaq', 1],
  ['cacby', 3],
  ['cacrb', 3],
  ['cacdn', 1],
  ['cayci', 4],
  ['caclt', 1],
  ['cacmv', 3],
  ['caycv', 4],
  ['cayrf', 4],
  ['caycg', 4],
  ['cayac', 4],
  ['cacat', 3],
  ['cayce', 4],
  ['cachr', 1],
  ['cacil', 3],
  ['capbq', 3],
  ['cayld', 4],
  ['caycl', 4],
  ['caski', 1],
  ['cacha', 5],
  ['cachn', 3],
  ['cachm', 1],
  ['cacst', 1],
  ['caycs', 5],
  ['cactr', 1],
  ['caycq', 4],
  ['cayhr', 4],
  ['caymt', 4],
  ['cachi', 1],
  ['cacjh', 4],
  ['cayku', 4],
  ['cachv', 5],
  ['cazum', 4],
  ['carre', 3],
  ['cacht', 3],
  ['cacle', 3],
  ['caclh', 3],
  ['caclc', 1],
  ['cacsn', 1],
  ['cacwt', 3],
  ['cacva', 3],
  ['callf', 1],
  ['caylm', 4],
  ['caclr', 5],
  ['caycp', 4],
  ['cacau', 3],
  ['cacbg', 3],
  ['caycn', 4],
  ['cachs', 1],
  ['caclb', 3],
  ['cayod', 4],
  ['cacyp', 1],
  ['cacol', 1],
  ['caykc', 4],
  ['cayck', 4],
  ['cacbc', 3],
  ['caccv', 3],
  ['cacox', 1],
  ['cacsj', 1],
  ['cacqt', 1],
  ['cacoc', 3],
  ['cacfl', 3],
  ['cacop', 1],
  ['cacbk', 3],
  ['cacwl', 1],
  ['cacwi', 1],
  ['cayct', 4],
  ['caycf', 4],
  ['cacor', 1],
  ['cacqa', 3],
  ['cacoh', 1],
  ['cacou', 1],
  ['cacrr', 1],
  ['cacwh', 1],
  ['caccb', 1],
  ['cayym', 4],
  ['cayxc', 4],
  ['cacra', 3],
  ['cacro', 1],
  ['caycr', 4],
  ['cacci', 3],
  ['cacum', 3],
  ['cacsc', 1],
  ['cadhs', 1],
  ['caydn', 4],
  ['caydi', 4],
  ['cayda', 4],
  ['caydq', 4],
  ['cayrd', 4],
  ['caydl', 4],
  ['cadeb', 1],
  ['cadei', 1],
  ['caydf', 6],
  ['cayvz', 4],
  ['cadel', 3],
  ['cadsa', 3],
  ['cayds', 4],
  ['cadig', 1],
  ['cadil', 3],
  ['cadwl', 1],
  ['caydx', 4],
  ['caydo', 4],
  ['cadcs', 3],
  ['cador', 6],
  ['cadgf', 4],
  ['caydc', 4],
  ['cadry', 6],
  ['cadln', 3],
  ['caduq', 6],
  ['caywj', 4],
  ['cayxr', 4],
  ['cazem', 4],
  ['caedm', 4],
  ['cayeg', 4],
  ['caeds', 4],
  ['caedc', 1],
  ['cayel', 4],
  ['caxvx', 3],
  ['caend', 3],
  ['caeby', 3],
  ['caene', 1],
  ['caesq', 1],
  ['cayep', 4],
  ['caetn', 1],
  ['caetz', 1],
  ['caycz', 4],
  ['cazfw', 4],
  ['cayoe', 4],
  ['caflr', 3],
  ['cazfa', 4],
  ['cafap', 1],
  ['cayfo', 4],
  ['cafog', 1],
  ['cazfd', 4],
  ['cayfg', 4],
  ['cazft', 3],
  ['cafrv', 1],
  ['cafal', 1],
  ['caypy', 4],
  ['caffs', 5],
  ['cafkn', 3],
  ['caygh', 4],
  ['cayfh', 4],
  ['cafly', 3],
  ['cayjf', 4],
  ['cafma', 3],
  ['caymm', 4],
  ['cazfm', 4],
  ['cafne', 6],
  ['cayfl', 4],
  ['cayfr', 4],
  ['cayer', 4],
  ['cafsi', 5],
  ['cafsm', 5],
  ['cafwl', 3],
  ['cafsj', 6],
  ['cafor', 3],
  ['cayfx', 4],
  ['cafrr', 1],
  ['cafws', 3],
  ['cafsd', 3],
  ['cayfb', 4],
  ['caycx', 4],
  ['cayga', 4],
  ['caglt', 3],
  ['caggs', 1],
  ['cagpe', 5],
  ['caggw', 4],
  ['caygq', 4],
  ['caygs', 4],
  ['cazgs', 4],
  ['caygx', 4],
  ['caygb', 4],
  ['caygm', 4],
  ['cayhk', 5],
  ['caglb', 1],
  ['cagbt', 3],
  ['cagoh', 1],
  ['caygo', 4],
  ['cazgi', 4],
  ['cagor', 1],
  ['cagoo', 5],
  ['cayze', 4],
  ['cayge', 4],
  ['cagrb', 3],
  ['cazgf', 4],
  ['cagma', 3],
  ['caygc', 4],
  ['cayqu', 4],
  ['caedi', 3],
  ['cadas', 4],
  ['caygn', 4],
  ['cayzx', 4],
  ['caxgy', 6],
  ['cagrs', 1],
  ['caygz', 5],
  ['cagca', 1],
  ['cahcc', 3],
  ['cahju', 6],
  ['cayhc', 4],
  ['cahal', 5],
  ['cayhz', 4],
  ['cahab', 1],
  ['caoon', 1],
  ['caham', 5],
  ['cayhm', 4],
  ['cahmp', 3],
  ['cahvr', 3],
  ['cahhb', 3],
  ['cahpt', 1],
  ['cahbr', 3],
  ['cahre', 3],
  ['cahar', 3],
  ['caytb', 4],
  ['cahon', 3],
  ['cahck', 6],
  ['caydj', 4],
  ['cahsp', 5],
  ['cayhy', 4],
  ['cayhf', 4],
  ['cahbn', 1],
  ['cahmm', 1],
  ['cahli', 1],
  ['cahib', 3],
  ['cayoj', 4],
  ['cazhp', 4],
  ['cahid', 3],
  ['cayhi', 4],
  ['cahod', 3],
  ['cayhe', 4],
  ['cayhn', 4],
  ['cahrf', 3],
  ['cahws', 1],
  ['cayhb', 4],
  ['caynh', 4],
  ['caheb', 3],
  ['caigl', 5],
  ['cazuc', 4],
  ['caygr', 4],
  ['cailf', 4],
  ['caiam', 1],
  ['caigs', 6],
  ['caikm', 3],
  ['cayph', 4],
  ['cayev', 4],
  ['catil', 4],
  ['caivm', 3],
  ['caioc', 1],
  ['caion', 1],
  ['caiql', 3],
  ['caivn', 3],
  ['caisl', 3],
  ['cayiv', 4],
  ['cayik', 4],
  ['cajac', 3],
  ['cajpr', 6],
  ['cajeg', 3],
  ['cazjg', 4],
  ['cajei', 1],
  ['cayjo', 4],
  ['cajol', 6],
  ['cakml', 6],
  ['caxgr', 4],
  ['caywb', 4],
  ['caykg', 4],
  ['cayyu', 4],
  ['caxks', 4],
  ['caydu', 4],
  ['cazke', 4],
  ['caklo', 3],
  ['cayau', 4],
  ['cakee', 1],
  ['cazkg', 4],
  ['cakwl', 6],
  ['cakes', 4],
  ['cayki', 4],
  ['cayqk', 4],
  ['cakho', 3],
  ['caykj', 4],
  ['cayxi', 4],
  ['cakly', 1],
  ['caxbw', 4],
  ['cayqe', 4],
  ['caylc', 4],
  ['cakac', 6],
  ['cayky', 4],
  ['cakwi', 1],
  ['cakif', 4],
  ['cakgn', 6],
  ['cakin', 5],
  ['cakvl', 1],
  ['cakkr', 1],
  ['cakne', 3],
  ['cakny', 4],
  ['caykx', 4],
  ['caktc', 4],
  ['caktm', 1],
  ['caykk', 4],
  ['caykt', 4],
  ['cayke', 4],
  ['caknv', 4],
  ['cakov', 3],
  ['caybb', 6],
  ['cafcm', 5],
  ['cayvp', 4],
  ['caalo', 3],
  ['calb3', 1],
  ['cails', 1],
  ['caygl', 4],
  ['carel', 1],
  ['cayvc', 4],
  ['calmw', 3],
  ['cassq', 4],
  ['cazlt', 4],
  ['caylq', 4],
  ['caylb', 4],
  ['caaab', 1],
  ['caldt', 1],
  ['calac', 1],
  ['calch', 3],
  ['calco', 3],
  ['caldn', 3],
  ['calad', 1],
  ['caylf', 4],
  ['cayar', 4],
  ['cayah', 4],
  ['calah', 1],
  ['calse', 3],
  ['calaw', 1],
  ['cayla', 4],
  ['calng', 3],
  ['calds', 3],
  ['caylh', 4],
  ['calzv', 3],
  ['calrq', 4],
  ['calvh', 3],
  ['caxlf', 4],
  ['calra', 6],
  ['calmn', 3],
  ['cayls', 4],
  ['camhn', 3],
  ['caltb', 4],
  ['calwp', 3],
  ['calic', 1],
  ['cazgr', 4],
  ['calin', 1],
  ['caliv', 1],
  ['cayll', 6],
  ['calob', 3],
  ['calcp', 3],
  ['calmd', 3],
  ['calod', 6],
  ['caloh', 3],
  ['caylx', 4],
  ['calop', 1],
  ['calou', 3],
  ['calwb', 3],
  ['calcv', 3],
  ['caskl', 3],
  ['calwh', 3],
  ['calun', 1],
  ['caysg', 4],
  ['cayaj', 4],
  ['cayyl', 4],
  ['cayly', 4],
  ['calev', 1],
  ['camab', 3],
  ['camtq', 3],
  ['camgd', 1],
  ['camag', 3],
  ['camhb', 3],
  ['cambr', 3],
  ['caydk', 4],
  ['caymn', 4],
  ['camti', 1],
  ['caymg', 4],
  ['cayem', 4],
  ['caymw', 4],
  ['cayaq', 4],
  ['camri', 1],
  ['camar', 5],
  ['caymc', 4],
  ['caymh', 5],
  ['camtn', 3],
  ['camss', 5],
  ['caynm', 4],
  ['camne', 5],
  ['cayma', 4],
  ['caylj', 4],
  ['cayxh', 6],
  ['camdb', 1],
  ['cameb', 1],
  ['camgh', 3],
  ['caymb', 4],
  ['caymr', 4],
  ['camet', 1],
  ['camsw', 1],
  ['cayto', 4],
  ['camih', 1],
  ['camid', 3],
  ['camlk', 1],
  ['camni', 1],
  ['caymi', 4],
  ['camyo', 3],
  ['cayav', 4],
  ['camsv', 3],
  ['caylp', 4],
  ['camto', 3],
  ['camrb', 4],
  ['caymx', 4],
  ['camir', 3],
  ['camib', 3],
  ['camnt', 5],
  ['cayyy', 4],
  ['camol', 1],
  ['caymf', 4],
  ['camtr', 5],
  ['cayul', 4],
  ['camre', 3],
  ['caymj', 6],
  ['cayad', 4],
  ['camou', 5],
  ['camob', 1],
  ['caymd', 5],
  ['camul', 1],
  ['cayml', 4],
  ['cayqa', 4],
  ['camsa', 4],
  ['camus', 1],
  ['cayqn', 4],
  ['canak', 3],
  ['caznu', 4],
  ['canno', 5],
  ['canvk', 5],
  ['canne', 3],
  ['canan', 3],
  ['canmt', 3],
  ['cayna', 4],
  ['cazng', 4],
  ['cans3', 3],
  ['canls', 3],
  ['cangl', 1],
  ['canrc', 5],
  ['canwe', 1],
  ['canct', 1],
  ['caewt', 3],
  ['canol', 3],
  ['cayni', 4],
  ['caynk', 4],
  ['cayvq', 4],
  ['cancv', 3],
  ['canaf', 1],
  ['cayqw', 4],
  ['canor', 6],
  ['canrb', 1],
  ['cayno', 4],
  ['cansy', 1],
  ['cavac', 6],
  ['cayne', 4],
  ['candl', 1],
  ['canbc', 3],
  ['cayns', 4],
  ['caoaj', 3],
  ['caoak', 5],
  ['caydw', 4],
  ['caofa', 5],
  ['caode', 3],
  ['cayog', 4],
  ['cayoc', 4],
  ['cazfb', 4],
  ['caols', 3],
  ['caosh', 5],
  ['caott', 5],
  ['cayow', 4],
  ['cayoh', 4],
  ['cayif', 4],
  ['capnt', 1],
  ['cayde', 4],
  ['capar', 3],
  ['caprs', 5],
  ['capas', 1],
  ['captb', 1],
  ['caypc', 4],
  ['caype', 4],
  ['cayyz', 6],
  ['caypo', 4],
  ['cayuf', 4],
  ['capem', 4],
  ['capeh', 5],
  ['capth', 3],
  ['cayyf', 6],
  ['caywa', 4],
  ['capbh', 6],
  ['caphl', 3],
  ['caypl', 4],
  ['capic', 1],
  ['capto', 1],
  ['caypm', 4],
  ['capiw', 4],
  ['cawpc', 4],
  ['cazpo', 4],
  ['caypp', 4],
  ['capsv', 3],
  ['caphm', 3],
  ['capew', 3],
  ['captu', 3],
  ['caesx', 1],
  ['cappc', 1],
  ['capno', 1],
  ['capop', 1],
  ['capaq', 1],
  ['capxt', 3],
  ['capoi', 3],
  ['capft', 3],
  ['capdi', 5],
  ['capab', 5],
  ['capac', 1],
  ['capoa', 1],
  ['capap', 3],
  ['capco', 1],
  ['capcr', 1],
  ['capdv', 3],
  ['capte', 1],
  ['capel', 3],
  ['caphy', 5],
  ['caphw', 5],
  ['capma', 5],
  ['capml', 1],
  ['capmo', 3],
  ['capne', 1],
  ['caprp', 3],
  ['capsi', 1],
  ['capst', 1],
  ['capwe', 3],
  ['capaf', 3],
  ['capca', 1],
  ['capdl', 3],
  ['cagpd', 1],
  ['capme', 5],
  ['caplp', 6],
  ['captn', 1],
  ['capmg', 6],
  ['capou', 3],
  ['cawpl', 4],
  ['capow', 5],
  ['cacq5', 3],
  ['capre', 5],
  ['capra', 6],
  ['caprg', 5],
  ['caprr', 5],
  ['cappt', 3],
  ['cavos', 1],
  ['capug', 1],
  ['cayqc', 4],
  ['caqts', 1],
  ['caque', 5],
  ['cayqb', 4],
  ['caqch', 3],
  ['caqsn', 6],
  ['caram', 3],
  ['carit', 3],
  ['cayqf', 6],
  ['carla', 6],
  ['carrc', 3],
  ['careg', 4],
  ['cayqr', 4],
  ['careb', 5],
  ['cayrv', 4],
  ['cayrg', 6],
  ['carim', 5],
  ['cayyi', 4],
  ['cayrn', 4],
  ['caytn', 4],
  ['caroa', 3],
  ['cartb', 3],
  ['carsv', 3],
  ['cayrj', 4],
  ['carbn', 1],
  ['cagnn', 1],
  ['carcd', 3],
  ['cayrm', 4],
  ['caabr', 3],
  ['carod', 3],
  ['carpo', 3],
  ['cazrj', 4],
  ['cayuy', 6],
  ['carui', 1],
  ['carus', 3],
  ['caysa', 4],
  ['cazpb', 4],
  ['caysy', 4],
  ['caysv', 4],
  ['casag', 3],
  ['cas8a', 1],
  ['casad', 1],
  ['caske', 3],
  ['castd', 3],
  ['casau', 1],
  ['casbd', 3],
  ['caqcs', 3],
  ['caboj', 1],
  ['cazcr', 3],
  ['cascp', 1],
  ['casdn', 3],
  ['cadds', 3],
  ['caseu', 3],
  ['casfr', 3],
  ['cast3', 3],
  ['casfx', 3],
  ['caw4c', 1],
  ['cashu', 6],
  ['casid', 3],
  ['caxzc', 3],
  ['casjc', 6],
  ['caysj', 4],
  ['casjf', 3],
  ['cayyt', 4],
  ['caxjh', 3],
  ['caslt', 3],
  ['caslw', 3],
  ['caysl', 4],
  ['casms', 3],
  ['caspa', 3],
  ['cazsp', 4],
  ['casrs', 3],
  ['caspi', 3],
  ['capdo', 3],
  ['casra', 3],
  ['casro', 3],
  ['casst', 3],
  ['casts', 1],
  ['casth', 5],
  ['cazti', 3],
  ['caszt', 1],
  ['casct', 3],
  ['casfi', 3],
  ['camgt', 3],
  ['cayst', 4],
  ['casvd', 1],
  ['caysw', 4],
  ['caysn', 4],
  ['cassp', 5],
  ['casbh', 3],
  ['cazsj', 4],
  ['casf2', 1],
  ['caysk', 4],
  ['caysi', 4],
  ['casni', 5],
  ['casak', 6],
  ['cayxe', 4],
  ['cassm', 5],
  ['casyw', 3],
  ['caykl', 4],
  ['cayhs', 4],
  ['cased', 1],
  ['casem', 3],
  ['casel', 3],
  ['casei', 5],
  ['caseb', 1],
  ['caztm', 4],
  ['canon', 3],
  ['cashv', 3],
  ['casnv', 3],
  ['casha', 6],
  ['cayaw', 4],
  ['caysx', 4],
  ['cashe', 1],
  ['cashh', 3],
  ['casbu', 1],
  ['caslb', 3],
  ['casbe', 6],
  ['casbr', 4],
  ['caylo', 6],
  ['cashp', 1],
  ['casio', 3],
  ['casdy', 1],
  ['casyf', 4],
  ['casic', 1],
  ['cayxl', 4],
  ['cayss', 4],
  ['cayzh', 4],
  ['caysh', 4],
  ['cayyd', 4],
  ['cayxf', 4],
  ['cayfj', 4],
  ['casom', 1],
  ['casoo', 1],
  ['casor', 1],
  ['cast6', 1],
  ['casou', 1],
  ['caxsi', 4],
  ['cazfl', 4],
  ['caspn', 3],
  ['caslk', 3],
  ['caspr', 1],
  ['caysq', 4],
  ['caspd', 3],
  ['casqa', 5],
  ['caysz', 4],
  ['castw', 5],
  ['castk', 1],
  ['castc', 3],
  ['casyi', 3],
  ['caysf', 4],
  ['casmt', 3],
  ['castr', 6],
  ['castt', 6],
  ['cayrr', 4],
  ['caytc', 4],
  ['casud', 5],
  ['caysd', 4],
  ['caytg', 4],
  ['casur', 4],
  ['casum', 5],
  ['caium', 4],
  ['casuy', 1],
  ['cazjn', 4],
  ['cayyn', 4],
  ['casyd', 5],
  ['casyl', 3],
  ['caxtl', 4],
  ['catad', 1],
  ['capta', 5],
  ['cayyh', 4],
  ['cagsl', 4],
  ['caytq', 6],
  ['catas', 5],
  ['caytx', 4],
  ['caybq', 4],
  ['cayxt', 4],
  ['caytj', 4],
  ['cayzw', 4],
  ['catex', 1],
  ['catha', 3],
  ['catwn', 3],
  ['cayqd', 4],
  ['cattl', 3],
  ['caytd', 4],
  ['cayth', 4],
  ['catby', 1],
  ['cathl', 3],
  ['cathd', 3],
  ['cathh', 3],
  ['cathu', 5],
  ['catlb', 1],
  ['catcv', 1],
  ['cayts', 6],
  ['catng', 3],
  ['caytt', 4],
  ['cayaz', 4],
  ['cator', 5],
  ['catrc', 1],
  ['catrn', 6],
  ['cattc', 5],
  ['catjn', 3],
  ['catrb', 1],
  ['cayti', 4],
  ['catro', 3],
  ['catrr', 5],
  ['catru', 6],
  ['catuk', 5],
  ['cazfn', 4],
  ['caytk', 4],
  ['catux', 4],
  ['catns', 4],
  ['catwi', 3],
  ['caztb', 4],
  ['caucl', 1],
  ['caupt', 3],
  ['caybe', 4],
  ['cayvo', 4],
  ['cayoy', 4],
  ['cavlf', 3],
  ['cavnd', 1],
  ['cavan', 5],
  ['cayvr', 4],
  ['cacxh', 4],
  ['cavch', 1],
  ['cayvg', 4],
  ['caver', 4],
  ['cavic', 5],
  ['cavih', 3],
  ['cavob', 1],
  ['caywk', 4],
  ['cawal', 3],
  ['cawtn', 3],
  ['caykq', 4],
  ['cawtf', 6],
  ['cawai', 1],
  ['cawat', 4],
  ['cayqh', 6],
  ['cayxz', 4],
  ['caywp', 4],
  ['cawel', 1],
  ['caeiw', 3],
  ['cawep', 1],
  ['caync', 4],
  ['cawvr', 3],
  ['cawto', 3],
  ['cawey', 1],
  ['cayle', 4],
  ['cayxn', 5],
  ['cawhh', 1],
  ['cayws', 4],
  ['cawhi', 3],
  ['caywr', 4],
  ['cawtc', 6],
  ['cawhf', 1],
  ['cayxy', 4],
  ['cayvv', 4],
  ['cawwo', 3],
  ['caywm', 5],
  ['caywl', 4],
  ['cawdr', 3],
  ['cawnd', 5],
  ['cawig', 3],
  ['caywn', 4],
  ['cawnp', 5],
  ['caywg', 4],
  ['cazwl', 4],
  ['cawoo', 1],
  ['cawsk', 6],
  ['caywy', 4],
  ['cawnn', 4],
  ['cazye', 3],
  ['cayrh', 5],
  ['cayzf', 4],
  ['cazac', 4],
  ['cayqv', 4],
  ['cazeb', 1],
  ['cazur', 6],
  ['cccck', 5],
  ['cdbnw', 3],
  ['cdfdu', 4],
  ['cdbsu', 4],
  ['cdban', 4],
  ['cdbnc', 4],
  ['cdbnb', 4],
  ['cdboa', 5],
  ['cdbky', 4],
  ['cdbmb', 4],
  ['cdbux', 4],
  ['cdbzu', 4],
  ['cddic', 4],
  ['cdgdj', 4],
  ['cdbdt', 4],
  ['cdgma', 4],
  ['cdgom', 4],
  ['cdidf', 4],
  ['cdikl', 4],
  ['cdpfr', 4],
  ['cdino', 4],
  ['cdirp', 4],
  ['cdkbo', 4],
  ['cdkbn', 4],
  ['cdfmi', 4],
  ['cdkly', 4],
  ['cdkmn', 4],
  ['cdkga', 4],
  ['cdknm', 4],
  ['cdkap', 4],
  ['cdkec', 4],
  ['cdkgn', 4],
  ['cdkkw', 4],
  ['cdkil', 4],
  ['cdknd', 4],
  ['cdfih', 5],
  ['cdkrz', 4],
  ['cdfki', 4],
  ['cdkwz', 4],
  ['cdkoo', 4],
  ['cdkli', 4],
  ['cdlie', 4],
  ['cdliq', 4],
  ['cdlja', 4],
  ['cdfbm', 4],
  ['cdlza', 4],
  ['cdlzi', 4],
  ['cdlbo', 4],
  ['cdlus', 4],
  ['cdmkl', 3],
  ['cdmno', 4],
  ['cdmsm', 4],
  ['cdmat', 5],
  ['cdmdk', 4],
  ['cdmjm', 4],
  ['cdmnb', 4],
  ['cdbdv', 4],
  ['cdmhb', 1],
  ['cdmwg', 6],
  ['cdmew', 4],
  ['cdnlo', 4],
  ['cdnio', 4],
  ['cdnkl', 4],
  ['cdpun', 4],
  ['cdpwo', 4],
  ['cdtsh', 4],
  ['cdyan', 4],
  ['cfbmf', 4],
  ['cfbby', 4],
  ['cfbgu', 4],
  ['cfbgf', 4],
  ['cfbtg', 4],
  ['cfbbt', 4],
  ['cfiro', 4],
  ['cfbsn', 6],
  ['cfbem', 4],
  ['cfbop', 4],
  ['cfbcf', 4],
  ['cfboz', 4],
  ['cfbiv', 4],
  ['cfcrf', 4],
  ['cfgdi', 4],
  ['cfgda', 4],
  ['cfkwd', 4],
  ['cfkol', 4],
  ['cfmki', 6],
  ['cfndl', 4],
  ['cfoda', 4],
  ['cfodj', 4],
  ['cfrfa', 4],
  ['cfaig', 4],
  ['cfimo', 4],
  ['cgbtb', 4],
  ['cgboe', 4],
  ['cgbzv', 4],
  ['cgdjm', 4],
  ['cgdje', 1],
  ['cgepn', 4],
  ['cgewo', 4],
  ['cggmm', 4],
  ['cgion', 4],
  ['cgknj', 4],
  ['cgkee', 4],
  ['cglco', 4],
  ['cglkc', 4],
  ['cgdis', 4],
  ['cgkmk', 4],
  ['cgmkj', 4],
  ['cgmos', 3],
  ['cgmsx', 4],
  ['cgmuy', 4],
  ['cgnko', 1],
  ['cgnky', 4],
  ['cgokg', 4],
  ['cgoue', 5],
  ['cgftx', 6],
  ['cgoyo', 3],
  ['cgpnr', 5],
  ['cgsib', 4],
  ['cgsoe', 4],
  ['cgyom', 1],
  ['cganj', 4],
  ['chxre', 3],
  ['chatr', 6],
  ['chasc', 6],
  ['chbrz', 3],
  ['chba8', 3],
  ['chbrn', 6],
  ['chbib', 3],
  ['chbir', 1],
  ['chbly', 3],
  ['chbsm', 1],
  ['chbru', 3],
  ['chbtt', 1],
  ['chbxo', 6],
  ['chbur', 3],
  ['chhhm', 3],
  ['chchs', 1],
  ['chche', 1],
  ['chn66', 1],
  ['chcoi', 6],
  ['chcbl', 1],
  ['chcop', 3],
  ['chcvt', 1],
  ['chdot', 3],
  ['chdsf', 1],
  ['cheps', 3],
  ['cherb', 3],
  ['chfem', 3],
  ['chfla', 3],
  ['chgva', 6],
  ['chgrg', 1],
  ['chgtt', 3],
  ['chhte', 3],
  ['chhbg', 3],
  ['chxzx', 3],
  ['chrgu', 1],
  ['chk3l', 1],
  ['chkpp', 1],
  ['chkrz', 1],
  ['chkss', 1],
  ['chgel', 3],
  ['chldo', 3],
  ['chllu', 3],
  ['chlug', 6],
  ['chmau', 3],
  ['chmln', 3],
  ['chmwa', 3],
  ['chjjo', 1],
  ['chmig', 1],
  ['chmdf', 3],
  ['chmot', 4],
  ['chndb', 1],
  ['chnef', 3],
  ['chodb', 3],
  ['chpor', 3],
  ['chrrn', 3],
  ['chrnb', 3],
  ['chxxx', 1],
  ['chrmh', 3],
  ['chrgt', 1],
  ['chsas', 3],
  ['chssv', 1],
  ['chttg', 1],
  ['chqgl', 6],
  ['chsen', 3],
  ['chsmk', 3],
  ['chsir', 6],
  ['chsta', 3],
  ['chsti', 3],
  ['chtan', 3],
  ['chuks', 3],
  ['chtze', 1],
  ['chvau', 1],
  ['chvsx', 3],
  ['chvs5', 1],
  ['chvzu', 3],
  ['chwcw', 3],
  ['chwlo', 3],
  ['chzll', 6],
  ['chzrh', 4],
  ['ciogo', 4],
  ['ciabj', 5],
  ['ciabo', 4],
  ['ciafi', 4],
  ['ciass', 3],
  ['cibao', 3],
  ['cibdk', 4],
  ['cibgg', 4],
  ['cibrz', 4],
  ['cibyk', 4],
  ['cibqo', 4],
  ['cibxi', 4],
  ['cibuu', 4],
  ['cidab', 3],
  ['cidjo', 4],
  ['cidnc', 4],
  ['cidim', 4],
  ['cidiv', 4],
  ['ciesp', 1],
  ['cifek', 4],
  ['cifre', 3],
  ['ciggn', 6],
  ['cigba', 1],
  ['ciglu', 3],
  ['cibbv', 4],
  ['ciggo', 6],
  ['cijac', 1],
  ['ciktc', 4],
  ['cihgo', 4],
  ['cikos', 1],
  ['cilkt', 4],
  ['cixmb', 4],
  ['cimjc', 4],
  ['cimok', 4],
  ['cikeo', 4],
  ['ciofi', 4],
  ['cipbt', 1],
  ['cizss', 5],
  ['ciseq', 4],
  ['citbx', 4],
  ['citxu', 4],
  ['citgx', 4],
  ['citoz', 4],
  ['ciask', 4],
  ['cizue', 4],
  ['ckait', 5],
  ['ckaru', 1],
  ['ckaiu', 5],
  ['ckmgs', 5],
  ['ckmhx', 4],
  ['ckmuk', 4],
  ['ckmoi', 5],
  ['ckpye', 4],
  ['ckpzk', 4],
  ['ckrar', 5],
  ['clach', 1],
  ['clwap', 6],
  ['clanf', 5],
  ['clara', 3],
  ['claya', 1],
  ['clbag', 1],
  ['clbba', 6],
  ['clbar', 1],
  ['clchr', 1],
  ['clcnx', 1],
  ['clcjc', 6],
  ['clcbc', 3],
  ['clcld', 1],
  ['clcaa', 3],
  ['clclr', 1],
  ['clcol', 1],
  ['clcal', 1],
  ['clcrh', 3],
  ['clsmb', 6],
  ['clchb', 1],
  ['clcca', 3],
  ['clwch', 5],
  ['clchi', 3],
  ['clyai', 6],
  ['clcho', 3],
  ['clcst', 3],
  ['clcpo', 5],
  ['clcqq', 1],
  ['clcnl', 3],
  ['clcxq', 6],
  ['clcgr', 1],
  ['clcdv', 1],
  ['cldch', 3],
  ['clesr', 4],
  ['clffu', 6],
  ['clgua', 1],
  ['clgyc', 1],
  ['clhuh', 3],
  ['clhso', 1],
  ['cliqq', 5],
  ['clipc', 5],
  ['clisg', 1],
  ['cljfz', 1],
  ['cllsc', 5],
  ['clleb', 3],
  ['cllqn', 3],
  ['cllia', 3],
  ['cllli', 3],
  ['clulc', 6],
  ['cllvt', 3],
  ['cllos', 1],
  ['cllsq', 6],
  ['cllta', 3],
  ['clmll', 3],
  ['clmjs', 1],
  ['clmli', 3],
  ['clmic', 3],
  ['clnal', 3],
  ['clohi', 1],
  ['clovl', 5],
  ['clpan', 3],
  ['clpga', 3],
  ['clloh', 3],
  ['clpti', 1],
  ['clpck', 1],
  ['clpeo', 3],
  ['clpis', 1],
  ['clpvr', 6],
  ['clwpr', 6],
  ['clpos', 3],
  ['clzpc', 4],
  ['clwpa', 5],
  ['clpag', 3],
  ['clpch', 3],
  ['clcis', 3],
  ['clede', 1],
  ['clpoc', 3],
  ['clpat', 1],
  ['clppy', 1],
  ['clpvs', 3],
  ['clwpu', 5],
  ['clptc', 3],
  ['clppd', 1],
  ['clpqd', 1],
  ['clqln', 3],
  ['clpte', 3],
  ['clquv', 3],
  ['clqmc', 3],
  ['clqco', 1],
  ['clqtv', 3],
  ['clqrc', 6],
  ['clraf', 3],
  ['clsaa', 3],
  ['clsai', 3],
  ['clsve', 3],
  ['clscl', 6],
  ['cltal', 3],
  ['clttc', 5],
  ['clzco', 6],
  ['cltoq', 5],
  ['cltom', 3],
  ['clton', 3],
  ['cltor', 1],
  ['cltpt', 3],
  ['clvlr', 6],
  ['clvnt', 3],
  ['clzic', 6],
  ['clvil', 3],
  ['cmbfx', 6],
  ['cmblc', 4],
  ['cmbpc', 4],
  ['cmour', 4],
  ['cmbta', 4],
  ['cmdla', 5],
  ['cmdsc', 4],
  ['cmebw', 4],
  ['cmfom', 4],
  ['cmkle', 4],
  ['cmkol', 1],
  ['cmkob', 4],
  ['cmkbi', 5],
  ['cmkum', 1],
  ['cmlim', 3],
  ['cmlit', 1],
  ['cmmmf', 4],
  ['cmmvr', 4],
  ['cmmou', 3],
  ['cmnks', 6],
  ['cmtkc', 5],
  ['cmgxx', 4],
  ['cmyao', 4],
  ['cnaku', 4],
  ['cnaat', 4],
  ['cnaka', 4],
  ['cnapp', 1],
  ['cnaqg', 1],
  ['cnanq', 3],
  ['cnaog', 4],
  ['cnant', 1],
  ['cnajg', 1],
  ['cnasw', 1],
  ['cnbco', 1],
  ['cnbdg', 1],
  ['cnbah', 1],
  ['cnbcg', 1],
  ['cnblo', 1],
  ['cnbmj', 3],
  ['cnima', 1],
  ['cnbse', 1],
  ['cnbsi', 1],
  ['cnbyg', 1],
  ['cnbli', 1],
  ['cnbpx', 4],
  ['cnbsd', 1],
  ['cnbsp', 1],
  ['cnbau', 1],
  ['cnbya', 1],
  ['cnbyq', 1],
  ['cnbic', 1],
  ['cnbhy', 4],
  ['cnbih', 1],
  ['cnbij', 1],
  ['cnbjs', 6],
  ['cnpek', 4],
  ['cnbei', 3],
  ['cnblg', 1],
  ['cnsxb', 1],
  ['cnbiy', 1],
  ['cnphb', 1],
  ['cnbhx', 1],
  ['cnbyn', 3],
  ['cnboa', 1],
  ['cndbx', 1],
  ['cnbzg', 1],
  ['cnbhe', 1],
  ['cngsa', 3],
  ['cncgw', 3],
  ['cncfd', 1],
  ['cncjg', 3],
  ['cncgc', 4],
  ['cncgq', 4],
  ['cncdh', 1],
  ['cncni', 4],
  ['cncah', 1],
  ['cncgj', 1],
  ['cncsh', 5],
  ['cncsx', 4],
  ['cncsj', 1],
  ['cncso', 1],
  ['cncgs', 1],
  ['cncih', 4],
  ['cnczx', 1],
  ['cnchu', 3],
  ['cncyn', 6],
  ['cnchy', 1],
  ['cncoz', 1],
  ['cncbg', 1],
  ['cncun', 3],
  ['cnche', 1],
  ['cncng', 3],
  ['cncdu', 4],
  ['cnctu', 4],
  ['cncji', 1],
  ['cnclj', 1],
  ['cnchx', 1],
  ['cncig', 1],
  ['cncbi', 1],
  ['cncwn', 1],
  ['cnchi', 1],
  ['cncgm', 1],
  ['cncqi', 5],
  ['cnckg', 4],
  ['cnchq', 1],
  ['cnqzc', 1],
  ['cncgz', 1],
  ['cnchs', 1],
  ['cncum', 3],
  ['cnchz', 3],
  ['cnciv', 1],
  ['cncix', 3],
  ['cncja', 1],
  ['cndcb', 1],
  ['cndan', 1],
  ['cnddd', 1],
  ['cndiz', 1],
  ['cnddk', 1],
  ['cndfg', 1],
  ['cndaa', 3],
  ['cndhp', 1],
  ['cndji', 1],
  ['cndlu', 4],
  ['cndal', 5],
  ['cndag', 1],
  ['cndlc', 4],
  ['cndld', 1],
  ['cndmy', 1],
  ['cnddz', 3],
  ['cnddg', 1],
  ['cnfzl', 1],
  ['cndnt', 1],
  ['cndzm', 1],
  ['cndhu', 3],
  ['cndjo', 3],
  ['cndpg', 3],
  ['cndqd', 1],
  ['cndsa', 1],
  ['cndas', 1],
  ['cndso', 1],
  ['cndtz', 1],
  ['cndwn', 1],
  ['cndax', 1],
  ['cnpkx', 4],
  ['cndxu', 1],
  ['cndyw', 1],
  ['cnday', 1],
  ['cndyi', 1],
  ['cndzu', 4],
  ['cndef', 3],
  ['cndig', 4],
  ['cndez', 3],
  ['cnddp', 1],
  ['cndia', 1],
  ['cndbg', 1],
  ['cnddw', 1],
  ['cndfa', 3],
  ['cndfn', 1],
  ['cndgn', 1],
  ['cndgu', 1],
  ['cndgg', 1],
  ['cndng', 3],
  ['cndjg', 1],
  ['cndjk', 1],
  ['cndjt', 1],
  ['cndop', 1],
  ['cndli', 1],
  ['cndgp', 1],
  ['cndsn', 1],
  ['cndsh', 1],
  ['cndsi', 1],
  ['cndsb', 1],
  ['cndox', 3],
  ['cndyn', 1],
  ['cndoz', 1],
  ['cndhd', 1],
  ['cndou', 1],
  ['cndcg', 1],
  ['cndnh', 4],
  ['cnecg', 1],
  ['cnenh', 4],
  ['cneba', 1],
  ['cnegn', 3],
  ['cnetk', 1],
  ['cnezh', 3],
  ['cnfan', 1],
  ['cnfch', 1],
  ['cnfdu', 1],
  ['cnfje', 1],
  ['cnfki', 3],
  ['cnfuo', 4],
  ['cnfrt', 1],
  ['cnfuc', 1],
  ['cnfag', 1],
  ['cnfuj', 1],
  ['cnflg', 3],
  ['cnfsa', 1],
  ['cnfsi', 1],
  ['cnfwn', 1],
  ['cnfxg', 1],
  ['cnfyx', 4],
  ['cnfng', 3],
  ['cnfuy', 1],
  ['cnfyn', 4],
  ['cnfzh', 5],
  ['cnfoc', 4],
  ['cnfzg', 1],
  ['cnfzx', 1],
  ['cngul', 1],
  ['cnkow', 4],
  ['cngao', 1],
  ['cngln', 3],
  ['cngon', 3],
  ['cnglg', 1],
  ['cngom', 1],
  ['cngsh', 1],
  ['cngdi', 1],
  ['cngoq', 4],
  ['cnggn', 1],
  ['cnggu', 1],
  ['cnggk', 1],
  ['cngog', 3],
  ['cntsg', 1],
  ['cngbg', 1],
  ['cngch', 1],
  ['cngbt', 1],
  ['cngut', 1],
  ['cnghi', 1],
  ['cnghn', 4],
  ['cnlhk', 4],
  ['cngli', 1],
  ['cngng', 1],
  ['cngni', 1],
  ['cnycu', 4],
  ['cngys', 4],
  ['cncan', 4],
  ['cngzg', 1],
  ['cngzc', 1],
  ['cngyo', 1],
  ['cngug', 1],
  ['cngui', 3],
  ['cnkwl', 4],
  ['cngpg', 1],
  ['cngis', 1],
  ['cngya', 4],
  ['cnkwe', 4],
  ['cngze', 3],
  ['cnxwh', 1],
  ['cnhdn', 1],
  ['cnhgn', 1],
  ['cnhko', 5],
  ['cnhak', 4],
  ['cnhxg', 1],
  ['cnhig', 1],
  ['cncyh', 1],
  ['cnhme', 1],
  ['cnhim', 1],
  ['cnhht', 3],
  ['cnhto', 1],
  ['cnhmi', 4],
  ['cnhaz', 4],
  ['cnhgh', 4],
  ['cnhxe', 1],
  ['cnhrn', 5],
  ['cnhbg', 1],
  ['cnhrb', 4],
  ['cnheb', 3],
  ['cnhfi', 4],
  ['cnhfe', 4],
  ['cnhek', 1],
  ['cnhst', 1],
  ['cnxhh', 1],
  ['cnhlw', 1],
  ['cnenp', 1],
  ['cnhni', 3],
  ['cnhxa', 1],
  ['cnhny', 6],
  ['cnhas', 1],
  ['cnhsn', 1],
  ['cnhtg', 1],
  ['cnhhh', 6],
  ['cnhet', 4],
  ['cnhod', 1],
  ['cnhgg', 1],
  ['cnblx', 1],
  ['cnhoh', 1],
  ['cnhga', 1],
  ['cnhoq', 1],
  ['cnhtn', 4],
  ['cnhou', 3],
  ['cnhzu', 3],
  ['cnhcn', 1],
  ['cnhdg', 1],
  ['cnhjj', 4],
  ['cnhij', 3],
  ['cnhli', 3],
  ['cnain', 3],
  ['cnhnh', 1],
  ['cnhuh', 1],
  ['cnhgj', 3],
  ['cnhji', 1],
  ['cnwch', 1],
  ['cnhun', 1],
  ['cnhua', 1],
  ['cnhaq', 1],
  ['cnhsk', 1],
  ['cnhsh', 6],
  ['cntxn', 4],
  ['cnhsi', 1],
  ['cnang', 1],
  ['cnhzo', 1],
  ['cnhns', 1],
  ['cnhus', 1],
  ['cnhuy', 1],
  ['cnhue', 1],
  ['cnhid', 1],
  ['cnhik', 1],
  ['cnhui', 1],
  ['cnhku', 1],
  ['cnhle', 1],
  ['cnhul', 1],
  ['cnhud', 1],
  ['cnhld', 4],
  ['cnhum', 1],
  ['cnhmn', 1],
  ['cnhup', 1],
  ['cnhzh', 1],
  ['cnjmu', 4],
  ['cnjms', 1],
  ['cnjbi', 1],
  ['cnjaa', 1],
  ['cnjch', 1],
  ['cnjcn', 1],
  ['cnjgj', 1],
  ['cnfkj', 1],
  ['cnjko', 1],
  ['cnjmn', 1],
  ['cnjip', 3],
  ['cnjpu', 1],
  ['cnjis', 1],
  ['cnjgy', 3],
  ['cnjia', 1],
  ['cnjli', 1],
  ['cnjto', 1],
  ['cnjxn', 3],
  ['cnjok', 1],
  ['cnjsg', 1],
  ['cnjax', 1],
  ['cnjay', 1],
  ['cnjgn', 4],
  ['cnjiz', 1],
  ['cnjes', 1],
  ['cnswa', 4],
  ['cnjil', 6],
  ['cnjmy', 1],
  ['cnjna', 4],
  ['cntna', 4],
  ['cndmj', 1],
  ['cnjgs', 4],
  ['cnhlj', 1],
  ['cnjog', 1],
  ['cnjij', 1],
  ['cnjtg', 1],
  ['cnjgu', 3],
  ['cnjzk', 1],
  ['cnjnh', 1],
  ['cnjhk', 1],
  ['cnjng', 4],
  ['cnptj', 1],
  ['cnjnl', 1],
  ['cnjpi', 1],
  ['cnjnz', 1],
  ['cnjuj', 1],
  ['cnjiu', 1],
  ['cnjul', 1],
  ['cnjlp', 1],
  ['cnjuz', 1],
  ['cnjzu', 1],
  ['cnkfg', 3],
  ['cnkjh', 6],
  ['cnkgt', 4],
  ['cnkzg', 1],
  ['cnkmn', 1],
  ['cnkry', 6],
  ['cnkhg', 4],
  ['cnknm', 4],
  ['cnkmg', 4],
  ['cnkca', 4],
  ['cncsl', 1],
  ['cnlzo', 1],
  ['cnlsn', 1],
  ['cnlns', 1],
  ['cnlxi', 3],
  ['cnlaz', 4],
  ['cnlhw', 4],
  ['cnldk', 1],
  ['cnlal', 1],
  ['cnlxh', 1],
  ['cnltp', 1],
  ['cnlha', 4],
  ['cnlxa', 4],
  ['cnlcx', 4],
  ['cnlgp', 4],
  ['cnlih', 1],
  ['cnlyg', 1],
  ['cnliz', 3],
  ['cnlid', 1],
  ['cnlij', 1],
  ['cnljg', 4],
  ['cnlli', 3],
  ['cnlnc', 4],
  ['cnllf', 4],
  ['cnlnh', 3],
  ['cntnc', 1],
  ['cnlni', 4],
  ['cnlip', 6],
  ['cnlui', 3],
  ['cnlud', 1],
  ['cnljp', 1],
  ['cnljd', 1],
  ['cnljn', 1],
  ['cnlkp', 1],
  ['cnlzl', 1],
  ['cnxml', 1],
  ['cnliu', 1],
  ['cnlzh', 1],
  ['cnlzp', 1],
  ['cnlyp', 1],
  ['cnlga', 1],
  ['cnlgu', 3],
  ['cnlgi', 3],
  ['cnlku', 1],
  ['cnlmn', 1],
  ['cnlpp', 1],
  ['cnlgw', 1],
  ['cnlgy', 1],
  ['cnloz', 1],
  ['cnlun', 3],
  ['cnljk', 1],
  ['cnubu', 1],
  ['cnlcp', 1],
  ['cnlfn', 3],
  ['cnlnn', 1],
  ['cnlub', 1],
  ['cnloh', 1],
  ['cnljt', 1],
  ['cnlqi', 1],
  ['cnlya', 4],
  ['cnlsh', 1],
  ['cnlus', 1],
  ['cnlum', 4],
  ['cnlxp', 1],
  ['cnlzi', 1],
  ['cnluz', 1],
  ['cnluh', 1],
  ['cnmaa', 1],
  ['cnmbi', 1],
  ['cnmac', 1],
  ['cnshm', 1],
  ['cnmjs', 1],
  ['cnmjd', 1],
  ['cnmaj', 1],
  ['cnnzh', 4],
  ['cnmog', 3],
  ['cnmlg', 1],
  ['cnmpp', 1],
  ['cnmsp', 1],
  ['cnmtz', 1],
  ['cnmwn', 1],
  ['cnmaw', 1],
  ['cnmay', 1],
  ['cnmio', 1],
  ['cnmgg', 1],
  ['cnmli', 1],
  ['cnmsa', 1],
  ['cnmsn', 1],
  ['cnmsg', 1],
  ['cnmxz', 4],
  ['cnmez', 6],
  ['cnmzw', 1],
  ['cnmig', 4],
  ['cnmot', 1],
  ['cnmhg', 3],
  ['cnmiy', 1],
  ['cnmoh', 1],
  ['cnlpg', 1],
  ['cnmym', 1],
  ['cnmdg', 4],
  ['cnmzu', 1],
  ['cngyn', 1],
  ['cnnan', 1],
  ['cnnch', 4],
  ['cnkhn', 4],
  ['cnnad', 1],
  ['cnnah', 1],
  ['cnnjk', 1],
  ['cnnji', 5],
  ['cnnkg', 4],
  ['cnnjg', 1],
  ['cnnig', 1],
  ['cnnng', 4],
  ['cnnsa', 1],
  ['cnntg', 1],
  ['cnnnx', 1],
  ['cnnxi', 1],
  ['cnnay', 4],
  ['cnznz', 1],
  ['cnneg', 1],
  ['cnnsy', 1],
  ['cnnca', 1],
  ['cnnbo', 5],
  ['cnngb', 4],
  ['cnnbg', 1],
  ['cnnde', 1],
  ['cnnmi', 1],
  ['cnnkl', 1],
  ['cnntn', 1],
  ['cnnud', 1],
  ['cnlzy', 4],
  ['cnpzo', 1],
  ['cnpaj', 1],
  ['cnpnt', 1],
  ['cnpzi', 4],
  ['cnpli', 1],
  ['cnpze', 1],
  ['cnpko', 1],
  ['cnpil', 1],
  ['cnpgn', 1],
  ['cnpsh', 1],
  ['cnpxm', 1],
  ['cnpgz', 1],
  ['cnpjs', 1],
  ['cnpjo', 1],
  ['cnpdg', 3],
  ['cnpdx', 1],
  ['cnpqi', 1],
  ['cnpqa', 1],
  ['cnput', 1],
  ['cnpxi', 1],
  ['cnpyg', 3],
  ['cnqjo', 1],
  ['cnqsn', 3],
  ['cnqaw', 3],
  ['cnljq', 1],
  ['cniqm', 4],
  ['cnqke', 1],
  ['cnqmn', 1],
  ['cnqdl', 1],
  ['cnqin', 5],
  ['cntao', 4],
  ['cnqdg', 1],
  ['cnqgg', 1],
  ['cnqig', 1],
  ['cnqhe', 1],
  ['cnqji', 1],
  ['cnqln', 1],
  ['cnqgs', 1],
  ['cnqse', 1],
  ['cnqya', 1],
  ['cniqn', 4],
  ['cnqgy', 1],
  ['cnshp', 1],
  ['cnqjg', 1],
  ['cnqzh', 1],
  ['cnsaj', 1],
  ['cnndg', 4],
  ['cnqsa', 1],
  ['cnqxt', 1],
  ['cnqzo', 1],
  ['cntcg', 4],
  ['cnqzl', 1],
  ['cnjjn', 4],
  ['cnqzj', 1],
  ['cnqku', 1],
  ['cnjdr', 1],
  ['cnroh', 1],
  ['cnrpg', 3],
  ['cnrzh', 1],
  ['cnrga', 1],
  ['cnrcg', 3],
  ['cnrch', 1],
  ['cnrgc', 1],
  ['cnrjg', 1],
  ['cnroq', 1],
  ['cnrsi', 1],
  ['cnrug', 1],
  ['cnria', 1],
  ['cnrag', 1],
  ['cnrjn', 1],
  ['cnwhr', 1],
  ['cnrsn', 1],
  ['cnsiq', 1],
  ['cnsbm', 1],
  ['cnsbu', 1],
  ['cnsdp', 1],
  ['cnsaa', 1],
  ['cnsig', 1],
  ['cnpts', 1],
  ['cnsam', 1],
  ['cnsrg', 1],
  ['cnsns', 1],
  ['cnnhs', 1],
  ['cnsjq', 1],
  ['cnsyx', 4],
  ['cnsya', 1],
  ['cnsna', 1],
  ['cnsza', 3],
  ['cnszo', 1],
  ['cnnds', 1],
  ['cnsat', 1],
  ['cnsez', 1],
  ['cnsho', 1],
  ['cnsha', 4],
  ['cnshg', 1],
  ['cnpvg', 4],
  ['cnsgu', 3],
  ['cnstg', 1],
  ['cnsaw', 1],
  ['cnswe', 1],
  ['cnhsc', 1],
  ['cnspa', 1],
  ['cnsap', 1],
  ['cnssn', 1],
  ['cnssg', 3],
  ['cnsti', 1],
  ['cnsht', 3],
  ['cnsyc', 1],
  ['cnshk', 1],
  ['cnsnh', 1],
  ['cnsqn', 1],
  ['cnsnw', 1],
  ['cnshy', 4],
  ['cnshe', 4],
  ['cnszx', 4],
  ['cnszp', 1],
  ['cnsey', 1],
  ['cnsbh', 1],
  ['cnshd', 1],
  ['cnsjz', 4],
  ['cnsjw', 4],
  ['cnsij', 1],
  ['cnsjj', 1],
  ['cnsjo', 1],
  ['cnsju', 1],
  ['cnslg', 1],
  ['cnslo', 3],
  ['cnzsm', 1],
  ['cnspi', 3],
  ['cnxsp', 1],
  ['cnshq', 1],
  ['cnpny', 1],
  ['cnsip', 1],
  ['cnshh', 3],
  ['cnstb', 1],
  ['cnsan', 3],
  ['cnswi', 1],
  ['cnsyg', 1],
  ['cnsiu', 1],
  ['cnssp', 1],
  ['cnsdg', 1],
  ['cnsik', 1],
  ['cnsud', 1],
  ['cnshl', 1],
  ['cnsjt', 1],
  ['cnsks', 1],
  ['cnsym', 1],
  ['cnsog', 1],
  ['cnsgj', 1],
  ['cnsmn', 1],
  ['cnson', 1],
  ['cnszk', 1],
  ['cnsbn', 1],
  ['cncff', 1],
  ['cnsnn', 1],
  ['cnsiz', 1],
  ['cnwux', 4],
  ['cnsuw', 1],
  ['cnszh', 1],
  ['cnszg', 1],
  ['cntac', 1],
  ['cntjg', 3],
  ['cntap', 1],
  ['cntpk', 1],
  ['cntpw', 1],
  ['cntis', 1],
  ['cntyu', 4],
  ['cntyn', 4],
  ['cntzo', 3],
  ['cntaz', 1],
  ['cntzu', 1],
  ['cntno', 1],
  ['cntgg', 1],
  ['cntja', 1],
  ['cntgs', 1],
  ['cnqnh', 1],
  ['cnyku', 1],
  ['cntxi', 1],
  ['cntid', 1],
  ['cntsn', 4],
  ['cntng', 1],
  ['cntxg', 1],
  ['cntbs', 1],
  ['cntiy', 1],
  ['cntze', 1],
  ['cnbht', 1],
  ['cntoh', 1],
  ['cntoj', 1],
  ['cntgo', 6],
  ['cntol', 1],
  ['cnten', 4],
  ['cntxz', 3],
  ['cntox', 1],
  ['cntoz', 1],
  ['cntfg', 1],
  ['cntjd', 1],
  ['cntqo', 1],
  ['cnhlh', 6],
  ['cncif', 4],
  ['cnurm', 6],
  ['cnurc', 4],
  ['cnwig', 1],
  ['cnwgq', 1],
  ['cnwih', 1],
  ['cnwjg', 1],
  ['cnwas', 1],
  ['cnwax', 1],
  ['cnwaz', 1],
  ['cnwzh', 1],
  ['cnwef', 5],
  ['cnwei', 5],
  ['cnweh', 5],
  ['cnweg', 1],
  ['cnwit', 1],
  ['cnwyk', 1],
  ['cnlmg', 1],
  ['cnwsh', 3],
  ['cnwnz', 4],
  ['cnwzo', 1],
  ['cnwhu', 3],
  ['cnwaa', 4],
  ['cnnhn', 5],
  ['cnwhg', 1],
  ['cnwuh', 4],
  ['cnwhe', 1],
  ['cnwhi', 1],
  ['cnwji', 1],
  ['cnwuk', 1],
  ['cnwsa', 1],
  ['cnwsn', 1],
  ['cnwug', 1],
  ['cnwxs', 5],
  ['cnwxa', 3],
  ['cnwxp', 1],
  ['cnwyp', 1],
  ['cnwys', 6],
  ['cnwus', 4],
  ['cnwuz', 1],
  ['cnxdj', 1],
  ['cnxih', 1],
  ['cnxua', 3],
  ['cnfqx', 1],
  ['cnxam', 5],
  ['cnxmn', 4],
  ['cnxmh', 1],
  ['cnxmg', 1],
  ['cnxia', 5],
  ['cnsia', 4],
  ['cnxgk', 1],
  ['cnxsi', 3],
  ['cnxta', 3],
  ['cnxag', 3],
  ['cnxxy', 3],
  ['cnxzi', 1],
  ['cnxgz', 3],
  ['cnxzu', 1],
  ['cnxng', 1],
  ['cnxtn', 3],
  ['cnxci', 1],
  ['cnxcu', 1],
  ['cnxho', 1],
  ['cnxjb', 1],
  ['cnxao', 1],
  ['cnxot', 3],
  ['cnxas', 1],
  ['cnxyn', 1],
  ['cnzxz', 1],
  ['cnxic', 4],
  ['cnxcg', 1],
  ['cnxti', 1],
  ['cnxex', 1],
  ['cnxij', 1],
  ['cnxls', 1],
  ['cnxht', 1],
  ['cnshx', 1],
  ['cnlsx', 1],
  ['cnxid', 1],
  ['cnxdi', 1],
  ['cnxdu', 1],
  ['cnxga', 1],
  ['cnxip', 1],
  ['cnxce', 1],
  ['cnxen', 4],
  ['cnxgd', 3],
  ['cnacx', 4],
  ['cnxhk', 1],
  ['cndmx', 1],
  ['cnxin', 1],
  ['cnxnt', 4],
  ['cnxnn', 4],
  ['cnxik', 1],
  ['cnxna', 3],
  ['cnxit', 1],
  ['cnxni', 1],
  ['cncnx', 1],
  ['cning', 1],
  ['cnxyi', 3],
  ['cnxsb', 4],
  ['cnjhg', 4],
  ['cnxto', 1],
  ['cnxul', 1],
  ['cnxyg', 1],
  ['cnxum', 1],
  ['cnxeo', 1],
  ['cnxjd', 1],
  ['cnxtz', 1],
  ['cnxuk', 1],
  ['cnxzh', 4],
  ['cnxuz', 4],
  ['cnybg', 1],
  ['cneny', 4],
  ['cnynz', 4],
  ['cnyjc', 1],
  ['cnyji', 1],
  ['cnygk', 1],
  ['cnykg', 1],
  ['cnyli', 1],
  ['cnymd', 1],
  ['cnype', 1],
  ['cnypg', 1],
  ['cnysn', 1],
  ['cnysa', 1],
  ['cnyso', 1],
  ['cnygx', 1],
  ['cnyxt', 1],
  ['cnyng', 3],
  ['cnyzh', 1],
  ['cnynj', 4],
  ['cnyja', 1],
  ['cnyat', 5],
  ['cnynt', 4],
  ['cnytg', 1],
  ['cnytn', 1],
  ['cnygo', 1],
  ['cnyjp', 1],
  ['cnyib', 1],
  ['cnybp', 4],
  ['cnycg', 1],
  ['cnyih', 4],
  ['cnyln', 4],
  ['cnych', 4],
  ['cninc', 4],
  ['cnyca', 1],
  ['cnygd', 1],
  ['cnyik', 1],
  ['cnljy', 1],
  ['cnyxs', 3],
  ['cnyiz', 3],
  ['cnygn', 1],
  ['cnyfu', 3],
  ['cnylo', 1],
  ['cnynn', 1],
  ['cnyti', 1],
  ['cnyua', 4],
  ['cnysh', 1],
  ['cnyhg', 1],
  ['cnyla', 1],
  ['cnyya', 3],
  ['cnyuh', 1],
  ['cnyyp', 1],
  ['cnyzu', 3],
  ['cnzag', 1],
  ['cnzjb', 3],
  ['cnwhz', 1],
  ['cnzjg', 1],
  ['cndyg', 4],
  ['cnzgs', 3],
  ['cnzgw', 1],
  ['cnzzu', 1],
  ['cnzha', 4],
  ['cnzng', 1],
  ['cnzdo', 3],
  ['cnzhg', 3],
  ['cnzpi', 1],
  ['cnzqg', 1],
  ['cnzat', 4],
  ['cnzhy', 3],
  ['cnzpo', 1],
  ['cnzpk', 1],
  ['cnzpu', 1],
  ['cnzel', 1],
  ['cnzpg', 1],
  ['cnzgz', 4],
  ['cncgo', 4],
  ['cnzhi', 1],
  ['cnzhe', 1],
  ['cnzxi', 1],
  ['cnzcg', 1],
  ['cnzsn', 1],
  ['cnzgt', 1],
  ['cnzxn', 1],
  ['cnzqj', 1],
  ['cnzos', 1],
  ['cnzzg', 1],
  ['cnzhh', 1],
  ['cnzyo', 1],
  ['cnzuh', 1],
  ['cnzlo', 3],
  ['cnzus', 1],
  ['cnzuy', 1],
  ['cnziy', 1],
  ['cnzyg', 1],
  ['cnzyi', 4],
  ['coacd', 4],
  ['coarf', 4],
  ['coacl', 4],
  ['coafi', 4],
  ['coadn', 4],
  ['coapo', 4],
  ['coapi', 4],
  ['coacr', 4],
  ['coauc', 4],
  ['coarq', 4],
  ['coaro', 4],
  ['coacm', 3],
  ['coaxm', 4],
  ['coayc', 4],
  ['cobsc', 4],
  ['cobhf', 4],
  ['cobal', 1],
  ['cobac', 4],
  ['coeja', 5],
  ['conbb', 4],
  ['cobaq', 5],
  ['cobog', 6],
  ['cobga', 4],
  ['cobun', 5],
  ['coclo', 4],
  ['cocjd', 4],
  ['cocpb', 4],
  ['cocqt', 4],
  ['cocco', 4],
  ['coctg', 5],
  ['cocrc', 5],
  ['cocuo', 4],
  ['cocsr', 4],
  ['cocaq', 4],
  ['cocpl', 4],
  ['coigo', 4],
  ['coivo', 4],
  ['cocim', 4],
  ['codzi', 4],
  ['cocog', 4],
  ['cocnr', 3],
  ['coczu', 4],
  ['cocov', 1],
  ['cocve', 4],
  ['corav', 4],
  ['cocuc', 4],
  ['cocui', 4],
  ['coebg', 4],
  ['coelb', 4],
  ['coebq', 1],
  ['coecr', 4],
  ['coeco', 4],
  ['coelj', 4],
  ['coeyp', 4],
  ['coenv', 3],
  ['cofla', 6],
  ['cogra', 4],
  ['coggl', 4],
  ['cogir', 4],
  ['cogca', 4],
  ['cogaa', 4],
  ['cogpi', 4],
  ['cogmc', 4],
  ['cohtz', 4],
  ['cohrr', 4],
  ['coibe', 4],
  ['copda', 6],
  ['coipi', 4],
  ['coisd', 4],
  ['cojuo', 4],
  ['colcr', 4],
  ['colpd', 4],
  ['colpe', 4],
  ['colat', 4],
  ['colmc', 4],
  ['colgt', 4],
  ['colet', 5],
  ['colmx', 4],
  ['colri', 4],
  ['conad', 4],
  ['comgn', 4],
  ['comcj', 4],
  ['comam', 1],
  ['comau', 3],
  ['comzl', 4],
  ['comqu', 4],
  ['comde', 4],
  ['comnd', 4],
  ['comfs', 4],
  ['comix', 4],
  ['comvp', 4],
  ['commp', 4],
  ['comfb', 4],
  ['comtb', 6],
  ['comtr', 4],
  ['comoy', 4],
  ['comhf', 4],
  ['comqr', 4],
  ['couls', 4],
  ['conar', 4],
  ['conci', 4],
  ['conva', 6],
  ['conuh', 4],
  ['conqu', 4],
  ['coocv', 4],
  ['coorc', 4],
  ['cootu', 4],
  ['copal', 4],
  ['coqpi', 4],
  ['coeuo', 4],
  ['copso', 4],
  ['copyn', 6],
  ['copza', 4],
  ['copei', 4],
  ['coptx', 6],
  ['copla', 4],
  ['coplc', 4],
  ['coplt', 4],
  ['coppn', 6],
  ['copre', 4],
  ['copoc', 1],
  ['copva', 4],
  ['copbe', 5],
  ['copbo', 1],
  ['copuc', 3],
  ['colqm', 6],
  ['copnm', 3],
  ['copul', 3],
  ['copcc', 6],
  ['couib', 4],
  ['corco', 1],
  ['corch', 5],
  ['coron', 4],
  ['cosnt', 6],
  ['coadz', 4],
  ['cossd', 6],
  ['cosje', 4],
  ['cosjr', 4],
  ['cosjh', 4],
  ['cosqe', 4],
  ['cosrs', 4],
  ['cosjg', 4],
  ['conpu', 4],
  ['cosvi', 4],
  ['cosqb', 6],
  ['cosca', 4],
  ['cosmc', 4],
  ['cosmr', 5],
  ['cossl', 6],
  ['cosro', 6],
  ['corve', 4],
  ['copsb', 1],
  ['cospc', 3],
  ['cosox', 4],
  ['cosqf', 6],
  ['cosoh', 4],
  ['cottm', 4],
  ['cotme', 4],
  ['cotcd', 6],
  ['cotau', 4],
  ['cotib', 6],
  ['cotbd', 6],
  ['cotlu', 5],
  ['cotqs', 4],
  ['cotda', 4],
  ['coulq', 4],
  ['cotco', 5],
  ['cotrb', 5],
  ['counc', 4],
  ['couri', 4],
  ['courr', 4],
  ['covup', 6],
  ['covgz', 6],
  ['covvc', 4],
  ['coayg', 6],
  ['coayi', 4],
  ['covab', 6],
  ['coymb', 3],
  ['coazt', 6],
  ['crabg', 1],
  ['crbcl', 4],
  ['crbat', 1],
  ['crbai', 4],
  ['crcab', 3],
  ['crcai', 3],
  ['crcal', 3],
  ['crcsc', 4],
  ['crrik', 4],
  ['crcom', 3],
  ['crcir', 3],
  ['crotr', 4],
  ['crcyl', 3],
  ['crcrr', 1],
  ['crcut', 3],
  ['crfil', 3],
  ['crfmg', 4],
  ['crfon', 4],
  ['crfpn', 3],
  ['crgdp', 3],
  ['crgda', 3],
  ['crgua', 3],
  ['crhsa', 1],
  ['crjic', 3],
  ['crlaf', 3],
  ['crtig', 3],
  ['crlcs', 4],
  ['crlir', 6],
  ['crlsl', 6],
  ['crmat', 1],
  ['crctc', 1],
  ['crmob', 3],
  ['crmue', 3],
  ['crnan', 3],
  ['crnar', 3],
  ['crcdn', 3],
  ['crnct', 6],
  ['crnob', 4],
  ['croro', 3],
  ['crpmz', 6],
  ['crpan', 3],
  ['crpar', 3],
  ['crpen', 1],
  ['crpit', 3],
  ['crpld', 4],
  ['crpoa', 1],
  ['crptc', 1],
  ['crpjm', 4],
  ['crlio', 3],
  ['crpmn', 1],
  ['crvdt', 3],
  ['crpbp', 4],
  ['crpum', 1],
  ['crjap', 4],
  ['crpas', 3],
  ['crqgg', 3],
  ['crxqp', 5],
  ['crrji', 1],
  ['crrox', 1],
  ['crrcd', 3],
  ['crrio', 3],
  ['crsan', 3],
  ['crmtz', 1],
  ['crssp', 3],
  ['crsrg', 1],
  ['crtoo', 4],
  ['crsmp', 1],
  ['crsrp', 1],
  ['crspi', 3],
  ['crsri', 3],
  ['crsix', 1],
  ['crsam', 3],
  ['crttq', 4],
  ['crtre', 3],
  ['crtua', 1],
  ['crzar', 1],
  ['cubho', 1],
  ['cuban', 3],
  ['cubca', 5],
  ['cubym', 4],
  ['cubog', 1],
  ['cubuf', 3],
  ['cucab', 1],
  ['cucai', 1],
  ['cucmw', 5],
  ['cucas', 3],
  ['cuccc', 4],
  ['cucyo', 4],
  ['cucei', 3],
  ['cucfg', 5],
  ['cucar', 3],
  ['cugib', 3],
  ['cugub', 3],
  ['cunbw', 4],
  ['cugyb', 1],
  ['cuhog', 4],
  ['cuids', 1],
  ['cujuc', 3],
  ['culcl', 4],
  ['cubww', 4],
  ['cuvtu', 6],
  ['cugao', 4],
  ['cumzo', 5],
  ['cuqma', 5],
  ['cuavi', 4],
  ['cumjg', 4],
  ['cumel', 3],
  ['cumoa', 4],
  ['cuicr', 5],
  ['cuniq', 3],
  ['cuger', 5],
  ['cunvt', 3],
  ['cupal', 3],
  ['cuqpd', 4],
  ['cupst', 4],
  ['cuvit', 3],
  ['cupas', 3],
  ['cumnt', 3],
  ['cuppa', 3],
  ['cupta', 3],
  ['cuupa', 4],
  ['cuuma', 6],
  ['cusdt', 1],
  ['cusnj', 4],
  ['cuqsn', 4],
  ['cusnu', 1],
  ['cuscs', 1],
  ['cuuss', 6],
  ['cuscu', 5],
  ['cuszj', 4],
  ['cutnd', 6],
  ['cutdz', 3],
  ['cuvra', 6],
  ['cvbvc', 4],
  ['cvbvr', 4],
  ['cvsid', 4],
  ['cvmmo', 4],
  ['cvmin', 3],
  ['cvmti', 4],
  ['cvgra', 1],
  ['cvrai', 5],
  ['cvnto', 4],
  ['cvsfl', 4],
  ['cvsne', 4],
  ['cvvxe', 5],
  ['cwbub', 1],
  ['cwcrb', 1],
  ['cwcur', 5],
  ['cwfui', 1],
  ['cwsmb', 1],
  ['cwwil', 1],
  ['cxxch', 4],
  ['cxffc', 1],
  ['cyakt', 5],
  ['cydhk', 3],
  ['cyepk', 4],
  ['cyfmg', 3],
  ['cykar', 1],
  ['cykyr', 1],
  ['cylca', 5],
  ['cylat', 3],
  ['cylms', 3],
  ['cymoi', 3],
  ['cynic', 1],
  ['cypfo', 5],
  ['cyvas', 1],
  ['cyzyy', 1],
  ['czbbc', 3],
  ['czbar', 3],
  ['cztnn', 1],
  ['czbdc', 1],
  ['czbol', 3],
  ['czbtc', 3],
  ['czbrq', 6],
  ['czbub', 1],
  ['czbys', 1],
  ['czbln', 3],
  ['czcep', 3],
  ['czc7v', 1],
  ['czcev', 3],
  ['czccn', 3],
  ['czcul', 3],
  ['czchz', 1],
  ['czchu', 3],
  ['czg44', 1],
  ['czmce', 1],
  ['czdcb', 3],
  ['czskj', 1],
  ['czdoh', 1],
  ['czdis', 3],
  ['czdiv', 3],
  ['czdrs', 3],
  ['czdro', 1],
  ['czddd', 1],
  ['czkrb', 1],
  ['czdob', 3],
  ['czbbe', 3],
  ['czsk5', 3],
  ['czdol', 3],
  ['czdrn', 1],
  ['czdkv', 3],
  ['czhlp', 1],
  ['czhlu', 3],
  ['czhol', 4],
  ['czhtv', 1],
  ['czhst', 1],
  ['czhot', 1],
  ['czdek', 1],
  ['czhrn', 3],
  ['czhsa', 3],
  ['czhru', 1],
  ['czjab', 3],
  ['czjen', 1],
  ['czjie', 3],
  ['czkad', 3],
  ['czklv', 6],
  ['czkuv', 3],
  ['czktv', 6],
  ['czklc', 6],
  ['czkor', 3],
  ['czkoq', 1],
  ['czksl', 3],
  ['czrnh', 3],
  ['czknv', 3],
  ['czkvr', 6],
  ['czzly', 3],
  ['czkka', 1],
  ['czlec', 3],
  ['czloe', 3],
  ['czlob', 1],
  ['czluk', 3],
  ['czlod', 1],
  ['czlbc', 3],
  ['czlcc', 1],
  ['czmka', 6],
  ['czmnv', 3],
  ['czmib', 3],
  ['czmor', 3],
  ['czmra', 3],
  ['czmyt', 1],
  ['czneh', 1],
  ['cznrt', 3],
  ['cznet', 3],
  ['czovv', 1],
  ['cznvv', 3],
  ['czodo', 3],
  ['czolo', 6],
  ['czocx', 1],
  ['czosi', 1],
  ['czpao', 1],
  ['czosr', 6],
  ['czotc', 6],
  ['czprb', 6],
  ['czpau', 3],
  ['czplz', 6],
  ['czpnj', 3],
  ['czpna', 1],
  ['czpop', 3],
  ['czxuy', 3],
  ['czprg', 6],
  ['czprv', 6],
  ['czrau', 1],
  ['czrdn', 1],
  ['czraj', 3],
  ['czxxx', 1],
  ['czjk6', 3],
  ['cznkn', 3],
  ['czsem', 1],
  ['czsta', 3],
  ['czcrs', 1],
  ['czovk', 3],
  ['czsmo', 3],
  ['czspc', 3],
  ['czste', 3],
  ['czstt', 3],
  ['czbra', 1],
  ['czsdo', 3],
  ['cztva', 3],
  ['cztis', 3],
  ['cztom', 3],
  ['czvka', 1],
  ['cztuc', 1],
  ['czuhe', 6],
  ['czuln', 3],
  ['czves', 1],
  ['czvaa', 3],
  ['czvry', 3],
  ['czvmo', 6],
  ['czzln', 6],
  ['desde', 3],
  ['desdd', 1],
  ['deabh', 3],
  ['deabf', 3],
  ['deabl', 3],
  ['deabr', 3],
  ['deamr', 3],
  ['deawr', 3],
  ['deahc', 3],
  ['deaho', 3],
  ['deait', 3],
  ['deile', 1],
  ['detsh', 3],
  ['deaqf', 3],
  ['dealf', 3],
  ['deaxb', 3],
  ['deabo', 3],
  ['dealt', 3],
  ['deanr', 1],
  ['desh4', 1],
  ['deatl', 3],
  ['dehhv', 3],
  ['deahu', 1],
  ['deamb', 3],
  ['deaml', 3],
  ['dearl', 3],
  ['deiam', 1],
  ['deand', 3],
  ['deank', 3],
  ['deape', 3],
  ['deapn', 3],
  ['dease', 3],
  ['dearn', 3],
  ['deasc', 3],
  ['deass', 3],
  ['deauo', 1],
  ['deagb', 3],
  ['deaug', 3],
  ['deaul', 3],
  ['deaur', 3],
  ['deaeb', 3],
  ['deasr', 3],
  ['debbn', 6],
  ['dedch', 3],
  ['debbv', 3],
  ['derhx', 1],
  ['debdu', 6],
  ['deubg', 3],
  ['debes', 3],
  ['debfh', 3],
  ['debgm', 6],
  ['debgo', 3],
  ['debhe', 3],
  ['debnr', 6],
  ['deboy', 3],
  ['debds', 3],
  ['debsa', 3],
  ['debez', 3],
  ['detej', 1],
  ['debam', 3],
  ['degbx', 1],
  ['debrk', 3],
  ['debbh', 3],
  ['debas', 3],
  ['debyu', 6],
  ['debzs', 3],
  ['debkw', 3],
  ['debei', 3],
  ['deelg', 3],
  ['debdf', 3],
  ['deben', 3],
  ['dehub', 3],
  ['debgl', 3],
  ['debnt', 6],
  ['desxf', 6],
  ['detxl', 4],
  ['dethf', 6],
  ['debmn', 3],
  ['debof', 1],
  ['debek', 3],
  ['debti', 3],
  ['debev', 3],
  ['debkf', 3],
  ['deien', 3],
  ['deerx', 1],
  ['debbi', 3],
  ['debiw', 3],
  ['debin', 3],
  ['debzr', 1],
  ['debq2', 3],
  ['debwq', 1],
  ['debit', 6],
  ['deckh', 3],
  ['debmg', 6],
  ['deoin', 3],
  ['deblu', 6],
  ['deblm', 3],
  ['deckk', 3],
  ['debco', 3],
  ['deboc', 3],
  ['debtc', 3],
  ['debff', 3],
  ['deboi', 1],
  ['debok', 3],
  ['debyt', 3],
  ['debmk', 5],
  ['debct', 3],
  ['debfl', 3],
  ['debta', 3],
  ['debot', 3],
  ['debxb', 3],
  ['debcw', 3],
  ['debtf', 3],
  ['debke', 3],
  ['derlz', 3],
  ['debra', 3],
  ['debrm', 3],
  ['debbg', 3],
  ['debwe', 3],
  ['deudf', 3],
  ['debsh', 3],
  ['debrt', 3],
  ['debmv', 3],
  ['detld', 1],
  ['debri', 3],
  ['debnk', 3],
  ['deriz', 3],
  ['debda', 3],
  ['debro', 3],
  ['debrb', 3],
  ['debsi', 3],
  ['delyy', 1],
  ['debeu', 3],
  ['deurd', 6],
  ['debrg', 3],
  ['debld', 3],
  ['debsk', 3],
  ['debzq', 3],
  ['deurr', 1],
  ['debut', 3],
  ['debux', 3],
  ['deboe', 3],
  ['debmo', 3],
  ['debrn', 3],
  ['deusf', 3],
  ['debul', 3],
  ['debuq', 6],
  ['debum', 3],
  ['debuz', 3],
  ['decan', 6],
  ['decar', 3],
  ['decyn', 1],
  ['decrl', 3],
  ['dechi', 3],
  ['decoc', 3],
  ['decso', 6],
  ['decpz', 3],
  ['decrr', 3],
  ['decum', 1],
  ['decux', 3],
  ['dedad', 1],
  ['dedag', 3],
  ['dedap', 3],
  ['dedan', 3],
  ['dedwd', 3],
  ['demvd', 3],
  ['dedat', 3],
  ['dedga', 3],
  ['dedeg', 3],
  ['dedmn', 1],
  ['dednr', 3],
  ['dedeu', 3],
  ['derpd', 1],
  ['dedsu', 1],
  ['dedfn', 1],
  ['dedeb', 1],
  ['dedia', 3],
  ['dedhm', 3],
  ['dedsb', 3],
  ['dedbl', 6],
  ['dedsc', 3],
  ['dedin', 3],
  ['dedt8', 3],
  ['dedit', 3],
  ['dede3', 3],
  ['dedow', 3],
  ['dednt', 3],
  ['dedmg', 3],
  ['derkq', 1],
  ['dedob', 3],
  ['dedon', 3],
  ['dedra', 3],
  ['dedr5', 3],
  ['dendq', 1],
  ['dedro', 3],
  ['dedul', 3],
  ['dedyk', 3],
  ['delz6', 1],
  ['dedrp', 3],
  ['dedve', 3],
  ['deebb', 3],
  ['deegc', 3],
  ['deeck', 3],
  ['deedi', 3],
  ['deeax', 3],
  ['deegg', 6],
  ['deegl', 3],
  ['deegj', 1],
  ['deeom', 3],
  ['deehi', 3],
  ['deebi', 3],
  ['deeic', 6],
  ['deedd', 3],
  ['deehs', 3],
  ['deelb', 3],
  ['deelm', 3],
  ['deelf', 3],
  ['deels', 3],
  ['deler', 3],
  ['deetn', 3],
  ['deezh', 3],
  ['deem4', 1],
  ['deesu', 1],
  ['deemm', 3],
  ['deerf', 6],
  ['deery', 1],
  ['deeau', 1],
  ['deeam', 3],
  ['deesf', 3],
  ['deety', 1],
  ['dexax', 3],
  ['deeut', 3],
  ['defbr', 1],
  ['defhm', 3],
  ['demv5', 3],
  ['derp7', 1],
  ['defbp', 6],
  ['defsh', 1],
  ['deffo', 3],
  ['defrr', 3],
  ['defes', 3],
  ['defre', 3],
  ['defwh', 3],
  ['defhl', 3],
  ['defri', 3],
  ['defhe', 1],
  ['defye', 3],
  ['defnw', 1],
  ['degxg', 3],
  ['delli', 3],
  ['degan', 6],
  ['degao', 3],
  ['degxs', 1],
  ['degst', 3],
  ['degnl', 1],
  ['degek', 3],
  ['degel', 3],
  ['degem', 3],
  ['deger', 3],
  ['deghm', 3],
  ['degfd', 3],
  ['deghz', 3],
  ['degev', 3],
  ['degs5', 1],
  ['degxn', 1],
  ['degnp', 3],
  ['degig', 3],
  ['degit', 3],
  ['deglk', 1],
  ['deglu', 3],
  ['deund', 3],
  ['degdo', 3],
  ['degtr', 3],
  ['degkr', 1],
  ['degmv', 1],
  ['degff', 1],
  ['derbi', 3],
  ['degnr', 3],
  ['derlb', 3],
  ['degre', 3],
  ['degrh', 6],
  ['degrd', 3],
  ['deith', 3],
  ['degmr', 3],
  ['degmn', 3],
  ['degnu', 3],
  ['degpr', 3],
  ['degrk', 6],
  ['degkg', 3],
  ['dehho', 1],
  ['degro', 3],
  ['degsi', 3],
  ['degsa', 3],
  ['degzg', 3],
  ['deefe', 1],
  ['degxr', 1],
  ['degdz', 1],
  ['degue', 3],
  ['degnx', 1],
  ['dego4', 1],
  ['degug', 3],
  ['deqgt', 4],
  ['dehaf', 3],
  ['dehnh', 4],
  ['dehlh', 3],
  ['dehrx', 1],
  ['dehtj', 3],
  ['dehan', 3],
  ['dehmm', 3],
  ['dehau', 3],
  ['dexzs', 1],
  ['dehbu', 1],
  ['dehrn', 3],
  ['dehsx', 3],
  ['denih', 1],
  ['dehlo', 3],
  ['dehao', 3],
  ['dehme', 3],
  ['dehh7', 1],
  ['dehhl', 3],
  ['dehde', 6],
  ['dehei', 3],
  ['dehsi', 3],
  ['dehed', 3],
  ['dehen', 3],
  ['dehhf', 3],
  ['dehe5', 1],
  ['dehrc', 3],
  ['dehgl', 5],
  ['dehml', 3],
  ['dehmo', 3],
  ['dehnf', 3],
  ['dexrt', 1],
  ['deh54', 1],
  ['dehbr', 3],
  ['dehgz', 3],
  ['dehdf', 6],
  ['dehee', 3],
  ['dehrt', 3],
  ['dehzh', 6],
  ['dessl', 3],
  ['deheu', 6],
  ['dehdi', 3],
  ['dehie', 3],
  ['dehlp', 3],
  ['dehre', 3],
  ['dehd8', 3],
  ['dehod', 3],
  ['deho4', 3],
  ['dehoc', 6],
  ['dehoq', 6],
  ['dejuy', 1],
  ['dehoj', 1],
  ['dehhs', 3],
  ['dehsa', 3],
  ['deowh', 3],
  ['devvh', 1],
  ['dehln', 3],
  ['dehtu', 3],
  ['dehzn', 3],
  ['dehbo', 3],
  ['dehge', 3],
  ['dehoo', 3],
  ['dehpe', 3],
  ['de3l4', 3],
  ['dehob', 3],
  ['dehrb', 3],
  ['dehos', 3],
  ['dehro', 3],
  ['dehsu', 3],
  ['dehus', 3],
  ['dehzz', 3],
  ['dehcs', 3],
  ['dehrm', 3],
  ['dehsb', 3],
  ['dehox', 3],
  ['dehux', 1],
  ['deigh', 3],
  ['deihl', 1],
  ['dezve', 1],
  ['deiln', 6],
  ['deinm', 3],
  ['deing', 3],
  ['deisp', 1],
  ['deisu', 3],
  ['deitz', 3],
  ['dejem', 3],
  ['dejtn', 3],
  ['dejui', 5],
  ['dejsn', 3],
  ['dekam', 3],
  ['dekmt', 3],
  ['dekop', 1],
  ['dekdt', 1],
  ['dekpe', 3],
  ['dekap', 3],
  ['dekbn', 3],
  ['dekar', 3],
  ['dekhu', 3],
  ['dekas', 6],
  ['dekeh', 3],
  ['dekem', 3],
  ['dekhn', 3],
  ['dekez', 3],
  ['dekeb', 3],
  ['dennp', 3],
  ['dekls', 3],
  ['dejiv', 1],
  ['dekie', 3],
  ['dekbr', 3],
  ['dekpf', 3],
  ['dekcn', 6],
  ['denwc', 1],
  ['dekco', 3],
  ['derft', 1],
  ['dekit', 3],
  ['deklx', 1],
  ['dekob', 3],
  ['dekho', 3],
  ['dekof', 3],
  ['dekol', 3],
  ['dekon', 3],
  ['dekb6', 1],
  ['dekzb', 3],
  ['dekra', 3],
  ['dekre', 3],
  ['deknt', 3],
  ['dekgu', 3],
  ['dekr9', 1],
  ['dekrv', 3],
  ['dekku', 3],
  ['dekup', 3],
  ['dekut', 3],
  ['dekwh', 3],
  ['dekgm', 3],
  ['deknw', 6],
  ['dekow', 3],
  ['dektn', 3],
  ['dekvg', 3],
  ['delab', 3],
  ['delhd', 3],
  ['delam', 3],
  ['delad', 3],
  ['deaad', 1],
  ['deldh', 3],
  ['de73a', 3],
  ['delgb', 3],
  ['deldm', 3],
  ['delhn', 6],
  ['delaw', 3],
  ['de6nk', 3],
  ['delki', 3],
  ['delsa', 3],
  ['delat', 3],
  ['deubt', 3],
  ['dede8', 1],
  ['delnu', 3],
  ['delpm', 6],
  ['dels4', 1],
  ['deuta', 1],
  ['delee', 3],
  ['deccc', 1],
  ['deeem', 1],
  ['delia', 3],
  ['de23a', 1],
  ['delph', 3],
  ['delej', 6],
  ['deliz', 3],
  ['delrc', 3],
  ['deezn', 3],
  ['deddd', 3],
  ['delev', 3],
  ['deilh', 3],
  ['deltf', 3],
  ['delib', 3],
  ['delpq', 3],
  ['delsr', 3],
  ['delsh', 1],
  ['deliu', 3],
  ['delig', 3],
  ['delin', 3],
  ['delrn', 3],
  ['delis', 3],
  ['descj', 1],
  ['deong', 3],
  ['delon', 3],
  ['derpl', 1],
  ['delcw', 3],
  ['delbm', 3],
  ['delsu', 3],
  ['deluh', 3],
  ['delgx', 3],
  ['delow', 1],
  ['detje', 1],
  ['delae', 3],
  ['delnh', 3],
  ['dells', 3],
  ['delt9', 3],
  ['demag', 3],
  ['demai', 3],
  ['degym', 3],
  ['demam', 3],
  ['demfl', 3],
  ['demmf', 1],
  ['demmi', 3],
  ['demrm', 3],
  ['demrs', 3],
  ['demtd', 1],
  ['demlo', 3],
  ['demsk', 3],
  ['demxa', 3],
  ['demxm', 3],
  ['demhr', 3],
  ['demru', 3],
  ['demdi', 3],
  ['demzn', 6],
  ['deme8', 1],
  ['demim', 3],
  ['demei', 3],
  ['demel', 3],
  ['demze', 3],
  ['demem', 1],
  ['demnd', 6],
  ['degj9', 1],
  ['demse', 6],
  ['demsh', 3],
  ['dez4z', 3],
  ['demil', 3],
  ['demid', 3],
  ['demnm', 3],
  ['demin', 3],
  ['demib', 3],
  ['demwf', 3],
  ['demsb', 3],
  ['demmm', 3],
  ['demww', 1],
  ['demnf', 3],
  ['demhm', 3],
  ['dexmp', 3],
  ['demoz', 3],
  ['demuk', 3],
  ['deuls', 1],
  ['dem5b', 1],
  ['demun', 3],
  ['demml', 6],
  ['demgw', 1],
  ['demoe', 3],
  ['demgl', 6],
  ['degl3', 3],
  ['demxb', 1],
  ['demmo', 3],
  ['demuh', 3],
  ['demuc', 6],
  ['defmo', 6],
  ['demnq', 3],
  ['denzm', 3],
  ['denst', 3],
  ['denta', 3],
  ['denze', 3],
  ['denfe', 3],
  ['densy', 1],
  ['denes', 3],
  ['defer', 1],
  ['denbg', 6],
  ['dennh', 1],
  ['densc', 3],
  ['dense', 3],
  ['denef', 3],
  ['denhs', 3],
  ['deneh', 3],
  ['deuki', 1],
  ['denel', 3],
  ['denn4', 3],
  ['denmn', 3],
  ['densv', 3],
  ['denbd', 1],
  ['denca', 3],
  ['denss', 3],
  ['denho', 3],
  ['dengl', 3],
  ['dened', 3],
  ['debwj', 3],
  ['dedfx', 3],
  ['dech5', 3],
  ['dengh', 3],
  ['denhl', 3],
  ['denie', 3],
  ['dencz', 3],
  ['denbi', 6],
  ['denha', 3],
  ['denhe', 3],
  ['denhn', 3],
  ['denor', 3],
  ['depjq', 1],
  ['deno9', 3],
  ['deoab', 1],
  ['deorm', 3],
  ['deolm', 1],
  ['deoex', 1],
  ['deobe', 3],
  ['deoan', 3],
  ['deoky', 3],
  ['deobp', 6],
  ['dedeo', 3],
  ['deoee', 3],
  ['deowt', 3],
  ['deoen', 6],
  ['deosw', 3],
  ['deoff', 3],
  ['deolo', 3],
  ['deolb', 3],
  ['deold', 3],
  ['deolu', 1],
  ['deolp', 3],
  ['deotk', 3],
  ['deopp', 3],
  ['deors', 3],
  ['deort', 3],
  ['deosl', 3],
  ['deosn', 3],
  ['deosb', 3],
  ['deosr', 3],
  ['detrd', 3],
  ['deter', 3],
  ['deokg', 3],
  ['desxd', 1],
  ['deott', 3],
  ['depad', 6],
  ['depah', 1],
  ['depap', 3],
  ['depry', 3],
  ['deptn', 3],
  ['depas', 3],
  ['depzi', 3],
  ['depei', 3],
  ['depel', 3],
  ['depen', 3],
  ['deprc', 3],
  ['deptd', 3],
  ['depsw', 3],
  ['depfo', 6],
  ['depfr', 3],
  ['deaq2', 3],
  ['depsb', 3],
  ['deppt', 3],
  ['depsr', 3],
  ['depin', 3],
  ['depra', 3],
  ['dettp', 1],
  ['depsa', 3],
  ['depls', 3],
  ['depll', 3],
  ['depow', 6],
  ['depot', 3],
  ['deprw', 3],
  ['deprn', 3],
  ['depce', 3],
  ['deput', 3],
  ['depku', 3],
  ['derdu', 3],
  ['derad', 3],
  ['dere5', 1],
  ['derck', 3],
  ['deree', 3],
  ['dereg', 3],
  ['dertj', 1],
  ['derfi', 3],
  ['derem', 3],
  ['deren', 3],
  ['deret', 3],
  ['derzb', 3],
  ['derhb', 3],
  ['derhl', 3],
  ['derhd', 6],
  ['derhe', 3],
  ['derrg', 3],
  ['derdl', 3],
  ['derik', 3],
  ['derbk', 3],
  ['dexxx', 1],
  ['dehjk', 1],
  ['deros', 3],
  ['derdt', 3],
  ['derbf', 3],
  ['derot', 6],
  ['deohe', 3],
  ['derdw', 3],
  ['derar', 3],
  ['derub', 3],
  ['de2ro', 1],
  ['deru7', 1],
  ['derue', 3],
  ['dernt', 3],
  ['derum', 3],
  ['desag', 3],
  ['desaa', 3],
  ['desks', 3],
  ['desar', 3],
  ['dezmu', 3],
  ['desma', 3],
  ['depsh', 3],
  ['desas', 3],
  ['deslu', 6],
  ['desau', 3],
  ['desdi', 3],
  ['des3b', 3],
  ['desef', 3],
  ['descv', 3],
  ['desls', 3],
  ['demha', 3],
  ['desnr', 3],
  ['despd', 3],
  ['desz8', 1],
  ['dechl', 3],
  ['deutt', 3],
  ['decwn', 3],
  ['deswb', 6],
  ['deswf', 3],
  ['deswq', 3],
  ['deswz', 3],
  ['desdt', 3],
  ['descw', 3],
  ['desgw', 3],
  ['deswt', 1],
  ['desri', 3],
  ['deiew', 3],
  ['deb85', 3],
  ['deoke', 3],
  ['desfl', 3],
  ['desps', 3],
  ['desxe', 1],
  ['dese8', 1],
  ['dekft', 1],
  ['deseh', 3],
  ['desne', 3],
  ['deysf', 3],
  ['desex', 6],
  ['dedee', 1],
  ['deehm', 3],
  ['desra', 3],
  ['desqj', 3],
  ['desge', 6],
  ['despe', 3],
  ['despi', 3],
  ['depyk', 3],
  ['desta', 3],
  ['dests', 3],
  ['deslo', 6],
  ['desl5', 1],
  ['desn2', 1],
  ['dehsj', 1],
  ['desws', 1],
  ['deskn', 3],
  ['desrp', 1],
  ['desoc', 3],
  ['desnp', 3],
  ['destl', 3],
  ['destd', 3],
  ['destb', 3],
  ['detlb', 3],
  ['destr', 6],
  ['deuhf', 3],
  ['deslz', 3],
  ['dezb4', 1],
  ['desep', 3],
  ['desud', 3],
  ['desnc', 3],
  ['detar', 3],
  ['detae', 3],
  ['detlt', 3],
  ['detmm', 3],
  ['detcn', 1],
  ['detwz', 3],
  ['dethr', 1],
  ['detoy', 1],
  ['dethu', 3],
  ['detyn', 1],
  ['derrt', 1],
  ['detgm', 3],
  ['detsu', 3],
  ['detch', 1],
  ['detmh', 3],
  ['detos', 3],
  ['detrt', 3],
  ['detrv', 3],
  ['detbn', 3],
  ['detrh', 3],
  ['detkd', 3],
  ['detri', 3],
  ['detrf', 3],
  ['detoe', 1],
  ['deuh7', 1],
  ['deuck', 3],
  ['deuel', 3],
  ['deues', 3],
  ['deuet', 3],
  ['deulm', 3],
  ['deups', 3],
  ['deguz', 3],
  ['devar', 3],
  ['devab', 6],
  ['devna', 3],
  ['deven', 3],
  ['devej', 3],
  ['devtz', 3],
  ['deviu', 1],
  ['deviw', 1],
  ['devrw', 1],
  ['devgf', 3],
  ['devbs', 3],
  ['dev44', 3],
  ['devtt', 1],
  ['devoc', 3],
  ['devrd', 3],
  ['devol', 3],
  ['devoe', 3],
  ['dewaz', 3],
  ['deahh', 1],
  ['dewle', 3],
  ['dewrn', 6],
  ['deweo', 4],
  ['dewlm', 3],
  ['dewtp', 3],
  ['dewnl', 3],
  ['dewke', 3],
  ['deane', 1],
  ['dewar', 3],
  ['deera', 3],
  ['deywx', 3],
  ['dewed', 3],
  ['dewnt', 3],
  ['desww', 1],
  ['dewee', 3],
  ['dewgq', 1],
  ['dewei', 6],
  ['dewkk', 3],
  ['dewwi', 3],
  ['deiwn', 1],
  ['deadt', 3],
  ['derrp', 1],
  ['debyw', 1],
  ['dewng', 3],
  ['dewnu', 3],
  ['deitn', 3],
  ['dewmm', 3],
  ['deric', 3],
  ['dewtm', 3],
  ['dewes', 3],
  ['dewe5', 3],
  ['dewac', 3],
  ['degwt', 6],
  ['dettr', 1],
  ['dewew', 3],
  ['dewkz', 1],
  ['dewik', 3],
  ['dewie', 3],
  ['dewid', 6],
  ['dewb2', 1],
  ['dewil', 3],
  ['dewim', 3],
  ['dewnh', 3],
  ['dewwh', 1],
  ['dewni', 3],
  ['dewhp', 1],
  ['dewph', 6],
  ['dewih', 3],
  ['dewif', 3],
  ['dewis', 3],
  ['dewtg', 3],
  ['detnr', 3],
  ['dewtu', 3],
  ['dewit', 3],
  ['dewmd', 6],
  ['deohl', 3],
  ['dewob', 3],
  ['dewol', 3],
  ['deolk', 3],
  ['delzg', 3],
  ['dewor', 3],
  ['dewog', 3],
  ['dewry', 1],
  ['derze', 3],
  ['dewri', 3],
  ['dewup', 6],
  ['dewfa', 1],
  ['dewyk', 3],
  ['dewoe', 3],
  ['dewta', 3],
  ['dewue', 3],
  ['dezha', 3],
  ['deziz', 1],
  ['dezai', 1],
  ['dezrh', 3],
  ['dezhd', 3],
  ['dezh8', 3],
  ['dezzl', 3],
  ['dezlt', 3],
  ['dezth', 3],
  ['dezis', 3],
  ['dezin', 3],
  ['dezor', 3],
  ['dezzh', 3],
  ['deawi', 1],
  ['deueb', 3],
  ['djaii', 4],
  ['djjib', 5],
  ['djpod', 3],
  ['djdct', 3],
  ['djmhi', 6],
  ['djobc', 4],
  ['djtdj', 4],
  ['dkndb', 1],
  ['dkore', 1],
  ['dkves', 1],
  ['dkror', 1],
  ['dkago', 1],
  ['dkagh', 3],
  ['dkash', 1],
  ['dkage', 3],
  ['dkanh', 1],
  ['dkasa', 3],
  ['dkask', 1],
  ['dkasv', 1],
  ['dkasn', 1],
  ['dkaub', 1],
  ['dkvre', 1],
  ['dkave', 1],
  ['dkavk', 1],
  ['dkavn', 1],
  ['dkbgo', 1],
  ['dkblu', 3],
  ['dkbag', 1],
  ['dkblb', 1],
  ['dkbal', 1],
  ['dkbdx', 1],
  ['dkbll', 4],
  ['dkbla', 1],
  ['dkbgz', 3],
  ['dkbog', 1],
  ['dkbrb', 1],
  ['dkbrh', 1],
  ['dkbos', 3],
  ['dkbbg', 3],
  ['dkdan', 1],
  ['dkkon', 1],
  ['dkoot', 1],
  ['dkcso', 1],
  ['dkdn6', 3],
  ['dkdas', 1],
  ['dkdra', 1],
  ['dkdys', 1],
  ['dkebt', 1],
  ['dkegn', 1],
  ['dkend', 3],
  ['dkedl', 1],
  ['dkens', 1],
  ['dkek4', 3],
  ['dkfaa', 1],
  ['dkfak', 1],
  ['dkfgs', 1],
  ['dkfej', 1],
  ['dkfmo', 1],
  ['dkfer', 3],
  ['dkfrc', 1],
  ['dkfdh', 1],
  ['dkfds', 3],
  ['dkfdv', 1],
  ['dkfuh', 1],
  ['dkfyh', 3],
  ['dkfrk', 3],
  ['dkged', 1],
  ['dkgts', 1],
  ['dkgly', 1],
  ['dkgre', 3],
  ['dkgra', 1],
  ['dkgdm', 3],
  ['dkgfh', 1],
  ['dkhad', 1],
  ['dkhsu', 3],
  ['dkhas', 1],
  ['dkhhv', 1],
  ['dkhan', 3],
  ['dkhdh', 1],
  ['dkhsl', 1],
  ['dkhnb', 1],
  ['dkhvn', 1],
  ['dkhej', 1],
  ['dkhvt', 3],
  ['dkhls', 1],
  ['dkher', 1],
  ['dkhir', 1],
  ['dkhjt', 3],
  ['dkhbo', 1],
  ['dkhbk', 1],
  ['dkhsb', 1],
  ['dkhor', 1],
  ['dkhoh', 1],
  ['dkhwo', 1],
  ['dkhba', 3],
  ['dkhun', 3],
  ['dkhva', 1],
  ['dkhvs', 3],
  ['dkhj3', 3],
  ['dkjel', 1],
  ['dkjue', 1],
  ['dkkal', 1],
  ['dkkar', 3],
  ['dkkbk', 3],
  ['dkkrp', 4],
  ['dkktp', 1],
  ['dkktd', 1],
  ['dkkt4', 3],
  ['dkklp', 3],
  ['dkkbg', 3],
  ['dkklh', 1],
  ['dkkhv', 1],
  ['dkkok', 1],
  ['dkkol', 3],
  ['dkkdm', 3],
  ['dkkgl', 1],
  ['dkkrr', 1],
  ['dkkra', 1],
  ['dkkvr', 1],
  ['dkkby', 1],
  ['dkkog', 1],
  ['dklvg', 1],
  ['dklhh', 1],
  ['dklin', 1],
  ['dkloh', 1],
  ['dklun', 3],
  ['dklyo', 1],
  ['dklgr', 1],
  ['dkmrb', 1],
  ['dkmrr', 3],
  ['dkmrv', 6],
  ['dkmrs', 1],
  ['dkmkh', 3],
  ['dkmns', 3],
  ['dkunx', 1],
  ['dkmas', 1],
  ['dkmid', 1],
  ['dkmom', 1],
  ['dkngb', 3],
  ['dknak', 1],
  ['dknex', 1],
  ['dknhn', 1],
  ['dkvsv', 1],
  ['dknbg', 1],
  ['dknyb', 1],
  ['dkn3s', 3],
  ['dknyf', 3],
  ['dknym', 3],
  ['dknr4', 3],
  ['dkntd', 1],
  ['dknud', 1],
  ['dknvd', 3],
  ['dknre', 1],
  ['dknsd', 3],
  ['dknrs', 1],
  ['dkomo', 1],
  ['dkoro', 1],
  ['dkran', 3],
  ['dkrmo', 3],
  ['dkrd4', 3],
  ['dkrkb', 1],
  ['dkrys', 1],
  ['dkrof', 3],
  ['dkrod', 3],
  ['dkrdk', 3],
  ['dkrdh', 1],
  ['dkrnn', 5],
  ['dkrrv', 1],
  ['dkkii', 1],
  ['dksax', 1],
  ['dksd7', 3],
  ['dksvg', 3],
  ['dkseo', 1],
  ['dkslb', 3],
  ['dkska', 1],
  ['dksdo', 1],
  ['dkskv', 1],
  ['dksk3', 3],
  ['dkskh', 1],
  ['dkskr', 6],
  ['dkssk', 1],
  ['dksne', 1],
  ['dksn2', 3],
  ['dkspb', 1],
  ['dkstt', 1],
  ['dksta', 5],
  ['dkste', 1],
  ['dksn7', 3],
  ['dkstp', 1],
  ['dkstg', 1],
  ['dksit', 1],
  ['dkstn', 1],
  ['dksf2', 3],
  ['dksh6', 3],
  ['dksm6', 3],
  ['dkstb', 1],
  ['dkstr', 1],
  ['dksno', 1],
  ['dksb2', 3],
  ['dksbk', 1],
  ['dkssv', 1],
  ['dkeot', 1],
  ['dksvv', 1],
  ['dksny', 3],
  ['dksue', 1],
  ['dksva', 3],
  ['dksve', 1],
  ['dksda', 3],
  ['dksae', 3],
  ['dkslv', 1],
  ['dksob', 1],
  ['dksl2', 3],
  ['dkted', 5],
  ['dktkb', 3],
  ['dktjl', 1],
  ['dktb4', 3],
  ['dktrg', 3],
  ['dktmd', 3],
  ['dktro', 1],
  ['dktub', 1],
  ['dktn3', 3],
  ['dktno', 1],
  ['dktyb', 1],
  ['dktrs', 1],
  ['dkvae', 3],
  ['dkvng', 1],
  ['dkvb2', 3],
  ['dkvej', 1],
  ['dkvk7', 3],
  ['dkvm2', 3],
  ['dkven', 1],
  ['dkvt2', 3],
  ['dksks', 6],
  ['dkvor', 1],
  ['dkvbn', 1],
  ['dkvbs', 1],
  ['dkvg4', 3],
  ['dkvle', 6],
  ['dkaab', 3],
  ['dkkk4', 3],
  ['dkak3', 3],
  ['dkaro', 1],
  ['dkard', 1],
  ['dkark', 1],
  ['dmadm', 1],
  ['dmbel', 1],
  ['dmdom', 4],
  ['dmpor', 3],
  ['dmrsu', 1],
  ['doazu', 3],
  ['doban', 3],
  ['dobrx', 5],
  ['dobql', 1],
  ['dobav', 1],
  ['dobcc', 1],
  ['docbj', 5],
  ['docdc', 1],
  ['docai', 1],
  ['docau', 3],
  ['docal', 1],
  ['doeps', 4],
  ['doina', 1],
  ['dojqb', 4],
  ['dohig', 1],
  ['dolrm', 1],
  ['doman', 3],
  ['dooco', 1],
  ['dopdr', 1],
  ['dopuo', 1],
  ['dopal', 3],
  ['dopop', 1],
  ['dopva', 1],
  ['dopuj', 6],
  ['dohai', 1],
  ['dosnx', 5],
  ['doazs', 4],
  ['dosfn', 1],
  ['dospm', 1],
  ['dosti', 3],
  ['dosnz', 3],
  ['doval', 3],
  ['dzazr', 4],
  ['dzbuj', 4],
  ['dzaae', 5],
  ['dzazw', 3],
  ['dzblj', 4],
  ['dzcbh', 4],
  ['dzbja', 5],
  ['dzbsf', 1],
  ['dzzbi', 4],
  ['dzbha', 1],
  ['dzbta', 1],
  ['dzbsk', 4],
  ['dzbmw', 4],
  ['dzbiu', 3],
  ['dzche', 3],
  ['dzcol', 3],
  ['dzczl', 4],
  ['dzdel', 3],
  ['dzdjg', 4],
  ['dzdje', 1],
  ['dzebh', 4],
  ['dzelg', 4],
  ['dzelu', 4],
  ['dzgbb', 4],
  ['dzghz', 3],
  ['dzhme', 6],
  ['dzinf', 4],
  ['dzvvz', 4],
  ['dziam', 4],
  ['dzinz', 4],
  ['dzgjl', 4],
  ['dzdji', 1],
  ['dzkhe', 3],
  ['dzloo', 4],
  ['dzmuw', 4],
  ['dzmos', 1],
  ['dzmzw', 4],
  ['dzorn', 5],
  ['dz2on', 1],
  ['dzogx', 4],
  ['dzrou', 1],
  ['dzskk', 6],
  ['dzski', 5],
  ['dztmr', 4],
  ['dztee', 4],
  ['dztbs', 1],
  ['dztid', 4],
  ['dztmx', 4],
  ['dztin', 4],
  ['dztgr', 4],
  ['dzten', 1],
  ['ecayo', 1],
  ['ecvil', 1],
  ['ecatf', 6],
  ['ecbha', 5],
  ['ecebl', 1],
  ['ecbli', 1],
  ['eccat', 1],
  ['eccue', 6],
  ['ecdun', 1],
  ['ecesm', 5],
  ['ecesp', 1],
  ['ecflo', 1],
  ['ecgps', 4],
  ['ecgye', 5],
  ['ecgua', 1],
  ['ecwgl', 4],
  ['ecjip', 6],
  ['eclld', 1],
  ['eclpt', 3],
  ['eclgq', 4],
  ['ecloh', 4],
  ['ecmrr', 4],
  ['ecxms', 4],
  ['ecmch', 4],
  ['ecmec', 5],
  ['ecmzd', 4],
  ['ecptz', 4],
  ['eccar', 3],
  ['ecpvo', 6],
  ['ecpsj', 3],
  ['ecpbg', 1],
  ['ecbaq', 1],
  ['ecpbo', 1],
  ['ecpeg', 1],
  ['ecpev', 1],
  ['ecpun', 3],
  ['ecpyo', 4],
  ['ecuio', 4],
  ['ecrab', 1],
  ['ecsnc', 1],
  ['ecscy', 4],
  ['ecslr', 1],
  ['ecwse', 4],
  ['ecsym', 1],
  ['ecsuq', 4],
  ['ectsc', 4],
  ['ectpc', 4],
  ['ectep', 1],
  ['ectpn', 4],
  ['ectua', 4],
  ['ecztl', 1],
  ['eeabj', 1],
  ['eeabr', 1],
  ['eeadv', 1],
  ['eeaen', 1],
  ['eeaho', 1],
  ['eealj', 1],
  ['eeajs', 1],
  ['eeaks', 1],
  ['eease', 3],
  ['eeatl', 1],
  ['eebek', 1],
  ['eedir', 1],
  ['eedob', 1],
  ['eeeis', 1],
  ['eehaa', 3],
  ['eehvv', 1],
  ['eehld', 1],
  ['eehra', 1],
  ['eehrs', 1],
  ['eehlt', 1],
  ['eehii', 1],
  ['eehun', 1],
  ['eejgp', 1],
  ['eejht', 1],
  ['eejep', 1],
  ['eejkr', 1],
  ['eejos', 1],
  ['eekvi', 1],
  ['eekbn', 1],
  ['eekak', 1],
  ['eekal', 1],
  ['eekas', 1],
  ['eekld', 1],
  ['eekjk', 1],
  ['eekle', 3],
  ['eekll', 3],
  ['eeklp', 1],
  ['eeklr', 1],
  ['eekam', 1],
  ['eekpa', 1],
  ['eekap', 1],
  ['eekks', 1],
  ['eeksn', 1],
  ['eeksp', 1],
  ['eekvr', 1],
  ['eekln', 1],
  ['eekel', 1],
  ['eekej', 1],
  ['eekhn', 1],
  ['eekii', 1],
  ['eekgv', 1],
  ['eeklk', 1],
  ['eekol', 1],
  ['eekdr', 1],
  ['eekui', 1],
  ['eekug', 1],
  ['eeknd', 1],
  ['eekng', 1],
  ['eekur', 1],
  ['eekke', 1],
  ['eekdl', 5],
  ['eeksa', 1],
  ['eekgs', 1],
  ['eekrg', 1],
  ['eelks', 1],
  ['eelhs', 3],
  ['eelao', 1],
  ['eelau', 1],
  ['eelht', 1],
  ['eelhk', 1],
  ['eelen', 1],
  ['eelep', 3],
  ['eelpn', 1],
  ['eelin', 1],
  ['eeliu', 1],
  ['eelsl', 1],
  ['eelsa', 1],
  ['eelui', 1],
  ['eelan', 1],
  ['eelat', 1],
  ['eelma', 1],
  ['eelps', 1],
  ['eelnr', 1],
  ['eemah', 1],
  ['eeman', 1],
  ['eemrs', 1],
  ['eemeh', 1],
  ['eemrl', 1],
  ['eemih', 1],
  ['eemdr', 1],
  ['eemun', 1],
  ['eemur', 1],
  ['eemtv', 1],
  ['eemug', 1],
  ['eemni', 1],
  ['eemnt', 1],
  ['eemnv', 1],
  ['eemol', 1],
  ['eenai', 1],
  ['eenst', 1],
  ['eenjs', 1],
  ['eenas', 1],
  ['eenjd', 1],
  ['eenme', 1],
  ['eenob', 1],
  ['eenrm', 1],
  ['eenva', 1],
  ['eeoiu', 1],
  ['eeomr', 1],
  ['eeori', 1],
  ['eeorj', 1],
  ['eeosm', 1],
  ['eeoti', 1],
  ['eeots', 1],
  ['eepaa', 1],
  ['eepsm', 1],
  ['eepls', 1],
  ['eepln', 1],
  ['eepas', 1],
  ['eepap', 1],
  ['eepsv', 1],
  ['eepar', 3],
  ['eepri', 1],
  ['eephl', 1],
  ['eepre', 1],
  ['eepvs', 1],
  ['eepir', 1],
  ['eeprl', 1],
  ['eepur', 3],
  ['eeprj', 1],
  ['eeprk', 1],
  ['eepus', 1],
  ['eeprn', 1],
  ['eeprv', 1],
  ['eeraj', 1],
  ['eernm', 1],
  ['eerbs', 1],
  ['eervs', 1],
  ['eerng', 3],
  ['eerst', 3],
  ['eerjs', 1],
  ['eerls', 1],
  ['eerhk', 1],
  ['eeron', 3],
  ['eernv', 1],
  ['eergr', 1],
  ['eerms', 1],
  ['eeroo', 1],
  ['eeruv', 1],
  ['eerlb', 1],
  ['eerpi', 1],
  ['eesgs', 1],
  ['eesma', 3],
  ['eesra', 1],
  ['eesvs', 1],
  ['eesln', 1],
  ['eesnk', 1],
  ['eesnp', 1],
  ['eesle', 1],
  ['eesam', 1],
  ['eesas', 1],
  ['eesig', 1],
  ['eeslm', 3],
  ['eesoe', 1],
  ['eesso', 1],
  ['eesua', 1],
  ['eesuk', 1],
  ['eeshl', 1],
  ['eesul', 1],
  ['eesur', 1],
  ['eesus', 1],
  ['eesvi', 1],
  ['eesru', 1],
  ['eesrv', 1],
  ['eetal', 1],
  ['eetll', 5],
  ['eetlv', 1],
  ['eetmn', 1],
  ['eetpl', 3],
  ['eetay', 6],
  ['eetrv', 1],
  ['eetil', 1],
  ['eetoi', 3],
  ['eetjs', 1],
  ['eetre', 1],
  ['eetgi', 1],
  ['eetlt', 1],
  ['eetja', 1],
  ['eetru', 1],
  ['eetma', 1],
  ['eevpa', 1],
  ['eevlr', 1],
  ['eevsg', 1],
  ['eevan', 1],
  ['eevps', 1],
  ['eevrs', 1],
  ['eevnj', 1],
  ['eevna', 3],
  ['eevvn', 1],
  ['eevsk', 1],
  ['eevks', 1],
  ['eevus', 1],
  ['eevee', 1],
  ['eevvm', 1],
  ['eeveb', 3],
  ['eever', 1],
  ['eevin', 1],
  ['eevik', 1],
  ['eevir', 1],
  ['eevis', 1],
  ['eevvs', 1],
  ['eevtr', 1],
  ['eevri', 1],
  ['eevar', 1],
  ['eevdu', 1],
  ['eevoi', 1],
  ['eevrk', 1],
  ['eevos', 1],
  ['eevop', 1],
  ['eewst', 1],
  ['eewjs', 1],
  ['eeost', 1],
  ['eeurg', 1],
  ['egaki', 1],
  ['egaue', 5],
  ['egabs', 4],
  ['egaza', 1],
  ['egais', 1],
  ['egada', 3],
  ['egdbb', 4],
  ['egagz', 3],
  ['egmin', 1],
  ['egaqu', 3],
  ['egeeg', 1],
  ['egsuz', 3],
  ['egatz', 4],
  ['egasw', 5],
  ['egast', 3],
  ['egbgb', 1],
  ['egben', 1],
  ['eghbe', 6],
  ['egdak', 4],
  ['egdmn', 4],
  ['egbeh', 1],
  ['egedk', 1],
  ['egaly', 5],
  ['egeob', 1],
  ['egcai', 5],
  ['egtor', 1],
  ['egaac', 6],
  ['eggua', 3],
  ['egfad', 1],
  ['egfan', 1],
  ['eggei', 1],
  ['eghal', 1],
  ['egham', 1],
  ['eghel', 1],
  ['eghrg', 1],
  ['egism', 1],
  ['egkeh', 1],
  ['egken', 1],
  ['eglxr', 4],
  ['egrmf', 4],
  ['egmuh', 5],
  ['egmah', 1],
  ['egemy', 4],
  ['egqah', 3],
  ['egca7', 1],
  ['eguvl', 4],
  ['egnuw', 1],
  ['egpib', 1],
  ['egpsd', 5],
  ['egpse', 3],
  ['egptk', 1],
  ['egqub', 1],
  ['egrab', 1],
  ['egrag', 1],
  ['egrsh', 1],
  ['egrsu', 1],
  ['egpra', 3],
  ['egsga', 1],
  ['egskr', 3],
  ['egskv', 4],
  ['eggsq', 4],
  ['egssh', 5],
  ['egsba', 1],
  ['egsew', 4],
  ['egshg', 1],
  ['egsok', 1],
  ['egsos', 1],
  ['egsin', 1],
  ['egscn', 1],
  ['egtcp', 4],
  ['egelt', 4],
  ['egwaf', 1],
  ['eheai', 3],
  ['erasm', 6],
  ['ertes', 4],
  ['esmpg', 1],
  ['esaxo', 3],
  ['esabh', 1],
  ['esach', 1],
  ['esadr', 3],
  ['esaga', 1],
  ['esagu', 1],
  ['esads', 1],
  ['esrda', 1],
  ['esaca', 3],
  ['esald', 1],
  ['esalg', 1],
  ['esalt', 3],
  ['esarz', 3],
  ['esalx', 1],
  ['esamx', 1],
  ['es2tt', 1],
  ['esamp', 3],
  ['esasl', 3],
  ['esarn', 1],
  ['esagg', 1],
  ['esari', 1],
  ['esami', 4],
  ['esast', 4],
  ['esatq', 1],
  ['esavs', 1],
  ['esuny', 1],
  ['esaya', 1],
  ['esnaa', 1],
  ['esayr', 1],
  ['esb8c', 1],
  ['esbjz', 6],
  ['esbdp', 1],
  ['esbdf', 3],
  ['esblr', 1],
  ['esite', 1],
  ['esucc', 1],
  ['esbda', 1],
  ['esbu3', 3],
  ['esbem', 1],
  ['esbmo', 3],
  ['esbgj', 1],
  ['esbgg', 1],
  ['esbrm', 3],
  ['esbsl', 1],
  ['esbs3', 1],
  ['esbla', 1],
  ['esboa', 1],
  ['esbbj', 1],
  ['esbvn', 3],
  ['esnnn', 1],
  ['esbrl', 1],
  ['esbos', 1],
  ['esbrx', 1],
  ['esjon', 1],
  ['escbz', 1],
  ['escbp', 1],
  ['escrg', 1],
  ['esas8', 3],
  ['esoop', 3],
  ['escad', 1],
  ['escbs', 1],
  ['eslel', 3],
  ['eschr', 1],
  ['esc9a', 3],
  ['escge', 1],
  ['esias', 3],
  ['esczt', 1],
  ['escbd', 1],
  ['escbl', 3],
  ['escpl', 3],
  ['escgg', 1],
  ['escpa', 1],
  ['esces', 1],
  ['escrs', 3],
  ['esccx', 1],
  ['escno', 3],
  ['escrp', 1],
  ['escqu', 1],
  ['escdi', 3],
  ['escar', 3],
  ['esres', 3],
  ['escfl', 1],
  ['escas', 3],
  ['eshhu', 1],
  ['escur', 3],
  ['escso', 3],
  ['escoi', 3],
  ['esebo', 1],
  ['esced', 3],
  ['escee', 3],
  ['escel', 1],
  ['esceu', 1],
  ['escri', 3],
  ['esciv', 1],
  ['esgva', 1],
  ['escsn', 1],
  ['esqiu', 5],
  ['escgr', 1],
  ['esccn', 1],
  ['esnco', 1],
  ['esqfu', 5],
  ['escje', 1],
  ['escud', 3],
  ['esodb', 6],
  ['esdba', 3],
  ['esdna', 1],
  ['esdri', 1],
  ['esdag', 1],
  ['esz8r', 1],
  ['esejc', 3],
  ['esato', 3],
  ['esect', 3],
  ['esgcs', 3],
  ['esmed', 3],
  ['eseoo', 1],
  ['essol', 3],
  ['esepb', 6],
  ['esrom', 3],
  ['estal', 1],
  ['eselv', 1],
  ['esemp', 1],
  ['eseng', 1],
  ['esent', 1],
  ['esesc', 1],
  ['escc7', 1],
  ['esekz', 1],
  ['esedl', 1],
  ['esezc', 3],
  ['esfrm', 1],
  ['esrrs', 1],
  ['esfro', 3],
  ['esgu6', 3],
  ['esfld', 1],
  ['esfra', 1],
  ['esfsl', 1],
  ['esfal', 1],
  ['esewe', 3],
  ['esgan', 3],
  ['esgrc', 1],
  ['esgga', 1],
  ['esgar', 1],
  ['esgro', 6],
  ['esgtx', 1],
  ['esgdl', 1],
  ['esgdr', 1],
  ['esgtl', 1],
  ['esgrx', 6],
  ['esgya', 3],
  ['esgry', 1],
  ['esegr', 3],
  ['esjue', 3],
  ['esgui', 6],
  ['eshsn', 1],
  ['eshig', 1],
  ['eshdd', 1],
  ['eshty', 1],
  ['eshll', 1],
  ['eshda', 1],
  ['eshuv', 3],
  ['eseth', 3],
  ['eshur', 1],
  ['esibz', 5],
  ['esnca', 1],
  ['esiru', 6],
  ['esitr', 1],
  ['esiua', 3],
  ['esjav', 3],
  ['eskll', 3],
  ['esbat', 3],
  ['esgle', 3],
  ['esles', 1],
  ['esfnc', 1],
  ['eslgc', 1],
  ['esagd', 3],
  ['esljo', 1],
  ['esmat', 1],
  ['eslmn', 1],
  ['eslpf', 1],
  ['eslrt', 1],
  ['estt2', 3],
  ['eslsl', 3],
  ['estji', 1],
  ['eslzr', 3],
  ['esgrt', 1],
  ['eslax', 3],
  ['eslan', 1],
  ['esron', 1],
  ['esace', 5],
  ['eslap', 3],
  ['escdk', 3],
  ['esndd', 3],
  ['eslec', 3],
  ['eslgu', 3],
  ['eslek', 1],
  ['estg2', 1],
  ['eslit', 1],
  ['esuri', 1],
  ['esllo', 3],
  ['es2bl', 1],
  ['eslcr', 1],
  ['eslsd', 3],
  ['eslsi', 1],
  ['esluq', 1],
  ['esmad', 4],
  ['esmrj', 3],
  ['esmgn', 1],
  ['esmma', 3],
  ['esmlo', 3],
  ['esmai', 3],
  ['esmrn', 1],
  ['esmqu', 1],
  ['esmsn', 1],
  ['esmec', 1],
  ['esntr', 1],
  ['esmia', 1],
  ['esmet', 1],
  ['esmic', 1],
  ['esmir', 1],
  ['esmix', 1],
  ['esmoa', 3],
  ['esmog', 3],
  ['esmoj', 3],
  ['esmby', 1],
  ['esnrt', 1],
  ['esmtc', 3],
  ['esmnf', 1],
  ['esmtq', 3],
  ['esmoi', 1],
  ['esomr', 1],
  ['esltv', 3],
  ['escut', 3],
  ['esmtu', 3],
  ['esmot', 1],
  ['esm8u', 1],
  ['esmcz', 1],
  ['esmga', 1],
  ['esmun', 3],
  ['esmjv', 6],
  ['esmlj', 1],
  ['esmrs', 1],
  ['esmus', 3],
  ['esagp', 5],
  ['esnbl', 1],
  ['esnvs', 1],
  ['esad6', 3],
  ['esnje', 3],
  ['esons', 3],
  ['esolz', 3],
  ['esoly', 1],
  ['esond', 3],
  ['esosr', 1],
  ['esotg', 3],
  ['esozl', 1],
  ['esout', 3],
  ['espaf', 1],
  ['espal', 1],
  ['esto9', 3],
  ['espna', 6],
  ['espbu', 3],
  ['espan', 1],
  ['espas', 1],
  ['espet', 1],
  ['espcn', 1],
  ['espaz', 1],
  ['espit', 3],
  ['esqly', 1],
  ['esdbo', 1],
  ['espli', 1],
  ['espsj', 1],
  ['espso', 3],
  ['esplc', 1],
  ['espjc', 1],
  ['espev', 3],
  ['esbpm', 1],
  ['esprt', 3],
  ['espor', 1],
  ['espsl', 1],
  ['eschz', 1],
  ['eslpc', 3],
  ['espre', 3],
  ['espco', 1],
  ['esgnd', 1],
  ['espgu', 3],
  ['esruz', 3],
  ['eshie', 3],
  ['espd3', 1],
  ['espps', 1],
  ['espds', 3],
  ['espsm', 1],
  ['espvg', 3],
  ['espuc', 3],
  ['esfue', 5],
  ['espro', 1],
  ['esptm', 3],
  ['esprg', 3],
  ['espva', 1],
  ['esqis', 1],
  ['esra5', 3],
  ['estry', 1],
  ['es8tc', 3],
  ['esrdp', 1],
  ['esrta', 3],
  ['esrus', 4],
  ['esrib', 1],
  ['esrbs', 1],
  ['esrbi', 3],
  ['esreg', 1],
  ['esrpl', 3],
  ['escaq', 3],
  ['esros', 1],
  ['esvez', 1],
  ['esrot', 1],
  ['esoto', 1],
  ['esrde', 1],
  ['esrue', 3],
  ['essad', 1],
  ['essag', 3],
  ['esslm', 6],
  ['ess9s', 3],
  ['esnra', 3],
  ['essat', 1],
  ['essdg', 1],
  ['esgdm', 1],
  ['essgt', 1],
  ['essaa', 1],
  ['essas', 3],
  ['essbt', 3],
  ['esscr', 3],
  ['essci', 1],
  ['esv8c', 1],
  ['esseo', 1],
  ['esliu', 3],
  ['essfo', 3],
  ['essid', 1],
  ['essjn', 1],
  ['esjrm', 3],
  ['esm8m', 1],
  ['esso8', 1],
  ['esspq', 1],
  ['esspp', 1],
  ['essap', 3],
  ['essrh', 1],
  ['eseas', 5],
  ['esssg', 5],
  ['essvi', 1],
  ['essvb', 3],
  ['essxx', 3],
  ['essp4', 1],
  ['espny', 1],
  ['essfu', 1],
  ['essfl', 3],
  ['essjo', 3],
  ['essl4', 1],
  ['essmd', 1],
  ['essgd', 1],
  ['esspc', 5],
  ['essct', 1],
  ['eseug', 3],
  ['esste', 1],
  ['esgcr', 1],
  ['esnll', 1],
  ['esspo', 1],
  ['essur', 1],
  ['ess8c', 1],
  ['esscq', 4],
  ['esaaq', 3],
  ['esrum', 3],
  ['essnr', 3],
  ['es4ss', 3],
  ['eshvc', 3],
  ['esral', 3],
  ['eszmf', 1],
  ['esleu', 6],
  ['esser', 1],
  ['essew', 3],
  ['essis', 1],
  ['essit', 3],
  ['essl8', 1],
  ['essov', 3],
  ['esptn', 3],
  ['essum', 1],
  ['estbr', 1],
  ['estbz', 1],
  ['estrf', 1],
  ['estaz', 3],
  ['estjq', 1],
  ['estem', 3],
  ['estci', 4],
  ['estfn', 4],
  ['estfs', 4],
  ['estjf', 3],
  ['estdy', 1],
  ['estvc', 1],
  ['estms', 1],
  ['estho', 1],
  ['estrr', 1],
  ['esle6', 3],
  ['estor', 1],
  ['estot', 1],
  ['estkg', 3],
  ['estre', 1],
  ['estto', 3],
  ['estdc', 3],
  ['estru', 3],
  ['esuis', 1],
  ['esurz', 3],
  ['esdej', 3],
  ['esvjo', 1],
  ['esvll', 6],
  ['esvcc', 3],
  ['esvgr', 1],
  ['escvj', 1],
  ['esv7n', 1],
  ['esvgo', 5],
  ['esvbn', 3],
  ['eszz4', 3],
  ['esvil', 3],
  ['esdgs', 1],
  ['esvlg', 3],
  ['esv8m', 1],
  ['esvzo', 1],
  ['esvzr', 3],
  ['esvvr', 1],
  ['esviv', 3],
  ['esvzm', 1],
  ['esz8n', 1],
  ['eszue', 3],
  ['esorj', 1],
  ['etadd', 4],
  ['etamh', 4],
  ['etaso', 4],
  ['etawa', 4],
  ['etaxu', 4],
  ['etbjr', 4],
  ['etbei', 4],
  ['etbcy', 4],
  ['etdbm', 4],
  ['etdbt', 4],
  ['etdem', 4],
  ['etdse', 4],
  ['etdir', 4],
  ['etgmb', 4],
  ['etete', 4],
  ['etgnn', 4],
  ['etgob', 4],
  ['etgde', 4],
  ['etgdq', 4],
  ['etgor', 4],
  ['ethue', 4],
  ['etjim', 4],
  ['etbco', 4],
  ['etlfo', 4],
  ['etabk', 4],
  ['etlli', 4],
  ['etmqx', 4],
  ['etmks', 4],
  ['etmzx', 4],
  ['etmtf', 4],
  ['etota', 4],
  ['etmys', 4],
  ['etmuj', 4],
  ['etegl', 4],
  ['etsxu', 4],
  ['ettie', 4],
  ['etwac', 4],
  ['fiajo', 1],
  ['fiahl', 1],
  ['fiakk', 1],
  ['fibar', 1],
  ['fipir', 6],
  ['fiprv', 1],
  ['firaa', 1],
  ['fibro', 3],
  ['fibra', 1],
  ['fidls', 1],
  ['fideg', 3],
  ['fidra', 1],
  ['fieck', 1],
  ['fitai', 1],
  ['fiem2', 3],
  ['fienk', 3],
  ['fienf', 4],
  ['fienf', 4],
  ['fiesp', 3],
  ['fiesp', 3],
  ['fiejo', 1],
  ['fiejo', 1],
  ['fisar', 3],
  ['fihmn', 1],
  ['fifar', 1],
  ['fifog', 1],
  ['fifor', 1],
  ['figdb', 1],
  ['figul', 3],
  ['fikus', 1],
  ['fihao', 1],
  ['fihak', 3],
  ['fihal', 3],
  ['filho', 1],
  ['fihmn', 1],
  ['fihko', 1],
  ['fihko', 1],
  ['fihar', 1],
  ['fihau', 1],
  ['fihel', 1],
  ['fihel', 1],
  ['fihit', 1],
  ['fihim', 3],
  ['fihma', 3],
  ['fihvm', 3],
  ['fihou', 1],
  ['fihou', 1],
  ['fihog', 3],
  ['fiiis', 3],
  ['fiiis', 3],
  ['fiima', 3],
  ['fiink', 1],
  ['fiini', 1],
  ['fiink', 1],
  ['fiisn', 1],
  ['fiivl', 6],
  ['fiprs', 3],
  ['fijan', 1],
  ['fijou', 3],
  ['fijus', 1],
  ['fijuk', 1],
  ['fikpl', 3],
  ['fikaj', 6],
  ['fikaj', 6],
  ['fikjo', 1],
  ['fikk5', 3],
  ['fiklv', 1],
  ['fikan', 3],
  ['fiknt', 1],
  ['fikok', 3],
  ['fikas', 1],
  ['fikas', 1],
  ['fiksn', 1],
  ['fikhj', 4],
  ['fikau', 4],
  ['fikim', 1],
  ['fikes', 3],
  ['fiskv', 1],
  ['fikim', 1],
  ['fikir', 3],
  ['fiktq', 5],
  ['fiktt', 4],
  ['fikla', 3],
  ['fikoj', 1],
  ['fikok', 3],
  ['fikor', 1],
  ['fikor', 1],
  ['fikkv', 1],
  ['fiktk', 3],
  ['fikvh', 1],
  ['fikvh', 1],
  ['fikrs', 1],
  ['fikrs', 1],
  ['fikro', 1],
  ['fikum', 1],
  ['fikev', 4],
  ['fikur', 3],
  ['fikus', 1],
  ['fikao', 6],
  ['fikkr', 1],
  ['filak', 1],
  ['fillo', 3],
  ['filpj', 1],
  ['filap', 1],
  ['filap', 1],
  ['filuk', 3],
  ['fillx', 1],
  ['filsk', 1],
  ['filov', 1],
  ['filov', 1],
  ['filua', 3],
  ['filuv', 1],
  ['filan', 1],
  ['filvb', 1],
  ['fimaa', 3],
  ['fimhq', 5],
  ['fimhq', 5],
  ['fimrs', 3],
  ['fimen', 4],
  ['fimer', 1],
  ['fimul', 1],
  ['fimun', 1],
  ['fimus', 1],
  ['fimty', 3],
  ['finli', 1],
  ['finau', 1],
  ['finau', 1],
  ['finls', 1],
  ['finou', 3],
  ['finur', 3],
  ['fiukp', 1],
  ['fiuki', 1],
  ['finli', 1],
  ['fiolk', 1],
  ['fipar', 1],
  ['fipar', 1],
  ['fipah', 1],
  ['fiprk', 3],
  ['fipaa', 3],
  ['fipnl', 1],
  ['fiper', 1],
  ['fiper', 1],
  ['fizbq', 3],
  ['fiprs', 3],
  ['fipt2', 3],
  ['fipk9', 1],
  ['fipir', 6],
  ['fipoh', 1],
  ['fipsi', 3],
  ['fiprv', 1],
  ['fipuh', 1],
  ['fipuu', 1],
  ['firaa', 1],
  ['firau', 3],
  ['firau', 3],
  ['firtr', 1],
  ['firep', 3],
  ['firym', 1],
  ['firis', 1],
  ['firhu', 1],
  ['firuo', 1],
  ['firui', 3],
  ['firym', 1],
  ['firoy', 1],
  ['fisal', 1],
  ['fisan', 3],
  ['fimer', 1],
  ['fisjy', 6],
  ['fisip', 1],
  ['fisii', 3],
  ['fisly', 3],
  ['fisip', 1],
  ['fiski', 3],
  ['fiskb', 1],
  ['fipoh', 1],
  ['fisku', 1],
  ['fiskv', 1],
  ['fisna', 3],
  ['fisot', 6],
  ['firuo', 1],
  ['fistr', 1],
  ['fisvk', 1],
  ['fisma', 3],
  ['fisul', 1],
  ['fisbg', 1],
  ['fisbg', 1],
  ['fissi', 3],
  ['fidls', 1],
  ['fitaa', 6],
  ['fithk', 1],
  ['fitvs', 1],
  ['fitmp', 5],
  ['fitai', 1],
  ['fitmp', 5],
  ['fitei', 1],
  ['fitsd', 1],
  ['fitjo', 1],
  ['fites', 3],
  ['fitok', 1],
  ['fitok', 1],
  ['fitor', 1],
  ['fitor', 1],
  ['fitot', 1],
  ['fitva', 3],
  ['fitoj', 3],
  ['fitvs', 1],
  ['fiuim', 3],
  ['fiulv', 3],
  ['fiusk', 3],
  ['fiuus', 3],
  ['fiukp', 1],
  ['fiuki', 1],
  ['fivko', 3],
  ['fivko', 3],
  ['fivat', 6],
  ['fivat', 6],
  ['fivjk', 1],
  ['fivir', 3],
  ['fivar', 3],
  ['fiwas', 3],
  ['fivei', 1],
  ['fivel', 1],
  ['fivas', 3],
  ['fivra', 3],
  ['fivkt', 3],
  ['fivss', 1],
  ['fivtf', 3],
  ['fiyli', 4],
  ['fiyxp', 3],
  ['fjbfj', 4],
  ['fjbxl', 4],
  ['fjbvf', 4],
  ['fjcst', 4],
  ['fjici', 4],
  ['fjden', 3],
  ['fjell', 1],
  ['fjlbs', 5],
  ['fjltk', 3],
  ['fjmal', 3],
  ['fjmom', 3],
  ['fjnan', 4],
  ['fjnam', 3],
  ['fjrta', 5],
  ['fjsvu', 5],
  ['fjsin', 3],
  ['fjsuv', 5],
  ['fjvat', 3],
  ['fjvud', 1],
  ['fjwai', 1],
  ['fkfbe', 1],
  ['fkmpn', 4],
  ['fkpsy', 5],
  ['fmtkk', 5],
  ['fmeau', 1],
  ['fmfsi', 1],
  ['fmgaf', 1],
  ['fmifa', 1],
  ['fmksa', 5],
  ['fmluk', 1],
  ['fmnga', 1],
  ['fmpni', 5],
  ['fmpul', 1],
  ['fmplw', 1],
  ['fmsat', 1],
  ['fmsor', 1],
  ['fmwol', 1],
  ['fmyap', 5],
  ['foedi', 1],
  ['fofug', 1],
  ['fofae', 4],
  ['fohyv', 3],
  ['fohus', 1],
  ['fokvi', 1],
  ['fokol', 3],
  ['folop', 1],
  ['fonsk', 1],
  ['fonls', 3],
  ['forvk', 3],
  ['fosjo', 1],
  ['foska', 3],
  ['fosmj', 1],
  ['fosrv', 1],
  ['fostr', 1],
  ['fosyn', 3],
  ['fotho', 1],
  ['fotof', 1],
  ['fotvo', 1],
  ['fotor', 1],
  ['fovid', 1],
  ['foves', 1],
  ['fovag', 3],
  ['frabb', 5],
  ['fra86', 3],
  ['fragf', 5],
  ['fraws', 3],
  ['frqxb', 4],
  ['fraja', 5],
  ['frtku', 1],
  ['fraxm', 3],
  ['fryms', 3],
  ['frams', 3],
  ['frami', 1],
  ['fradb', 3],
  ['frane', 6],
  ['frang', 6],
  ['frncy', 6],
  ['frxan', 3],
  ['frahh', 3],
  ['frant', 1],
  ['fraah', 3],
  ['fraao', 3],
  ['frarc', 5],
  ['frard', 3],
  ['fraxy', 3],
  ['frajr', 3],
  ['frar5', 1],
  ['fras8', 1],
  ['frrme', 3],
  ['frtwa', 3],
  ['fraqs', 3],
  ['fra84', 1],
  ['fraud', 1],
  ['fraiq', 3],
  ['fraul', 6],
  ['fraur', 4],
  ['fraeo', 3],
  ['frauf', 1],
  ['fravn', 5],
  ['fravf', 4],
  ['frayw', 3],
  ['frayt', 3],
  ['frzle', 1],
  ['frnop', 1],
  ['friba', 3],
  ['frblf', 3],
  ['frntz', 3],
  ['frbld', 1],
  ['frbae', 4],
  ['frbai', 1],
  ['frbas', 1],
  ['frbia', 5],
  ['frbqa', 3],
  ['frbav', 3],
  ['frwby', 3],
  ['frbay', 5],
  ['frbca', 3],
  ['frbmt', 3],
  ['frbva', 6],
  ['frbec', 1],
  ['frbor', 4],
  ['frbbq', 1],
  ['fregc', 4],
  ['frius', 3],
  ['frbee', 1],
  ['fretb', 3],
  ['frdf9', 1],
  ['frbev', 1],
  ['frbsn', 3],
  ['fr3cg', 1],
  ['frkj9', 1],
  ['frtu9', 1],
  ['frbtu', 3],
  ['frihs', 3],
  ['frbiq', 5],
  ['frbdd', 3],
  ['frdnz', 1],
  ['frirt', 3],
  ['freuy', 1],
  ['fryvg', 3],
  ['frncb', 1],
  ['frbye', 1],
  ['frwmn', 1],
  ['frhyd', 1],
  ['frmwf', 1],
  ['frbps', 3],
  ['frygo', 1],
  ['frhob', 1],
  ['frbcq', 3],
  ['frbxo', 3],
  ['frfax', 1],
  ['frbon', 1],
  ['frmqp', 1],
  ['frefg', 1],
  ['frhjk', 1],
  ['frbfc', 6],
  ['frbsm', 3],
  ['frbsc', 1],
  ['frgid', 1],
  ['frbg3', 1],
  ['frbft', 3],
  ['frxba', 1],
  ['frbau', 1],
  ['frbhc', 1],
  ['frbhr', 1],
  ['frfuc', 1],
  ['frugv', 1],
  ['frneg', 1],
  ['fruja', 1],
  ['fraai', 1],
  ['frogb', 3],
  ['frbol', 1],
  ['fruul', 1],
  ['frbh4', 1],
  ['frrte', 3],
  ['frbsg', 3],
  ['frbou', 4],
  ['fruuo', 1],
  ['froja', 1],
  ['frezr', 1],
  ['frguz', 3],
  ['frqdf', 1],
  ['frztw', 1],
  ['frqxc', 1],
  ['frajk', 1],
  ['frbep', 3],
  ['frbrq', 3],
  ['frbqt', 3],
  ['frehy', 3],
  ['fr66f', 1],
  ['frmht', 1],
  ['frb2t', 1],
  ['frbve', 4],
  ['frbsd', 3],
  ['frlyn', 4],
  ['fruuc', 1],
  ['freha', 3],
  ['frufn', 3],
  ['frbyb', 1],
  ['frp5c', 1],
  ['fruyo', 3],
  ['frxeu', 1],
  ['frbxe', 1],
  ['frzui', 3],
  ['frbxu', 1],
  ['freue', 3],
  ['frdbe', 3],
  ['fryf2', 1],
  ['frbet', 1],
  ['frbzr', 4],
  ['frcaw', 3],
  ['frzao', 4],
  ['frcqf', 5],
  ['frcly', 5],
  ['frqbr', 1],
  ['frqzx', 1],
  ['frudp', 1],
  ['frphg', 1],
  ['frqmm', 1],
  ['frzxc', 1],
  ['frlkj', 1],
  ['frceq', 5],
  ['frcck', 1],
  ['frcbt', 3],
  ['frcdx', 3],
  ['frcp3', 1],
  ['frcqq', 1],
  ['frc2p', 1],
  ['frccf', 4],
  ['fruxl', 1],
  ['frnac', 3],
  ['frvcs', 1],
  ['frzzu', 1],
  ['frzmb', 1],
  ['frcoz', 3],
  ['frcdh', 3],
  ['frcas', 4],
  ['frcqg', 3],
  ['frunl', 3],
  ['frcqd', 3],
  ['frcjo', 3],
  ['frer5', 1],
  ['fryxa', 3],
  ['frcss', 5],
  ['frcmc', 3],
  ['frcmf', 4],
  ['frcyi', 1],
  ['frn7e', 1],
  ['frnnh', 1],
  ['frc26', 1],
  ['frao7', 1],
  ['frhra', 3],
  ['frcdg', 4],
  ['frchm', 1],
  ['frcm4', 1],
  ['frv88', 3],
  ['frc69', 1],
  ['frrtv', 3],
  ['fr69r', 1],
  ['frcc4', 1],
  ['frfbg', 1],
  ['frcxa', 1],
  ['friy8', 1],
  ['frcmt', 1],
  ['frhao', 3],
  ['frc2h', 1],
  ['frdc2', 1],
  ['frcs5', 1],
  ['frhy9', 1],
  ['frcer', 5],
  ['frhey', 3],
  ['frgh7', 1],
  ['frhiy', 3],
  ['frhpy', 3],
  ['frcet', 4],
  ['frooz', 3],
  ['frlo9', 1],
  ['frg8r', 1],
  ['frcsm', 1],
  ['frcqn', 3],
  ['frefr', 1],
  ['frmhj', 1],
  ['frchr', 4],
  ['frqwc', 1],
  ['frtcj', 3],
  ['frcxs', 3],
  ['frxlz', 1],
  ['frcfe', 6],
  ['frcng', 5],
  ['frony', 1],
  ['frayr', 1],
  ['frzt7', 1],
  ['frcmr', 5],
  ['frmjy', 1],
  ['frqyv', 1],
  ['frrzw', 1],
  ['frjmq', 1],
  ['frqpl', 1],
  ['frcmp', 1],
  ['frezx', 1],
  ['frcoc', 1],
  ['frlkz', 1],
  ['frxcb', 1],
  ['frzll', 1],
  ['frctx', 1],
  ['frznn', 1],
  ['frcxy', 1],
  ['frzmm', 1],
  ['frzmy', 1],
  ['frqqq', 1],
  ['frcvf', 4],
  ['fruml', 3],
  ['frct2', 1],
  ['frrav', 3],
  ['frrhp', 1],
  ['frcrl', 1],
  ['fryen', 3],
  ['frhzk', 1],
  ['frmkx', 1],
  ['frqsm', 1],
  ['frckt', 1],
  ['frcqr', 1],
  ['frkly', 1],
  ['frcr4', 1],
  ['frvh4', 1],
  ['frcsd', 3],
  ['fryog', 3],
  ['frccp', 3],
  ['fruff', 3],
  ['frca2', 1],
  ['frccn', 3],
  ['frdal', 3],
  ['frdvt', 3],
  ['frdol', 5],
  ['frdmv', 1],
  ['frqvp', 1],
  ['frden', 3],
  ['frqlk', 1],
  ['frzpp', 1],
  ['frddr', 3],
  ['frdpe', 5],
  ['fr9ds', 1],
  ['fredu', 3],
  ['frdij', 5],
  ['frdnr', 4],
  ['frdnj', 1],
  ['frdle', 4],
  ['frdml', 3],
  ['frdon', 1],
  ['frdms', 3],
  ['frdrz', 1],
  ['frdkk', 1],
  ['frehu', 3],
  ['fresb', 3],
  ['frtjx', 1],
  ['frem2', 1],
  ['frexs', 3],
  ['freyz', 1],
  ['frf2v', 1],
  ['frful', 3],
  ['frfey', 1],
  ['frfsc', 4],
  ['frsnm', 3],
  ['fryso', 3],
  ['frfns', 3],
  ['frfie', 1],
  ['frfop', 3],
  ['frftb', 3],
  ['frfos', 3],
  ['frfus', 3],
  ['frfbi', 1],
  ['frfdr', 1],
  ['frfa3', 1],
  ['frrny', 1],
  ['frfau', 1],
  ['frf3m', 3],
  ['frfrj', 6],
  ['frfbl', 3],
  ['frfma', 3],
  ['frfec', 1],
  ['frgat', 4],
  ['frgur', 1],
  ['frgvl', 1],
  ['frgxy', 3],
  ['frghd', 1],
  ['frgao', 3],
  ['frg8v', 1],
  ['frglo', 1],
  ['frgds', 3],
  ['frgfr', 5],
  ['frgrv', 1],
  ['frgv6', 1],
  ['frgnb', 4],
  ['frgny', 3],
  ['frg2x', 1],
  ['frgbq', 1],
  ['frgdt', 3],
  ['frgew', 1],
  ['frgxe', 3],
  ['fruvi', 1],
  ['frueu', 1],
  ['frgch', 1],
  ['fruim', 1],
  ['frgms', 3],
  ['fruns', 3],
  ['frhs2', 1],
  ['fry78', 1],
  ['frhrf', 1],
  ['frhrn', 1],
  ['frh57', 1],
  ['frhv2', 1],
  ['frhet', 1],
  ['frhbv', 3],
  ['frh67', 1],
  ['frhog', 1],
  ['frrfa', 1],
  ['frjki', 1],
  ['frhsg', 1],
  ['frhun', 3],
  ['frhyr', 3],
  ['frh99', 1],
  ['frixy', 3],
  ['frljg', 1],
  ['frigs', 3],
  ['frism', 3],
  ['frisg', 1],
  ['friou', 3],
  ['frizo', 1],
  ['frjav', 1],
  ['fruxe', 1],
  ['frjuu', 3],
  ['fryan', 1],
  ['frjut', 1],
  ['fredt', 1],
  ['frahz', 4],
  ['frlad', 3],
  ['frrgu', 3],
  ['froei', 3],
  ['frucm', 1],
  ['frdlj', 1],
  ['fridy', 5],
  ['frilr', 1],
  ['frbr5', 1],
  ['frlbd', 3],
  ['frlby', 4],
  ['frbm3', 1],
  ['frbqh', 3],
  ['frcl2', 1],
  ['frm2s', 3],
  ['frcs2', 1],
  ['frpvf', 3],
  ['frlct', 1],
  ['frrdm', 3],
  ['frlzf', 3],
  ['frgdm', 3],
  ['frtuj', 1],
  ['frlmw', 3],
  ['frl83', 1],
  ['frlmd', 1],
  ['frlpe', 1],
  ['frjys', 1],
  ['frplg', 4],
  ['frrdt', 3],
  ['frg33', 1],
  ['fredm', 4],
  ['frlrh', 5],
  ['fryne', 3],
  ['frmhb', 3],
  ['frtbe', 1],
  ['frege', 3],
  ['frlgm', 3],
  ['frlqg', 3],
  ['frlp7', 1],
  ['frldn', 1],
  ['frldv', 4],
  ['frano', 3],
  ['frdut', 1],
  ['frlai', 5],
  ['frla5', 1],
  ['frla6', 1],
  ['frla4', 1],
  ['frlva', 4],
  ['frnsd', 3],
  ['frlav', 1],
  ['fryrc', 4],
  ['frzyl', 1],
  ['frbcj', 3],
  ['frbn4', 1],
  ['frlbg', 4],
  ['frouq', 1],
  ['frcij', 1],
  ['frlc4', 1],
  ['fragk', 3],
  ['freua', 1],
  ['frhti', 3],
  ['frehn', 3],
  ['frryg', 1],
  ['frlcr', 1],
  ['froty', 3],
  ['frlfr', 3],
  ['fruev', 3],
  ['frlgr', 3],
  ['frlgu', 1],
  ['frdxg', 3],
  ['frlme', 6],
  ['fre7m', 1],
  ['frlqa', 3],
  ['frlwm', 1],
  ['frmzk', 1],
  ['frlxa', 3],
  ['frlpy', 6],
  ['freqn', 1],
  ['frbbb', 3],
  ['frlbi', 6],
  ['frcgf', 1],
  ['frhsn', 3],
  ['frtew', 1],
  ['frltq', 6],
  ['frltr', 1],
  ['frlve', 3],
  ['frvm6', 1],
  ['frgla', 3],
  ['frlsb', 3],
  ['frdxa', 4],
  ['frftj', 1],
  ['frph2', 1],
  ['frltf', 3],
  ['frvo5', 6],
  ['frlkl', 3],
  ['frlqd', 3],
  ['frute', 3],
  ['frlib', 1],
  ['frl5t', 1],
  ['frlil', 6],
  ['frlmy', 3],
  ['frlmz', 3],
  ['frlig', 4],
  ['frl49', 1],
  ['frlo7', 1],
  ['froql', 3],
  ['frloc', 1],
  ['frlkr', 3],
  ['frlow', 3],
  ['frloa', 3],
  ['frlrt', 5],
  ['frgt3', 1],
  ['frlde', 4],
  ['frlqt', 3],
  ['frluq', 3],
  ['frxry', 1],
  ['frosh', 1],
  ['frzcy', 3],
  ['frlf2', 3],
  ['freui', 3],
  ['frlez', 1],
  ['frmcq', 3],
  ['frmvg', 3],
  ['frm89', 1],
  ['frmv2', 1],
  ['frmmv', 3],
  ['frmrn', 1],
  ['frulp', 3],
  ['frumx', 3],
  ['frmr7', 1],
  ['frmwr', 3],
  ['frmrs', 5],
  ['frmqa', 3],
  ['frmt8', 1],
  ['frmau', 3],
  ['frmgu', 3],
  ['fruvj', 1],
  ['frmea', 5],
  ['frmvv', 4],
  ['frmen', 4],
  ['frmqy', 3],
  ['frwww', 1],
  ['frmrw', 3],
  ['frdyj', 1],
  ['frml9', 1],
  ['frzxn', 1],
  ['frxvf', 1],
  ['frfbh', 1],
  ['fryup', 1],
  ['frnlj', 1],
  ['frpkm', 1],
  ['frujk', 1],
  ['frmdm', 4],
  ['frnyy', 1],
  ['frmos', 1],
  ['frmbt', 3],
  ['frmtd', 1],
  ['frmt6', 1],
  ['frmtx', 3],
  ['frmpl', 4],
  ['frnz3', 1],
  ['frils', 1],
  ['frmc9', 1],
  ['frmxn', 5],
  ['frgsu', 3],
  ['frmju', 3],
  ['fryus', 3],
  ['frmyk', 3],
  ['frmda', 3],
  ['frklu', 1],
  ['frm75', 1],
  ['frtxm', 1],
  ['frmlh', 5],
  ['frxrg', 1],
  ['frvje', 1],
  ['frmyw', 3],
  ['frgjn', 1],
  ['frmac', 1],
  ['frmfx', 4],
  ['frme4', 1],
  ['frmr6', 1],
  ['frmsi', 3],
  ['frmzs', 3],
  ['frwqa', 3],
  ['frenc', 5],
  ['frnus', 3],
  ['frnms', 1],
  ['frnef', 3],
  ['frnyi', 1],
  ['frnvs', 5],
  ['frnce', 5],
  ['frnit', 6],
  ['frn25', 1],
  ['frw2c', 3],
  ['frxvy', 1],
  ['frfni', 4],
  ['froyi', 1],
  ['fr43h', 1],
  ['frjfm', 3],
  ['frora', 4],
  ['frohp', 3],
  ['frory', 4],
  ['frorr', 6],
  ['frotm', 3],
  ['froui', 1],
  ['frpad', 1],
  ['frpnu', 3],
  ['frpbf', 3],
  ['frpai', 1],
  ['frtin', 3],
  ['frpyx', 3],
  ['frpar', 3],
  ['frpuf', 4],
  ['frpap', 1],
  ['frnrh', 1],
  ['frpwa', 3],
  ['frpgf', 6],
  ['frps9', 1],
  ['frpet', 1],
  ['frryy', 1],
  ['frpjy', 3],
  ['fr33g', 1],
  ['fr4ps', 1],
  ['frphn', 3],
  ['frpht', 3],
  ['frpxz', 3],
  ['froff', 3],
  ['frpd2', 1],
  ['frrur', 3],
  ['frzev', 3],
  ['frpgq', 3],
  ['frpis', 4],
  ['frpaj', 3],
  ['frf29', 1],
  ['frpnh', 3],
  ['frpti', 1],
  ['frpvx', 3],
  ['frpab', 1],
  ['frpqc', 3],
  ['frpsx', 3],
  ['frp62', 1],
  ['frpqr', 3],
  ['frptv', 3],
  ['frklo', 1],
  ['fryjk', 1],
  ['frpdf', 1],
  ['frzxh', 1],
  ['frpox', 1],
  ['frprc', 1],
  ['fremr', 1],
  ['frpg5', 1],
  ['frpm6', 1],
  ['frpdb', 1],
  ['frrtb', 3],
  ['frprj', 1],
  ['frpje', 1],
  ['frnou', 1],
  ['frplw', 3],
  ['frpnf', 1],
  ['frpsl', 1],
  ['frxer', 1],
  ['frpov', 1],
  ['frph4', 1],
  ['frpvo', 1],
  ['frpqe', 3],
  ['frzxd', 1],
  ['fryuj', 1],
  ['frujy', 1],
  ['frpks', 3],
  ['frpkl', 1],
  ['frzic', 3],
  ['frhyj', 1],
  ['frprp', 5],
  ['frpum', 3],
  ['frpi9', 1],
  ['frrzx', 1],
  ['frdxz', 1],
  ['frhej', 1],
  ['frarj', 1],
  ['frg63', 1],
  ['frpgx', 6],
  ['frqen', 3],
  ['frqui', 1],
  ['fruip', 5],
  ['frqsc', 1],
  ['fraba', 3],
  ['frrad', 3],
  ['frrli', 3],
  ['frxxd', 1],
  ['frrqe', 3],
  ['fraqx', 1],
  ['frrdn', 3],
  ['frqxa', 1],
  ['frrei', 1],
  ['frqwm', 1],
  ['frkrz', 1],
  ['frrns', 6],
  ['fr9ss', 1],
  ['friht', 3],
  ['friec', 3],
  ['frrn3', 1],
  ['frxxx', 1],
  ['frrvc', 3],
  ['frrne', 4],
  ['frr26', 1],
  ['frrco', 5],
  ['frrdz', 4],
  ['frygp', 1],
  ['frvfg', 1],
  ['frzog', 1],
  ['frron', 1],
  ['frrsa', 3],
  ['frwik', 1],
  ['frbkd', 1],
  ['frrxl', 3],
  ['frrsq', 1],
  ['frrou', 1],
  ['frryn', 5],
  ['frrun', 1],
  ['frrkz', 6],
  ['frsyy', 1],
  ['frmmj', 1],
  ['fr4s4', 1],
  ['frqey', 3],
  ['frbg2', 1],
  ['frsbk', 4],
  ['fricn', 3],
  ['frsqs', 3],
  ['frdcj', 1],
  ['frpjh', 1],
  ['frrzt', 1],
  ['frlys', 6],
  ['frngi', 3],
  ['frxgl', 3],
  ['frxgv', 3],
  ['fruji', 1],
  ['frgon', 3],
  ['frkaq', 1],
  ['frgpi', 3],
  ['frxdh', 1],
  ['frsml', 1],
  ['frdas', 1],
  ['frdad', 1],
  ['frtmg', 3],
  ['frsnr', 5],
  ['frpc6', 1],
  ['frfs4', 3],
  ['frsp5', 1],
  ['frpdu', 3],
  ['frx28', 1],
  ['frsqr', 3],
  ['frstq', 1],
  ['frqlc', 3],
  ['frsir', 3],
  ['frujj', 1],
  ['frss2', 1],
  ['frtqj', 1],
  ['frujq', 1],
  ['friav', 3],
  ['fruqj', 1],
  ['frtim', 3],
  ['frssk', 3],
  ['frvig', 3],
  ['frucs', 1],
  ['frtua', 3],
  ['frxcs', 3],
  ['frvti', 1],
  ['frsvs', 3],
  ['frvnr', 1],
  ['frva5', 1],
  ['frsit', 3],
  ['frswd', 1],
  ['frsty', 6],
  ['frebu', 6],
  ['frnla', 3],
  ['frsgt', 3],
  ['frmdw', 1],
  ['frqso', 1],
  ['frsmw', 1],
  ['frsnq', 3],
  ['frsqv', 3],
  ['frqts', 1],
  ['frrrb', 3],
  ['frsar', 3],
  ['frubs', 3],
  ['froyy', 1],
  ['frsyq', 3],
  ['frs5m', 1],
  ['frght', 1],
  ['fregr', 3],
  ['frikr', 1],
  ['frs8c', 1],
  ['frsec', 4],
  ['frsup', 3],
  ['frysu', 1],
  ['frsqg', 3],
  ['frslv', 3],
  ['frsxd', 4],
  ['frysm', 3],
  ['frqgs', 3],
  ['frspk', 3],
  ['frset', 3],
  ['frm56', 1],
  ['frrh7', 3],
  ['frtab', 4],
  ['frtzz', 1],
  ['fruvv', 1],
  ['frtvl', 1],
  ['frtgh', 3],
  ['frtmn', 3],
  ['frtig', 3],
  ['frtgf', 4],
  ['frjk9', 1],
  ['frton', 1],
  ['frtuu', 1],
  ['frrf3', 1],
  ['frtln', 5],
  ['frtls', 6],
  ['frtov', 3],
  ['frad5', 1],
  ['frtuf', 4],
  ['frtnf', 4],
  ['frxyr', 1],
  ['frtri', 3],
  ['frtpo', 3],
  ['frtje', 3],
  ['frqyr', 5],
  ['frtre', 1],
  ['frtr9', 1],
  ['frttg', 3],
  ['fr8ay', 3],
  ['frvaz', 4],
  ['freg5', 1],
  ['frm52', 1],
  ['frvne', 5],
  ['frvsq', 3],
  ['frvat', 6],
  ['frm57', 1],
  ['frenb', 3],
  ['frvhm', 3],
  ['frvtm', 1],
  ['frvrd', 1],
  ['freex', 3],
  ['frvej', 1],
  ['frveo', 3],
  ['frvm5', 1],
  ['frvnz', 3],
  ['frvhy', 4],
  ['frvss', 5],
  ['frvge', 3],
  ['frvux', 1],
  ['frv33', 1],
  ['frvvl', 3],
  ['frvc4', 1],
  ['fr4va', 3],
  ['frllw', 3],
  ['frvnh', 3],
  ['frjpl', 1],
  ['frvtl', 4],
  ['frneh', 1],
  ['frxsw', 1],
  ['frusu', 3],
  ['frvl4', 1],
  ['frvqr', 3],
  ['frwnt', 3],
  ['frwtt', 3],
  ['freci', 3],
  ['fruti', 3],
  ['fregg', 3],
  ['fr5ep', 1],
  ['frepl', 4],
  ['frep5', 1],
  ['frer4', 1],
  ['freta', 3],
  ['fresm', 1],
  ['freio', 3],
  ['frpf4', 1],
  ['frevr', 1],
  ['gaake', 4],
  ['gaawe', 4],
  ['gabwn', 4],
  ['gabmm', 4],
  ['gabng', 4],
  ['gabgb', 4],
  ['gaclz', 1],
  ['gaccb', 1],
  ['gaeku', 1],
  ['gafou', 4],
  ['gamvb', 4],
  ['gagax', 5],
  ['gaigl', 4],
  ['gakgb', 4],
  ['gakou', 6],
  ['galbq', 4],
  ['galtl', 6],
  ['galcn', 4],
  ['galbv', 5],
  ['galuc', 1],
  ['gamby', 1],
  ['gamku', 4],
  ['gamdj', 4],
  ['gamgo', 4],
  ['gamyb', 4],
  ['gambc', 4],
  ['gamvg', 4],
  ['gagim', 4],
  ['gamvx', 6],
  ['gamzc', 4],
  ['gamgx', 4],
  ['gamff', 4],
  ['gamjl', 4],
  ['gamdv', 4],
  ['gamkb', 4],
  ['gakdn', 4],
  ['gakdj', 4],
  ['ganka', 6],
  ['ganya', 1],
  ['gaogu', 1],
  ['gaokn', 4],
  ['gaomb', 4],
  ['gaouu', 4],
  ['gaowe', 5],
  ['gaoye', 4],
  ['gapog', 5],
  ['gazkm', 4],
  ['gatch', 4],
  ['gawgy', 4],
  ['gawne', 6],
  ['gbgig', 3],
  ['gbamd', 3],
  ['gbbkc', 3],
  ['gbion', 3],
  ['gbbmt', 3],
  ['gbbdf', 3],
  ['gbbdi', 3],
  ['gbbru', 3],
  ['gbcna', 3],
  ['gbcag', 3],
  ['gbcub', 3],
  ['gbras', 3],
  ['gbcgj', 3],
  ['gbcua', 3],
  ['gbcuv', 3],
  ['gbcms', 3],
  ['gbeaa', 3],
  ['gbego', 3],
  ['gbelb', 3],
  ['gbfio', 3],
  ['gbfnh', 3],
  ['gbgas', 3],
  ['gbgut', 3],
  ['gbhvo', 3],
  ['gbhns', 3],
  ['gbhmv', 3],
  ['gbhov', 3],
  ['gbkal', 3],
  ['gbket', 3],
  ['gbkoh', 3],
  ['gblvr', 3],
  ['gblbd', 3],
  ['gblrz', 3],
  ['gblho', 3],
  ['gblts', 3],
  ['gbmgp', 3],
  ['gbmsc', 3],
  ['gbmrb', 3],
  ['gbmlp', 3],
  ['gbmns', 3],
  ['gbnoh', 3],
  ['gbodd', 3],
  ['gbotr', 3],
  ['gbpie', 3],
  ['gbpoi', 3],
  ['gbpmr', 3],
  ['gbrap', 3],
  ['gbrhb', 3],
  ['gbsmh', 3],
  ['gbscg', 3],
  ['gbscc', 3],
  ['gbscn', 3],
  ['gbsco', 3],
  ['gblui', 3],
  ['gbuls', 3],
  ['gbuye', 3],
  ['gbwhl', 3],
  ['gbaot', 1],
  ['gbaol', 1],
  ['gbabs', 3],
  ['gbaba', 3],
  ['gbarc', 3],
  ['gbadr', 3],
  ['gbabd', 5],
  ['gbayd', 3],
  ['gbabv', 1],
  ['gbabf', 3],
  ['gbafw', 1],
  ['gbaee', 3],
  ['gbayw', 3],
  ['gbagn', 6],
  ['gbaca', 3],
  ['gbace', 1],
  ['gbahn', 3],
  ['gbamr', 3],
  ['gbadm', 3],
  ['gbait', 3],
  ['gbaly', 6],
  ['gbalb', 3],
  ['gbgsr', 3],
  ['gball', 3],
  ['gbalm', 3],
  ['gbalp', 3],
  ['gbavl', 1],
  ['gbamb', 1],
  ['gbaml', 3],
  ['gbamw', 3],
  ['gbana', 1],
  ['gbavr', 6],
  ['gbvly', 4],
  ['gbang', 3],
  ['gbann', 3],
  ['gbans', 3],
  ['gbaw3', 4],
  ['gbapi', 3],
  ['gbaps', 1],
  ['gbapp', 1],
  ['gbarb', 1],
  ['gbdbg', 1],
  ['gbaet', 1],
  ['gbadf', 3],
  ['gbagl', 3],
  ['gbagu', 3],
  ['gbadk', 3],
  ['gbamh', 3],
  ['gbamn', 1],
  ['gbasg', 1],
  ['gbard', 1],
  ['gbarv', 3],
  ['gbmdi', 1],
  ['gbdyn', 1],
  ['gbgyl', 1],
  ['gbago', 3],
  ['gbarg', 3],
  ['gbali', 1],
  ['gbnar', 1],
  ['gbarr', 1],
  ['gbha2', 3],
  ['gbasw', 1],
  ['gbahh', 1],
  ['gbakl', 1],
  ['gbaul', 3],
  ['gbavc', 3],
  ['gbavo', 1],
  ['gbaxo', 3],
  ['gbayf', 1],
  ['gbayr', 1],
  ['gbacb', 1],
  ['gbsgc', 1],
  ['gbbbn', 3],
  ['gbbdx', 1],
  ['gbbli', 3],
  ['gbbnr', 3],
  ['gbbyp', 1],
  ['gbbko', 4],
  ['gbbyc', 3],
  ['gbdor', 1],
  ['gbbhb', 1],
  ['gbblr', 3],
  ['gblmn', 1],
  ['gblyw', 1],
  ['gbbmc', 3],
  ['gbbsn', 1],
  ['gbbaw', 1],
  ['gbbvr', 1],
  ['gbbab', 1],
  ['gbbff', 3],
  ['gbban', 3],
  ['gbbng', 1],
  ['gbrlg', 1],
  ['gbbce', 1],
  ['gbbdz', 1],
  ['gbbkg', 3],
  ['gbblz', 1],
  ['gbbmu', 1],
  ['gbabw', 3],
  ['gbaxn', 1],
  ['gbbdb', 3],
  ['gbbsy', 3],
  ['gbbnd', 3],
  ['gbbbx', 1],
  ['gbbrr', 5],
  ['gbbif', 5],
  ['gbbuo', 3],
  ['gbbwu', 3],
  ['gbbhr', 3],
  ['gbbad', 1],
  ['gbbnm', 1],
  ['gbbaz', 1],
  ['gbbnh', 3],
  ['gbbxf', 1],
  ['gbbat', 1],
  ['gbcbx', 1],
  ['gbbqx', 1],
  ['gbblu', 3],
  ['gbbmr', 1],
  ['gbbec', 1],
  ['gbbd2', 3],
  ['gbbtk', 1],
  ['gbenh', 1],
  ['gbbxx', 1],
  ['gbbsx', 1],
  ['gbbel', 5],
  ['gbvve', 1],
  ['gbbbc', 4],
  ['gbbes', 4],
  ['gbrnr', 1],
  ['gbrnn', 1],
  ['gbbeh', 3],
  ['gbbwk', 1],
  ['gbbxb', 3],
  ['gbbqq', 1],
  ['gbbid', 1],
  ['gbbqh', 6],
  ['gbugh', 1],
  ['gbbhw', 1],
  ['gbbgy', 1],
  ['gbbda', 3],
  ['gbbrk', 3],
  ['gbbhm', 6],
  ['gbrsb', 1],
  ['gbbip', 1],
  ['gbqwr', 1],
  ['gbpqt', 1],
  ['gbblb', 1],
  ['gbbbs', 4],
  ['gbbfp', 1],
  ['gbbks', 3],
  ['gbblk', 5],
  ['gbbnx', 1],
  ['gbblj', 1],
  ['gbblc', 3],
  ['gbwjh', 1],
  ['gbbly', 1],
  ['gbyth', 1],
  ['gbbon', 1],
  ['gbdmb', 1],
  ['gbbfo', 1],
  ['gbbsi', 3],
  ['gbltd', 1],
  ['gbblt', 1],
  ['gbboe', 1],
  ['gbbss', 1],
  ['gbbot', 3],
  ['gbbsc', 3],
  ['gbohm', 1],
  ['gbbsl', 1],
  ['gbbos', 1],
  ['gbbol', 1],
  ['gbbmx', 1],
  ['gbbsm', 1],
  ['gbbow', 1],
  ['gbrcd', 1],
  ['gbbrf', 5],
  ['gbsdt', 1],
  ['gbdwl', 3],
  ['gbbfb', 1],
  ['gbrev', 1],
  ['gbmde', 1],
  ['gbbhj', 1],
  ['gbbcs', 3],
  ['gbbra', 6],
  ['gbbbe', 1],
  ['gbbwm', 1],
  ['gbrst', 1],
  ['gbbbb', 1],
  ['gbent', 1],
  ['gbbwx', 1],
  ['gbbrw', 1],
  ['gbbrt', 1],
  ['gbbrp', 1],
  ['gbbfe', 3],
  ['gbbxs', 1],
  ['gbbls', 1],
  ['gbbsh', 5],
  ['gbbik', 3],
  ['gbbfy', 1],
  ['gbbrx', 1],
  ['gbbzz', 4],
  ['gbbyn', 1],
  ['gbbpe', 3],
  ['gbbdt', 3],
  ['gbbhk', 1],
  ['gbbrj', 3],
  ['gbbxt', 1],
  ['gbogh', 1],
  ['gbbzt', 3],
  ['gbbfu', 1],
  ['gbbch', 3],
  ['gbbzr', 3],
  ['gbbyi', 1],
  ['gbbe2', 3],
  ['gbucn', 1],
  ['gbbcr', 1],
  ['gbbkd', 1],
  ['gbny2', 3],
  ['gbbuc', 1],
  ['gbbud', 3],
  ['gbbua', 3],
  ['gbbwv', 1],
  ['gbuns', 1],
  ['gbbnb', 3],
  ['gboor', 1],
  ['gbrgh', 1],
  ['gbbuh', 1],
  ['gbboc', 1],
  ['gbbrn', 1],
  ['gbbmo', 3],
  ['gbbtl', 1],
  ['gbbvo', 1],
  ['gbbuf', 3],
  ['gbbwr', 1],
  ['gbbpt', 3],
  ['gbkbk', 3],
  ['gbbbk', 1],
  ['gbbus', 1],
  ['gbbnw', 6],
  ['gbbwc', 3],
  ['gbbuy', 1],
  ['gbbse', 6],
  ['gbbtz', 1],
  ['gbcgz', 1],
  ['gbcsu', 3],
  ['gbcnv', 1],
  ['gbcbl', 1],
  ['gbcyn', 1],
  ['gbcsc', 1],
  ['gbcaa', 1],
  ['gbcbe', 1],
  ['gbcmg', 6],
  ['gbcbt', 5],
  ['gbcau', 3],
  ['gbcan', 1],
  ['gbccl', 3],
  ['gbcab', 1],
  ['gbcdf', 5],
  ['gbcar', 3],
  ['gbc5t', 3],
  ['gbcax', 5],
  ['gbrlw', 1],
  ['gbcrv', 1],
  ['gbcah', 3],
  ['gbcra', 3],
  ['gbcfg', 1],
  ['gbcrt', 1],
  ['gbrro', 1],
  ['gbcet', 1],
  ['gbcba', 1],
  ['gbctf', 1],
  ['gbcsw', 1],
  ['gbcce', 1],
  ['gbcnx', 1],
  ['gbctp', 3],
  ['gbcma', 3],
  ['gbcei', 3],
  ['gbcel', 3],
  ['gbceb', 3],
  ['gbcnm', 1],
  ['gbcpd', 1],
  ['gbchd', 1],
  ['gbchf', 1],
  ['gbcwn', 3],
  ['gbchj', 1],
  ['gbctm', 1],
  ['gbcht', 1],
  ['gbceg', 5],
  ['gbcsr', 1],
  ['gbcst', 3],
  ['gbckr', 1],
  ['gbcdk', 1],
  ['gbcii', 1],
  ['gbchr', 1],
  ['gbcch', 3],
  ['gbcls', 3],
  ['gbclj', 3],
  ['gbcyg', 1],
  ['gbqzl', 3],
  ['gbclf', 3],
  ['gbcgp', 3],
  ['gbcvl', 1],
  ['gbczv', 3],
  ['gbcly', 3],
  ['gbcyp', 1],
  ['gbcyd', 1],
  ['gbcnz', 1],
  ['gbckm', 1],
  ['gbcqq', 1],
  ['gbcol', 1],
  ['gbcgb', 3],
  ['gbclr', 1],
  ['gbcva', 3],
  ['gboll', 1],
  ['gbcaf', 3],
  ['gbcit', 1],
  ['gbcsa', 4],
  ['gbcta', 4],
  ['gbcyy', 3],
  ['gbcbm', 1],
  ['gbcgy', 3],
  ['gbcis', 3],
  ['gbcoq', 3],
  ['gbcqy', 1],
  ['gbczz', 1],
  ['gbcvw', 1],
  ['gbcwa', 3],
  ['gbcfm', 3],
  ['gbcor', 1],
  ['gborr', 1],
  ['gbcoy', 1],
  ['gbcve', 3],
  ['gbacv', 1],
  ['gbcvi', 1],
  ['gbcgt', 1],
  ['gbcvt', 5],
  ['gbcov', 3],
  ['gbcow', 1],
  ['gbcnu', 1],
  ['gbcrj', 3],
  ['gbcmd', 3],
  ['gbcbo', 3],
  ['gbcrx', 1],
  ['gbcks', 1],
  ['gbccc', 1],
  ['gbcfe', 3],
  ['gbcnc', 3],
  ['gbcrn', 5],
  ['gbcmp', 3],
  ['gbcrr', 3],
  ['gbcr5', 1],
  ['gbckk', 1],
  ['gbjhw', 1],
  ['gbckn', 3],
  ['gbclm', 1],
  ['gbcun', 3],
  ['gbcum', 1],
  ['gbcuj', 1],
  ['gbcu8', 1],
  ['gbcur', 1],
  ['gbcus', 3],
  ['gbcue', 3],
  ['gbdag', 3],
  ['gbdig', 3],
  ['gbdos', 1],
  ['gbdyb', 3],
  ['gbdal', 3],
  ['gbdwi', 3],
  ['gbds2', 1],
  ['gbdrl', 1],
  ['gbdfd', 3],
  ['gbdtm', 1],
  ['gbdex', 1],
  ['gbdnq', 1],
  ['gbeer', 1],
  ['gbdeb', 3],
  ['gbdet', 3],
  ['gbdxy', 1],
  ['gbder', 3],
  ['gbdvp', 1],
  ['gbdwy', 1],
  ['gbdin', 1],
  ['gbdiw', 3],
  ['gbdhd', 3],
  ['gbdon', 6],
  ['gbdoc', 4],
  ['gbdvr', 1],
  ['gbdrx', 3],
  ['gbdmo', 3],
  ['gbdut', 1],
  ['gbdby', 1],
  ['gbdum', 1],
  ['gbdbr', 3],
  ['gbdue', 3],
  ['gbduc', 3],
  ['gbdun', 5],
  ['gbdss', 1],
  ['gbdnu', 1],
  ['gbdnf', 1],
  ['gbdsk', 1],
  ['gbdte', 1],
  ['gbdtf', 1],
  ['gbdne', 1],
  ['gbdtn', 1],
  ['gbdre', 1],
  ['gbdvn', 1],
  ['gbdnw', 1],
  ['gbdrh', 1],
  ['gbddt', 1],
  ['gbdys', 3],
  ['gbrrr', 1],
  ['gbeco', 3],
  ['gbesg', 1],
  ['gbehy', 3],
  ['gbeme', 3],
  ['gbema', 4],
  ['gbesw', 1],
  ['gbewt', 1],
  ['gbebo', 3],
  ['gbeam', 1],
  ['gbecs', 1],
  ['gbeoi', 4],
  ['gblew', 1],
  ['gbedi', 5],
  ['gbegi', 3],
  ['gbigg', 1],
  ['gbesk', 1],
  ['gbekn', 1],
  ['gbeli', 3],
  ['gbein', 1],
  ['gbell', 1],
  ['gbewl', 1],
  ['gbels', 1],
  ['gberi', 3],
  ['gbrbl', 1],
  ['gbess', 1],
  ['gbevt', 3],
  ['gbexe', 5],
  ['gbexm', 1],
  ['gbeym', 3],
  ['gbfie', 4],
  ['gbfai', 1],
  ['gbfee', 3],
  ['gbfkm', 6],
  ['gbfal', 1],
  ['gbflt', 3],
  ['gbfbo', 6],
  ['gbfas', 1],
  ['gbfav', 1],
  ['gbfaw', 1],
  ['gbfei', 3],
  ['gbfxt', 1],
  ['gbfeo', 3],
  ['gbfrd', 1],
  ['gbfeb', 3],
  ['gbfrx', 1],
  ['gbfyd', 1],
  ['gbfea', 4],
  ['gbfzo', 4],
  ['gbfin', 3],
  ['gbfid', 3],
  ['gbfrh', 1],
  ['gbfnt', 1],
  ['gbfbu', 3],
  ['gbfih', 3],
  ['gbfis', 1],
  ['gbfbh', 3],
  ['gbfln', 1],
  ['gbfle', 1],
  ['gbflw', 1],
  ['gbflk', 1],
  ['gbflh', 5],
  ['gbflu', 1],
  ['gbfnv', 1],
  ['gbfoi', 1],
  ['gbfol', 3],
  ['gbfrm', 3],
  ['gbfrs', 6],
  ['gbfwm', 3],
  ['gbfor', 1],
  ['gbftr', 1],
  ['gbfdk', 1],
  ['gbdfn', 1],
  ['gblif', 1],
  ['gbfoa', 4],
  ['gbfoy', 1],
  ['gbfrb', 1],
  ['gbfrw', 3],
  ['gbfwe', 3],
  ['gbnfr', 1],
  ['gbfri', 3],
  ['gbfow', 1],
  ['gbfur', 1],
  ['gbgaw', 1],
  ['gbgai', 1],
  ['gbgar', 3],
  ['gbgdn', 3],
  ['gbglh', 1],
  ['gbgad', 1],
  ['gbgis', 1],
  ['gbrrb', 1],
  ['gbcsn', 1],
  ['gbgtn', 1],
  ['gbgat', 1],
  ['gblgw', 4],
  ['gbbhd', 4],
  ['gbgil', 3],
  ['gbill', 3],
  ['gbgir', 1],
  ['gbglw', 5],
  ['gbgld', 1],
  ['gbgla', 1],
  ['gbgnl', 1],
  ['gbglc', 1],
  ['gbgma', 1],
  ['gbgsa', 1],
  ['gbglg', 1],
  ['gbglo', 5],
  ['gbglp', 3],
  ['gbqug', 4],
  ['gbgoo', 1],
  ['gbgbs', 3],
  ['gbgor', 3],
  ['gbgos', 1],
  ['gbgud', 3],
  ['gbgur', 1],
  ['gbgiv', 1],
  ['gbwwl', 1],
  ['gbgox', 1],
  ['gbhxl', 1],
  ['gbgae', 1],
  ['gbgrg', 1],
  ['gbgrn', 3],
  ['gbgvs', 1],
  ['gbgys', 1],
  ['gbgrh', 1],
  ['gbmsi', 1],
  ['gbgoa', 3],
  ['gbgtd', 6],
  ['gbgwg', 1],
  ['gbgty', 1],
  ['gbhp2', 3],
  ['gbgnc', 1],
  ['gbgrk', 1],
  ['gbno2', 3],
  ['gbgmy', 1],
  ['gbgsy', 5],
  ['gbgro', 3],
  ['gbgrw', 1],
  ['gbgrj', 3],
  ['gbgru', 3],
  ['gbgdb', 3],
  ['gbgvl', 1],
  ['gbguw', 1],
  ['gbgnq', 3],
  ['gbgwk', 3],
  ['gbhnu', 1],
  ['gbhki', 3],
  ['gbham', 1],
  ['gbaew', 1],
  ['gbhrf', 1],
  ['gbhrl', 1],
  ['gbhaa', 1],
  ['gbhrt', 4],
  ['gbhtp', 3],
  ['gbyly', 1],
  ['gbhrw', 1],
  ['gbhdn', 1],
  ['gbhtg', 3],
  ['gbhat', 6],
  ['gbkwh', 5],
  ['gbhvr', 6],
  ['gbhay', 1],
  ['gbhyg', 3],
  ['gblhr', 4],
  ['gbheq', 1],
  ['gbheb', 1],
  ['gbhxx', 1],
  ['gbhln', 3],
  ['gbhdr', 1],
  ['gbhma', 3],
  ['gbemn', 1],
  ['gbhmy', 3],
  ['gbhdo', 4],
  ['gbhrn', 3],
  ['gbhse', 3],
  ['gbhbk', 1],
  ['gbhtt', 1],
  ['gbhyr', 1],
  ['gbhym', 1],
  ['gbhwy', 6],
  ['gbhgw', 3],
  ['gbtnn', 1],
  ['gbhdb', 1],
  ['gbhhn', 1],
  ['gbhds', 1],
  ['gbhom', 1],
  ['gbhyi', 1],
  ['gbhlo', 3],
  ['gbhly', 5],
  ['gbylw', 3],
  ['gboot', 1],
  ['gbhpc', 1],
  ['gbhop', 3],
  ['gbhos', 3],
  ['gbhgy', 1],
  ['gbhpt', 1],
  ['gbhut', 3],
  ['gbnfk', 1],
  ['gbhdd', 1],
  ['gbhoj', 3],
  ['gbhwv', 3],
  ['gbhkl', 6],
  ['gbhdf', 1],
  ['gbhwz', 1],
  ['gbhul', 1],
  ['gbhuy', 4],
  ['gbhlu', 3],
  ['gbhnt', 1],
  ['gbhst', 1],
  ['gbhux', 1],
  ['gbwww', 1],
  ['gbhth', 3],
  ['gbilf', 3],
  ['gbimm', 1],
  ['gbich', 3],
  ['gbigt', 1],
  ['gbigm', 3],
  ['gbins', 1],
  ['gbiay', 1],
  ['gbivr', 1],
  ['gbivg', 1],
  ['gbinr', 1],
  ['gbink', 1],
  ['gbivk', 3],
  ['gbinv', 5],
  ['gbips', 5],
  ['gbirb', 1],
  ['gbirv', 1],
  ['gbimg', 1],
  ['gbisy', 1],
  ['gbily', 4],
  ['gbiog', 1],
  ['gbios', 1],
  ['gbsky', 4],
  ['gbwhh', 3],
  ['gbisc', 4],
  ['gbitc', 1],
  ['gbjar', 1],
  ['gbjog', 3],
  ['gbjhv', 3],
  ['gbjtb', 3],
  ['gbjur', 1],
  ['gbkea', 1],
  ['gbkei', 1],
  ['gbkes', 3],
  ['gbkmi', 3],
  ['gbkcg', 1],
  ['gbktt', 3],
  ['gbkev', 3],
  ['gbkoa', 1],
  ['gbkic', 3],
  ['gbkly', 1],
  ['gbklk', 3],
  ['gbkgh', 1],
  ['gbkio', 3],
  ['gbkps', 3],
  ['gbkyh', 1],
  ['gbklr', 1],
  ['gbkim', 1],
  ['gbkde', 1],
  ['gbkln', 3],
  ['gbkhn', 1],
  ['gbkib', 3],
  ['gbknk', 1],
  ['gbkwe', 1],
  ['gbkbe', 1],
  ['gbknc', 1],
  ['gbkip', 3],
  ['gbkcn', 1],
  ['gbkkl', 1],
  ['gbkkd', 1],
  ['gbkkn', 3],
  ['gbkbt', 3],
  ['gbkih', 1],
  ['gbkkh', 1],
  ['gbkwl', 5],
  ['gbkrm', 1],
  ['gbkis', 3],
  ['gbksf', 1],
  ['gbkyl', 3],
  ['gbkyn', 3],
  ['gbkye', 1],
  ['gbkyu', 3],
  ['gbldc', 1],
  ['gblrg', 3],
  ['gblbh', 1],
  ['gblah', 1],
  ['gblan', 1],
  ['gblao', 3],
  ['gblsh', 3],
  ['gblgo', 3],
  ['gblgs', 3],
  ['gblar', 1],
  ['gbqla', 4],
  ['gblwl', 1],
  ['gblav', 3],
  ['gblax', 1],
  ['gblcs', 1],
  ['gblos', 1],
  ['gbire', 1],
  ['gblei', 1],
  ['gbler', 5],
  ['gblvk', 1],
  ['gblyl', 1],
  ['gblmk', 3],
  ['gblcn', 1],
  ['gblsm', 1],
  ['gblhv', 1],
  ['gblht', 1],
  ['gblok', 1],
  ['gbllu', 1],
  ['gbltl', 1],
  ['gblit', 1],
  ['gbliv', 5],
  ['gblib', 1],
  ['gblab', 3],
  ['gblld', 3],
  ['gbldv', 1],
  ['gblnd', 1],
  ['gbldo', 3],
  ['gblln', 1],
  ['gblng', 1],
  ['gblnl', 1],
  ['gbllz', 1],
  ['gbllo', 1],
  ['gblmt', 1],
  ['gblff', 1],
  ['gblls', 3],
  ['gblbe', 1],
  ['gblcc', 1],
  ['gblcg', 1],
  ['gblrp', 1],
  ['gblsc', 1],
  ['gblhs', 1],
  ['gblcd', 1],
  ['gblol', 1],
  ['gblca', 3],
  ['gblcr', 3],
  ['gblna', 3],
  ['gbloh', 4],
  ['gblov', 1],
  ['gblma', 1],
  ['gboch', 1],
  ['gbloz', 1],
  ['gblon', 1],
  ['gblct', 4],
  ['gblgp', 3],
  ['gbltp', 3],
  ['gbldy', 5],
  ['gblea', 1],
  ['gblhp', 1],
  ['gbaqw', 3],
  ['gbloe', 3],
  ['gblss', 5],
  ['gblou', 1],
  ['gblow', 1],
  ['gblxy', 1],
  ['gblug', 3],
  ['gbll3', 1],
  ['gblul', 3],
  ['gblwc', 1],
  ['gblut', 6],
  ['gblxg', 1],
  ['gblux', 3],
  ['gblyb', 3],
  ['gblyx', 6],
  ['gbyos', 3],
  ['gblyd', 3],
  ['gblyr', 3],
  ['gblym', 1],
  ['gblye', 4],
  ['gblys', 1],
  ['gblnm', 3],
  ['gblyt', 1],
  ['gblcm', 1],
  ['gbmtp', 1],
  ['gbmcl', 1],
  ['gbmcd', 1],
  ['gbmac', 6],
  ['gbmyw', 3],
  ['gbmgh', 3],
  ['gbmgo', 1],
  ['gbmai', 1],
  ['gbmsd', 1],
  ['gbmal', 1],
  ['gbmlg', 1],
  ['gbmnc', 5],
  ['gbmse', 6],
  ['gbmaw', 1],
  ['gbmgt', 3],
  ['gbmbt', 1],
  ['gbmht', 1],
  ['gbmry', 3],
  ['gbmys', 3],
  ['gbmed', 1],
  ['gbmes', 3],
  ['gbmfy', 1],
  ['gbmml', 3],
  ['gbmeb', 1],
  ['gbmhj', 1],
  ['gbmth', 1],
  ['gbmvg', 3],
  ['gbrgn', 1],
  ['gbmye', 3],
  ['gbmdl', 3],
  ['gbmid', 3],
  ['gbmdw', 1],
  ['gbmll', 6],
  ['gbmlf', 1],
  ['gbmfs', 1],
  ['gbmlm', 1],
  ['gbmik', 6],
  ['gbmhd', 3],
  ['gbm89', 1],
  ['gbmis', 1],
  ['gbmrr', 1],
  ['gbmno', 6],
  ['gbmon', 1],
  ['gbmvc', 1],
  ['gbmfn', 1],
  ['gbmrs', 3],
  ['gbmmr', 1],
  ['gbche', 1],
  ['gbmsh', 3],
  ['gbmua', 1],
  ['gbmul', 4],
  ['gbmli', 3],
  ['gbmyl', 1],
  ['gbnai', 3],
  ['gbntw', 1],
  ['gbneh', 1],
  ['gbnea', 3],
  ['gbnef', 3],
  ['gbnsn', 1],
  ['gbnln', 1],
  ['gbnsa', 1],
  ['gbnbi', 3],
  ['gbnho', 1],
  ['gbnwk', 1],
  ['gbngi', 1],
  ['gbnbc', 1],
  ['gbnbu', 1],
  ['gbnby', 6],
  ['gbncs', 3],
  ['gbncy', 3],
  ['gbnhv', 1],
  ['gbnwf', 3],
  ['gbnyl', 1],
  ['gbnpo', 1],
  ['gbnpt', 1],
  ['gbnqy', 4],
  ['gbnry', 1],
  ['gbnbs', 1],
  ['gbntf', 3],
  ['gbnwn', 3],
  ['gbnyd', 1],
  ['gbngg', 3],
  ['gbnmy', 3],
  ['gbnnr', 3],
  ['gbnbw', 3],
  ['gbnhc', 1],
  ['gbnrf', 1],
  ['gbnfc', 1],
  ['gbnhs', 1],
  ['gbntl', 1],
  ['gbnmt', 1],
  ['gbnoq', 3],
  ['gbnrr', 1],
  ['gbnro', 1],
  ['gbnrs', 4],
  ['gbnsh', 1],
  ['gbnsd', 1],
  ['gbntz', 1],
  ['gbnui', 1],
  ['gbnrm', 1],
  ['gbwol', 1],
  ['gbnhp', 6],
  ['gbnrb', 1],
  ['gbnft', 1],
  ['gbnme', 1],
  ['gbnht', 6],
  ['gbnth', 1],
  ['gb7tr', 3],
  ['gbnot', 3],
  ['gbncn', 3],
  ['gbnrw', 5],
  ['gbntg', 1],
  ['gbokm', 6],
  ['gboak', 1],
  ['gboba', 5],
  ['gbodm', 6],
  ['gbodl', 1],
  ['gbodo', 3],
  ['gbokk', 1],
  ['gbodh', 1],
  ['gborh', 3],
  ['gbork', 1],
  ['gboww', 1],
  ['gbobr', 1],
  ['gbouk', 4],
  ['gboxf', 6],
  ['gbpat', 3],
  ['gbpad', 1],
  ['gbpag', 1],
  ['gbpal', 1],
  ['gbpsv', 4],
  ['gbpar', 1],
  ['gbpst', 1],
  ['gbptt', 1],
  ['gbpna', 3],
  ['gbphv', 1],
  ['gbpmy', 1],
  ['gbpbd', 1],
  ['gbpem', 1],
  ['gbped', 1],
  ['gbpdd', 1],
  ['gbpnh', 3],
  ['gbpnb', 3],
  ['gbpne', 1],
  ['gbpkd', 1],
  ['gbpmm', 1],
  ['gbenn', 1],
  ['gbpry', 1],
  ['gbpae', 1],
  ['gbpen', 5],
  ['gbper', 5],
  ['gbphd', 1],
  ['gbptc', 3],
  ['gbpkr', 3],
  ['gbppg', 1],
  ['gbpi7', 3],
  ['gbpwm', 3],
  ['gbplo', 3],
  ['gbply', 5],
  ['gbpkl', 1],
  ['gbplp', 3],
  ['gbplr', 1],
  ['gbpdl', 3],
  ['gbpoo', 1],
  ['gbpwe', 1],
  ['gbprz', 1],
  ['gbpap', 3],
  ['gbpak', 1],
  ['gbpoc', 3],
  ['gbpdi', 1],
  ['gbpeg', 3],
  ['gbpln', 1],
  ['gbrtl', 1],
  ['gbpgv', 3],
  ['gbpgg', 1],
  ['gbpgd', 1],
  ['gbisa', 3],
  ['gbpog', 1],
  ['gbppe', 1],
  ['gbpsm', 1],
  ['gbpse', 3],
  ['gbpsu', 1],
  ['gbptb', 1],
  ['gbpwi', 3],
  ['gbpfy', 3],
  ['gbpvd', 3],
  ['gbpvg', 3],
  ['gbpba', 3],
  ['gbpru', 3],
  ['gbpor', 3],
  ['gbpco', 1],
  ['gbpcw', 3],
  ['gbpgi', 1],
  ['gbptg', 3],
  ['gbplv', 3],
  ['gbptd', 1],
  ['gbpoh', 3],
  ['gbprp', 1],
  ['gbpth', 3],
  ['gbpkn', 3],
  ['gbptl', 1],
  ['gbprl', 3],
  ['gbpmd', 1],
  ['gbpmk', 3],
  ['gbprn', 1],
  ['gbpnv', 1],
  ['gbpng', 1],
  ['gbppk', 3],
  ['gbprj', 3],
  ['gbprt', 1],
  ['gbptr', 1],
  ['gbpsc', 3],
  ['gbpka', 1],
  ['gbpts', 1],
  ['gbpme', 5],
  ['gbpsy', 3],
  ['gbpsw', 3],
  ['gbgl9', 1],
  ['gboyl', 1],
  ['gbpbm', 3],
  ['gbpre', 3],
  ['gbpik', 6],
  ['gbpft', 1],
  ['gbpwl', 3],
  ['gbqdl', 1],
  ['gbqub', 1],
  ['gbrmb', 3],
  ['gbrsy', 1],
  ['gbrmg', 1],
  ['gbrgl', 1],
  ['gbrav', 1],
  ['gbrbd', 1],
  ['gbrdn', 1],
  ['gbrea', 3],
  ['gbred', 1],
  ['gbrer', 1],
  ['gbreb', 1],
  ['gbrds', 1],
  ['gbreh', 6],
  ['gbrem', 1],
  ['gbrph', 1],
  ['gbrej', 1],
  ['gbren', 3],
  ['gbryl', 3],
  ['gbrne', 1],
  ['gbrhu', 3],
  ['gbrcb', 1],
  ['gbrhy', 1],
  ['gbrhs', 3],
  ['gbric', 3],
  ['gbrid', 1],
  ['gbrrd', 1],
  ['gbrvf', 1],
  ['gbdnd', 4],
  ['gbano', 1],
  ['gbrbb', 1],
  ['gbrcs', 5],
  ['gbrfd', 1],
  ['gbroc', 1],
  ['gbrfr', 1],
  ['gbrty', 3],
  ['gbrme', 1],
  ['gbrsh', 3],
  ['gbrda', 1],
  ['gbror', 1],
  ['gbroy', 1],
  ['gbrth', 1],
  ['gbrou', 3],
  ['gbrow', 1],
  ['gbrum', 1],
  ['gbrdh', 1],
  ['gbrun', 1],
  ['gbryd', 3],
  ['gbrye', 1],
  ['gbabj', 3],
  ['gbsag', 1],
  ['gbtbr', 1],
  ['gbcnp', 1],
  ['gbszc', 3],
  ['gbscm', 1],
  ['gbsvs', 1],
  ['gbshy', 1],
  ['gbtle', 3],
  ['gbtnr', 1],
  ['gbsju', 1],
  ['gbskv', 1],
  ['gblds', 3],
  ['gbsgp', 1],
  ['gbsqk', 3],
  ['gbsvz', 3],
  ['gbsma', 3],
  ['gbsmv', 3],
  ['gbsmm', 1],
  ['gbsnv', 1],
  ['gbsmc', 3],
  ['gbuen', 1],
  ['gbppo', 3],
  ['gbstq', 3],
  ['gbsmp', 3],
  ['gbasa', 3],
  ['gbscb', 3],
  ['gbaen', 1],
  ['gbsfy', 1],
  ['gbsed', 1],
  ['gbslc', 3],
  ['gbsth', 1],
  ['gbsnj', 3],
  ['gbndy', 5],
  ['gbsad', 1],
  ['gbspi', 1],
  ['gbsnk', 3],
  ['gbsdo', 1],
  ['gbsds', 1],
  ['gbsdw', 3],
  ['gbswc', 1],
  ['gbsrx', 1],
  ['gbsau', 3],
  ['gbswy', 1],
  ['gbsqz', 4],
  ['gbscf', 3],
  ['gbsfw', 1],
  ['gbsca', 1],
  ['gbsf5', 1],
  ['gbsfk', 1],
  ['gbscs', 4],
  ['gbrie', 1],
  ['gbscr', 1],
  ['gbscp', 3],
  ['gbsfr', 3],
  ['gbsef', 1],
  ['gbsea', 1],
  ['gbshu', 3],
  ['gbxss', 1],
  ['gbeon', 3],
  ['gbsvw', 1],
  ['gbslb', 3],
  ['gbssy', 3],
  ['gbsnc', 3],
  ['gbsvi', 3],
  ['gbshn', 3],
  ['gbsai', 1],
  ['gbspy', 1],
  ['gbsss', 1],
  ['gbsez', 1],
  ['gbshs', 1],
  ['gbshe', 1],
  ['gbeil', 1],
  ['gbsdq', 1],
  ['gbshv', 1],
  ['gbsgm', 3],
  ['gbtld', 1],
  ['gbshx', 3],
  ['gbifn', 1],
  ['gbsyl', 3],
  ['gbssk', 3],
  ['gbeen', 1],
  ['gbsho', 3],
  ['gbesh', 3],
  ['gbsht', 1],
  ['gboht', 1],
  ['gbses', 1],
  ['gbsdm', 3],
  ['gbsil', 1],
  ['gbsvt', 1],
  ['gbsmw', 1],
  ['gbszb', 1],
  ['gbske', 3],
  ['gbskz', 3],
  ['gbskr', 1],
  ['gbats', 1],
  ['gbsjj', 1],
  ['gbsid', 1],
  ['gbszt', 1],
  ['gbsdl', 3],
  ['gbslv', 3],
  ['gbspt', 1],
  ['gbsut', 1],
  ['gbtrs', 1],
  ['gbshg', 3],
  ['gbotc', 1],
  ['gbsoq', 3],
  ['gbsry', 1],
  ['gbssh', 1],
  ['gbuts', 1],
  ['gbtht', 1],
  ['gbsou', 5],
  ['gbsec', 1],
  ['gbsnd', 1],
  ['gbsen', 6],
  ['gbsaa', 3],
  ['gbsrq', 3],
  ['gbsrt', 3],
  ['gbshk', 3],
  ['gbswd', 1],
  ['gbsuh', 1],
  ['gbais', 1],
  ['gbspq', 1],
  ['gbstz', 3],
  ['gbsri', 3],
  ['gbere', 1],
  ['gbtnd', 3],
  ['gbsow', 1],
  ['gbted', 1],
  ['gbstn', 4],
  ['gbsba', 1],
  ['gbsxe', 1],
  ['gbst2', 1],
  ['gbsyy', 3],
  ['gbsgt', 1],
  ['gbsch', 1],
  ['gbsct', 1],
  ['gbstt', 3],
  ['gbsot', 1],
  ['gbsvn', 3],
  ['gbyvz', 1],
  ['gbsto', 5],
  ['gbsyr', 3],
  ['gbsbi', 1],
  ['gbsjl', 3],
  ['gbstr', 1],
  ['gbsav', 3],
  ['gbsrh', 1],
  ['gbsrc', 3],
  ['gbhta', 1],
  ['gbsrz', 1],
  ['gbsoa', 1],
  ['gbsns', 1],
  ['gbsoy', 4],
  ['gbsfu', 3],
  ['gbsjy', 3],
  ['gburt', 1],
  ['gbsul', 1],
  ['gblsi', 4],
  ['gbsmu', 1],
  ['gbsun', 1],
  ['gbpsb', 3],
  ['gbwob', 4],
  ['gbswe', 3],
  ['gbswa', 5],
  ['gbsnn', 6],
  ['gbswn', 1],
  ['gbsym', 1],
  ['gbtad', 1],
  ['gbtez', 1],
  ['gbtln', 1],
  ['gbtam', 1],
  ['gbtab', 1],
  ['gbtar', 3],
  ['gbtai', 3],
  ['gbtay', 1],
  ['gbtli', 1],
  ['gbtee', 1],
  ['gbmme', 4],
  ['gbtnm', 1],
  ['gbenb', 1],
  ['gbten', 3],
  ['gbrig', 1],
  ['gbttl', 1],
  ['gbkha', 1],
  ['gbths', 1],
  ['gbthp', 1],
  ['gbfti', 1],
  ['gbtrl', 3],
  ['gbnle', 1],
  ['gbtpb', 1],
  ['gbnss', 1],
  ['gbrsl', 1],
  ['gbfuu', 1],
  ['gbthr', 3],
  ['gbtig', 3],
  ['gbtil', 1],
  ['gbtfo', 3],
  ['gbtng', 3],
  ['gbtwl', 5],
  ['gbtre', 4],
  ['gbtvd', 3],
  ['gbtob', 1],
  ['gbtbn', 1],
  ['gbtft', 3],
  ['gbtol', 1],
  ['gbtgu', 3],
  ['gbthm', 1],
  ['gbtby', 3],
  ['gbtpo', 3],
  ['gbtor', 1],
  ['gbtrd', 1],
  ['gbttt', 6],
  ['gbtra', 1],
  ['gbtrr', 3],
  ['gbtfr', 3],
  ['gbtro', 1],
  ['gbtso', 1],
  ['gbtrn', 3],
  ['gbtru', 1],
  ['gbtdi', 1],
  ['gbtyd', 3],
  ['gbtyn', 1],
  ['gbtym', 1],
  ['gbuig', 3],
  ['gbull', 1],
  ['gbulf', 1],
  ['gbunt', 4],
  ['gbupv', 4],
  ['gbuph', 1],
  ['gbupr', 1],
  ['gbufy', 3],
  ['gbuhf', 4],
  ['gbupi', 1],
  ['gbvgi', 1],
  ['gbven', 3],
  ['gbvid', 3],
  ['gbvoe', 1],
  ['gbwdd', 6],
  ['gbwab', 3],
  ['gbwfd', 1],
  ['gbwlk', 3],
  ['gbwla', 1],
  ['gbqgt', 1],
  ['gbloy', 1],
  ['gbwlz', 3],
  ['gbwtw', 3],
  ['gbwmf', 3],
  ['gbwgw', 1],
  ['gbwnz', 3],
  ['gbwre', 3],
  ['gbwrh', 3],
  ['gbwko', 3],
  ['gbwnd', 1],
  ['gbwpt', 1],
  ['gbwrn', 1],
  ['gbwat', 1],
  ['gbfuc', 1],
  ['gbwft', 3],
  ['gbwaf', 1],
  ['gbwls', 1],
  ['gbwns', 3],
  ['gbwby', 1],
  ['gbwmb', 1],
  ['gbwax', 3],
  ['gbwyx', 1],
  ['gbwbf', 3],
  ['gbcwh', 1],
  ['gbwgd', 1],
  ['gbarp', 1],
  ['gbwki', 3],
  ['gbwrb', 1],
  ['gbwmd', 1],
  ['gbwml', 6],
  ['gbwms', 3],
  ['gblwt', 3],
  ['gbwwe', 3],
  ['gbwys', 3],
  ['gbwte', 1],
  ['gbwxw', 1],
  ['gbwsp', 3],
  ['gbwsm', 3],
  ['gbzoy', 1],
  ['gbwry', 5],
  ['gbrws', 1],
  ['gbwey', 1],
  ['gbwlg', 1],
  ['gbwhy', 4],
  ['gbwnl', 1],
  ['gbwtb', 1],
  ['gbwcb', 1],
  ['gbwhb', 1],
  ['gbwhv', 1],
  ['gbwhe', 3],
  ['gbhts', 1],
  ['gbynt', 1],
  ['gbwts', 1],
  ['gbwic', 5],
  ['gbwkm', 3],
  ['gbwdn', 1],
  ['gbwgn', 1],
  ['gbwig', 1],
  ['gbesd', 3],
  ['gbwl2', 3],
  ['gbint', 1],
  ['gbwis', 1],
  ['gbwex', 1],
  ['gbwiv', 1],
  ['gbwuf', 1],
  ['gbwbg', 6],
  ['gbwor', 1],
  ['gbwot', 3],
  ['gbwyc', 1],
  ['gbwyk', 1],
  ['gbwyr', 3],
  ['gbquy', 6],
  ['gbyap', 1],
  ['gbymo', 1],
  ['gbyfr', 1],
  ['gbyll', 1],
  ['gbyeo', 6],
  ['gbyke', 1],
  ['gbyrk', 1],
  ['gdhil', 1],
  ['gdcru', 6],
  ['gdgnd', 4],
  ['gdgre', 1],
  ['gdstg', 1],
  ['gebus', 5],
  ['geies', 3],
  ['gekul', 1],
  ['gekut', 4],
  ['gepti', 1],
  ['gesui', 6],
  ['geabk', 3],
  ['gesps', 1],
  ['getbs', 4],
  ['gevaz', 6],
  ['gfcay', 5],
  ['gfddc', 1],
  ['gfgde', 4],
  ['gflvt', 4],
  ['gfmpy', 4],
  ['gfrei', 4],
  ['gfoyp', 4],
  ['gfxau', 4],
  ['ggaci', 5],
  ['gggci', 5],
  ['ggspt', 3],
  ['ghacc', 4],
  ['ghada', 1],
  ['ghaur', 1],
  ['ghaxi', 1],
  ['ghcct', 1],
  ['ghkit', 1],
  ['ghkms', 4],
  ['ghspd', 1],
  ['ghsek', 1],
  ['ghsha', 1],
  ['ghnyi', 4],
  ['ghtkd', 5],
  ['ghtml', 4],
  ['ghtem', 1],
  ['ghwea', 1],
  ['gigib', 5],
  ['gljeg', 5],
  ['glllu', 4],
  ['gljrk', 4],
  ['gldan', 1],
  ['gldun', 4],
  ['globy', 5],
  ['gljav', 5],
  ['gliut', 1],
  ['glqkt', 6],
  ['glfhn', 1],
  ['glsfj', 4],
  ['glkan', 1],
  ['gljgr', 5],
  ['glkul', 3],
  ['glkus', 5],
  ['glkun', 1],
  ['glmml', 1],
  ['gljsu', 5],
  ['glmrg', 1],
  ['glnal', 3],
  ['gljnn', 5],
  ['gljns', 4],
  ['gluak', 5],
  ['glcnp', 4],
  ['gljfr', 5],
  ['glthu', 5],
  ['glnaq', 5],
  ['gljqa', 4],
  ['gljju', 5],
  ['gljch', 5],
  ['gljgo', 5],
  ['glque', 4],
  ['glseq', 3],
  ['gljhs', 5],
  ['glagm', 5],
  ['gltnt', 3],
  ['gltov', 1],
  ['gljuv', 4],
  ['glumd', 5],
  ['gmbjl', 5],
  ['gmsuk', 1],
  ['gnbty', 1],
  ['gnbkj', 4],
  ['gncky', 5],
  ['gnfaa', 4],
  ['gnfig', 4],
  ['gnknn', 4],
  ['gnksi', 4],
  ['gnsbi', 4],
  ['gnlek', 4],
  ['gnmca', 4],
  ['gnsby', 1],
  ['gnnze', 4],
  ['gnbrp', 1],
  ['gnkmr', 1],
  ['gngii', 4],
  ['gpbmh', 3],
  ['gpbbr', 5],
  ['gpcbe', 1],
  ['gpfae', 3],
  ['gpgrb', 1],
  ['gpjar', 1],
  ['gpdsd', 4],
  ['gpgos', 3],
  ['gpgbj', 4],
  ['gpptp', 5],
  ['gpptl', 3],
  ['gpsfc', 3],
  ['gphtb', 4],
  ['gplss', 4],
  ['gqbsg', 5],
  ['gqbul', 1],
  ['gqcog', 1],
  ['gqlub', 1],
  ['gqssg', 5],
  ['gqgem', 6],
  ['gqpet', 1],
  ['gqser', 1],
  ['gqzaf', 1],
  ['grani', 1],
  ['gracl', 1],
  ['gradl', 1],
  ['graeg', 1],
  ['gragn', 1],
  ['graef', 1],
  ['grpil', 1],
  ['grakm', 1],
  ['gramr', 1],
  ['gragm', 1],
  ['grgma', 1],
  ['graml', 1],
  ['grrou', 1],
  ['gragk', 1],
  ['graor', 1],
  ['grage', 1],
  ['grako', 1],
  ['graki', 1],
  ['gragf', 1],
  ['grasf', 1],
  ['gragg', 1],
  ['granl', 1],
  ['graga', 3],
  ['grape', 1],
  ['graid', 1],
  ['graig', 1],
  ['grakh', 1],
  ['grakt', 1],
  ['graio', 3],
  ['grlvr', 1],
  ['grats', 3],
  ['gralo', 1],
  ['grapl', 3],
  ['gramf', 1],
  ['grami', 1],
  ['grand', 1],
  ['grant', 1],
  ['gratk', 1],
  ['granp', 1],
  ['grana', 1],
  ['grarx', 1],
  ['grarm', 1],
  ['grard', 1],
  ['grark', 3],
  ['grtem', 1],
  ['grapv', 3],
  ['grass', 3],
  ['grsos', 1],
  ['grast', 1],
  ['grjty', 1],
  ['grata', 3],
  ['gratl', 1],
  ['gratn', 1],
  ['grath', 4],
  ['grava', 3],
  ['graen', 1],
  ['grclk', 1],
  ['grqkg', 6],
  ['grchq', 5],
  ['grjkh', 5],
  ['grdos', 1],
  ['grdia', 1],
  ['grdik', 1],
  ['grdmb', 1],
  ['grdon', 1],
  ['grdpa', 1],
  ['grdre', 1],
  ['grdaf', 1],
  ['gredi', 1],
  ['gregi', 1],
  ['greln', 1],
  ['grxzx', 3],
  ['grelt', 1],
  ['greeu', 1],
  ['grfls', 3],
  ['grepi', 1],
  ['gretk', 1],
  ['grerk', 1],
  ['grerm', 1],
  ['grery', 3],
  ['grere', 3],
  ['greyd', 1],
  ['grfnr', 1],
  ['grfam', 1],
  ['grfis', 1],
  ['grfol', 1],
  ['grfou', 1],
  ['grsat', 1],
  ['grgal', 1],
  ['grglx', 1],
  ['grgad', 1],
  ['grglo', 1],
  ['grgly', 1],
  ['grgfd', 3],
  ['grgyt', 1],
  ['grgav', 1],
  ['grhal', 1],
  ['grhrk', 1],
  ['grher', 5],
  ['grhyd', 1],
  ['grhyd', 1],
  ['gries', 1],
  ['grier', 3],
  ['grigo', 1],
  ['grjik', 4],
  ['grino', 1],
  ['grios', 1],
  ['grioa', 4],
  ['grher', 5],
  ['grnai', 6],
  ['gritm', 1],
  ['grita', 1],
  ['grith', 1],
  ['grklm', 1],
  ['grkai', 1],
  ['grklx', 5],
  ['grkld', 1],
  ['grkll', 1],
  ['grkre', 1],
  ['grksk', 1],
  ['grkap', 1],
  ['grkrm', 1],
  ['grkdm', 3],
  ['grkag', 1],
  ['grkst', 1],
  ['grksp', 1],
  ['grksj', 5],
  ['grkis', 1],
  ['grkas', 1],
  ['grkzs', 4],
  ['grkso', 4],
  ['grksi', 1],
  ['grkak', 3],
  ['grkei', 3],
  ['grktp', 3],
  ['grkef', 1],
  ['grker', 1],
  ['grkts', 3],
  ['grcsf', 1],
  ['grkio', 3],
  ['grkim', 3],
  ['grkym', 1],
  ['grkip', 1],
  ['grkli', 1],
  ['grkin', 1],
  ['grkns', 1],
  ['grkps', 3],
  ['grkgs', 5],
  ['grkem', 1],
  ['grkof', 1],
  ['grkzi', 4],
  ['grkyl', 1],
  ['grkit', 4],
  ['grkyt', 1],
  ['grkmi', 1],
  ['graok', 5],
  ['grkah', 3],
  ['grkea', 1],
  ['grcfu', 5],
  ['grkms', 1],
  ['grkrt', 3],
  ['grkim', 3],
  ['grpkk', 1],
  ['grlar', 1],
  ['grlry', 1],
  ['grlav', 1],
  ['grlav', 1],
  ['grlef', 1],
  ['grlev', 1],
  ['grlev', 1],
  ['grlfk', 1],
  ['grlxs', 4],
  ['grlnd', 1],
  ['grlrs', 5],
  ['grlii', 1],
  ['grlia', 1],
  ['grldr', 1],
  ['grlin', 1],
  ['grlip', 1],
  ['grlix', 1],
  ['grltr', 1],
  ['grptl', 1],
  ['grlra', 4],
  ['grm8l', 1],
  ['grmgl', 3],
  ['grmsa', 1],
  ['grmkp', 1],
  ['grmrm', 1],
  ['grmxk', 1],
  ['grmtr', 1],
  ['grmgn', 1],
  ['grmeg', 1],
  ['grmen', 1],
  ['grmel', 1],
  ['grmhi', 1],
  ['grmea', 1],
  ['grmet', 1],
  ['grmlo', 4],
  ['grmol', 3],
  ['grmon', 1],
  ['grmvo', 1],
  ['grmou', 1],
  ['grmdr', 1],
  ['grjmk', 5],
  ['grmyl', 1],
  ['grmrs', 1],
  ['grmyb', 1],
  ['grmjt', 5],
  ['grmyr', 1],
  ['grnas', 1],
  ['grnea', 1],
  ['grnid', 1],
  ['grnis', 1],
  ['grnaf', 3],
  ['grnpk', 1],
  ['grjnx', 5],
  ['grnal', 6],
  ['grnar', 3],
  ['grnka', 3],
  ['grnkv', 1],
  ['grnma', 1],
  ['grnpy', 1],
  ['grnst', 1],
  ['grkar', 1],
  ['groik', 1],
  ['grore', 1],
  ['groro', 1],
  ['groth', 3],
  ['grour', 1],
  ['grpme', 1],
  ['grpep', 1],
  ['grpfa', 3],
  ['grpch', 1],
  ['grpsf', 1],
  ['grpao', 3],
  ['grgpa', 5],
  ['grpax', 1],
  ['grpel', 3],
  ['grper', 1],
  ['grpgm', 1],
  ['grprr', 3],
  ['grpsk', 1],
  ['grpeb', 1],
  ['grplv', 1],
  ['grpre', 1],
  ['grpyv', 1],
  ['grpse', 1],
  ['grpir', 1],
  ['grpis', 1],
  ['grpit', 1],
  ['gralg', 1],
  ['grpia', 1],
  ['grpti', 1],
  ['grp5t', 3],
  ['grplg', 1],
  ['grplm', 3],
  ['grptk', 1],
  ['grplc', 1],
  ['grpke', 1],
  ['grptr', 1],
  ['grvth', 1],
  ['grphe', 1],
  ['grpkh', 4],
  ['grpoa', 1],
  ['grpvk', 5],
  ['grppi', 1],
  ['grdsd', 3],
  ['grpro', 1],
  ['grpsa', 1],
  ['grpaa', 1],
  ['grpte', 1],
  ['grpli', 1],
  ['grpyr', 5],
  ['grpyt', 1],
  ['grpar', 1],
  ['grpas', 5],
  ['grpms', 1],
  ['grpyl', 3],
  ['grraf', 1],
  ['grrev', 1],
  ['grrho', 5],
  ['grrio', 1],
  ['grret', 1],
  ['grriz', 1],
  ['grsgt', 1],
  ['grsgb', 1],
  ['grstn', 1],
  ['grsal', 1],
  ['grsnk', 1],
  ['grsmi', 5],
  ['grsam', 1],
  ['grshc', 1],
  ['grshi', 1],
  ['grsgr', 1],
  ['grsgi', 1],
  ['grsii', 1],
  ['grsik', 1],
  ['grjsh', 5],
  ['grskd', 1],
  ['grska', 1],
  ['grsko', 1],
  ['grskm', 1],
  ['grjsi', 5],
  ['grsku', 5],
  ['grsoi', 1],
  ['grsfn', 3],
  ['grsog', 1],
  ['grsor', 3],
  ['grsou', 1],
  ['grsla', 1],
  ['grsdh', 3],
  ['grspe', 1],
  ['grscr', 1],
  ['grsha', 1],
  ['grsti', 1],
  ['grsys', 3],
  ['grsys', 3],
  ['grsud', 1],
  ['grsym', 1],
  ['grjsy', 1],
  ['grjsy', 1],
  ['grsyv', 1],
  ['grsai', 3],
  ['grzaq', 3],
  ['grser', 1],
  ['grtso', 1],
  ['grskg', 5],
  ['grtko', 1],
  ['grtrs', 1],
  ['grthp', 1],
  ['grthm', 1],
  ['grjtr', 5],
  ['grtil', 1],
  ['grtla', 1],
  ['grtrk', 1],
  ['grtry', 1],
  ['grtsi', 1],
  ['grtvr', 3],
  ['grtin', 1],
  ['grvss', 1],
  ['grvth', 1],
  ['grvab', 1],
  ['grvlc', 1],
  ['grvom', 1],
  ['grvlm', 3],
  ['grvri', 1],
  ['grvol', 5],
  ['grxkb', 1],
  ['gryli', 1],
  ['gryal', 1],
  ['gryer', 1],
  ['grzth', 5],
  ['grago', 1],
  ['grars', 3],
  ['grpnt', 1],
  ['gsgrv', 3],
  ['gsleh', 1],
  ['gtcmm', 4],
  ['gtchr', 3],
  ['gtciq', 4],
  ['gtctf', 6],
  ['gtcbv', 6],
  ['gtdon', 6],
  ['gte8c', 1],
  ['gtele', 1],
  ['gtenj', 4],
  ['gtfrs', 4],
  ['gtgua', 4],
  ['gthug', 4],
  ['gtlaa', 1],
  ['gtlox', 4],
  ['gtliv', 3],
  ['gtmcr', 4],
  ['gtpcg', 4],
  ['gtpkj', 4],
  ['gtpon', 4],
  ['gtpbr', 5],
  ['gtprq', 1],
  ['gtstc', 1],
  ['gtaaz', 6],
  ['gtaqb', 4],
  ['gtruv', 4],
  ['gtrio', 1],
  ['gtsnj', 3],
  ['gttuc', 1],
  ['gttkm', 4],
  ['gtuax', 4],
  ['gtvin', 1],
  ['guapr', 1],
  ['gubrr', 1],
  ['gugum', 5],
  ['guygo', 6],
  ['gwbnt', 1],
  ['gwbol', 3],
  ['gwcac', 3],
  ['gwcja', 1],
  ['gwfar', 1],
  ['gyant', 1],
  ['gyahl', 4],
  ['gynai', 4],
  ['gybmj', 4],
  ['gygfo', 5],
  ['gybcg', 4],
  ['gyeke', 4],
  ['gyevr', 3],
  ['gygeo', 5],
  ['gyimb', 4],
  ['gykai', 4],
  ['gykar', 4],
  ['gykrm', 4],
  ['gykrg', 4],
  ['gykto', 4],
  ['gykkg', 4],
  ['gykpg', 4],
  ['gyltm', 4],
  ['gyldn', 1],
  ['gylub', 4],
  ['gyusi', 4],
  ['gymha', 4],
  ['gyveg', 4],
  ['gymwj', 4],
  ['gymym', 4],
  ['gynam', 5],
  ['gyogl', 4],
  ['gyorj', 4],
  ['gypmt', 4],
  ['gyprr', 4],
  ['gypiq', 4],
  ['gypkm', 4],
  ['gysdc', 4],
  ['gyskm', 4],
  ['hkabd', 3],
  ['hkalc', 1],
  ['hkcwb', 3],
  ['hkhkc', 3],
  ['hkhkg', 5],
  ['hkkwn', 3],
  ['hkktz', 6],
  ['hklam', 1],
  ['hktol', 1],
  ['hktst', 3],
  ['hkztw', 4],
  ['hkvic', 1],
  ['hkwni', 3],
  ['hkyue', 1],
  ['hmhea', 1],
  ['hmmcd', 1],
  ['hnamp', 1],
  ['hnahs', 6],
  ['hnbhg', 4],
  ['hncaa', 4],
  ['hncdd', 4],
  ['hncmy', 6],
  ['hnruy', 4],
  ['hnenq', 4],
  ['hncyl', 4],
  ['hnedq', 4],
  ['hngac', 4],
  ['hnguo', 4],
  ['hngja', 5],
  ['hnhnn', 1],
  ['hnirn', 4],
  ['hnjut', 4],
  ['hnlce', 5],
  ['hnlez', 4],
  ['hnlui', 4],
  ['hnllh', 4],
  ['hnlmh', 4],
  ['hnmrj', 4],
  ['hnoan', 4],
  ['hnomo', 1],
  ['hnpch', 4],
  ['hnpca', 1],
  ['hnpcr', 1],
  ['hnpeu', 4],
  ['hnrtb', 5],
  ['hnset', 4],
  ['hnslo', 1],
  ['hnsap', 5],
  ['hnsdh', 4],
  ['hnscd', 4],
  ['hntgu', 5],
  ['hntea', 5],
  ['hntcf', 4],
  ['hntji', 5],
  ['hnuii', 4],
  ['hnvta', 4],
  ['hnyor', 6],
  ['hralj', 3],
  ['hratl', 3],
  ['hrbak', 3],
  ['hrbas', 3],
  ['hrbat', 3],
  ['hrblc', 3],
  ['hrbnm', 3],
  ['hrble', 3],
  ['hrbol', 3],
  ['hrbor', 3],
  ['hrbrv', 3],
  ['hrbza', 3],
  ['hrbrb', 3],
  ['hrbri', 3],
  ['hrcvt', 3],
  ['hrcrs', 3],
  ['hrcra', 3],
  ['hrdlj', 3],
  ['hrdal', 1],
  ['hrdnc', 3],
  ['hrdrk', 3],
  ['hrres', 3],
  ['hrdur', 3],
  ['hrerd', 3],
  ['hrfna', 3],
  ['hrn9f', 1],
  ['hrfil', 1],
  ['hrgna', 3],
  ['hrhva', 3],
  ['hrilo', 3],
  ['hrist', 1],
  ['hrjab', 3],
  ['hrjdr', 3],
  ['hrjsa', 3],
  ['hrkal', 1],
  ['hrkfr', 1],
  ['hrkpr', 3],
  ['hrkab', 3],
  ['hrkjv', 1],
  ['hrklk', 3],
  ['hrkmn', 3],
  ['hrkza', 1],
  ['hrkmz', 3],
  ['hrkor', 3],
  ['hrkrm', 3],
  ['hrkos', 3],
  ['hrkra', 3],
  ['hrkrk', 3],
  ['hrkuk', 1],
  ['hrlam', 3],
  ['hrlst', 3],
  ['hrlkr', 3],
  ['hrlpr', 3],
  ['hrlpd', 3],
  ['hrmak', 3],
  ['hrmiz', 1],
  ['hrmal', 3],
  ['hrmta', 3],
  ['hrmas', 3],
  ['hrmrg', 3],
  ['hrmet', 3],
  ['hrmil', 3],
  ['hrmnk', 3],
  ['hrmhv', 3],
  ['hrmom', 1],
  ['hrmod', 3],
  ['hrmrj', 3],
  ['hrmur', 3],
  ['hrnrz', 3],
  ['hrnov', 1],
  ['hrnvl', 3],
  ['hrnvd', 3],
  ['hrnvg', 3],
  ['hrndd', 1],
  ['hronj', 3],
  ['hroms', 3],
  ['hromi', 3],
  ['hropa', 3],
  ['hropt', 3],
  ['hrorb', 3],
  ['hrpag', 3],
  ['hrpas', 1],
  ['hrple', 3],
  ['hrplm', 3],
  ['hrpla', 3],
  ['hrpmn', 3],
  ['hrpor', 3],
  ['hrprz', 3],
  ['hrprp', 3],
  ['hrpre', 3],
  ['hrpri', 3],
  ['hrpvk', 1],
  ['hrprn', 3],
  ['hrprv', 3],
  ['hrprs', 3],
  ['hrpca', 3],
  ['hrpnt', 3],
  ['hrrab', 3],
  ['hrrbc', 3],
  ['hrras', 3],
  ['hrxxx', 1],
  ['hrrgc', 3],
  ['hrrgn', 3],
  ['hrrov', 3],
  ['hrrvc', 1],
  ['hrsal', 3],
  ['hrsrg', 3],
  ['hrslc', 3],
  ['hrsen', 3],
  ['hrsib', 3],
  ['hrsil', 1],
  ['hrslo', 1],
  ['hrsis', 3],
  ['hrsrd', 3],
  ['hrsla', 3],
  ['hrslt', 3],
  ['hrsbd', 3],
  ['hrsbr', 3],
  ['hrspl', 1],
  ['hrsgd', 3],
  ['hrsgr', 3],
  ['hrstm', 3],
  ['hrsto', 3],
  ['hrsur', 3],
  ['hrsuc', 3],
  ['hrsdr', 3],
  ['hrsmn', 3],
  ['hrsup', 3],
  ['hrssk', 1],
  ['hrsus', 3],
  ['hrstp', 3],
  ['hrsut', 1],
  ['hrsvj', 3],
  ['hrsvk', 3],
  ['hrtno', 1],
  ['hrtkn', 3],
  ['hrtri', 1],
  ['hrtro', 3],
  ['hrtrj', 3],
  ['hrtrk', 3],
  ['hrtnr', 3],
  ['hrtur', 1],
  ['hrubl', 3],
  ['hrumg', 3],
  ['hrunj', 3],
  ['hruvm', 1],
  ['hrvlb', 3],
  ['hrvlk', 3],
  ['hrviz', 1],
  ['hrvln', 3],
  ['hr9sa', 3],
  ['hrvgn', 3],
  ['hrvis', 3],
  ['hrvdc', 3],
  ['hrvra', 3],
  ['hrvrk', 3],
  ['hrvrs', 3],
  ['hrvrg', 3],
  ['hrvrr', 3],
  ['hrvuk', 3],
  ['hrzag', 6],
  ['hrzgl', 3],
  ['hrzlr', 3],
  ['hrzut', 1],
  ['htsmc', 1],
  ['htcap', 5],
  ['htcrc', 3],
  ['htfom', 1],
  ['htfli', 1],
  ['htgvs', 1],
  ['htjak', 5],
  ['htjee', 5],
  ['htlff', 1],
  ['htlft', 3],
  ['htplh', 1],
  ['htaca', 1],
  ['htmir', 1],
  ['htpeg', 1],
  ['htpap', 5],
  ['huady', 3],
  ['huafu', 3],
  ['huasz', 3],
  ['huals', 3],
  ['huakt', 3],
  ['huzar', 1],
  ['hubrs', 1],
  ['hubzo', 1],
  ['hubda', 1],
  ['hubze', 3],
  ['hubud', 5],
  ['hucpe', 3],
  ['hucmr', 3],
  ['hudeb', 5],
  ['hudpa', 3],
  ['hudre', 3],
  ['hudve', 3],
  ['huduu', 3],
  ['huebs', 1],
  ['huerc', 1],
  ['huerd', 1],
  ['huest', 1],
  ['hufvs', 3],
  ['hugyo', 1],
  ['hugny', 3],
  ['huood', 3],
  ['huhal', 3],
  ['huhrt', 1],
  ['huhyf', 1],
  ['hujkz', 3],
  ['hukco', 3],
  ['hukar', 3],
  ['huxel', 1],
  ['hukku', 1],
  ['huket', 3],
  ['hukia', 3],
  ['hukom', 3],
  ['hukor', 3],
  ['hulab', 3],
  ['hulmy', 3],
  ['humkn', 3],
  ['humcq', 5],
  ['humoh', 1],
  ['humty', 3],
  ['hungb', 1],
  ['hunza', 3],
  ['hunsz', 3],
  ['hupal', 1],
  ['huxxx', 1],
  ['husze', 1],
  ['huszd', 3],
  ['hupes', 3],
  ['huszm', 3],
  ['husob', 4],
  ['hursp', 3],
  ['hutht', 1],
  ['hutoj', 1],
  ['hutac', 1],
  ['huvig', 3],
  ['huztk', 1],
  ['huzsi', 1],
  ['huups', 3],
  ['idama', 1],
  ['idamq', 1],
  ['idamp', 1],
  ['idajn', 1],
  ['idbdo', 4],
  ['idbju', 1],
  ['idbuw', 4],
  ['idblw', 1],
  ['idbks', 5],
  ['idbik', 5],
  ['idbmu', 5],
  ['idbmt', 1],
  ['idbit', 1],
  ['idbll', 1],
  ['idbli', 1],
  ['idbxt', 5],
  ['idbug', 1],
  ['idceb', 1],
  ['iddas', 3],
  ['iddps', 6],
  ['iddum', 5],
  ['idene', 4],
  ['idfkq', 5],
  ['idglx', 4],
  ['idgil', 1],
  ['idgto', 5],
  ['idgre', 3],
  ['idgns', 5],
  ['iddjb', 5],
  ['idkng', 4],
  ['idkam', 1],
  ['idkar', 1],
  ['idkas', 1],
  ['idkem', 1],
  ['idkdi', 5],
  ['idktg', 5],
  ['idkid', 1],
  ['idkpn', 1],
  ['idkka', 1],
  ['idkkb', 1],
  ['idkoe', 5],
  ['idlat', 1],
  ['idlas', 1],
  ['idlla', 1],
  ['idmaj', 1],
  ['idmli', 1],
  ['idmkw', 5],
  ['idmnt', 1],
  ['idmof', 5],
  ['idmes', 5],
  ['idmrk', 1],
  ['idmrb', 1],
  ['idmkq', 5],
  ['idmeq', 5],
  ['idmuo', 1],
  ['idmri', 1],
  ['idokq', 4],
  ['idpag', 1],
  ['idppo', 1],
  ['idplm', 5],
  ['idpnn', 1],
  ['idprn', 1],
  ['idpgx', 1],
  ['idpkr', 1],
  ['idpks', 1],
  ['idpgk', 1],
  ['idptl', 1],
  ['idpaz', 3],
  ['idpex', 1],
  ['idpku', 4],
  ['idpla', 3],
  ['idpnk', 5],
  ['idpsj', 5],
  ['idpro', 1],
  ['idpnt', 1],
  ['idpss', 1],
  ['idrgt', 5],
  ['idrtg', 4],
  ['idsbg', 1],
  ['idsad', 1],
  ['idsri', 5],
  ['idsbs', 1],
  ['idsmq', 5],
  ['idsan', 1],
  ['idski', 1],
  ['idsat', 1],
  ['idseb', 1],
  ['idspa', 1],
  ['idsmb', 1],
  ['idzri', 4],
  ['idsia', 1],
  ['idslg', 1],
  ['idskw', 1],
  ['idsup', 5],
  ['idsug', 1],
  ['idsuq', 1],
  ['idseq', 5],
  ['idsus', 1],
  ['idtax', 5],
  ['idtmh', 4],
  ['idtbt', 1],
  ['idtse', 1],
  ['idtpk', 4],
  ['idtrk', 1],
  ['idteg', 1],
  ['idtka', 1],
  ['idtbg', 1],
  ['idtte', 5],
  ['idtbn', 1],
  ['idupg', 5],
  ['idwgp', 5],
  ['idadb', 1],
  ['idaeg', 4],
  ['idake', 1],
  ['idard', 4],
  ['idahi', 4],
  ['idapn', 1],
  ['idang', 1],
  ['idagd', 4],
  ['idagr', 3],
  ['idano', 1],
  ['idanr', 3],
  ['idaas', 4],
  ['idarb', 1],
  ['idarj', 4],
  ['idaun', 1],
  ['idasi', 1],
  ['idayw', 4],
  ['idbxd', 4],
  ['idbjw', 4],
  ['idbpn', 5],
  ['idblh', 1],
  ['idbal', 1],
  ['idnaf', 4],
  ['idbtj', 4],
  ['idnda', 6],
  ['idtkg', 4],
  ['idbgg', 1],
  ['idbdj', 5],
  ['idbtn', 1],
  ['idbth', 4],
  ['idbtm', 3],
  ['idbur', 3],
  ['idbak', 1],
  ['idbam', 3],
  ['idbek', 1],
  ['idbnt', 1],
  ['idbet', 1],
  ['idblv', 1],
  ['idblt', 1],
  ['idben', 1],
  ['idboa', 1],
  ['idbej', 4],
  ['idnti', 4],
  ['idbir', 1],
  ['idbui', 4],
  ['idbjg', 4],
  ['idbng', 1],
  ['idbua', 1],
  ['idbyq', 1],
  ['iduol', 4],
  ['idcsa', 1],
  ['idcer', 1],
  ['idsza', 3],
  ['idciw', 1],
  ['iddtd', 4],
  ['iddma', 3],
  ['iddiv', 1],
  ['iddja', 1],
  ['iddob', 5],
  ['iddog', 3],
  ['idsda', 1],
  ['idela', 1],
  ['idewi', 4],
  ['idewe', 4],
  ['idexs', 1],
  ['idgts', 1],
  ['idgeb', 6],
  ['idgee', 1],
  ['idhlp', 4],
  ['idila', 4],
  ['idina', 5],
  ['idibt', 1],
  ['idjbk', 1],
  ['idjbt', 1],
  ['idjpu', 1],
  ['idutc', 1],
  ['idute', 1],
  ['idjwa', 1],
  ['idjep', 1],
  ['idjip', 1],
  ['idjor', 1],
  ['idkah', 1],
  ['idkhy', 1],
  ['idknt', 1],
  ['idkbh', 3],
  ['idkso', 3],
  ['idkpl', 1],
  ['idkrg', 1],
  ['idkbf', 4],
  ['idkse', 1],
  ['idkau', 1],
  ['idkeq', 4],
  ['idkea', 4],
  ['idlln', 4],
  ['idklq', 4],
  ['idkdw', 3],
  ['idkei', 4],
  ['idkrc', 4],
  ['idkmm', 4],
  ['idkoj', 3],
  ['idkok', 1],
  ['idkox', 4],
  ['idkol', 1],
  ['idkmd', 1],
  ['idkci', 4],
  ['idkod', 4],
  ['idkbu', 4],
  ['idkgh', 1],
  ['ideno', 1],
  ['idkno', 4],
  ['idkua', 1],
  ['idktj', 3],
  ['idkum', 3],
  ['idlbo', 3],
  ['idluv', 4],
  ['idlka', 4],
  ['idlbr', 3],
  ['idlhi', 4],
  ['idlwe', 4],
  ['idlsx', 4],
  ['idlif', 1],
  ['idlbm', 3],
  ['idlki', 1],
  ['idlom', 1],
  ['idlpu', 4],
  ['idlbw', 4],
  ['idlyk', 4],
  ['idluw', 5],
  ['idmdr', 1],
  ['idmak', 3],
  ['idmlg', 6],
  ['idmpt', 4],
  ['idmju', 5],
  ['idmdc', 5],
  ['idmgb', 3],
  ['idmkj', 1],
  ['idmal', 5],
  ['idmjy', 4],
  ['idmai', 1],
  ['idmar', 1],
  ['idmsi', 4],
  ['idmxb', 4],
  ['idmwk', 4],
  ['idami', 4],
  ['idmna', 6],
  ['idrde', 4],
  ['idmdp', 4],
  ['idmit', 1],
  ['idoni', 4],
  ['idmor', 1],
  ['idozi', 4],
  ['idmow', 1],
  ['idmbk', 1],
  ['idmub', 1],
  ['idmud', 1],
  ['idmpn', 1],
  ['idmup', 1],
  ['idmsk', 1],
  ['idmsb', 1],
  ['idmuw', 3],
  ['idmpc', 4],
  ['idlii', 4],
  ['idmuf', 4],
  ['idmtu', 1],
  ['idnbx', 4],
  ['idnah', 4],
  ['idnam', 4],
  ['idnre', 4],
  ['idnpo', 4],
  ['idntx', 4],
  ['idcto', 1],
  ['idnip', 1],
  ['idnpl', 1],
  ['idfoo', 4],
  ['idnnx', 5],
  ['idobd', 4],
  ['idobi', 1],
  ['idokl', 4],
  ['idoja', 1],
  ['idolo', 1],
  ['idpga', 1],
  ['idpgm', 1],
  ['idpky', 4],
  ['idpbw', 4],
  ['idpal', 3],
  ['idpjm', 1],
  ['idpkn', 5],
  ['idpnj', 1],
  ['idppr', 4],
  ['idpas', 1],
  ['idpbb', 1],
  ['idftg', 1],
  ['idpdo', 4],
  ['idpng', 1],
  ['idper', 3],
  ['idpwg', 3],
  ['idpir', 1],
  ['idpmk', 1],
  ['idpcb', 4],
  ['idpmg', 1],
  ['idsgo', 1],
  ['idpot', 1],
  ['idpps', 1],
  ['idpbj', 3],
  ['idput', 1],
  ['idppj', 4],
  ['idpwl', 4],
  ['idpsu', 4],
  ['idraq', 4],
  ['idrni', 1],
  ['idrsk', 4],
  ['idreo', 1],
  ['idrki', 4],
  ['idrti', 4],
  ['idswt', 1],
  ['idsqn', 4],
  ['idsgq', 4],
  ['idsgu', 1],
  ['idsae', 4],
  ['idsap', 3],
  ['idzrm', 4],
  ['idsas', 1],
  ['idstu', 1],
  ['idsxk', 4],
  ['idsau', 4],
  ['idskp', 3],
  ['idseh', 4],
  ['idzeg', 4],
  ['idszh', 1],
  ['idsph', 1],
  ['idsep', 1],
  ['idstn', 1],
  ['idsiw', 4],
  ['idsid', 3],
  ['idsdj', 1],
  ['idsng', 3],
  ['idnkd', 4],
  ['idsiq', 4],
  ['idsqg', 4],
  ['idrko', 4],
  ['idcgk', 4],
  ['idskk', 1],
  ['idsoc', 4],
  ['idsqr', 4],
  ['idsoq', 5],
  ['idzkl', 4],
  ['idsua', 1],
  ['idsbm', 1],
  ['idsuk', 1],
  ['idswq', 5],
  ['idsum', 1],
  ['idsun', 1],
  ['idsso', 1],
  ['idtab', 1],
  ['idtmc', 4],
  ['idttr', 4],
  ['idtnb', 4],
  ['idtal', 1],
  ['idpdj', 1],
  ['idtbu', 1],
  ['idtme', 1],
  ['idtpn', 1],
  ['idtsx', 4],
  ['idtjs', 4],
  ['idtjg', 4],
  ['idtjb', 5],
  ['idtnj', 5],
  ['idtan', 3],
  ['idtrh', 3],
  ['idtar', 1],
  ['idtsy', 4],
  ['idtmo', 1],
  ['idtbr', 3],
  ['idtim', 4],
  ['idtxm', 4],
  ['idter', 1],
  ['idtmk', 1],
  ['idtmy', 4],
  ['idtbo', 1],
  ['idtbl', 1],
  ['idtli', 5],
  ['idtua', 1],
  ['idtbm', 4],
  ['idubr', 4],
  ['idudg', 1],
  ['idwet', 4],
  ['idwba', 4],
  ['idwai', 1],
  ['idwmx', 4],
  ['idwss', 1],
  ['idwan', 1],
  ['idwar', 4],
  ['idwsr', 4],
  ['idwed', 1],
  ['idjog', 6],
  ['idruf', 4],
  ['idugu', 4],
  ['iealq', 1],
  ['iearo', 1],
  ['ieatn', 1],
  ['ieard', 3],
  ['ieark', 1],
  ['iearh', 1],
  ['ieaug', 1],
  ['iebif', 1],
  ['iebit', 1],
  ['iebbg', 3],
  ['iebal', 4],
  ['iebll', 3],
  ['iebna', 3],
  ['iebvd', 1],
  ['iebgy', 1],
  ['iebtm', 1],
  ['iebng', 3],
  ['iebth', 1],
  ['iebly', 6],
  ['iebht', 3],
  ['ieebf', 1],
  ['iebuc', 1],
  ['iebur', 3],
  ['iecar', 3],
  ['iecrs', 3],
  ['ieclb', 6],
  ['iecfi', 3],
  ['ietlk', 1],
  ['iectb', 3],
  ['ieclc', 3],
  ['iecli', 3],
  ['ieclg', 1],
  ['ieclr', 6],
  ['iecct', 1],
  ['iecob', 1],
  ['iefgy', 1],
  ['iecbr', 6],
  ['ieork', 5],
  ['iecro', 1],
  ['iedin', 1],
  ['iedro', 1],
  ['iedmf', 1],
  ['iedpp', 1],
  ['iedub', 5],
  ['iedft', 1],
  ['iedfa', 1],
  ['iedfb', 1],
  ['iedfc', 1],
  ['iemtl', 1],
  ['iedlg', 1],
  ['iedcn', 3],
  ['ieddk', 1],
  ['iedfy', 3],
  ['iedgv', 3],
  ['iednm', 1],
  ['ieeky', 3],
  ['iedwe', 3],
  ['ieesb', 1],
  ['iefen', 3],
  ['ieftd', 3],
  ['iefid', 1],
  ['iefin', 1],
  ['iefpd', 1],
  ['iefrw', 1],
  ['iefov', 1],
  ['iefcw', 1],
  ['iegga', 3],
  ['iegrt', 1],
  ['iegow', 1],
  ['iegre', 3],
  ['iegrn', 1],
  ['iehau', 1],
  ['iehow', 1],
  ['ieinq', 4],
  ['ieiia', 4],
  ['ieior', 4],
  ['iekir', 4],
  ['iekic', 3],
  ['ierrr', 1],
  ['iekla', 3],
  ['iekbs', 1],
  ['iekka', 1],
  ['ieklr', 1],
  ['iekng', 1],
  ['iekln', 1],
  ['ienoc', 4],
  ['ielmk', 5],
  ['ielar', 1],
  ['iemag', 3],
  ['iemrt', 1],
  ['iemap', 1],
  ['iemar', 1],
  ['iemot', 1],
  ['iemov', 3],
  ['ienrs', 1],
  ['ienrr', 1],
  ['ienet', 3],
  ['ienay', 3],
  ['ienex', 1],
  ['iepat', 1],
  ['iepap', 1],
  ['iepaw', 3],
  ['iertn', 3],
  ['ierdm', 3],
  ['ierat', 3],
  ['ierin', 1],
  ['ierdt', 1],
  ['ierpt', 1],
  ['iervm', 1],
  ['ierav', 1],
  ['iersm', 1],
  ['ieroe', 1],
  ['ieros', 1],
  ['ierus', 3],
  ['iesnn', 5],
  ['ieskl', 3],
  ['iesli', 1],
  ['iesxl', 4],
  ['iennr', 6],
  ['iesnr', 1],
  ['ietar', 1],
  ['ietiv', 1],
  ['ietob', 3],
  ['ietrp', 1],
  ['ietqy', 1],
  ['ietra', 1],
  ['ietme', 1],
  ['iewcq', 1],
  ['iewgi', 1],
  ['iewes', 1],
  ['iewex', 5],
  ['iewhi', 1],
  ['iewic', 1],
  ['iewil', 1],
  ['ieyou', 1],
  ['ilacr', 1],
  ['ilakk', 3],
  ['ilash', 1],
  ['ilakl', 1],
  ['ilbev', 4],
  ['ilbga', 6],
  ['ilbgv', 1],
  ['ileiy', 4],
  ['ileth', 5],
  ['il8uh', 3],
  ['ilhad', 1],
  ['ilhfa', 5],
  ['ilmsp', 1],
  ['iljul', 1],
  ['ilky7', 1],
  ['ilkfv', 1],
  ['ilksw', 4],
  ['ilm8a', 3],
  ['ilmtz', 4],
  ['ilas2', 1],
  ['ilmip', 6],
  ['ilnab', 3],
  ['ilnat', 3],
  ['ilofr', 1],
  ['ilvda', 4],
  ['ilrpn', 4],
  ['ilsed', 4],
  ['ilmen', 6],
  ['ilsdv', 4],
  ['iltib', 3],
  ['ilyot', 4],
  ['imctn', 3],
  ['imdgs', 3],
  ['impel', 3],
  ['impsm', 1],
  ['imram', 3],
  ['imiom', 4],
  ['imsan', 1],
  ['inpnm', 1],
  ['inach', 1],
  ['inixa', 4],
  ['inagx', 4],
  ['inagr', 4],
  ['inamd', 6],
  ['inajl', 4],
  ['inakd', 4],
  ['inala', 1],
  ['inabg', 1],
  ['inixd', 4],
  ['inalf', 1],
  ['inixv', 6],
  ['inais', 1],
  ['inatq', 4],
  ['inami', 1],
  ['inadi', 1],
  ['inang', 1],
  ['inanl', 1],
  ['inatu', 1],
  ['inixu', 4],
  ['inazk', 1],
  ['inbdg', 1],
  ['inixb', 4],
  ['inbbp', 1],
  ['inbdr', 1],
  ['inrgh', 4],
  ['inbnd', 1],
  ['inbda', 1],
  ['inblr', 6],
  ['inbpt', 3],
  ['inbkt', 1],
  ['inbek', 4],
  ['inbsn', 1],
  ['inbaw', 1],
  ['inbed', 1],
  ['inblp', 1],
  ['inblk', 1],
  ['inixg', 4],
  ['inbep', 4],
  ['inbet', 1],
  ['inbey', 1],
  ['inbyt', 1],
  ['inbdh', 1],
  ['inbgw', 1],
  ['inbru', 3],
  ['inbup', 4],
  ['inbtk', 1],
  ['inbhm', 1],
  ['inbwn', 1],
  ['inbho', 4],
  ['inbbi', 4],
  ['inbhj', 4],
  ['inbkb', 4],
  ['inpab', 4],
  ['inblm', 1],
  ['inbtr', 1],
  ['inbrm', 1],
  ['inbry', 1],
  ['inbrh', 1],
  ['inbud', 1],
  ['inbsr', 1],
  ['incap', 1],
  ['incam', 1],
  ['incnn', 1],
  ['incbd', 4],
  ['incar', 1],
  ['incbl', 1],
  ['inixc', 4],
  ['inchr', 1],
  ['incsi', 6],
  ['incti', 1],
  ['inchi', 1],
  ['incjb', 4],
  ['incol', 1],
  ['incoh', 4],
  ['incoo', 1],
  ['incrn', 1],
  ['incdl', 1],
  ['incdp', 4],
  ['indhp', 1],
  ['indrl', 1],
  ['indhu', 1],
  ['indah', 1],
  ['indam', 5],
  ['indtw', 1],
  ['indae', 4],
  ['indai', 4],
  ['inded', 4],
  ['indel', 4],
  ['indeg', 1],
  ['indep', 4],
  ['indma', 3],
  ['indmq', 1],
  ['indbd', 4],
  ['indsk', 1],
  ['indhm', 4],
  ['indmt', 1],
  ['indhr', 1],
  ['indib', 4],
  ['indig', 3],
  ['indmu', 4],
  ['indiu', 5],
  ['indiv', 1],
  ['indwa', 1],
  ['indrk', 1],
  ['inesh', 1],
  ['inenr', 1],
  ['inekm', 3],
  ['inero', 3],
  ['ingan', 1],
  ['inggv', 1],
  ['ingrr', 3],
  ['ingay', 4],
  ['ingha', 1],
  ['ingoi', 4],
  ['ingga', 1],
  ['ingpr', 1],
  ['ingop', 6],
  ['ingnd', 3],
  ['ingno', 1],
  ['ingux', 4],
  ['ingwl', 6],
  ['inhal', 1],
  ['inhgt', 1],
  ['inhrn', 1],
  ['inhza', 1],
  ['inhip', 3],
  ['inhss', 4],
  ['inhon', 1],
  ['inhwr', 1],
  ['inhbx', 4],
  ['inhyd', 6],
  ['inimf', 4],
  ['inidr', 6],
  ['injlr', 4],
  ['injbd', 3],
  ['injgb', 4],
  ['injgd', 1],
  ['injai', 6],
  ['inkku', 3],
  ['injsa', 4],
  ['injtp', 1],
  ['injak', 1],
  ['injga', 4],
  ['inixw', 6],
  ['innsa', 1],
  ['inpyb', 4],
  ['injdg', 1],
  ['injdh', 6],
  ['injda', 1],
  ['injrh', 4],
  ['inkdi', 1],
  ['inixh', 4],
  ['inkak', 1],
  ['inkal', 1],
  ['inkpi', 1],
  ['inkly', 1],
  ['inixq', 4],
  ['inknd', 1],
  ['inknu', 4],
  ['inkkm', 1],
  ['inkrk', 1],
  ['inkrn', 1],
  ['inkar', 3],
  ['inkrw', 1],
  ['inksg', 1],
  ['inkat', 3],
  ['inktp', 3],
  ['inkvt', 1],
  ['inkvi', 1],
  ['inksh', 1],
  ['inkiw', 1],
  ['inixk', 4],
  ['inhjr', 4],
  ['inkbt', 1],
  ['inkho', 1],
  ['inixn', 4],
  ['inkkr', 1],
  ['inkti', 1],
  ['inkrp', 1],
  ['inkod', 1],
  ['inktw', 1],
  ['inkoi', 1],
  ['inkok', 1],
  ['inklh', 6],
  ['inccu', 5],
  ['inkon', 3],
  ['inkdp', 1],
  ['inktu', 4],
  ['inktd', 1],
  ['inkvl', 1],
  ['inkri', 1],
  ['inksp', 1],
  ['inkuu', 4],
  ['inkmb', 1],
  ['inlng', 1],
  ['inlpr', 1],
  ['inixl', 4],
  ['inixi', 4],
  ['inlko', 4],
  ['inluh', 4],
  ['inmda', 1],
  ['inmah', 3],
  ['inmha', 1],
  ['inmrk', 1],
  ['inlda', 4],
  ['inmlp', 1],
  ['inmal', 1],
  ['inmlw', 1],
  ['inmdp', 1],
  ['inmnw', 1],
  ['inixe', 5],
  ['inmgr', 1],
  ['inmnr', 1],
  ['inmrm', 3],
  ['inmrm', 3],
  ['inmli', 1],
  ['inmap', 1],
  ['inmyb', 1],
  ['inmdw', 1],
  ['inmtw', 1],
  ['inmci', 1],
  ['inmri', 1],
  ['inmoh', 4],
  ['inmra', 1],
  ['inmob', 3],
  ['inmdk', 1],
  ['inmun', 1],
  ['inmug', 3],
  ['inmur', 1],
  ['inmza', 4],
  ['inmzu', 4],
  ['inmyq', 4],
  ['inmdv', 3],
  ['innpt', 3],
  ['inngc', 3],
  ['innag', 4],
  ['innan', 1],
  ['inndc', 4],
  ['inndg', 1],
  ['inisk', 4],
  ['innvb', 1],
  ['innvp', 1],
  ['innav', 1],
  ['innel', 1],
  ['inicd', 3],
  ['innml', 1],
  ['inntu', 3],
  ['innwp', 1],
  ['innvy', 4],
  ['innsa', 1],
  ['innhv', 1],
  ['innee', 3],
  ['innvt', 1],
  ['inokh', 1],
  ['inonj', 1],
  ['inors', 1],
  ['inomn', 4],
  ['inpdd', 1],
  ['inpsh', 1],
  ['inpmb', 1],
  ['inpan', 1],
  ['inpnj', 1],
  ['inpgh', 4],
  ['inprt', 3],
  ['inppt', 1],
  ['inixt', 4],
  ['inixp', 4],
  ['inpat', 4],
  ['inppj', 1],
  ['inper', 3],
  ['inpin', 1],
  ['inpav', 3],
  ['inpnn', 1],
  ['inpbd', 5],
  ['inixz', 5],
  ['inpid', 1],
  ['inppv', 1],
  ['inprn', 1],
  ['inpst', 3],
  ['inbpq', 3],
  ['inpul', 1],
  ['inpnq', 6],
  ['inprg', 1],
  ['inpur', 1],
  ['input', 4],
  ['inqui', 1],
  ['inrri', 1],
  ['inrpr', 4],
  ['inrja', 4],
  ['inrkg', 1],
  ['inraj', 6],
  ['inrji', 4],
  ['inrjp', 1],
  ['inrjr', 1],
  ['inrmd', 4],
  ['inram', 1],
  ['inixr', 6],
  ['inrgt', 1],
  ['inrnr', 1],
  ['inrtc', 5],
  ['inred', 1],
  ['inrvd', 1],
  ['inrew', 4],
  ['inrrk', 4],
  ['inroz', 1],
  ['inrup', 4],
  ['inswa', 1],
  ['insal', 1],
  ['insxv', 4],
  ['insam', 3],
  ['insad', 3],
  ['instn', 4],
  ['instp', 1],
  ['inshl', 4],
  ['inshi', 1],
  ['insse', 4],
  ['inswd', 1],
  ['insik', 3],
  ['inskk', 3],
  ['inixs', 4],
  ['insil', 3],
  ['insim', 1],
  ['inshp', 1],
  ['inslv', 4],
  ['insmr', 1],
  ['insbh', 1],
  ['insxr', 4],
  ['insrv', 1],
  ['intad', 3],
  ['intja', 1],
  ['intpn', 1],
  ['intnk', 1],
  ['intrp', 1],
  ['intel', 1],
  ['intks', 1],
  ['intez', 4],
  ['intei', 4],
  ['inthl', 1],
  ['intna', 1],
  ['inthn', 3],
  ['intjv', 4],
  ['intrv', 6],
  ['intph', 1],
  ['intrz', 4],
  ['intyr', 1],
  ['intir', 4],
  ['intiv', 1],
  ['intnd', 1],
  ['inktk', 1],
  ['intra', 1],
  ['intmp', 1],
  ['inzum', 3],
  ['intun', 1],
  ['intut', 3],
  ['inudr', 4],
  ['inulw', 1],
  ['inumr', 1],
  ['inumb', 1],
  ['inura', 3],
  ['inutn', 1],
  ['invru', 1],
  ['invad', 1],
  ['invkm', 1],
  ['invsi', 1],
  ['invns', 6],
  ['invrd', 1],
  ['invsv', 1],
  ['invzj', 1],
  ['invng', 1],
  ['inven', 1],
  ['invep', 1],
  ['invva', 1],
  ['invga', 4],
  ['ingwm', 4],
  ['invyd', 1],
  ['invig', 1],
  ['inwad', 3],
  ['inwgc', 4],
  ['inzer', 4],
  ['iqalf', 1],
  ['iqasd', 3],
  ['iqhil', 1],
  ['iqtqd', 3],
  ['iqtal', 6],
  ['iqbmn', 4],
  ['iqbsh', 6],
  ['iqbsr', 1],
  ['iqmab', 1],
  ['iqebl', 6],
  ['iqfao', 1],
  ['iqhaf', 6],
  ['iqikd', 1],
  ['iqkar', 1],
  ['iqkha', 1],
  ['iqkaz', 1],
  ['iqkik', 1],
  ['iqman', 1],
  ['iqosm', 3],
  ['iqnas', 1],
  ['iqnsr', 3],
  ['iqnam', 6],
  ['iqsam', 1],
  ['iqisu', 4],
  ['iqtji', 3],
  ['iqtll', 6],
  ['iquqr', 3],
  ['iqzao', 3],
  ['irabd', 5],
  ['iraeu', 4],
  ['irakw', 4],
  ['irawz', 4],
  ['iramp', 1],
  ['irbam', 1],
  ['iradu', 4],
  ['irpgu', 4],
  ['irasa', 3],
  ['irbbl', 4],
  ['iriaq', 4],
  ['irbxr', 4],
  ['irbnd', 5],
  ['iramd', 3],
  ['irbah', 1],
  ['irbmr', 1],
  ['irnek', 3],
  ['irbsr', 1],
  ['irbaz', 1],
  ['irbkk', 1],
  ['irbrg', 1],
  ['irbdh', 4],
  ['irbpa', 1],
  ['irxbj', 4],
  ['irbsm', 4],
  ['irbjb', 4],
  ['irzbr', 5],
  ['irday', 1],
  ['irdef', 4],
  ['irfaz', 4],
  ['irfkr', 1],
  ['irgch', 4],
  ['irgsm', 4],
  ['irgbt', 4],
  ['irhdm', 4],
  ['irhdr', 4],
  ['irifh', 4],
  ['iriil', 4],
  ['irimh', 1],
  ['irika', 4],
  ['irbik', 1],
  ['irifn', 5],
  ['irknr', 4],
  ['irqkc', 4],
  ['irkas', 1],
  ['irker', 4],
  ['irksh', 3],
  ['irkha', 4],
  ['irkhk', 5],
  ['irkhd', 4],
  ['irkho', 1],
  ['irkih', 4],
  ['irlfm', 4],
  ['irlrr', 4],
  ['irlvp', 5],
  ['irlin', 1],
  ['irmhd', 4],
  ['irqmj', 4],
  ['irnka', 1],
  ['irnuj', 4],
  ['irnsh', 1],
  ['iromi', 4],
  ['irpfq', 4],
  ['irpyk', 6],
  ['irwps', 4],
  ['irqum', 6],
  ['irrjn', 4],
  ['irrzr', 4],
  ['irrba', 1],
  ['irras', 4],
  ['irafz', 4],
  ['iracp', 4],
  ['irsdg', 4],
  ['irckt', 4],
  ['irsix', 1],
  ['irsra', 1],
  ['irsry', 4],
  ['irsbr', 1],
  ['irsrp', 1],
  ['irqhk', 4],
  ['irsyz', 5],
  ['irsxi', 5],
  ['ircyt', 1],
  ['irtcx', 4],
  ['irtbz', 5],
  ['irthr', 4],
  ['irihr', 4],
  ['irtew', 4],
  ['irtmb', 3],
  ['iromh', 4],
  ['irqys', 4],
  ['irazd', 5],
  ['iracz', 4],
  ['irzah', 4],
  ['irzan', 6],
  ['irzvi', 4],
  ['ishfn', 5],
  ['isakr', 1],
  ['isaku', 1],
  ['isaey', 4],
  ['isass', 1],
  ['isbak', 1],
  ['isbil', 1],
  ['isbiu', 4],
  ['isblo', 5],
  ['isboi', 1],
  ['isbgj', 1],
  ['isbor', 1],
  ['isbre', 1],
  ['isbrj', 1],
  ['isbud', 3],
  ['isdal', 1],
  ['isdpv', 1],
  ['isdju', 5],
  ['isdra', 1],
  ['isegs', 4],
  ['isesk', 1],
  ['isefj', 4],
  ['isfag', 4],
  ['isfas', 5],
  ['isfey', 1],
  ['isfla', 1],
  ['isfli', 4],
  ['isgrb', 1],
  ['isgrd', 1],
  ['isgjr', 4],
  ['isgre', 1],
  ['isgry', 4],
  ['isgri', 1],
  ['isgrf', 1],
  ['isgrt', 1],
  ['isguf', 1],
  ['ishaf', 1],
  ['ishnr', 1],
  ['ishel', 1],
  ['ishja', 1],
  ['ishof', 1],
  ['ishri', 1],
  ['ishus', 1],
  ['ishvm', 1],
  ['ishvk', 4],
  ['isisa', 1],
  ['iskev', 1],
  ['iskef', 4],
  ['iskop', 1],
  ['iskro', 1],
  ['iskov', 1],
  ['islan', 1],
  ['islsa', 1],
  ['ishvr', 1],
  ['ismjh', 3],
  ['ismjo', 1],
  ['ismos', 3],
  ['ismva', 4],
  ['isnes', 1],
  ['isnja', 1],
  ['isnou', 1],
  ['isolf', 1],
  ['isolv', 1],
  ['isosp', 1],
  ['ispat', 1],
  ['israu', 5],
  ['isrfj', 1],
  ['isrha', 1],
  ['isrey', 1],
  ['isrif', 5],
  ['issan', 1],
  ['issau', 1],
  ['issfs', 4],
  ['issey', 1],
  ['issig', 1],
  ['isska', 1],
  ['iskjf', 1],
  ['isstd', 1],
  ['isstr', 1],
  ['issty', 1],
  ['issuv', 1],
  ['issud', 1],
  ['issva', 1],
  ['istal', 1],
  ['istey', 5],
  ['isthh', 1],
  ['istho', 5],
  ['isthn', 1],
  ['isves', 1],
  ['isvog', 1],
  ['isvpn', 1],
  ['itmnd', 3],
  ['itslc', 3],
  ['itvsg', 3],
  ['itaol', 3],
  ['itacl', 1],
  ['itact', 3],
  ['itagp', 1],
  ['itals', 3],
  ['itall', 4],
  ['itaho', 3],
  ['itali', 1],
  ['itama', 1],
  ['itamt', 3],
  ['itada', 3],
  ['itgzs', 1],
  ['itanz', 1],
  ['itaot', 6],
  ['itatx', 1],
  ['itaza', 3],
  ['itata', 3],
  ['itaug', 1],
  ['itavo', 3],
  ['itbcb', 3],
  ['itbln', 1],
  ['itbai', 1],
  ['itb8d', 1],
  ['itblt', 1],
  ['itbla', 3],
  ['itblx', 4],
  ['itbmt', 3],
  ['itbgo', 6],
  ['itbnc', 3],
  ['itbce', 3],
  ['itblq', 6],
  ['itbzo', 6],
  ['itbgh', 3],
  ['itboa', 3],
  ['itbvm', 3],
  ['itbma', 1],
  ['itbds', 1],
  ['itnne', 3],
  ['itcls', 1],
  ['itglj', 3],
  ['itnig', 1],
  ['itaor', 3],
  ['itccb', 1],
  ['itcoq', 3],
  ['itcpa', 1],
  ['itprj', 1],
  ['itcrt', 3],
  ['itclf', 1],
  ['ittto', 6],
  ['itcml', 3],
  ['itael', 3],
  ['itctr', 1],
  ['itcas', 1],
  ['itctk', 3],
  ['itati', 3],
  ['itceq', 3],
  ['itcrq', 3],
  ['itcta', 1],
  ['itccl', 3],
  ['itcvx', 1],
  ['itcec', 1],
  ['itcfu', 3],
  ['itcev', 3],
  ['itcnt', 3],
  ['ittaa', 3],
  ['itccq', 3],
  ['itchx', 3],
  ['itchi', 1],
  ['itcia', 4],
  ['itisi', 6],
  ['itmrm', 3],
  ['itcvv', 1],
  ['itciy', 4],
  ['itcpn', 3],
  ['itcgc', 3],
  ['itcdf', 4],
  ['itqcs', 4],
  ['itcrv', 1],
  ['itcpm', 3],
  ['itdci', 4],
  ['itdia', 3],
  ['itdno', 3],
  ['iteba', 4],
  ['itfal', 1],
  ['itfao', 3],
  ['itfav', 1],
  ['itagk', 4],
  ['itfpo', 1],
  ['itfnl', 3],
  ['itflr', 4],
  ['itfco', 5],
  ['itfco', 5],
  ['itfcv', 3],
  ['itfol', 1],
  ['itfrd', 3],
  ['itfrl', 6],
  ['itfom', 3],
  ['itfme', 3],
  ['itfge', 3],
  ['itfsa', 3],
  ['itgma', 3],
  ['itgae', 1],
  ['itgal', 1],
  ['itgea', 1],
  ['itgii', 1],
  ['itgia', 3],
  ['itgit', 1],
  ['itgvz', 3],
  ['itgui', 3],
  ['itgai', 1],
  ['itgor', 1],
  ['itgoo', 3],
  ['itgrd', 1],
  ['itgrs', 6],
  ['itimp', 1],
  ['itish', 5],
  ['itisn', 1],
  ['itidg', 1],
  ['itidf', 3],
  ['itiss', 1],
  ['itfor', 1],
  ['itmda', 3],
  ['itspe', 1],
  ['itlae', 3],
  ['itlad', 3],
  ['itsuf', 4],
  ['itlmp', 5],
  ['itlaw', 3],
  ['itlcc', 4],
  ['itler', 3],
  ['itles', 3],
  ['itlec', 3],
  ['itlen', 3],
  ['itlev', 3],
  ['itlic', 1],
  ['itlsd', 3],
  ['itlin', 4],
  ['itliu', 5],
  ['itlip', 1],
  ['itliv', 1],
  ['itloa', 3],
  ['itioi', 3],
  ['itmxp', 4],
  ['itmfr', 1],
  ['itmaj', 3],
  ['ittea', 3],
  ['itmam', 3],
  ['itmmo', 1],
  ['itpma', 3],
  ['itmdw', 3],
  ['itmlb', 3],
  ['itmit', 3],
  ['itmrp', 3],
  ['itmdc', 1],
  ['itaas', 3],
  ['itmah', 3],
  ['itmsd', 3],
  ['itqmm', 3],
  ['itmpa', 3],
  ['itmsb', 3],
  ['itm2r', 3],
  ['itmge', 3],
  ['itmnu', 3],
  ['itrtt', 3],
  ['itmra', 3],
  ['itmlu', 3],
  ['itmaz', 3],
  ['itmps', 3],
  ['itmsn', 1],
  ['itmth', 3],
  ['itmlz', 1],
  ['itmim', 3],
  ['itmis', 3],
  ['itodb', 3],
  ['itmol', 1],
  ['itodg', 3],
  ['itmnf', 1],
  ['itmnp', 1],
  ['itmls', 3],
  ['itrsm', 3],
  ['itmch', 6],
  ['itugg', 3],
  ['itntt', 3],
  ['itnli', 3],
  ['itnsr', 1],
  ['itnum', 3],
  ['itogn', 3],
  ['itone', 1],
  ['itobt', 3],
  ['itoas', 6],
  ['itqos', 3],
  ['itotn', 1],
  ['itoto', 1],
  ['itpau', 1],
  ['itpmo', 5],
  ['itpln', 3],
  ['itpmm', 3],
  ['itpan', 1],
  ['itpnl', 5],
  ['itaoa', 3],
  ['itpmf', 6],
  ['itpti', 3],
  ['itped', 3],
  ['itpeg', 6],
  ['itpes', 3],
  ['itpch', 1],
  ['itprr', 3],
  ['itpia', 1],
  ['itisb', 1],
  ['itpio', 1],
  ['itpsa', 4],
  ['itpiz', 5],
  ['itzqq', 3],
  ['itpom', 1],
  ['itpnz', 1],
  ['itorl', 3],
  ['itptc', 3],
  ['itpgl', 3],
  ['itpaz', 1],
  ['itpce', 1],
  ['itpcn', 3],
  ['itpdi', 1],
  ['itpem', 1],
  ['itpth', 3],
  ['itpfx', 1],
  ['itpga', 1],
  ['itpvt', 3],
  ['itpli', 1],
  ['itpmz', 1],
  ['itpng', 1],
  ['itppf', 3],
  ['itprf', 3],
  ['itsig', 3],
  ['itpss', 1],
  ['itppt', 3],
  ['itpto', 1],
  ['itpoq', 3],
  ['itpfe', 1],
  ['itptf', 1],
  ['itppl', 3],
  ['itpve', 3],
  ['itprw', 3],
  ['itpos', 1],
  ['itpzl', 3],
  ['itozi', 3],
  ['itpoz', 1],
  ['itpim', 3],
  ['itprl', 3],
  ['itpro', 1],
  ['itpuc', 1],
  ['itrpo', 3],
  ['itran', 1],
  ['itrec', 3],
  ['itrce', 3],
  ['itrin', 1],
  ['itrmg', 3],
  ['itrma', 1],
  ['itrpt', 3],
  ['itxxx', 1],
  ['itrts', 3],
  ['itroc', 3],
  ['itrgg', 1],
  ['itrom', 6],
  ['itrdl', 6],
  ['itgsv', 3],
  ['itsal', 3],
  ['itsdb', 3],
  ['itnct', 3],
  ['ittqr', 4],
  ['itsfe', 3],
  ['itgbn', 4],
  ['itsre', 1],
  ['itsvc', 1],
  ['itsat', 1],
  ['itnta', 3],
  ['itsfm', 3],
  ['itsml', 1],
  ['itsmk', 3],
  ['itamn', 3],
  ['itsla', 3],
  ['itsma', 3],
  ['itspa', 3],
  ['itste', 1],
  ['itssf', 3],
  ['ittef', 3],
  ['itprh', 3],
  ['itpfx', 1],
  ['itqlp', 4],
  ['itllt', 3],
  ['itsvn', 3],
  ['itlea', 3],
  ['itsrh', 3],
  ['ituri', 3],
  ['itsck', 3],
  ['itsll', 3],
  ['itsco', 3],
  ['itseg', 3],
  ['itnte', 3],
  ['itrrv', 3],
  ['itsid', 3],
  ['itnsy', 4],
  ['itslv', 3],
  ['itsir', 1],
  ['itsiu', 6],
  ['itrro', 1],
  ['itsoo', 3],
  ['itnga', 3],
  ['itrns', 3],
  ['itttn', 3],
  ['itstr', 1],
  ['ittal', 1],
  ['ittao', 3],
  ['ittar', 1],
  ['ittri', 1],
  ['ittmi', 1],
  ['ittrc', 1],
  ['itsnr', 3],
  ['ittrn', 4],
  ['ittoa', 1],
  ['ittcs', 3],
  ['ittrh', 3],
  ['ittgv', 3],
  ['ittru', 3],
  ['ittgr', 1],
  ['itttb', 4],
  ['itica', 3],
  ['ittvc', 1],
  ['ittni', 1],
  ['ittps', 5],
  ['ittcc', 3],
  ['ittmt', 1],
  ['ittrs', 5],
  ['itudn', 4],
  ['itust', 1],
  ['itvda', 1],
  ['itvdl', 3],
  ['itvso', 1],
  ['itven', 3],
  ['itvtt', 1],
  ['itvzn', 3],
  ['itvrn', 4],
  ['itvia', 1],
  ['itvva', 1],
  ['itvie', 1],
  ['itvt3', 1],
  ['itvti', 1],
  ['itvul', 1],
  ['itule', 1],
  ['jejer', 4],
  ['jesab', 3],
  ['jescj', 1],
  ['jesth', 3],
  ['jmalp', 1],
  ['jmblr', 1],
  ['jmbwn', 1],
  ['jmfmh', 1],
  ['jmkin', 5],
  ['jmktp', 4],
  ['jmmvj', 4],
  ['jmmbj', 5],
  ['jmneg', 4],
  ['jmocj', 5],
  ['jmorc', 1],
  ['jmpot', 5],
  ['jmpev', 1],
  ['jmphe', 1],
  ['jmpks', 1],
  ['jmpmo', 1],
  ['jmprh', 1],
  ['jmpro', 1],
  ['jmrib', 1],
  ['jmrop', 1],
  ['jmsaw', 1],
  ['jmsri', 1],
  ['jmslm', 1],
  ['joaqb', 3],
  ['jodah', 1],
  ['joaqj', 5],
  ['joamm', 4],
  ['jompq', 4],
  ['joqmd', 4],
  ['joomf', 4],
  ['jophn', 6],
  ['jpaik', 1],
  ['jpaiw', 1],
  ['jpaiz', 1],
  ['jpajr', 1],
  ['jpazj', 1],
  ['jpazr', 1],
  ['jpakk', 1],
  ['jpask', 1],
  ['jpaam', 1],
  ['jpamm', 1],
  ['jpari', 3],
  ['jpasz', 1],
  ['jpbep', 1],
  ['jpbpu', 5],
  ['jpcht', 1],
  ['jpeno', 1],
  ['jpenr', 1],
  ['jpfae', 1],
  ['jpfkh', 1],
  ['jpfkk', 1],
  ['jpfkr', 1],
  ['jpfke', 1],
  ['jpfuj', 5],
  ['jpfra', 1],
  ['jpfuu', 1],
  ['jpfkm', 3],
  ['jpfku', 3],
  ['jpfrj', 1],
  ['jpfua', 1],
  ['jpfur', 1],
  ['jpfky', 1],
  ['jpfym', 1],
  ['jpfre', 1],
  ['jpfrm', 1],
  ['jpfru', 1],
  ['jpfrx', 1],
  ['jpfrs', 1],
  ['jpfst', 3],
  ['jpfmt', 1],
  ['jpftt', 1],
  ['jphtm', 1],
  ['jphut', 1],
  ['jpfts', 1],
  ['jphab', 1],
  ['jphau', 1],
  ['jphbb', 1],
  ['jphjt', 1],
  ['jphta', 1],
  ['jphnj', 1],
  ['jphon', 1],
  ['jphos', 1],
  ['jphsi', 1],
  ['jpies', 1],
  ['jpika', 1],
  ['jpikd', 1],
  ['jpiuh', 1],
  ['jpink', 1],
  ['jpitj', 1],
  ['jpitu', 1],
  ['jpiwg', 1],
  ['jpiou', 1],
  ['jpiwo', 4],
  ['jpiwt', 1],
  ['jpizi', 1],
  ['jpizm', 1],
  ['jpkjg', 1],
  ['jpkaj', 1],
  ['jpkjm', 1],
  ['jpkmr', 1],
  ['jpknd', 1],
  ['jpkry', 1],
  ['jpkss', 1],
  ['jpksy', 1],
  ['jpksc', 1],
  ['jpksm', 1],
  ['jpksw', 1],
  ['jpkat', 1],
  ['jpkur', 1],
  ['jpkch', 1],
  ['jpkwc', 1],
  ['jpkwj', 1],
  ['jpkwr', 1],
  ['jpkir', 1],
  ['jpkit', 1],
  ['jpkjt', 1],
  ['jpktr', 1],
  ['jpkdm', 1],
  ['jpkod', 1],
  ['jpkgs', 1],
  ['jpkog', 1],
  ['jpkmq', 4],
  ['jpkmx', 1],
  ['jpkju', 1],
  ['jpkor', 1],
  ['jpkji', 1],
  ['jpkku', 1],
  ['jpkre', 1],
  ['jpkue', 1],
  ['jpkrj', 1],
  ['jpmdj', 1],
  ['jpmsd', 1],
  ['jpmat', 1],
  ['jpmra', 1],
  ['jpmss', 1],
  ['jpmau', 1],
  ['jpmts', 1],
  ['jpmtu', 1],
  ['jpmii', 1],
  ['jpmij', 1],
  ['jpmnt', 1],
  ['jpmik', 1],
  ['jpmki', 1],
  ['jpmsx', 1],
  ['jpmkx', 1],
  ['jpmis', 1],
  ['jpmmi', 1],
  ['jpmit', 1],
  ['jpmqt', 1],
  ['jpmyk', 1],
  ['jpmnr', 1],
  ['jpmuy', 1],
  ['jpkmi', 5],
  ['jpmzk', 1],
  ['jpmyt', 1],
  ['jpmrs', 1],
  ['jpmrt', 1],
  ['jpmux', 1],
  ['jpmyx', 1],
  ['jpnex', 1],
  ['jpnge', 1],
  ['jpnag', 1],
  ['jpngh', 1],
  ['jpnhm', 1],
  ['jpnma', 1],
  ['jpnga', 1],
  ['jpngu', 1],
  ['jpnsu', 1],
  ['jpnaa', 1],
  ['jpngo', 5],
  ['jpnah', 5],
  ['jpnha', 1],
  ['jpnkh', 1],
  ['jpnkj', 1],
  ['jpnkl', 1],
  ['jpnkm', 1],
  ['jpnkt', 1],
  ['jpntx', 1],
  ['jpoba', 1],
  ['jpobb', 1],
  ['jpobm', 1],
  ['jpobe', 1],
  ['jpogi', 1],
  ['jpogk', 1],
  ['jpoii', 1],
  ['jpkhm', 1],
  ['jpoha', 1],
  ['jpohm', 1],
  ['jpomx', 1],
  ['jpooh', 1],
  ['jpobx', 1],
  ['jpoim', 5],
  ['jpoom', 1],
  ['jposh', 1],
  ['jposi', 1],
  ['jposo', 1],
  ['jposs', 1],
  ['jpokw', 1],
  ['jpoow', 1],
  ['jpokc', 1],
  ['jpokt', 1],
  ['jpoiu', 1],
  ['jpokx', 1],
  ['jpokb', 1],
  ['jpooj', 1],
  ['jpouk', 1],
  ['jposk', 1],
  ['jposx', 1],
  ['jposl', 1],
  ['jposj', 1],
  ['jpotu', 1],
  ['jpooa', 1],
  ['jpqsg', 5],
  ['jpsga', 1],
  ['jpsac', 1],
  ['jpsja', 1],
  ['jpsai', 1],
  ['jpsgu', 1],
  ['jpsdj', 5],
  ['jpstt', 1],
  ['jpsxt', 1],
  ['jpsda', 1],
  ['jpsia', 1],
  ['jpsmd', 1],
  ['jpsmo', 1],
  ['jpshg', 3],
  ['jpskw', 1],
  ['jpsnk', 1],
  ['jpsio', 1],
  ['jpsya', 1],
  ['jpshh', 1],
  ['jpshm', 4],
  ['jpsih', 1],
  ['jpsir', 1],
  ['jpsrh', 1],
  ['jpsrx', 1],
  ['jpsji', 1],
  ['jpsjm', 1],
  ['jpsuh', 1],
  ['jpsut', 1],
  ['jptjr', 1],
  ['jptah', 1],
  ['jptkg', 1],
  ['jptxk', 1],
  ['jptms', 1],
  ['jptmt', 1],
  ['jptnr', 1],
  ['jptur', 1],
  ['jptet', 1],
  ['jptts', 1],
  ['jptmr', 1],
  ['jptmo', 1],
  ['jptom', 1],
  ['jptjo', 1],
  ['jptyh', 1],
  ['jptyj', 1],
  ['jptru', 1],
  ['jpucr', 1],
  ['jpucu', 1],
  ['jpuou', 1],
  ['jpuoz', 1],
  ['jputm', 1],
  ['jputs', 1],
  ['jpwtu', 1],
  ['jpyag', 1],
  ['jpygi', 1],
  ['jpyad', 1],
  ['jpymd', 1],
  ['jpykt', 1],
  ['jpyda', 1],
  ['jpysd', 1],
  ['jpyra', 1],
  ['jpyua', 1],
  ['jpyuj', 1],
  ['jpyur', 1],
  ['jposm', 1],
  ['jpaba', 1],
  ['jpabs', 1],
  ['jpabo', 1],
  ['jpabu', 1],
  ['jpabt', 1],
  ['jpade', 1],
  ['jpado', 1],
  ['jpagn', 1],
  ['jpags', 1],
  ['jpagr', 1],
  ['jpagj', 5],
  ['jpakz', 1],
  ['jpaki', 1],
  ['jpain', 1],
  ['jpaia', 1],
  ['jpaii', 1],
  ['jpaio', 1],
  ['jpaim', 1],
  ['jpajx', 1],
  ['jpaji', 1],
  ['jpajk', 1],
  ['jpajn', 1],
  ['jpach', 1],
  ['jpakd', 1],
  ['jpakg', 1],
  ['jpakm', 1],
  ['jpaka', 1],
  ['jpakh', 1],
  ['jpaig', 1],
  ['jpaxt', 5],
  ['jpafg', 1],
  ['jpakt', 1],
  ['jpake', 1],
  ['jpako', 1],
  ['jpakb', 1],
  ['jpakn', 1],
  ['jpama', 1],
  ['jpamx', 1],
  ['jpamj', 1],
  ['jpasj', 5],
  ['jpams', 1],
  ['jpamt', 1],
  ['jpamy', 1],
  ['jpamu', 1],
  ['jparg', 1],
  ['jpanm', 1],
  ['jpana', 1],
  ['jpanb', 1],
  ['jpane', 1],
  ['jpaoe', 1],
  ['jpaog', 1],
  ['jpaok', 1],
  ['jpaoi', 1],
  ['jpoma', 1],
  ['jpaoj', 5],
  ['jpaon', 1],
  ['jpaos', 1],
  ['jparo', 1],
  ['jpara', 1],
  ['jpark', 1],
  ['jpamr', 1],
  ['jparz', 1],
  ['jpasa', 1],
  ['jpakj', 4],
  ['jpasi', 3],
  ['jpasw', 1],
  ['jpaui', 1],
  ['jpasm', 1],
  ['jpamg', 1],
  ['jpasb', 1],
  ['jpash', 1],
  ['jpast', 1],
  ['jpasr', 1],
  ['jpata', 3],
  ['jpami', 1],
  ['jpatt', 1],
  ['jpnja', 4],
  ['jpatk', 1],
  ['jpatm', 1],
  ['jpawa', 1],
  ['jptbs', 1],
  ['jpyya', 1],
  ['jpaws', 1],
  ['jpawm', 1],
  ['jpawz', 1],
  ['jpayu', 1],
  ['jpakw', 1],
  ['jpazu', 1],
  ['jpbfs', 1],
  ['jpbdm', 1],
  ['jpbom', 1],
  ['jpbyo', 1],
  ['jpchb', 1],
  ['jpchh', 1],
  ['jpchg', 3],
  ['jpcju', 1],
  ['jpchk', 1],
  ['jpckm', 1],
  ['jpchw', 1],
  ['jpchi', 1],
  ['jpcns', 1],
  ['jpcio', 1],
  ['jpcta', 1],
  ['jpcts', 4],
  ['jpcyz', 1],
  ['jpchf', 1],
  ['jpcho', 1],
  ['jpchu', 1],
  ['jpdtu', 1],
  ['jpdat', 1],
  ['jpdsr', 1],
  ['jpdzk', 1],
  ['jpebi', 1],
  ['jpego', 1],
  ['jpeii', 1],
  ['jpegs', 1],
  ['jpeis', 1],
  ['jpejm', 1],
  ['jpemu', 1],
  ['jpenn', 1],
  ['jpena', 1],
  ['jpenh', 1],
  ['jpekz', 1],
  ['jpemm', 1],
  ['jpesk', 1],
  ['jpesi', 1],
  ['jpess', 1],
  ['jpesm', 1],
  ['jpeta', 1],
  ['jpetm', 1],
  ['jpeza', 1],
  ['jpfcm', 1],
  ['jpfgs', 1],
  ['jpfjn', 1],
  ['jpfki', 1],
  ['jpfum', 1],
  ['jpfri', 1],
  ['jpfuw', 1],
  ['jpfue', 1],
  ['jpfkd', 1],
  ['jpfkj', 1],
  ['jpfuk', 6],
  ['jpfuo', 1],
  ['jpfks', 4],
  ['jpfnb', 1],
  ['jpfnd', 1],
  ['jpfnk', 1],
  ['jpfsh', 1],
  ['jpfns', 1],
  ['jpfnu', 1],
  ['jpfnr', 1],
  ['jpfna', 1],
  ['jpfnz', 1],
  ['jpfhr', 1],
  ['jpfrk', 1],
  ['jpuru', 1],
  ['jpfsk', 1],
  ['jpftx', 1],
  ['jpfte', 1],
  ['jpfgw', 1],
  ['jpftg', 1],
  ['jpfmd', 1],
  ['jpftm', 1],
  ['jpgam', 1],
  ['jpgpt', 1],
  ['jpgnw', 1],
  ['jpgoo', 1],
  ['jpgkb', 1],
  ['jpgot', 1],
  ['jpgoi', 1],
  ['jpgkn', 1],
  ['jpgks', 1],
  ['jpgon', 1],
  ['jpgng', 1],
  ['jpgka', 1],
  ['jphbo', 1],
  ['jphgo', 1],
  ['jphac', 4],
  ['jphhe', 5],
  ['jphae', 1],
  ['jphag', 1],
  ['jphai', 1],
  ['jphkt', 3],
  ['jphks', 1],
  ['jphkd', 5],
  ['jphku', 1],
  ['jphmd', 1],
  ['jphmg', 1],
  ['jphjm', 1],
  ['jphjr', 1],
  ['jphkw', 1],
  ['jphmm', 1],
  ['jphmn', 1],
  ['jphkj', 1],
  ['jphmx', 1],
  ['jphsk', 1],
  ['jpham', 1],
  ['jphsu', 1],
  ['jphwa', 1],
  ['jphmw', 1],
  ['jphaa', 1],
  ['jphmc', 1],
  ['jphmr', 1],
  ['jphna', 4],
  ['jphnk', 1],
  ['jphda', 1],
  ['jphne', 1],
  ['jphnd', 4],
  ['jphan', 1],
  ['jphur', 1],
  ['jphny', 1],
  ['jphar', 1],
  ['jphrm', 1],
  ['jphss', 1],
  ['jphsh', 1],
  ['jphas', 1],
  ['jphah', 1],
  ['jphsr', 1],
  ['jphtt', 1],
  ['jphmi', 1],
  ['jphtr', 4],
  ['jphto', 1],
  ['jphyk', 1],
  ['jphym', 1],
  ['jphyt', 1],
  ['jphad', 1],
  ['jphkn', 1],
  ['jphei', 1],
  ['jphes', 1],
  ['jphet', 1],
  ['jphed', 1],
  ['jphia', 1],
  ['jphib', 1],
  ['jphbk', 1],
  ['jphbr', 1],
  ['jphdk', 1],
  ['jphio', 3],
  ['jphhr', 1],
  ['jphgh', 1],
  ['jphhh', 1],
  ['jphig', 1],
  ['jphgn', 1],
  ['jphji', 1],
  ['jphkr', 1],
  ['jphea', 1],
  ['jphik', 1],
  ['jphmt', 1],
  ['jphie', 1],
  ['jphis', 1],
  ['jphkz', 1],
  ['jphdo', 1],
  ['jphim', 1],
  ['jphmk', 1],
  ['jphnr', 1],
  ['jphmh', 1],
  ['jphmj', 1],
  ['jphms', 1],
  ['jphng', 1],
  ['jphin', 1],
  ['jphns', 1],
  ['jphnz', 1],
  ['jphrd', 1],
  ['jphrj', 1],
  ['jphrw', 1],
  ['jphrk', 1],
  ['jphra', 1],
  ['jphrr', 1],
  ['jphii', 1],
  ['jphsw', 1],
  ['jphrs', 1],
  ['jphiu', 3],
  ['jphro', 1],
  ['jphrh', 1],
  ['jphir', 1],
  ['jphij', 5],
  ['jphru', 1],
  ['jphgu', 1],
  ['jphsn', 1],
  ['jphys', 1],
  ['jphma', 1],
  ['jphtc', 1],
  ['jphic', 1],
  ['jphtk', 1],
  ['jphts', 1],
  ['jphws', 1],
  ['jphiz', 1],
  ['jphud', 1],
  ['jphof', 3],
  ['jphhm', 1],
  ['jphjo', 1],
  ['jphod', 1],
  ['jphja', 1],
  ['jphkm', 1],
  ['jphnn', 3],
  ['jphpp', 1],
  ['jphre', 1],
  ['jphri', 1],
  ['jphsm', 1],
  ['jphot', 1],
  ['jphkk', 1],
  ['jphyr', 1],
  ['jphyg', 1],
  ['jpibr', 1],
  ['jpibs', 1],
  ['jpicd', 1],
  ['jpich', 1],
  ['jpick', 1],
  ['jpico', 3],
  ['jpiej', 5],
  ['jpier', 1],
  ['jpigo', 1],
  ['jpiho', 1],
  ['jpiii', 1],
  ['jpiib', 1],
  ['jpida', 1],
  ['jpint', 1],
  ['jpzka', 1],
  ['jpikr', 1],
  ['jpikt', 1],
  ['jpike', 1],
  ['jpiki', 4],
  ['jpikn', 1],
  ['jpikk', 1],
  ['jpikc', 1],
  ['jpiku', 1],
  ['jpimb', 1],
  ['jpima', 1],
  ['jpigr', 1],
  ['jpimi', 1],
  ['jpimt', 1],
  ['jpimz', 1],
  ['jpimm', 1],
  ['jpina', 1],
  ['jpinr', 1],
  ['jpizw', 3],
  ['jpidj', 1],
  ['jpine', 1],
  ['jpins', 1],
  ['jpino', 1],
  ['jpind', 1],
  ['jping', 1],
  ['jpinz', 1],
  ['jpirk', 1],
  ['jpisy', 1],
  ['jpise', 1],
  ['jpisk', 1],
  ['jpisb', 1],
  ['jpisd', 1],
  ['jpisg', 1],
  ['jpigk', 4],
  ['jpisi', 1],
  ['jpicw', 1],
  ['jpikw', 3],
  ['jpish', 1],
  ['jpism', 1],
  ['jpisc', 1],
  ['jpisx', 1],
  ['jpisa', 1],
  ['jpikj', 1],
  ['jpkyr', 1],
  ['jpisu', 1],
  ['jpitk', 1],
  ['jpito', 1],
  ['jpits', 1],
  ['jpiwh', 1],
  ['jpiwi', 1],
  ['jpiwk', 1],
  ['jpiwa', 1],
  ['jpiwj', 4],
  ['jpiwn', 1],
  ['jpiwy', 1],
  ['jpidm', 1],
  ['jpiya', 1],
  ['jpiyo', 1],
  ['jpiym', 1],
  ['jpizh', 1],
  ['jpizu', 1],
  ['jpizs', 1],
  ['jpizo', 5],
  ['jpjts', 3],
  ['jpjkw', 1],
  ['jpjon', 1],
  ['jpkab', 1],
  ['jpkbm', 1],
  ['jpkaa', 1],
  ['jpdna', 4],
  ['jpkdw', 1],
  ['jpkdo', 1],
  ['jpkbk', 1],
  ['jpkaf', 1],
  ['jpkgm', 1],
  ['jpkoj', 5],
  ['jpkgt', 1],
  ['jpkai', 1],
  ['jpkad', 1],
  ['jpkaz', 1],
  ['jpkjk', 1],
  ['jpker', 1],
  ['jpkga', 1],
  ['jpkme', 1],
  ['jpkgr', 1],
  ['jpkis', 1],
  ['jpkee', 1],
  ['jpkam', 1],
  ['jpkah', 1],
  ['jpkkt', 1],
  ['jpkmw', 1],
  ['jpkmm', 1],
  ['jpkmf', 1],
  ['jpkmk', 1],
  ['jpkox', 1],
  ['jpkmu', 1],
  ['jpkmy', 1],
  ['jpkmo', 1],
  ['jpkse', 1],
  ['jpkyk', 1],
  ['jpknz', 1],
  ['jpkgu', 1],
  ['jpknm', 1],
  ['jpkjn', 1],
  ['jpkra', 1],
  ['jpkkw', 1],
  ['jpkya', 1],
  ['jpkix', 4],
  ['jpkrk', 1],
  ['jpkka', 1],
  ['jpktm', 1],
  ['jpkar', 1],
  ['jpkrb', 1],
  ['jpkan', 1],
  ['jpksd', 1],
  ['jpksa', 1],
  ['jpkac', 1],
  ['jpkdj', 1],
  ['jpkkz', 1],
  ['jpkae', 1],
  ['jpkao', 1],
  ['jpkwz', 1],
  ['jpkxs', 1],
  ['jpkau', 3],
  ['jpasu', 1],
  ['jpktz', 1],
  ['jpkdr', 1],
  ['jpkkm', 1],
  ['jpktk', 1],
  ['jpkts', 1],
  ['jpkba', 1],
  ['jpksu', 1],
  ['jpkwa', 1],
  ['jpkwn', 1],
  ['jpkwi', 1],
  ['jpkag', 1],
  ['jpkws', 1],
  ['jpkwm', 1],
  ['jpkwt', 1],
  ['jpkaw', 1],
  ['jpkzh', 1],
  ['jpkzm', 1],
  ['jpkzs', 1],
  ['jpkeb', 1],
  ['jpkec', 1],
  ['jpkeg', 1],
  ['jpkej', 1],
  ['jpkjp', 4],
  ['jpkem', 1],
  ['jpksn', 1],
  ['jpkia', 1],
  ['jpkib', 1],
  ['jpkgj', 1],
  ['jpkhr', 1],
  ['jpkii', 1],
  ['jpkzi', 1],
  ['jpkkx', 5],
  ['jpkik', 1],
  ['jpkmt', 1],
  ['jpknn', 1],
  ['jpkim', 1],
  ['jpkjo', 1],
  ['jpknk', 1],
  ['jpknx', 1],
  ['jpkne', 1],
  ['jpknt', 1],
  ['jpknu', 1],
  ['jpkin', 1],
  ['jpkjr', 1],
  ['jpkrt', 1],
  ['jpkst', 1],
  ['jpknj', 1],
  ['jpkzu', 1],
  ['jpkiw', 1],
  ['jpksh', 1],
  ['jpksr', 1],
  ['jpkda', 1],
  ['jpktx', 1],
  ['jpkdx', 1],
  ['jpktd', 4],
  ['jpktn', 3],
  ['jpktl', 1],
  ['jpkta', 1],
  ['jpksf', 1],
  ['jpkbt', 1],
  ['jpkbs', 1],
  ['jpkbi', 1],
  ['jpkcz', 5],
  ['jpkdi', 1],
  ['jpkdk', 1],
  ['jpkuf', 3],
  ['jpkgc', 1],
  ['jpkoh', 1],
  ['jpkja', 1],
  ['jpkzr', 1],
  ['jpkoo', 1],
  ['jpknr', 1],
  ['jpkfu', 1],
  ['jpktu', 1],
  ['jpkkl', 1],
  ['jpkou', 1],
  ['jpkoi', 1],
  ['jpkkb', 1],
  ['jpkok', 3],
  ['jpkom', 1],
  ['jpkko', 1],
  ['jpkmn', 1],
  ['jpkmd', 1],
  ['jpkax', 1],
  ['jpkke', 1],
  ['jpkni', 1],
  ['jpkof', 1],
  ['jpknb', 1],
  ['jpkny', 1],
  ['jpkon', 1],
  ['jpkno', 1],
  ['jpkos', 1],
  ['jpapp', 1],
  ['jpkjj', 1],
  ['jpksx', 1],
  ['jpktj', 1],
  ['jpjkt', 1],
  ['jpkto', 1],
  ['jpkkr', 1],
  ['jpkwx', 1],
  ['jpkoy', 1],
  ['jpkyo', 1],
  ['jpkob', 1],
  ['jpkzj', 1],
  ['jpkze', 1],
  ['jpkzk', 1],
  ['jpkoz', 1],
  ['jpkbu', 1],
  ['jpkbr', 1],
  ['jpkcf', 1],
  ['jpkuc', 1],
  ['jpkjd', 1],
  ['jpkud', 1],
  ['jpkug', 1],
  ['jpkrr', 1],
  ['jpkgb', 1],
  ['jpkge', 1],
  ['jpkgo', 1],
  ['jpkmj', 5],
  ['jpkuu', 1],
  ['jpueo', 4],
  ['jpkms', 1],
  ['jpkmh', 1],
  ['jpknh', 1],
  ['jpkng', 1],
  ['jpkns', 1],
  ['jpkhs', 1],
  ['jpkrc', 1],
  ['jpkrn', 1],
  ['jpkrh', 1],
  ['jpkru', 1],
  ['jpkhj', 1],
  ['jpkri', 1],
  ['jpkrd', 1],
  ['jpkrx', 1],
  ['jpkrm', 1],
  ['jpkux', 1],
  ['jpksk', 1],
  ['jpkrs', 1],
  ['jpkkk', 1],
  ['jpkuj', 4],
  ['jpkuh', 5],
  ['jpksb', 1],
  ['jpkxk', 1],
  ['jpkut', 1],
  ['jpktg', 1],
  ['jpkna', 1],
  ['jpkuw', 1],
  ['jpkym', 1],
  ['jpkuz', 1],
  ['jpuky', 4],
  ['jpmad', 1],
  ['jpmdr', 1],
  ['jpmhm', 1],
  ['jpmma', 1],
  ['jpmer', 1],
  ['jpmmm', 1],
  ['jpmgm', 1],
  ['jpmgc', 1],
  ['jpmgu', 1],
  ['jpmai', 1],
  ['jpmkg', 1],
  ['jpmak', 1],
  ['jpmkk', 1],
  ['jpmna', 1],
  ['jpmus', 4],
  ['jpmar', 1],
  ['jpmab', 1],
  ['jpmwa', 1],
  ['jpmru', 1],
  ['jpmas', 1],
  ['jpmsk', 1],
  ['jpmam', 1],
  ['jpmag', 1],
  ['jpmty', 1],
  ['jpmte', 1],
  ['jpmga', 1],
  ['jpmtm', 1],
  ['jpmmj', 4],
  ['jpmng', 1],
  ['jpmao', 1],
  ['jpmsa', 1],
  ['jpmyj', 5],
  ['jpmtz', 1],
  ['jpmwr', 1],
  ['jpmbr', 1],
  ['jpmeg', 1],
  ['jpmgs', 1],
  ['jpmen', 1],
  ['jpmei', 1],
  ['jpmet', 1],
  ['jpmmb', 4],
  ['jpmsi', 1],
  ['jpmhr', 1],
  ['jpmih', 1],
  ['jpmhn', 1],
  ['jpmkm', 1],
  ['jpmkw', 1],
  ['jpmkj', 1],
  ['jpmkr', 1],
  ['jpmmt', 1],
  ['jpmin', 1],
  ['jpmnm', 1],
  ['jpmmd', 5],
  ['jpmne', 1],
  ['jpmny', 1],
  ['jpmnj', 1],
  ['jpmnn', 1],
  ['jpmst', 1],
  ['jpmsj', 4],
  ['jpmsr', 1],
  ['jpmso', 1],
  ['jpmxt', 1],
  ['jpmtc', 1],
  ['jpmjr', 3],
  ['jpmti', 1],
  ['jpmth', 1],
  ['jpmks', 1],
  ['jpmtk', 1],
  ['jpmir', 1],
  ['jpmyg', 1],
  ['jpmyi', 3],
  ['jpmye', 4],
  ['jpmmy', 5],
  ['jpmyn', 1],
  ['jpmno', 1],
  ['jpmyu', 1],
  ['jpmiy', 1],
  ['jpmzs', 1],
  ['jpmiz', 1],
  ['jpmcd', 1],
  ['jpmch', 1],
  ['jpmog', 1],
  ['jpmgn', 1],
  ['jpmoj', 3],
  ['jpmbe', 1],
  ['jpmox', 1],
  ['jpmrk', 1],
  ['jpmor', 1],
  ['jpmrg', 1],
  ['jpmoo', 1],
  ['jpmrh', 1],
  ['jpmrw', 6],
  ['jpmom', 1],
  ['jpmrz', 1],
  ['jpmbt', 1],
  ['jpmot', 1],
  ['jpmto', 1],
  ['jpmou', 1],
  ['jpmzr', 1],
  ['jpmug', 1],
  ['jpmuk', 1],
  ['jpmka', 1],
  ['jpmku', 1],
  ['jpmre', 1],
  ['jpmro', 1],
  ['jpmrm', 1],
  ['jpmur', 1],
  ['jpmrj', 1],
  ['jpmzm', 1],
  ['jpmsh', 1],
  ['jpmut', 1],
  ['jpmtr', 1],
  ['jpmya', 1],
  ['jpnar', 1],
  ['jpnad', 1],
  ['jpndy', 1],
  ['jpngg', 1],
  ['jpnab', 1],
  ['jpngs', 5],
  ['jpnhn', 1],
  ['jpnsa', 1],
  ['jpngr', 1],
  ['jpmgy', 1],
  ['jpnay', 1],
  ['jpngm', 1],
  ['jpnoa', 1],
  ['jpngd', 1],
  ['jpnhi', 1],
  ['jpngn', 1],
  ['jpnkd', 1],
  ['jpnak', 1],
  ['jpnjm', 1],
  ['jpnkk', 1],
  ['jpnmc', 3],
  ['jpnmt', 1],
  ['jpnkx', 1],
  ['jpnan', 1],
  ['jpnks', 1],
  ['jpsxz', 1],
  ['jpnkn', 1],
  ['jpshb', 4],
  ['jpnkz', 1],
  ['jpnat', 1],
  ['jpnky', 1],
  ['jpnkr', 1],
  ['jpnam', 1],
  ['jpnit', 1],
  ['jpnno', 1],
  ['jpnnt', 1],
  ['jpnty', 1],
  ['jpnko', 1],
  ['jpnao', 1],
  ['jpnas', 1],
  ['jpnro', 1],
  ['jpnai', 1],
  ['jpnrt', 4],
  ['jpnrs', 1],
  ['jpnru', 1],
  ['jpnsx', 1],
  ['jpnaz', 1],
  ['jpngi', 1],
  ['jpnej', 1],
  ['jpnem', 1],
  ['jpnnk', 1],
  ['jpneo', 1],
  ['jpnez', 1],
  ['jpnic', 1],
  ['jpnxg', 1],
  ['jpnix', 1],
  ['jpkij', 1],
  ['jpnih', 1],
  ['jpnin', 1],
  ['jpiha', 5],
  ['jpnij', 1],
  ['jpxca', 1],
  ['jpnik', 1],
  ['jpnim', 1],
  ['jpnio', 1],
  ['jpnid', 1],
  ['jpndi', 1],
  ['jpnsb', 1],
  ['jpnsi', 1],
  ['jpnhr', 1],
  ['jpnst', 1],
  ['jpnsk', 1],
  ['jpmaw', 1],
  ['jpnsm', 1],
  ['jpnis', 1],
  ['jpnya', 3],
  ['jpiin', 5],
  ['jpnsh', 1],
  ['jpnto', 1],
  ['jpnur', 1],
  ['jpnia', 1],
  ['jpnob', 1],
  ['jpnbs', 1],
  ['jpnbu', 1],
  ['jpnou', 1],
  ['jpnoh', 1],
  ['jpnhj', 1],
  ['jpnqh', 1],
  ['jpnjr', 1],
  ['jpnmk', 1],
  ['jpnmi', 1],
  ['jpnom', 1],
  ['jpnon', 1],
  ['jpnsr', 1],
  ['jpnum', 1],
  ['jpnnh', 1],
  ['jpnwa', 1],
  ['jpnyk', 1],
  ['jpnyz', 1],
  ['jpoar', 1],
  ['jpoob', 1],
  ['jpobo', 4],
  ['jpooc', 1],
  ['jpoda', 1],
  ['jponh', 4],
  ['jpods', 1],
  ['jpodo', 1],
  ['jpodm', 1],
  ['jpoga', 1],
  ['jpoat', 1],
  ['jposg', 1],
  ['jpogf', 1],
  ['jpogh', 1],
  ['jpogm', 1],
  ['jpoht', 1],
  ['jpobs', 1],
  ['jpokr', 1],
  ['jpodn', 1],
  ['jpofr', 1],
  ['jpofk', 1],
  ['jpoft', 1],
  ['jpoog', 1],
  ['jposr', 1],
  ['jpoge', 1],
  ['jpogs', 1],
  ['jpogu', 1],
  ['jpoho', 1],
  ['jpoig', 1],
  ['jpokh', 1],
  ['jpone', 1],
  ['jpons', 1],
  ['jpoon', 1],
  ['jpora', 1],
  ['jpous', 1],
  ['jphgs', 1],
  ['jpoym', 1],
  ['jpozj', 1],
  ['jpois', 1],
  ['jpoit', 5],
  ['jpoji', 1],
  ['jpoya', 1],
  ['jpoaa', 1],
  ['jpokd', 4],
  ['jpokm', 1],
  ['jpomr', 1],
  ['jpokj', 5],
  ['jpoki', 5],
  ['jpokn', 1],
  ['jpoka', 4],
  ['jpoke', 4],
  ['jpokk', 1],
  ['jpoks', 1],
  ['jpokg', 1],
  ['jpoku', 1],
  ['jpoch', 1],
  ['jpoir', 4],
  ['jpoax', 1],
  ['jpomc', 1],
  ['jpomz', 1],
  ['jpaom', 3],
  ['jpomi', 1],
  ['jpomt', 1],
  ['jpoms', 1],
  ['jpomy', 1],
  ['jpomd', 1],
  ['jpomn', 1],
  ['jpomw', 1],
  ['jpojm', 1],
  ['jpomh', 1],
  ['jpomo', 1],
  ['jpoum', 1],
  ['jpomj', 5],
  ['jpomu', 1],
  ['jpong', 1],
  ['jpona', 1],
  ['jpogc', 1],
  ['jponj', 1],
  ['jponk', 1],
  ['jponi', 1],
  ['jponw', 1],
  ['jpoad', 1],
  ['jpond', 1],
  ['jpnoj', 1],
  ['jpono', 1],
  ['jponx', 1],
  ['jpopa', 3],
  ['jporg', 1],
  ['jporn', 1],
  ['jposa', 5],
  ['jposn', 1],
  ['jpojo', 1],
  ['jpoto', 1],
  ['jpost', 1],
  ['jposd', 1],
  ['jpose', 1],
  ['jposu', 1],
  ['jpotk', 1],
  ['jpota', 1],
  ['jpotr', 5],
  ['jpotw', 1],
  ['jpotm', 1],
  ['jpotj', 1],
  ['jpoua', 1],
  ['jpour', 1],
  ['jpoor', 1],
  ['jporx', 1],
  ['jporr', 1],
  ['jpowa', 1],
  ['jpoyb', 1],
  ['jpozx', 1],
  ['jpooo', 1],
  ['jpozu', 1],
  ['jprau', 1],
  ['jprbj', 4],
  ['jprhk', 1],
  ['jpris', 4],
  ['jprko', 1],
  ['jprnd', 1],
  ['jprmi', 1],
  ['jprys', 1],
  ['jpryo', 1],
  ['jpryg', 1],
  ['jprya', 1],
  ['jpsab', 1],
  ['jpsdo', 4],
  ['jpsos', 6],
  ['jphsg', 1],
  ['jpsag', 1],
  ['jpsgr', 1],
  ['jpsaw', 1],
  ['jpsgj', 1],
  ['jpsdz', 1],
  ['jpsad', 1],
  ['jpsaj', 1],
  ['jpsss', 1],
  ['jpsae', 1],
  ['jpstz', 1],
  ['jpssk', 1],
  ['jpsak', 1],
  ['jpskd', 1],
  ['jpsmn', 1],
  ['jpsbk', 1],
  ['jpskk', 1],
  ['jpskt', 1],
  ['jpsat', 1],
  ['jpskh', 1],
  ['jpsmu', 1],
  ['jpsto', 1],
  ['jpsau', 1],
  ['jpsko', 1],
  ['jpskr', 1],
  ['jpsrz', 1],
  ['jpsam', 1],
  ['jpsme', 1],
  ['jpsnx', 1],
  ['jpsnm', 1],
  ['jpsan', 1],
  ['jpsyd', 3],
  ['jpspk', 6],
  ['jpsas', 1],
  ['jpssb', 1],
  ['jpssi', 1],
  ['jpsse', 1],
  ['jpssn', 1],
  ['jpsta', 1],
  ['jpsao', 1],
  ['jpste', 1],
  ['jpstw', 1],
  ['jpsen', 1],
  ['jpsaz', 1],
  ['jpseq', 1],
  ['jpsgw', 1],
  ['jpseb', 1],
  ['jpseg', 1],
  ['jpsei', 1],
  ['jpsek', 1],
  ['jpsky', 1],
  ['jpsds', 1],
  ['jpsgm', 1],
  ['jpssu', 1],
  ['jpszk', 1],
  ['jpses', 1],
  ['jpstn', 1],
  ['jpset', 1],
  ['jpstd', 1],
  ['jpsez', 1],
  ['jpshr', 1],
  ['jpsbu', 1],
  ['jpsby', 1],
  ['jpsbh', 1],
  ['jpbkw', 1],
  ['jpskb', 1],
  ['jpsbs', 1],
  ['jpsch', 1],
  ['jpscr', 1],
  ['jpsid', 1],
  ['jpsig', 1],
  ['jpsgi', 1],
  ['jpsin', 1],
  ['jpskm', 1],
  ['jpsik', 1],
  ['jpstj', 1],
  ['jpsar', 1],
  ['jpskn', 1],
  ['jpsmb', 1],
  ['jpsim', 1],
  ['jpsmm', 1],
  ['jpsnu', 1],
  ['jpshz', 1],
  ['jpsmz', 1],
  ['jpsmr', 1],
  ['jpsmh', 1],
  ['jpshi', 4],
  ['jpswa', 1],
  ['jpsoo', 1],
  ['jpshs', 1],
  ['jpsmt', 1],
  ['jpsti', 1],
  ['jpssa', 1],
  ['jpshn', 1],
  ['jpszi', 1],
  ['jpshk', 1],
  ['jpshj', 3],
  ['jpsmj', 1],
  ['jpsnj', 1],
  ['jpsnw', 1],
  ['jpsnz', 1],
  ['jpsgt', 1],
  ['jpsoh', 1],
  ['jpsyg', 1],
  ['jpsrt', 1],
  ['jpsra', 1],
  ['jpsku', 1],
  ['jpsro', 1],
  ['jpsyz', 1],
  ['jpsrj', 1],
  ['jpsjr', 1],
  ['jpsrk', 1],
  ['jpsrs', 1],
  ['jpstr', 1],
  ['jpssj', 1],
  ['jpssh', 1],
  ['jpsis', 1],
  ['jpssm', 1],
  ['jpstb', 1],
  ['jpsno', 1],
  ['jpsit', 1],
  ['jpsdk', 1],
  ['jpszg', 1],
  ['jpfsz', 4],
  ['jpszu', 1],
  ['jpsoi', 4],
  ['jpsnn', 1],
  ['jpsyo', 1],
  ['jpsnr', 1],
  ['jpshu', 1],
  ['jpsdu', 1],
  ['jpsog', 1],
  ['jpsni', 1],
  ['jpsna', 1],
  ['jpsne', 1],
  ['jpsgx', 1],
  ['jpsor', 1],
  ['jpsym', 1],
  ['jpsot', 1],
  ['jpsma', 1],
  ['jpsoy', 1],
  ['jpsoz', 1],
  ['jpsug', 1],
  ['jpsuu', 1],
  ['jpsge', 1],
  ['jpsur', 1],
  ['jpsuk', 1],
  ['jpsmi', 1],
  ['jpsum', 1],
  ['jpsmy', 1],
  ['jpsxm', 1],
  ['jpsue', 1],
  ['jpsus', 1],
  ['jpsuz', 1],
  ['jpstu', 1],
  ['jpsuw', 1],
  ['jptbr', 1],
  ['jptbu', 1],
  ['jptab', 1],
  ['jptbn', 1],
  ['jptac', 1],
  ['jptdn', 1],
  ['jptad', 1],
  ['jptag', 1],
  ['jptao', 1],
  ['jptgo', 1],
  ['jptar', 1],
  ['jptaz', 1],
  ['jptkw', 1],
  ['jptal', 1],
  ['jptin', 1],
  ['jptaq', 1],
  ['jptdt', 1],
  ['jptia', 1],
  ['jptza', 1],
  ['jptjh', 4],
  ['jptaj', 1],
  ['jptkd', 1],
  ['jptkh', 3],
  ['jptak', 5],
  ['jptmj', 1],
  ['jptaa', 1],
  ['jptnb', 1],
  ['jptkc', 1],
  ['jptkk', 1],
  ['jptji', 1],
  ['jptsg', 1],
  ['jptjk', 1],
  ['jptsm', 1],
  ['jptee', 1],
  ['jptdj', 1],
  ['jpthr', 1],
  ['jptea', 1],
  ['jptke', 1],
  ['jptas', 1],
  ['jptsx', 1],
  ['jptth', 1],
  ['jptkt', 1],
  ['jptkz', 1],
  ['jptki', 1],
  ['jptkm', 1],
  ['jptku', 1],
  ['jptam', 1],
  ['jptmn', 1],
  ['jptae', 1],
  ['jptan', 1],
  ['jptnk', 1],
  ['jptne', 4],
  ['jptny', 1],
  ['jptaw', 1],
  ['jpthm', 1],
  ['jptnu', 1],
  ['jptnw', 1],
  ['jptra', 4],
  ['jptrh', 1],
  ['jptmz', 1],
  ['jptss', 1],
  ['jptty', 1],
  ['jptat', 1],
  ['jptyu', 1],
  ['jptei', 1],
  ['jptis', 1],
  ['jptek', 1],
  ['jptrd', 1],
  ['jptzu', 1],
  ['jptrz', 1],
  ['jptsi', 1],
  ['jptso', 1],
  ['jpteu', 1],
  ['jpter', 1],
  ['jptob', 1],
  ['jptbt', 1],
  ['jptbo', 3],
  ['jptod', 1],
  ['jptoj', 1],
  ['jptoc', 1],
  ['jptog', 1],
  ['jptgc', 1],
  ['jptda', 1],
  ['jptoi', 1],
  ['jptjm', 1],
  ['jptok', 1],
  ['jptka', 1],
  ['jptjs', 1],
  ['jpttu', 1],
  ['jptxn', 1],
  ['jptko', 1],
  ['jptkr', 1],
  ['jptcc', 1],
  ['jptkj', 1],
  ['jptkn', 4],
  ['jptks', 5],
  ['jptky', 1],
  ['jptxd', 1],
  ['jptmk', 5],
  ['jptjj', 1],
  ['jptma', 1],
  ['jptrr', 1],
  ['jptme', 1],
  ['jptmm', 1],
  ['jptmu', 1],
  ['jptnd', 1],
  ['jptno', 1],
  ['jptnh', 1],
  ['jpton', 1],
  ['jptro', 1],
  ['jptsz', 1],
  ['jptox', 3],
  ['jptot', 1],
  ['jpttj', 5],
  ['jptou', 1],
  ['jptoy', 5],
  ['jptos', 1],
  ['jptoa', 1],
  ['jpths', 1],
  ['jptus', 1],
  ['jptya', 3],
  ['jptyr', 1],
  ['jptsu', 1],
  ['jptub', 1],
  ['jptch', 1],
  ['jptud', 1],
  ['jptui', 1],
  ['jptyn', 1],
  ['jptwa', 1],
  ['jptmi', 1],
  ['jptuu', 1],
  ['jptna', 1],
  ['jptni', 1],
  ['jptns', 1],
  ['jptrg', 1],
  ['jptsk', 1],
  ['jptrc', 1],
  ['jptsh', 1],
  ['jptsj', 4],
  ['jptst', 1],
  ['jpttm', 1],
  ['jptyp', 1],
  ['jptuz', 1],
  ['jpttk', 1],
  ['jpubj', 5],
  ['jpuby', 1],
  ['jpuhh', 1],
  ['jpucn', 1],
  ['jpuur', 1],
  ['jpunu', 1],
  ['jpuch', 1],
  ['jpudd', 1],
  ['jpudo', 1],
  ['jpuec', 1],
  ['jpuga', 1],
  ['jpugu', 1],
  ['jpuji', 1],
  ['jpujy', 1],
  ['jpukj', 1],
  ['jpukn', 1],
  ['jpukt', 1],
  ['jpuki', 1],
  ['jpumg', 1],
  ['jpuun', 1],
  ['jpuno', 1],
  ['jpuns', 1],
  ['jpunt', 1],
  ['jpuom', 1],
  ['jpura', 1],
  ['jpurg', 1],
  ['jpurm', 1],
  ['jpuao', 1],
  ['jpurh', 1],
  ['jpurk', 1],
  ['jpunm', 1],
  ['jpurr', 1],
  ['jpurs', 1],
  ['jpusa', 1],
  ['jpubk', 1],
  ['jpusg', 1],
  ['jpush', 1],
  ['jpusi', 1],
  ['jpust', 1],
  ['jpusj', 1],
  ['jpuka', 1],
  ['jpusk', 1],
  ['jpusn', 1],
  ['jpusu', 1],
  ['jputz', 1],
  ['jpuwa', 1],
  ['jpuzk', 1],
  ['jpwda', 1],
  ['jpwad', 1],
  ['jpwai', 1],
  ['jpwjm', 1],
  ['jpwam', 3],
  ['jpwkt', 1],
  ['jpwak', 1],
  ['jpwki', 1],
  ['jpwmt', 1],
  ['jpwkw', 1],
  ['jpwkz', 1],
  ['jpwkj', 5],
  ['jpwkr', 1],
  ['jpwan', 1],
  ['jpwni', 1],
  ['jpwna', 1],
  ['jpwsb', 1],
  ['jpwat', 1],
  ['jpych', 3],
  ['jpyne', 1],
  ['jpygr', 1],
  ['jpyzu', 1],
  ['jpkum', 4],
  ['jpyku', 1],
  ['jpyqd', 1],
  ['jpgaj', 4],
  ['jpyam', 1],
  ['jpymg', 1],
  ['jpygh', 1],
  ['jpymn', 1],
  ['jpymt', 1],
  ['jpyaa', 1],
  ['jpyan', 1],
  ['jpyni', 1],
  ['jpynn', 1],
  ['jpyao', 6],
  ['jpysg', 1],
  ['jpyas', 1],
  ['jpyma', 1],
  ['jpyat', 1],
  ['jpywt', 3],
  ['jpywh', 1],
  ['jpybk', 1],
  ['jpyic', 1],
  ['jpyoc', 1],
  ['jpykk', 1],
  ['jpyks', 1],
  ['jpyos', 3],
  ['jpoko', 4],
  ['jpygj', 4],
  ['jpogn', 4],
  ['jpyog', 1],
  ['jpynz', 1],
  ['jpyor', 1],
  ['jpyro', 1],
  ['jprnj', 4],
  ['jpyss', 1],
  ['jpysm', 1],
  ['jpysn', 1],
  ['jpyhi', 3],
  ['jpysu', 1],
  ['jpysr', 1],
  ['jpyha', 1],
  ['jpysz', 1],
  ['jpyot', 1],
  ['jpyou', 1],
  ['jpysh', 1],
  ['jpybt', 1],
  ['jpydm', 1],
  ['jpyge', 1],
  ['jpyuk', 1],
  ['jpymc', 1],
  ['jpymu', 1],
  ['jpynm', 1],
  ['jpynt', 1],
  ['jpyrg', 1],
  ['jpyus', 1],
  ['jpyti', 1],
  ['jpyuu', 1],
  ['jpywn', 1],
  ['jpzmm', 1],
  ['jpaxz', 1],
  ['keasv', 4],
  ['kebmq', 4],
  ['keedl', 4],
  ['keeys', 4],
  ['kefer', 4],
  ['kegas', 4],
  ['kehoa', 4],
  ['keklk', 4],
  ['kekey', 4],
  ['kekrv', 4],
  ['keilu', 4],
  ['kekil', 1],
  ['kekis', 5],
  ['kektl', 4],
  ['kekiu', 4],
  ['kekwy', 4],
  ['kelbn', 4],
  ['kelku', 4],
  ['kelau', 5],
  ['kelbk', 4],
  ['kelok', 4],
  ['kelkg', 4],
  ['keloy', 4],
  ['kemyd', 5],
  ['kende', 4],
  ['kemre', 4],
  ['kerbt', 4],
  ['kemba', 5],
  ['keoyl', 4],
  ['kemum', 4],
  ['kenbo', 6],
  ['kenuu', 4],
  ['kenyk', 4],
  ['kenye', 4],
  ['kenzo', 4],
  ['keuas', 4],
  ['kesmn', 3],
  ['kesia', 1],
  ['keuka', 4],
  ['kewjr', 4],
  ['kewil', 4],
  ['kgfru', 6],
  ['kgman', 6],
  ['kgoss', 4],
  ['khbbm', 4],
  ['khche', 1],
  ['khkmt', 4],
  ['khkko', 1],
  ['khkkz', 4],
  ['khkzk', 4],
  ['khkti', 4],
  ['khkzd', 4],
  ['khkzc', 4],
  ['khmwv', 4],
  ['khomy', 4],
  ['khpai', 4],
  ['khpnh', 5],
  ['khrbe', 4],
  ['khrep', 4],
  ['khtnx', 4],
  ['khsvr', 4],
  ['khtkh', 1],
  ['kiabf', 4],
  ['kiaea', 4],
  ['kiaak', 4],
  ['kiais', 4],
  ['kibez', 4],
  ['kibir', 1],
  ['kibbg', 4],
  ['kicis', 4],
  ['kicxi', 4],
  ['kiebi', 1],
  ['kifis', 1],
  ['kihul', 1],
  ['kikuc', 4],
  ['kimnk', 4],
  ['kimtk', 4],
  ['kimzk', 4],
  ['kimck', 1],
  ['kinik', 1],
  ['kinig', 4],
  ['kinon', 4],
  ['kioot', 4],
  ['kipho', 1],
  ['kisyi', 1],
  ['kitbf', 4],
  ['kitsu', 4],
  ['kitnv', 4],
  ['kitmn', 4],
  ['kitrw', 5],
  ['kitnq', 4],
  ['kiwni', 1],
  ['kmfou', 1],
  ['kmmut', 3],
  ['kmhah', 4],
  ['kmnwa', 6],
  ['kmyva', 5],
  ['knbas', 1],
  ['kncha', 5],
  ['knnev', 5],
  ['knskb', 4],
  ['kpcho', 1],
  ['kpgen', 1],
  ['kphae', 1],
  ['kphgm', 1],
  ['kpnam', 3],
  ['kpoda', 1],
  ['kpfnj', 4],
  ['kprjn', 3],
  ['kpriw', 1],
  ['kpsam', 1],
  ['kpsin', 1],
  ['kpson', 1],
  ['kpsgn', 1],
  ['kptch', 1],
  ['kpwon', 1],
  ['krawl', 1],
  ['kranj', 1],
  ['krbin', 1],
  ['krbor', 3],
  ['krbuk', 3],
  ['krbnp', 1],
  ['krbjg', 3],
  ['krcjj', 6],
  ['krchn', 6],
  ['krcja', 1],
  ['krcgy', 1],
  ['krdbl', 3],
  ['krdcn', 1],
  ['krqet', 6],
  ['krtae', 6],
  ['krdhs', 1],
  ['krtsn', 1],
  ['krtji', 3],
  ['krddo', 1],
  ['krtgh', 3],
  ['krggh', 1],
  ['krgdo', 1],
  ['krkcn', 1],
  ['krggu', 1],
  ['krkag', 6],
  ['krgno', 4],
  ['krjge', 4],
  ['krkje', 3],
  ['krgmd', 1],
  ['krgmp', 6],
  ['krkhn', 1],
  ['krgrp', 1],
  ['krkwj', 6],
  ['krkan', 3],
  ['krgin', 1],
  ['krhlm', 1],
  ['krhar', 1],
  ['krhdo', 1],
  ['krhas', 1],
  ['krhpo', 1],
  ['krhhp', 1],
  ['krhsn', 3],
  ['krinc', 3],
  ['kricn', 4],
  ['krchg', 1],
  ['krhin', 6],
  ['krjmj', 1],
  ['krjha', 1],
  ['krkdo', 1],
  ['krkwa', 1],
  ['krmas', 3],
  ['krmip', 3],
  ['krmok', 3],
  ['krmpk', 4],
  ['krmwx', 4],
  ['krmuk', 3],
  ['krnrd', 1],
  ['krnds', 1],
  ['krokk', 1],
  ['krokp', 3],
  ['krons', 1],
  ['krosn', 4],
  ['krptk', 3],
  ['krscp', 3],
  ['krswd', 1],
  ['krspo', 3],
  ['krssp', 3],
  ['krsma', 1],
  ['krshg', 3],
  ['krsho', 3],
  ['krsgg', 1],
  ['krsbu', 1],
  ['krsys', 6],
  ['krssn', 6],
  ['krsuo', 3],
  ['krswu', 4],
  ['krtan', 3],
  ['krtga', 3],
  ['krtyg', 3],
  ['krull', 1],
  ['krwnd', 3],
  ['krwju', 6],
  ['kryny', 6],
  ['kryec', 6],
  ['krypd', 1],
  ['kryos', 3],
  ['krrsu', 4],
  ['kryoc', 3],
  ['krygp', 1],
  ['kwsal', 3],
  ['kwkht', 3],
  ['kwcbg', 6],
  ['kwdoh', 1],
  ['kwjbd', 1],
  ['kwkwm', 1],
  ['kwkwi', 5],
  ['kwmzr', 1],
  ['kwmis', 1],
  ['kwmib', 1],
  ['kwmea', 1],
  ['kwsmy', 1],
  ['kwsaa', 3],
  ['kwswk', 1],
  ['kycyb', 4],
  ['kygcm', 6],
  ['kylyb', 4],
  ['kzaau', 5],
  ['kzsco', 4],
  ['kzala', 4],
  ['kzakx', 6],
  ['kzayk', 4],
  ['kzast', 4],
  ['kzatx', 4],
  ['kzguw', 4],
  ['kzbkz', 6],
  ['kzbxh', 4],
  ['kzbtn', 1],
  ['kzbxj', 4],
  ['kzekb', 4],
  ['kzkgf', 6],
  ['kzksn', 4],
  ['kzkzo', 4],
  ['kzkov', 6],
  ['kznov', 6],
  ['kzpwq', 6],
  ['kzplx', 6],
  ['kzcit', 6],
  ['kztdk', 4],
  ['kztau', 3],
  ['kzura', 4],
  ['kzukk', 4],
  ['kzszi', 4],
  ['kzhrc', 4],
  ['kzdmb', 4],
  ['kzdzn', 4],
  ['laaou', 4],
  ['laoui', 4],
  ['lahoe', 4],
  ['lakog', 4],
  ['lalxg', 4],
  ['lalpq', 4],
  ['lauon', 4],
  ['laody', 4],
  ['lapks', 4],
  ['lapkz', 6],
  ['lapcq', 4],
  ['laneu', 4],
  ['lavna', 4],
  ['lazby', 4],
  ['lasnd', 4],
  ['lathk', 4],
  ['laudo', 4],
  ['lavgg', 4],
  ['lavng', 4],
  ['lavte', 4],
  ['laxay', 4],
  ['laxkh', 4],
  ['laxie', 4],
  ['lbact', 1],
  ['lbbey', 5],
  ['lbln8', 3],
  ['lbbru', 3],
  ['lbbyl', 3],
  ['lbchk', 1],
  ['lbdra', 1],
  ['lbjie', 1],
  ['lbqjn', 4],
  ['lbkha', 1],
  ['lbouz', 1],
  ['lbsay', 3],
  ['lbsel', 1],
  ['lbsur', 1],
  ['lbkye', 5],
  ['lbzhr', 1],
  ['lbzuk', 1],
  ['lccas', 3],
  ['lccds', 1],
  ['lcuvf', 4],
  ['lcslu', 4],
  ['lcvif', 1],
  ['lkamp', 4],
  ['lkadp', 4],
  ['lkbtc', 5],
  ['lkbjt', 4],
  ['lkbrw', 1],
  ['lkrml', 4],
  ['lkdbu', 4],
  ['lkdiw', 4],
  ['lkgoy', 4],
  ['lkgal', 1],
  ['lkkdy', 4],
  ['lkhba', 1],
  ['lkhim', 4],
  ['lkjaf', 5],
  ['lkjct', 1],
  ['lkkal', 1],
  ['lkknk', 1],
  ['lkkdz', 4],
  ['lkkay', 1],
  ['lkkez', 4],
  ['lkkon', 3],
  ['lkmhw', 4],
  ['lkman', 1],
  ['lkmaw', 3],
  ['lkmnh', 4],
  ['lkmul', 1],
  ['lkolu', 1],
  ['lkppe', 1],
  ['lksgt', 1],
  ['lktal', 1],
  ['lktrr', 5],
  ['lkuct', 1],
  ['lkwrz', 4],
  ['lrbyl', 4],
  ['lrucn', 5],
  ['lrcmt', 1],
  ['lrcpa', 5],
  ['lrfim', 1],
  ['lrfoy', 4],
  ['lrgbs', 1],
  ['lrgrc', 4],
  ['lrgre', 1],
  ['lrhar', 3],
  ['lrlob', 1],
  ['lrmar', 1],
  ['lrnia', 4],
  ['lrrvc', 5],
  ['lrrob', 6],
  ['lrrox', 1],
  ['lrsab', 1],
  ['lrsaz', 5],
  ['lrsni', 5],
  ['lrtpt', 4],
  ['lrthc', 4],
  ['lrtrt', 1],
  ['lrvoi', 4],
  ['lrwes', 4],
  ['lrwoi', 4],
  ['lsbut', 6],
  ['lslef', 4],
  ['lslrb', 4],
  ['lsles', 4],
  ['lsmfc', 4],
  ['lsmsu', 4],
  ['lsmsg', 4],
  ['lsmkh', 4],
  ['lsnku', 4],
  ['lspel', 4],
  ['lsune', 4],
  ['lsutg', 4],
  ['lsshk', 4],
  ['lsskq', 4],
  ['lssok', 4],
  ['lsshz', 4],
  ['lsthb', 4],
  ['lstko', 4],
  ['ltbot', 1],
  ['ltdki', 6],
  ['ltkun', 6],
  ['ltklj', 5],
  ['ltmlm', 3],
  ['ltmom', 3],
  ['ltplq', 4],
  ['ltpnv', 6],
  ['ltplm', 3],
  ['ltsqq', 6],
  ['ltvno', 4],
  ['lubkm', 3],
  ['luezt', 3],
  ['luzxc', 3],
  ['luhdk', 3],
  ['lulux', 5],
  ['lumrt', 1],
  ['luxxx', 1],
  ['lurod', 1],
  ['luwlg', 1],
  ['lvbmt', 1],
  ['lvain', 1],
  ['lvaiz', 3],
  ['lvb9g', 3],
  ['lvdgp', 4],
  ['lvege', 3],
  ['lvjps', 3],
  ['lvjga', 6],
  ['lvuty', 3],
  ['lvlms', 3],
  ['lvbnh', 3],
  ['lvzvt', 3],
  ['lvmrx', 3],
  ['lvore', 3],
  ['lvrix', 5],
  ['lvroj', 3],
  ['lvrop', 3],
  ['lvrum', 3],
  ['lvsal', 3],
  ['lvsku', 3],
  ['lvsto', 3],
  ['lvxrt', 3],
  ['lvlm8', 3],
  ['lvvnt', 1],
  ['lyabk', 1],
  ['lyaba', 1],
  ['lykho', 3],
  ['lyakf', 4],
  ['lyapo', 1],
  ['lyesi', 1],
  ['lyzaw', 1],
  ['lybar', 1],
  ['lylaq', 4],
  ['lyben', 5],
  ['lybcq', 4],
  ['lybua', 1],
  ['lydrx', 1],
  ['lybou', 1],
  ['lyelk', 3],
  ['lyltd', 4],
  ['lyght', 4],
  ['lyhuq', 6],
  ['lylmq', 5],
  ['lymeh', 1],
  ['lymhr', 1],
  ['lymth', 1],
  ['lymel', 3],
  ['lymra', 5],
  ['lymji', 4],
  ['lyqmq', 4],
  ['lynfr', 4],
  ['lyrla', 1],
  ['lyseb', 6],
  ['lysrx', 4],
  ['lysrt', 1],
  ['lysou', 1],
  ['lytag', 1],
  ['lytob', 5],
  ['lytoa', 1],
  ['lytip', 5],
  ['lytuk', 1],
  ['lyzli', 1],
  ['lyzua', 1],
  ['lywax', 4],
  ['manps', 1],
  ['maaga', 5],
  ['maahu', 5],
  ['maanz', 3],
  ['mad2v', 1],
  ['maasi', 1],
  ['maaze', 1],
  ['masmd', 1],
  ['mabjd', 3],
  ['macas', 5],
  ['mavil', 5],
  ['maelj', 3],
  ['maerh', 4],
  ['maess', 3],
  ['mafez', 4],
  ['magln', 4],
  ['majfl', 3],
  ['manna', 5],
  ['masek', 4],
  ['maeun', 5],
  ['malar', 1],
  ['marak', 6],
  ['macmn', 4],
  ['mamoh', 1],
  ['mandr', 1],
  ['maozz', 4],
  ['maoud', 4],
  ['marba', 5],
  ['marem', 1],
  ['marna', 3],
  ['masfi', 5],
  ['masal', 1],
  ['masii', 1],
  ['masmw', 4],
  ['ma6kn', 1],
  ['matta', 4],
  ['maptm', 3],
  ['matng', 5],
  ['matfy', 4],
  ['matrd', 6],
  ['matem', 1],
  ['mattu', 5],
  ['mcmon', 1],
  ['mcmcm', 5],
  ['mdkiv', 4],
  ['mdgiu', 3],
  ['mdxxx', 1],
  ['mebar', 3],
  ['meivg', 6],
  ['mebij', 3],
  ['mebud', 3],
  ['meigl', 3],
  ['mepvc', 3],
  ['metgd', 6],
  ['mersn', 3],
  ['meulc', 3],
  ['mezbk', 6],
  ['mezel', 3],
  ['mfges', 1],
  ['mfsfg', 6],
  ['mfmar', 1],
  ['mgahy', 4],
  ['mgamy', 4],
  ['mgwam', 4],
  ['mgamb', 4],
  ['mgamp', 4],
  ['mghva', 4],
  ['mgzwa', 4],
  ['mgwad', 4],
  ['mgadk', 1],
  ['mgjva', 4],
  ['mgwak', 4],
  ['mgnko', 4],
  ['mgwor', 4],
  ['mganm', 5],
  ['mgtnr', 4],
  ['mgwaq', 4],
  ['mgatj', 4],
  ['mgdie', 5],
  ['mgwai', 4],
  ['mgbrr', 1],
  ['mgwbe', 4],
  ['mgwbd', 4],
  ['mgova', 4],
  ['mgbmd', 5],
  ['mgwbo', 4],
  ['mgbsv', 4],
  ['mgbpy', 5],
  ['mgbku', 4],
  ['mgbik', 1],
  ['mgdoa', 4],
  ['mgehl', 1],
  ['mgrva', 5],
  ['mgwfi', 4],
  ['mgftu', 5],
  ['mghlv', 1],
  ['mgiho', 4],
  ['mgilk', 4],
  ['mgwmv', 4],
  ['mgvvb', 5],
  ['mgmxt', 5],
  ['mgmjn', 5],
  ['mgwml', 4],
  ['mgwmp', 4],
  ['mgwvk', 5],
  ['mgwmr', 5],
  ['mgmnj', 5],
  ['mgwmd', 4],
  ['mgwma', 4],
  ['mgmja', 4],
  ['mgmob', 1],
  ['mgwmn', 5],
  ['mgzva', 4],
  ['mgtva', 4],
  ['mgmxm', 5],
  ['mgmoq', 5],
  ['mgnov', 1],
  ['mgwpb', 4],
  ['mgpsl', 1],
  ['mgsms', 4],
  ['mgsvb', 5],
  ['mgdwb', 5],
  ['mgtmm', 5],
  ['mgwta', 4],
  ['mgtdv', 4],
  ['mgtoa', 1],
  ['mgtts', 4],
  ['mgwts', 4],
  ['mgtle', 5],
  ['mgvnd', 4],
  ['mgvat', 5],
  ['mgveh', 1],
  ['mgvoh', 4],
  ['mhbii', 4],
  ['mhaip', 4],
  ['mhaim', 4],
  ['mhaic', 4],
  ['mhamr', 4],
  ['mhaul', 4],
  ['mhebn', 4],
  ['mhebo', 4],
  ['mhent', 4],
  ['mhimi', 4],
  ['mhjat', 4],
  ['mhuit', 4],
  ['mhjej', 4],
  ['mhkbt', 4],
  ['mhkio', 4],
  ['mhkwa', 4],
  ['mhlml', 4],
  ['mhlik', 4],
  ['mhlof', 4],
  ['mhmje', 4],
  ['mhmaj', 5],
  ['mhmav', 4],
  ['mhmjb', 4],
  ['mhmij', 4],
  ['mhndk', 4],
  ['mhnmu', 4],
  ['mhrnp', 4],
  ['mhtbv', 4],
  ['mhtar', 1],
  ['mhtic', 4],
  ['mhuje', 4],
  ['mhutk', 4],
  ['mhwja', 4],
  ['mhwto', 4],
  ['mhwte', 4],
  ['mkbtl', 6],
  ['mkdkj', 3],
  ['mknod', 1],
  ['mkohd', 6],
  ['mkptr', 4],
  ['mkrad', 1],
  ['mkskp', 6],
  ['mksni', 1],
  ['mlaao', 4],
  ['mlbko', 4],
  ['mlgaq', 4],
  ['mlgud', 4],
  ['mlkys', 4],
  ['mlknz', 4],
  ['mlktx', 6],
  ['mlmzi', 4],
  ['mlnrm', 4],
  ['mlnix', 4],
  ['mlszu', 6],
  ['mltom', 4],
  ['mleyl', 4],
  ['mmaky', 5],
  ['mmbpe', 4],
  ['mmbsx', 5],
  ['mmbmo', 4],
  ['mmbil', 1],
  ['mmbog', 1],
  ['mmtvy', 4],
  ['mmgaw', 4],
  ['mmgwa', 4],
  ['mmhen', 4],
  ['mmheb', 5],
  ['mmhox', 4],
  ['mmins', 1],
  ['mmkmv', 4],
  ['mmkaw', 4],
  ['mmket', 4],
  ['mmkhm', 4],
  ['mmkya', 3],
  ['mmkyp', 5],
  ['mmkyt', 4],
  ['mmlsh', 4],
  ['mmliw', 4],
  ['mmmwq', 4],
  ['mmmxe', 3],
  ['mmmgu', 4],
  ['mmmdl', 4],
  ['mmmar', 1],
  ['mmmnu', 5],
  ['mmmer', 1],
  ['mmmoe', 4],
  ['mmmog', 4],
  ['mmmgk', 4],
  ['mmmur', 1],
  ['mmmgz', 4],
  ['mmmyt', 4],
  ['mmnms', 4],
  ['mmnmt', 4],
  ['mmnyu', 4],
  ['mmpaa', 4],
  ['mmpkk', 4],
  ['mmppu', 4],
  ['mmpau', 4],
  ['mmpeg', 1],
  ['mmpru', 4],
  ['mmpbu', 4],
  ['mmthl', 4],
  ['mmtas', 1],
  ['mmten', 1],
  ['mmtav', 1],
  ['mmter', 1],
  ['mmsnw', 5],
  ['mmtha', 5],
  ['mmtla', 3],
  ['mmtio', 4],
  ['mmvic', 1],
  ['mmrgn', 5],
  ['mmxye', 5],
  ['mnlti', 4],
  ['mnavk', 4],
  ['mnuun', 4],
  ['mnbyn', 4],
  ['mnuga', 4],
  ['mncoq', 4],
  ['mndlz', 4],
  ['mnert', 4],
  ['mnkhr', 4],
  ['mnhvd', 4],
  ['mnhjt', 4],
  ['mnmxw', 4],
  ['mnmxv', 4],
  ['mntsz', 4],
  ['mnuln', 4],
  ['mnulo', 4],
  ['mnulz', 4],
  ['mnunr', 4],
  ['mnulg', 6],
  ['momfm', 5],
  ['mprop', 4],
  ['mpspn', 5],
  ['mptiq', 4],
  ['mqlmt', 6],
  ['mqler', 3],
  ['mqqpc', 3],
  ['mraeo', 4],
  ['mrajj', 4],
  ['mrleg', 4],
  ['mratr', 4],
  ['mrbgh', 4],
  ['mrotl', 4],
  ['mrcgt', 4],
  ['mrzlg', 4],
  ['mrfgd', 4],
  ['mrked', 4],
  ['mrkfa', 4],
  ['mrmbr', 4],
  ['mrmom', 4],
  ['mrndb', 5],
  ['mrnkc', 5],
  ['mremn', 4],
  ['mrpce', 1],
  ['mrros', 3],
  ['mrsey', 4],
  ['mrtht', 4],
  ['mrthi', 4],
  ['mrtiy', 4],
  ['mrtmd', 4],
  ['mrouz', 4],
  ['msltb', 3],
  ['msmni', 4],
  ['msply', 1],
  ['mtmgz', 3],
  ['mtbal', 3],
  ['mtbze', 3],
  ['mtbla', 3],
  ['mtckw', 3],
  ['mtjco', 4],
  ['mtdmp', 3],
  ['mtgha', 1],
  ['mtgzi', 1],
  ['mtkir', 1],
  ['mtluq', 6],
  ['mtdis', 3],
  ['mtmsa', 1],
  ['mtmsx', 1],
  ['mtmss', 1],
  ['mtmar', 1],
  ['mtspb', 1],
  ['mtslm', 1],
  ['mttar', 1],
  ['mtmla', 5],
  ['muabn', 1],
  ['mubam', 1],
  ['mucur', 3],
  ['muplu', 5],
  ['mupma', 3],
  ['murrg', 4],
  ['mumru', 4],
  ['mutam', 1],
  ['mvadu', 3],
  ['mvbal', 1],
  ['mvcpp', 1],
  ['mvgan', 4],
  ['mvhid', 1],
  ['mvhaq', 4],
  ['mvhtp', 1],
  ['mvhhe', 1],
  ['mvkdm', 4],
  ['mvkdo', 4],
  ['mvkel', 1],
  ['mvkfp', 1],
  ['mvkul', 3],
  ['mvkdp', 1],
  ['mvmmp', 1],
  ['mvmgp', 1],
  ['mvmle', 5],
  ['mvmcp', 1],
  ['mvrgi', 1],
  ['mvvhp', 1],
  ['mwblz', 4],
  ['mwceh', 4],
  ['mwcpk', 3],
  ['mwcmk', 4],
  ['mwdwa', 4],
  ['mwkgj', 4],
  ['mwkbq', 4],
  ['mwlix', 4],
  ['mwllw', 4],
  ['mwmai', 4],
  ['mwmyz', 4],
  ['mwzzu', 4],
  ['mwlmb', 4],
  ['mwthy', 3],
  ['mxajs', 4],
  ['mxanh', 1],
  ['mxaca', 5],
  ['mxacm', 1],
  ['mxapr', 3],
  ['mxagu', 6],
  ['mxatm', 3],
  ['mxavd', 1],
  ['mxaob', 1],
  ['mxatn', 3],
  ['mxazg', 4],
  ['mxarz', 3],
  ['mxabb', 1],
  ['mxbhl', 4],
  ['mxea2', 1],
  ['mxbdr', 3],
  ['mxcsl', 3],
  ['mxzca', 3],
  ['mxaao', 1],
  ['mxcpe', 5],
  ['mxcun', 4],
  ['mxcat', 1],
  ['mxced', 3],
  ['mxcri', 1],
  ['mxctm', 5],
  ['mxcza', 4],
  ['mxcuu', 4],
  ['mxtab', 1],
  ['mxacn', 4],
  ['mxcua', 6],
  ['mxmex', 6],
  ['mxcme', 1],
  ['mxcjs', 4],
  ['mxcen', 4],
  ['mxrbv', 3],
  ['mxcsg', 3],
  ['mxcvm', 4],
  ['mxcoa', 5],
  ['mxcol', 6],
  ['mxcjt', 4],
  ['mxctx', 3],
  ['mxczm', 5],
  ['mxctl', 3],
  ['mxcvc', 6],
  ['mxcul', 6],
  ['mxdhd', 3],
  ['mxdbt', 1],
  ['mxdgo', 6],
  ['mxeld', 1],
  ['mxese', 5],
  ['mxfrn', 1],
  ['mxget', 3],
  ['mxgra', 1],
  ['mxgdl', 4],
  ['mxgsv', 3],
  ['mxgym', 5],
  ['mxgub', 4],
  ['mxhmo', 4],
  ['mxhto', 3],
  ['mxhun', 1],
  ['mxisj', 5],
  ['mxsmi', 1],
  ['mxzih', 5],
  ['mxzip', 3],
  ['mxizt', 4],
  ['mxjal', 6],
  ['mxjza', 1],
  ['mxjmn', 1],
  ['mxjdz', 3],
  ['mxltj', 1],
  ['mxlom', 4],
  ['mxlto', 5],
  ['mxlmm', 4],
  ['mxlzc', 1],
  ['mxlen', 4],
  ['mxmht', 1],
  ['mxmam', 6],
  ['mxmaz', 3],
  ['mxmzt', 5],
  ['mxmxl', 6],
  ['mxmtt', 5],
  ['mxlov', 6],
  ['mxmty', 6],
  ['mxmlm', 6],
  ['mxmre', 1],
  ['mxmug', 4],
  ['mxmid', 5],
  ['mxnan', 1],
  ['mxnau', 1],
  ['mxnog', 6],
  ['mxncg', 4],
  ['mxnld', 6],
  ['mxoax', 4],
  ['mxobr', 3],
  ['mxoco', 6],
  ['mxpaj', 1],
  ['mxpqm', 4],
  ['mxpic', 3],
  ['mxpno', 4],
  ['mxpcm', 5],
  ['mxpuh', 4],
  ['mxpib', 1],
  ['mxpaz', 4],
  ['mxpgo', 1],
  ['mxpbc', 6],
  ['mxpta', 3],
  ['mxpxm', 4],
  ['mxpjz', 4],
  ['mxpmd', 3],
  ['mxpms', 3],
  ['mxppe', 5],
  ['mxpvr', 5],
  ['mxpng', 3],
  ['mxpbj', 3],
  ['mxpcv', 4],
  ['mxpco', 4],
  ['mxpvn', 1],
  ['mxpmx', 1],
  ['mxqro', 6],
  ['mxrbg', 3],
  ['mxrex', 6],
  ['mxrst', 1],
  ['mxscx', 5],
  ['mxslw', 6],
  ['mxsbs', 1],
  ['mxscr', 1],
  ['mxszt', 4],
  ['mxsfh', 4],
  ['mxsgm', 4],
  ['mxsjd', 4],
  ['mxsjc', 1],
  ['mxslo', 1],
  ['mxslp', 6],
  ['mxuac', 4],
  ['mxsgx', 1],
  ['mxsnq', 4],
  ['mxsn2', 1],
  ['mxtpx', 3],
  ['mxsrl', 1],
  ['mxstt', 3],
  ['mxsey', 1],
  ['mxtak', 1],
  ['mxtmp', 3],
  ['mxtam', 5],
  ['mxtsl', 4],
  ['mxtap', 4],
  ['mxtcu', 1],
  ['mxtcn', 4],
  ['mxtxh', 3],
  ['mxten', 1],
  ['mx4te', 1],
  ['mxtpq', 6],
  ['mxtxc', 1],
  ['mxtzm', 4],
  ['mxtjt', 1],
  ['mxtlc', 6],
  ['mxtpb', 1],
  ['mxtrc', 4],
  ['mxtuy', 4],
  ['mxtux', 1],
  ['mxtgz', 6],
  ['mxupn', 6],
  ['mxvib', 4],
  ['mxvnr', 3],
  ['mxvsa', 6],
  ['mxxal', 3],
  ['mx9as', 3],
  ['mxyuk', 1],
  ['mxzcl', 6],
  ['mxzkp', 3],
  ['mxzmm', 6],
  ['mxzrz', 3],
  ['mxxih', 1],
  ['mybdv', 1],
  ['mybba', 1],
  ['mybli', 1],
  ['mybau', 1],
  ['myblg', 5],
  ['mybel', 1],
  ['mybnt', 1],
  ['mybtg', 1],
  ['mybia', 1],
  ['mybng', 1],
  ['mybtu', 1],
  ['mybgg', 1],
  ['mydro', 1],
  ['myjam', 1],
  ['mykab', 1],
  ['mykpi', 4],
  ['mykim', 1],
  ['mykbd', 1],
  ['mykbe', 1],
  ['mykpu', 1],
  ['myksg', 1],
  ['mykch', 5],
  ['mykun', 1],
  ['mylbu', 5],
  ['myldu', 5],
  ['mylwy', 5],
  ['mylmn', 1],
  ['mylgg', 1],
  ['mylun', 1],
  ['mylut', 1],
  ['mymur', 5],
  ['mymem', 1],
  ['mymyy', 1],
  ['mymkm', 5],
  ['mypal', 1],
  ['mypgu', 1],
  ['mypba', 1],
  ['myptb', 1],
  ['mypun', 1],
  ['myrej', 1],
  ['mysdk', 5],
  ['mysej', 1],
  ['mysel', 1],
  ['mybse', 5],
  ['mysmm', 5],
  ['mysep', 3],
  ['mysbw', 5],
  ['mysmg', 1],
  ['myspg', 1],
  ['mysmj', 1],
  ['myspt', 1],
  ['mysrk', 1],
  ['mysdr', 1],
  ['mytwu', 5],
  ['myten', 1],
  ['mywal', 1],
  ['mywes', 1],
  ['myabu', 1],
  ['myaor', 4],
  ['myang', 1],
  ['mybkm', 6],
  ['mybag', 1],
  ['mybkp', 1],
  ['mybma', 1],
  ['mybtk', 1],
  ['mybgu', 1],
  ['mybbn', 4],
  ['mybak', 1],
  ['mytjh', 1],
  ['mybtw', 1],
  ['mybtm', 1],
  ['mybat', 1],
  ['mybea', 3],
  ['mybtn', 1],
  ['mybgr', 1],
  ['mycen', 1],
  ['myddw', 1],
  ['myend', 1],
  ['mygtb', 4],
  ['myhum', 1],
  ['myiph', 4],
  ['myjel', 1],
  ['myjhb', 5],
  ['mytkp', 3],
  ['mytla', 1],
  ['mykes', 1],
  ['mykem', 1],
  ['mykgu', 4],
  ['mykpn', 1],
  ['myket', 1],
  ['mykij', 1],
  ['mykbr', 5],
  ['mykss', 1],
  ['mykti', 1],
  ['mykre', 1],
  ['mykba', 1],
  ['mykbs', 1],
  ['mybst', 1],
  ['mydgn', 3],
  ['mykug', 1],
  ['mykkh', 1],
  ['mykul', 4],
  ['mykps', 1],
  ['myprn', 1],
  ['myksd', 1],
  ['myksl', 1],
  ['mytgg', 5],
  ['mykua', 5],
  ['mykkp', 1],
  ['mylbh', 1],
  ['mylbp', 4],
  ['myllm', 4],
  ['mylgl', 4],
  ['mygsa', 4],
  ['mylsm', 4],
  ['myodn', 4],
  ['mylsu', 4],
  ['mylum', 1],
  ['mymkz', 5],
  ['myman', 1],
  ['mymbk', 3],
  ['mymep', 5],
  ['mymzs', 4],
  ['mymua', 1],
  ['mymud', 1],
  ['mymzv', 4],
  ['mymen', 3],
  ['mynii', 1],
  ['mysnp', 1],
  ['mylpk', 1],
  ['mypay', 4],
  ['mypan', 1],
  ['mypas', 1],
  ['myphi', 1],
  ['myped', 1],
  ['mypen', 5],
  ['mypmt', 1],
  ['mypnd', 3],
  ['mypgg', 1],
  ['mypkk', 1],
  ['myrai', 3],
  ['mypow', 1],
  ['mypdi', 1],
  ['myxpq', 1],
  ['mypkg', 3],
  ['mypra', 1],
  ['mybru', 1],
  ['mylau', 1],
  ['mypjk', 1],
  ['mylac', 4],
  ['myppi', 1],
  ['myrnu', 4],
  ['myrdn', 4],
  ['mysbt', 1],
  ['mysam', 1],
  ['mysar', 3],
  ['mysgm', 3],
  ['mysei', 6],
  ['mysnr', 3],
  ['myspe', 4],
  ['mysha', 1],
  ['mysij', 1],
  ['mysil', 1],
  ['mysdm', 1],
  ['mysir', 1],
  ['myswy', 4],
  ['mysme', 1],
  ['myszb', 6],
  ['mysum', 1],
  ['mysre', 1],
  ['mysgg', 1],
  ['mysup', 1],
  ['mysuw', 3],
  ['mygtk', 4],
  ['mytpg', 4],
  ['mysxt', 4],
  ['mytai', 3],
  ['mytte', 1],
  ['mytba', 1],
  ['mytbe', 1],
  ['mytbi', 1],
  ['mytda', 1],
  ['mytki', 1],
  ['mytsr', 1],
  ['mytge', 3],
  ['mytag', 3],
  ['mytmp', 1],
  ['mytpp', 3],
  ['mytat', 1],
  ['mytas', 1],
  ['mytrm', 1],
  ['mytrb', 3],
  ['mytel', 4],
  ['myasq', 1],
  ['mytod', 5],
  ['mytmg', 5],
  ['mytub', 3],
  ['mytmb', 1],
  ['mytum', 1],
  ['mytun', 1],
  ['mzame', 4],
  ['mzano', 5],
  ['mzbjn', 4],
  ['mzbzb', 4],
  ['mzbew', 5],
  ['mzbel', 1],
  ['mzbcw', 4],
  ['mzcmz', 4],
  ['mzvpy', 4],
  ['mzine', 5],
  ['mzfxo', 4],
  ['mzvjq', 4],
  ['mzibo', 5],
  ['mzinh', 5],
  ['mzimg', 4],
  ['mzvxc', 4],
  ['mzlbm', 4],
  ['mzmcu', 1],
  ['mzmjs', 4],
  ['mzmfw', 4],
  ['mzmpm', 5],
  ['mzrrm', 4],
  ['mzmsg', 1],
  ['mzmat', 1],
  ['mzmem', 1],
  ['mzmzq', 1],
  ['mzmmw', 5],
  ['mzmtu', 4],
  ['mzmor', 1],
  ['mzmud', 4],
  ['mzmnc', 5],
  ['mzapl', 4],
  ['mznnd', 4],
  ['mznso', 1],
  ['mzlmz', 4],
  ['mzpeb', 5],
  ['mzpol', 5],
  ['mzpbe', 1],
  ['mzuel', 5],
  ['mzntc', 4],
  ['mztet', 4],
  ['mzvnx', 4],
  ['mzvjb', 4],
  ['naaiw', 4],
  ['naadi', 4],
  ['nabqi', 4],
  ['nagog', 4],
  ['nagfy', 4],
  ['nahal', 4],
  ['nakas', 4],
  ['nakmp', 4],
  ['nalud', 5],
  ['namqg', 4],
  ['naoku', 4],
  ['namjo', 4],
  ['nampa', 4],
  ['nanni', 4],
  ['naokf', 4],
  ['naomg', 4],
  ['naond', 4],
  ['naogv', 4],
  ['naopw', 4],
  ['naomd', 4],
  ['naohi', 4],
  ['naotj', 4],
  ['narua', 1],
  ['nandu', 4],
  ['naszm', 4],
  ['naswp', 4],
  ['natcy', 4],
  ['natsb', 4],
  ['nawvb', 1],
  ['nawdh', 4],
  ['naers', 4],
  ['ncbug', 3],
  ['ncbmy', 4],
  ['ncdus', 1],
  ['nchlu', 4],
  ['ncilp', 4],
  ['nciou', 4],
  ['ncknq', 4],
  ['nckou', 3],
  ['nckoc', 4],
  ['ncmtd', 1],
  ['nclif', 4],
  ['ncmee', 4],
  ['ncpdc', 6],
  ['ncnak', 3],
  ['ncnou', 5],
  ['ncgea', 4],
  ['ncnep', 5],
  ['ncuve', 4],
  ['ncpor', 3],
  ['ncpny', 3],
  ['nctud', 3],
  ['ncthi', 1],
  ['nctgj', 4],
  ['ncton', 5],
  ['nctou', 4],
  ['ncvav', 1],
  ['ncwal', 1],
  ['neajy', 4],
  ['nerlt', 4],
  ['nebkn', 4],
  ['nemfq', 4],
  ['nenim', 4],
  ['nethz', 4],
  ['neznd', 4],
  ['nfnlk', 4],
  ['ngabo', 1],
  ['ngabv', 4],
  ['ngado', 1],
  ['ngabm', 1],
  ['ngakp', 1],
  ['ngakr', 4],
  ['nganb', 1],
  ['ngant', 1],
  ['ngana', 1],
  ['ngapp', 1],
  ['ngbad', 1],
  ['ngqbu', 4],
  ['ngbni', 4],
  ['ngbon', 1],
  ['ngbra', 1],
  ['ngbur', 1],
  ['ngcbq', 5],
  ['ngden', 1],
  ['ngeat', 1],
  ['ngeke', 1],
  ['ngenu', 4],
  ['ngert', 1],
  ['ngesc', 1],
  ['ngflm', 3],
  ['ngfor', 1],
  ['nggrp', 1],
  ['ngiba', 4],
  ['ngike', 6],
  ['ngilr', 4],
  ['ngima', 1],
  ['ngjos', 4],
  ['ngkad', 4],
  ['ngkan', 4],
  ['ngkt7', 1],
  ['ngkir', 3],
  ['ngkok', 1],
  ['ngkul', 1],
  ['nglos', 5],
  ['ngloa', 6],
  ['ngmiu', 4],
  ['ngmdi', 4],
  ['ngqnn', 4],
  ['ngmxj', 4],
  ['ngokn', 1],
  ['ngokr', 1],
  ['ngokw', 1],
  ['ngqni', 4],
  ['ngonn', 3],
  ['ngoro', 1],
  ['ngoso', 1],
  ['ngoyo', 1],
  ['ngphc', 5],
  ['ngobo', 1],
  ['ngsot', 3],
  ['ngspl', 1],
  ['ngsko', 4],
  ['ngtin', 1],
  ['ngwar', 1],
  ['ngyol', 4],
  ['ngzar', 4],
  ['nibef', 5],
  ['nibza', 4],
  ['nicio', 1],
  ['nirni', 4],
  ['nielb', 1],
  ['nilm8', 1],
  ['nimga', 4],
  ['nimac', 1],
  ['nipti', 1],
  ['nipri', 1],
  ['nipuz', 1],
  ['nipib', 3],
  ['nipsn', 1],
  ['niram', 3],
  ['nirfs', 4],
  ['nincr', 4],
  ['nisjs', 1],
  ['nisiu', 4],
  ['nitpa', 3],
  ['ni8lg', 1],
  ['niwsp', 4],
  ['nlgra', 1],
  ['nlhag', 6],
  ['nlgrz', 3],
  ['nlhtb', 3],
  ['nlkri', 3],
  ['nllkn', 3],
  ['nlsto', 3],
  ['nltwa', 3],
  ['nlaam', 1],
  ['nlamb', 3],
  ['nlari', 3],
  ['nlaho', 3],
  ['nlakl', 1],
  ['nlabl', 1],
  ['nlawd', 3],
  ['nlalk', 3],
  ['nlalm', 3],
  ['nlaer', 3],
  ['nlapn', 3],
  ['nlaml', 5],
  ['nlamr', 3],
  ['nlame', 3],
  ['nlanp', 3],
  ['nlapp', 3],
  ['nlarc', 3],
  ['nlbge', 3],
  ['nlbra', 3],
  ['nlban', 3],
  ['nlbrr', 3],
  ['nlbgo', 3],
  ['nlbhm', 3],
  ['nlzak', 4],
  ['nlbsl', 3],
  ['nlbts', 3],
  ['nlbfd', 3],
  ['nlbem', 3],
  ['nlbeg', 1],
  ['nlbzm', 3],
  ['nlbgi', 3],
  ['nlblu', 3],
  ['nlbst', 3],
  ['nlbnn', 3],
  ['nlbev', 3],
  ['nlbnz', 3],
  ['nlbit', 3],
  ['nlbdr', 3],
  ['nlbnm', 1],
  ['nlbda', 3],
  ['nlbnh', 3],
  ['nlbog', 3],
  ['nlbzw', 3],
  ['nlbww', 3],
  ['nlbgw', 3],
  ['nlbon', 3],
  ['nlbor', 3],
  ['nlbsk', 3],
  ['nlbsh', 3],
  ['nlbot', 3],
  ['nlbta', 3],
  ['nlbvi', 3],
  ['nlbrd', 3],
  ['nlbrs', 3],
  ['nlruk', 3],
  ['nlbri', 3],
  ['nlbro', 1],
  ['nlbse', 3],
  ['nlbrm', 3],
  ['nlbud', 5],
  ['nlbnu', 3],
  ['nlbne', 3],
  ['nlbun', 3],
  ['nlbvg', 3],
  ['nlbhs', 1],
  ['nlbwe', 3],
  ['nlsso', 1],
  ['nlczd', 3],
  ['nlcpd', 3],
  ['nlcpi', 1],
  ['nlcoe', 3],
  ['nlcri', 3],
  ['nlcub', 3],
  ['nlcdp', 3],
  ['nldhk', 3],
  ['nldhe', 3],
  ['nldko', 3],
  ['nldrp', 3],
  ['nldso', 3],
  ['nldst', 3],
  ['nldel', 3],
  ['nldft', 3],
  ['nldzl', 3],
  ['nldmk', 3],
  ['nldbm', 3],
  ['nldhr', 3],
  ['nldho', 3],
  ['nldev', 3],
  ['nldvb', 3],
  ['nldwa', 3],
  ['nldoe', 3],
  ['nldoi', 3],
  ['nldok', 1],
  ['nldbr', 3],
  ['nldon', 3],
  ['nldsi', 3],
  ['nldor', 3],
  ['nldra', 6],
  ['nlnd5', 1],
  ['nldum', 3],
  ['nldrl', 3],
  ['nldsm', 3],
  ['nldro', 3],
  ['nldvt', 3],
  ['nldug', 3],
  ['nlecd', 1],
  ['nlehu', 3],
  ['nledm', 3],
  ['nlefe', 3],
  ['nleem', 3],
  ['nlewo', 3],
  ['nlekk', 3],
  ['nlelb', 3],
  ['nlelx', 3],
  ['nlqcu', 3],
  ['nlepl', 3],
  ['nlenk', 3],
  ['nlera', 3],
  ['nleur', 3],
  ['nlank', 1],
  ['nlewk', 3],
  ['nlexz', 3],
  ['nlfar', 3],
  ['nlfja', 3],
  ['nlfox', 3],
  ['nlfkp', 3],
  ['nlgkn', 3],
  ['nlgwo', 3],
  ['nlgss', 1],
  ['nlgwu', 3],
  ['nlgdm', 3],
  ['nlget', 3],
  ['nlgll', 3],
  ['nlgho', 3],
  ['nlgdr', 1],
  ['nlgoe', 3],
  ['nlgoo', 3],
  ['nlgor', 3],
  ['nlgou', 3],
  ['nlgun', 3],
  ['nlgbg', 3],
  ['nlgrk', 3],
  ['nlgrw', 3],
  ['nlgwd', 3],
  ['nlgdo', 3],
  ['nlhfn', 3],
  ['nlhbk', 3],
  ['nlhaa', 3],
  ['nlhlr', 3],
  ['nlhae', 3],
  ['nlhst', 3],
  ['nlhan', 3],
  ['nlhbg', 3],
  ['nlhrd', 3],
  ['nlhgs', 3],
  ['nlhxg', 3],
  ['nlhre', 3],
  ['nlhar', 3],
  ['nlhsk', 1],
  ['nlhtm', 3],
  ['nlhve', 3],
  ['nlhzk', 3],
  ['nlhdl', 3],
  ['nlhee', 3],
  ['nlhms', 3],
  ['nlhrv', 3],
  ['nlhrw', 3],
  ['nlhsw', 3],
  ['nlhej', 3],
  ['nlhlo', 3],
  ['nlhko', 3],
  ['nlhsl', 1],
  ['nlhlm', 3],
  ['nlhpe', 3],
  ['nlhgl', 3],
  ['nlhnz', 1],
  ['nlhtr', 3],
  ['nlhuk', 1],
  ['nlhum', 3],
  ['nlhkm', 3],
  ['nlhil', 3],
  ['nlhvk', 3],
  ['nlhvs', 3],
  ['nlhvh', 3],
  ['nlhon', 6],
  ['nlhso', 3],
  ['nlhot', 3],
  ['nlhme', 3],
  ['nlhlw', 3],
  ['nlhtn', 1],
  ['nlhpl', 3],
  ['nlhmi', 3],
  ['nlhkk', 3],
  ['nlhgm', 3],
  ['nlhrn', 3],
  ['nlhpt', 3],
  ['nlhou', 3],
  ['nlhun', 3],
  ['nlijm', 3],
  ['nlism', 3],
  ['nlsl7', 1],
  ['nlilo', 3],
  ['nlitr', 3],
  ['nlkko', 3],
  ['nlkam', 3],
  ['nlktn', 3],
  ['nlktv', 3],
  ['nlkat', 3],
  ['nlkwr', 3],
  ['nlknt', 3],
  ['nlkzv', 3],
  ['nlkpe', 3],
  ['nlkrd', 3],
  ['nlksl', 3],
  ['nlkst', 3],
  ['nlkhv', 3],
  ['nlklz', 3],
  ['nlklv', 3],
  ['nlklu', 1],
  ['nlkhr', 3],
  ['nlklm', 3],
  ['nlijk', 3],
  ['nlkms', 3],
  ['nlkre', 3],
  ['nlkai', 3],
  ['nlkho', 3],
  ['nllts', 3],
  ['nllaw', 3],
  ['nllza', 3],
  ['nllgd', 3],
  ['nllwe', 3],
  ['nllgb', 3],
  ['nllgi', 3],
  ['nllwg', 3],
  ['nllan', 1],
  ['nlles', 3],
  ['nllrd', 3],
  ['nlldd', 3],
  ['nllru', 3],
  ['nllem', 1],
  ['nlleo', 3],
  ['nltte', 1],
  ['nllie', 1],
  ['nllen', 1],
  ['nllij', 3],
  ['nllme', 3],
  ['nllwo', 3],
  ['nlqdg', 3],
  ['nllit', 1],
  ['nllto', 3],
  ['nllav', 3],
  ['nllno', 3],
  ['nlmhu', 3],
  ['nlmsb', 1],
  ['nlmsl', 3],
  ['nlmsv', 3],
  ['nlmak', 3],
  ['nlmja', 3],
  ['nlmar', 3],
  ['nlmkn', 3],
  ['nlmau', 3],
  ['nlmew', 1],
  ['nlmnu', 3],
  ['nlmsw', 3],
  ['nlmep', 3],
  ['nlmeh', 3],
  ['nlge7', 1],
  ['nlmla', 3],
  ['nlmid', 3],
  ['nlmih', 3],
  ['nlmdu', 3],
  ['nlmiz', 4],
  ['nlmdi', 1],
  ['nlmwd', 1],
  ['nlmie', 3],
  ['nlmnh', 1],
  ['nlmdt', 3],
  ['nlmlr', 3],
  ['nlmoe', 3],
  ['nlmgt', 3],
  ['nlmol', 3],
  ['nlmok', 3],
  ['nlmud', 3],
  ['nlmdg', 3],
  ['nlmke', 3],
  ['nlnaw', 3],
  ['nlnec', 3],
  ['nlnst', 3],
  ['nlndb', 3],
  ['nlntj', 1],
  ['nlnes', 3],
  ['nlnsa', 3],
  ['nlnsu', 3],
  ['nlnam', 3],
  ['nlnsd', 3],
  ['nlniu', 3],
  ['nlnbu', 3],
  ['nlnsz', 3],
  ['nlnwg', 3],
  ['nlnbg', 3],
  ['nlnie', 3],
  ['nln8w', 1],
  ['nlnwo', 3],
  ['nlngv', 3],
  ['nlnzj', 3],
  ['nlnkk', 3],
  ['nlnij', 3],
  ['nlnz8', 1],
  ['nlnwj', 1],
  ['nlndl', 1],
  ['nlnrg', 3],
  ['nlodl', 1],
  ['nloge', 3],
  ['nloih', 3],
  ['nlogp', 3],
  ['nloos', 3],
  ['nlols', 3],
  ['nlome', 3],
  ['nlohm', 3],
  ['nlohz', 3],
  ['nlomh', 3],
  ['nloss', 3],
  ['nlozl', 3],
  ['nlorl', 3],
  ['nlogn', 3],
  ['nla2z', 1],
  ['nlobl', 3],
  ['nlosg', 3],
  ['nlozu', 3],
  ['nlohw', 3],
  ['nloga', 3],
  ['nlorj', 3],
  ['nlodr', 3],
  ['nlovg', 1],
  ['nlosk', 3],
  ['nlphl', 3],
  ['nlpap', 3],
  ['nlper', 3],
  ['nlpzi', 3],
  ['nlptg', 3],
  ['nlpum', 3],
  ['nlqds', 3],
  ['nlrpl', 3],
  ['nlras', 3],
  ['nlrew', 1],
  ['nlrnk', 3],
  ['nlrhd', 3],
  ['nlrid', 3],
  ['nlred', 3],
  ['nlrsw', 1],
  ['nlrbg', 3],
  ['nlrij', 3],
  ['nlrsh', 3],
  ['nlrys', 3],
  ['nlrla', 3],
  ['nlxxx', 1],
  ['nlrbo', 3],
  ['nlroc', 3],
  ['nlomd', 3],
  ['nlrsl', 3],
  ['nlrst', 3],
  ['nlroz', 1],
  ['nlrtg', 3],
  ['nlrut', 3],
  ['nlsab', 1],
  ['nlsvg', 3],
  ['nlsch', 3],
  ['nlsbu', 3],
  ['nlskw', 3],
  ['nlsrd', 3],
  ['nlsmd', 3],
  ['nlsmz', 3],
  ['nlsho', 3],
  ['nlze5', 1],
  ['nlsce', 3],
  ['nlsci', 3],
  ['nlsmo', 3],
  ['nlsnd', 3],
  ['nlsdw', 3],
  ['nlshh', 3],
  ['nlsrl', 1],
  ['nlszl', 3],
  ['nlswl', 3],
  ['nlsbs', 3],
  ['nlsar', 3],
  ['nlsmv', 3],
  ['nlsmg', 3],
  ['nlanc', 3],
  ['nlsnn', 1],
  ['nlswk', 3],
  ['nlsld', 3],
  ['nlskg', 3],
  ['nlskm', 3],
  ['nlslo', 3],
  ['nlslt', 3],
  ['nlslu', 3],
  ['nlsml', 1],
  ['nlsmi', 3],
  ['nlson', 1],
  ['nlspi', 3],
  ['nlsca', 3],
  ['nlsat', 3],
  ['nlsdm', 3],
  ['nlsdb', 3],
  ['nlsvn', 3],
  ['nlsdn', 3],
  ['nlstd', 3],
  ['nlskl', 3],
  ['nlsop', 3],
  ['nlspv', 3],
  ['nlspt', 3],
  ['nlsre', 3],
  ['nlsrt', 1],
  ['nlthd', 1],
  ['nltro', 3],
  ['nltmz', 3],
  ['nltnz', 3],
  ['nltsl', 3],
  ['nltge', 6],
  ['nltex', 5],
  ['nltho', 3],
  ['nltlb', 3],
  ['nltkw', 3],
  ['nltkm', 3],
  ['nltok', 3],
  ['nltor', 3],
  ['nltul', 3],
  ['nlufe', 3],
  ['nlutr', 3],
  ['nlutg', 3],
  ['nlulr', 1],
  ['nlurk', 3],
  ['nlumo', 3],
  ['nlnpz', 1],
  ['nlutc', 6],
  ['nlvks', 3],
  ['nlvar', 3],
  ['nlnev', 3],
  ['nlvdm', 3],
  ['nlvno', 3],
  ['nlver', 1],
  ['nlvel', 1],
  ['nlvsn', 3],
  ['nlvan', 3],
  ['nlvie', 1],
  ['nlvvl', 3],
  ['nlvla', 3],
  ['nlvwd', 3],
  ['nlvll', 1],
  ['nlvli', 3],
  ['nlvgb', 3],
  ['nlvkr', 3],
  ['nlvob', 3],
  ['nlvos', 3],
  ['nlvdn', 1],
  ['nlvra', 3],
  ['nlwaa', 1],
  ['nlwlk', 3],
  ['nlwde', 3],
  ['nlwad', 3],
  ['nlwgw', 3],
  ['nlwso', 3],
  ['nlwas', 3],
  ['nlwhu', 3],
  ['nlwrm', 3],
  ['nlwtn', 3],
  ['nlwhz', 3],
  ['nlwks', 3],
  ['nlwrt', 3],
  ['nlwsp', 3],
  ['nlwho', 3],
  ['nlwel', 3],
  ['nlwrp', 3],
  ['nlwkd', 1],
  ['nlwgd', 3],
  ['nlwte', 1],
  ['nlwbo', 3],
  ['nlwbk', 3],
  ['nlwkp', 3],
  ['nlwtz', 3],
  ['nlweu', 3],
  ['nlwrg', 3],
  ['nlwgr', 3],
  ['nlwiw', 3],
  ['nlwbd', 3],
  ['nlwig', 1],
  ['nlwhd', 3],
  ['nlwns', 3],
  ['nlwiu', 1],
  ['nlwoe', 6],
  ['nlwor', 3],
  ['nlwkg', 3],
  ['nlmkm', 1],
  ['nlwku', 3],
  ['nlwmo', 3],
  ['nlwcm', 3],
  ['nlysk', 3],
  ['nlzaa', 3],
  ['nlzpo', 3],
  ['nlzew', 3],
  ['nlzvg', 3],
  ['nlzie', 1],
  ['nlzps', 3],
  ['nlzou', 3],
  ['nlzld', 3],
  ['nlzbo', 3],
  ['nlzei', 3],
  ['nlzul', 3],
  ['nlzdl', 3],
  ['nlzut', 3],
  ['nlzho', 3],
  ['nlzws', 3],
  ['nlzwi', 3],
  ['nlzwn', 3],
  ['noabe', 3],
  ['noabv', 1],
  ['noagd', 1],
  ['noaft', 3],
  ['noakk', 1],
  ['noals', 1],
  ['noalv', 1],
  ['noady', 1],
  ['noafs', 1],
  ['noans', 1],
  ['noare', 3],
  ['noarf', 1],
  ['noarm', 1],
  ['noasl', 1],
  ['noasy', 1],
  ['noatl', 1],
  ['noauk', 1],
  ['noaue', 1],
  ['noaul', 1],
  ['noasv', 1],
  ['noash', 1],
  ['noaus', 1],
  ['noava', 3],
  ['noave', 1],
  ['nobls', 1],
  ['nobll', 1],
  ['nobsd', 1],
  ['nobaf', 1],
  ['nobam', 1],
  ['nobdu', 4],
  ['nobej', 1],
  ['nobel', 1],
  ['nober', 1],
  ['nobgn', 1],
  ['nobfj', 1],
  ['nobvg', 5],
  ['nobin', 1],
  ['nobis', 3],
  ['nobjy', 1],
  ['nobkv', 3],
  ['nobjd', 1],
  ['nobju', 1],
  ['nobjx', 3],
  ['noblk', 1],
  ['noblo', 1],
  ['noblv', 1],
  ['nobog', 3],
  ['nobon', 1],
  ['nooyb', 1],
  ['nobok', 1],
  ['nobos', 3],
  ['nobrr', 1],
  ['nosbt', 1],
  ['nobtn', 1],
  ['nobth', 3],
  ['nobkd', 3],
  ['nobrn', 1],
  ['nobsf', 1],
  ['nobrv', 3],
  ['nobvr', 1],
  ['nobvb', 3],
  ['nobks', 1],
  ['nobre', 1],
  ['nobrh', 1],
  ['nobts', 1],
  ['nobvk', 3],
  ['nobro', 1],
  ['nobug', 3],
  ['nobuf', 1],
  ['nobuv', 3],
  ['nobyn', 1],
  ['nobys', 1],
  ['nobyr', 1],
  ['nobal', 1],
  ['nobar', 3],
  ['nobom', 1],
  ['noboe', 1],
  ['nobov', 3],
  ['nodsf', 3],
  ['nodim', 3],
  ['nodir', 1],
  ['nodju', 3],
  ['nodol', 1],
  ['nodrg', 1],
  ['nodrm', 3],
  ['nodrk', 3],
  ['nodus', 1],
  ['nodyd', 3],
  ['nodyr', 3],
  ['nodyy', 1],
  ['nodyl', 1],
  ['nodfj', 1],
  ['nodon', 1],
  ['noege', 3],
  ['noegg', 3],
  ['noeid', 1],
  ['noeif', 1],
  ['noede', 1],
  ['noeds', 1],
  ['noedt', 1],
  ['noedf', 1],
  ['noedd', 1],
  ['noeil', 3],
  ['noegd', 1],
  ['noekf', 1],
  ['noeik', 3],
  ['noelo', 3],
  ['noevv', 3],
  ['noell', 3],
  ['noeln', 3],
  ['noeng', 3],
  ['noerf', 1],
  ['noddy', 1],
  ['noesp', 3],
  ['noetn', 1],
  ['noeve', 6],
  ['noevt', 1],
  ['noeyd', 3],
  ['nofgn', 6],
  ['nofax', 3],
  ['nofau', 3],
  ['nofed', 1],
  ['nofsv', 1],
  ['nofnd', 1],
  ['nofin', 1],
  ['noffb', 3],
  ['nofne', 3],
  ['nofny', 1],
  ['nofst', 1],
  ['nofbl', 1],
  ['nofsk', 1],
  ['nofit', 1],
  ['nofja', 1],
  ['nofje', 1],
  ['nofjl', 3],
  ['nofsd', 3],
  ['nofys', 1],
  ['nofks', 1],
  ['noflt', 1],
  ['nofly', 1],
  ['noffd', 3],
  ['noflm', 1],
  ['noflr', 1],
  ['noflo', 3],
  ['nofla', 3],
  ['nofol', 3],
  ['nofnn', 1],
  ['nofns', 1],
  ['nofor', 1],
  ['nofsl', 1],
  ['nofrs', 3],
  ['nofon', 1],
  ['nofos', 3],
  ['nofsn', 1],
  ['nofoi', 1],
  ['noolt', 3],
  ['nofrk', 3],
  ['nofrj', 1],
  ['nofkg', 3],
  ['nofoa', 1],
  ['nofrn', 1],
  ['nofrt', 1],
  ['nofre', 1],
  ['nofra', 1],
  ['nofus', 1],
  ['nogam', 1],
  ['nogen', 6],
  ['nogau', 1],
  ['nogpn', 3],
  ['nogil', 1],
  ['nogis', 1],
  ['nogik', 1],
  ['nogjm', 1],
  ['norom', 3],
  ['nogjs', 1],
  ['nogjv', 1],
  ['nogjd', 1],
  ['nonzl', 3],
  ['noglo', 3],
  ['noglp', 1],
  ['nogoo', 1],
  ['nogll', 6],
  ['nogrv', 1],
  ['nogrt', 1],
  ['nogrd', 1],
  ['nogtd', 3],
  ['nogrp', 1],
  ['nogrf', 1],
  ['nogry', 1],
  ['nogrs', 1],
  ['nogot', 1],
  ['nogud', 1],
  ['nogul', 1],
  ['noghv', 1],
  ['nogur', 3],
  ['nogut', 1],
  ['nohad', 1],
  ['nohal', 3],
  ['nohls', 1],
  ['nohsa', 1],
  ['nohaz', 1],
  ['nohan', 3],
  ['nohmy', 1],
  ['nonia', 1],
  ['nohfl', 1],
  ['nohbt', 1],
  ['nohae', 1],
  ['nohot', 1],
  ['nohar', 1],
  ['nohri', 3],
  ['nohrd', 3],
  ['nohrs', 3],
  ['nohvk', 1],
  ['nohsv', 1],
  ['nohav', 1],
  ['nohgr', 1],
  ['nohek', 1],
  ['nohle', 1],
  ['nohkr', 1],
  ['nohsy', 3],
  ['nohev', 1],
  ['nohel', 3],
  ['nohme', 1],
  ['nohms', 1],
  ['nohne', 1],
  ['nohen', 1],
  ['nohef', 1],
  ['noher', 3],
  ['nohrr', 1],
  ['nohey', 1],
  ['nohro', 3],
  ['nohry', 3],
  ['nohet', 1],
  ['nohes', 1],
  ['nohit', 1],
  ['nohjl', 3],
  ['nohjj', 1],
  ['nohjo', 3],
  ['nohof', 1],
  ['nohoa', 1],
  ['nohol', 3],
  ['nohok', 3],
  ['nohrv', 3],
  ['nohoo', 1],
  ['nohor', 3],
  ['nohsl', 1],
  ['nohha', 1],
  ['nohmv', 1],
  ['nohud', 1],
  ['nohur', 1],
  ['nohus', 3],
  ['nohso', 1],
  ['nohss', 1],
  ['nohoy', 3],
  ['nohva', 1],
  ['nohvs', 1],
  ['nohyl', 1],
  ['nohaa', 1],
  ['nohem', 1],
  ['nohvi', 3],
  ['nohog', 3],
  ['nohll', 3],
  ['nohyr', 3],
  ['nohlb', 3],
  ['noibe', 1],
  ['noige', 1],
  ['noigl', 1],
  ['noikr', 3],
  ['noind', 1],
  ['noisy', 1],
  ['noidr', 1],
  ['noinn', 1],
  ['noinv', 3],
  ['nojan', 1],
  ['nojoy', 1],
  ['nojel', 3],
  ['nojon', 1],
  ['nojos', 1],
  ['nojov', 1],
  ['nokab', 1],
  ['nokdf', 1],
  ['nokan', 1],
  ['nokvg', 1],
  ['nokmo', 3],
  ['nokay', 1],
  ['nokmy', 3],
  ['nokpg', 3],
  ['noktk', 6],
  ['nokil', 1],
  ['nokiv', 3],
  ['nokir', 1],
  ['nokje', 1],
  ['nokjf', 3],
  ['nokms', 1],
  ['nokjk', 3],
  ['nokjb', 1],
  ['nokle', 3],
  ['nokrv', 1],
  ['nokbv', 1],
  ['nokon', 1],
  ['nokol', 1],
  ['nokld', 1],
  ['nokgf', 1],
  ['nokop', 3],
  ['nokra', 3],
  ['nokrs', 3],
  ['nokvl', 1],
  ['nokuv', 1],
  ['nokvs', 1],
  ['nokvv', 1],
  ['nokvm', 1],
  ['nokdl', 3],
  ['nokvh', 1],
  ['nokia', 1],
  ['noksy', 1],
  ['nokvf', 1],
  ['nokvr', 1],
  ['nokvn', 1],
  ['nokyr', 3],
  ['nokaf', 1],
  ['nokmn', 1],
  ['nokar', 1],
  ['nokah', 1],
  ['nolkl', 4],
  ['nolvg', 3],
  ['nolad', 3],
  ['nolag', 1],
  ['nolan', 3],
  ['nolgs', 1],
  ['nolao', 1],
  ['nolrk', 3],
  ['nonzc', 1],
  ['nolar', 3],
  ['nolvk', 1],
  ['nolav', 1],
  ['noleb', 1],
  ['nolek', 1],
  ['nolne', 1],
  ['nolef', 1],
  ['nolep', 3],
  ['nolri', 3],
  ['nolea', 1],
  ['nolka', 1],
  ['nolkv', 1],
  ['nolas', 1],
  ['nolay', 1],
  ['noles', 1],
  ['nolre', 1],
  ['nolev', 3],
  ['nolia', 1],
  ['nolie', 3],
  ['nolid', 1],
  ['nolil', 3],
  ['nolds', 3],
  ['nolin', 1],
  ['nolff', 1],
  ['nolit', 1],
  ['noloe', 3],
  ['nolon', 1],
  ['nolop', 1],
  ['nolov', 3],
  ['nolra', 3],
  ['nolur', 1],
  ['nolus', 1],
  ['nolul', 1],
  ['nolyn', 1],
  ['nolyd', 1],
  ['nolys', 3],
  ['nolyf', 3],
  ['nolsu', 1],
  ['nolrd', 1],
  ['nolod', 3],
  ['nomlm', 3],
  ['nomak', 1],
  ['noman', 3],
  ['nomgr', 1],
  ['nomsf', 1],
  ['nomat', 1],
  ['nomah', 1],
  ['nomau', 1],
  ['nomld', 1],
  ['nomel', 3],
  ['nomls', 1],
  ['nomlk', 1],
  ['nomev', 3],
  ['nomey', 1],
  ['nomen', 1],
  ['nomid', 1],
  ['nomit', 1],
  ['nomis', 1],
  ['nomvv', 1],
  ['nomsd', 1],
  ['nomod', 1],
  ['nomlt', 3],
  ['nomon', 3],
  ['nomob', 1],
  ['nomsk', 1],
  ['nomss', 3],
  ['nomos', 1],
  ['nomsv', 1],
  ['nomrv', 3],
  ['nomyo', 3],
  ['nomav', 1],
  ['nomay', 3],
  ['nomsy', 1],
  ['nomov', 1],
  ['nomor', 1],
  ['nodsn', 1],
  ['nonau', 1],
  ['nonsn', 1],
  ['noneo', 1],
  ['nonsb', 1],
  ['nonst', 1],
  ['nonev', 1],
  ['nodby', 1],
  ['nonod', 1],
  ['nonfd', 3],
  ['nonfo', 1],
  ['nonok', 1],
  ['nonkb', 3],
  ['nonol', 1],
  ['nonom', 1],
  ['nonor', 1],
  ['nonsf', 1],
  ['nonhs', 3],
  ['nontb', 6],
  ['noznj', 1],
  ['nonye', 1],
  ['nonyh', 1],
  ['nonry', 1],
  ['nonty', 1],
  ['noodd', 3],
  ['nooks', 1],
  ['noold', 1],
  ['nooll', 1],
  ['noolr', 1],
  ['nooma', 1],
  ['noomb', 1],
  ['noopg', 1],
  ['noork', 3],
  ['noord', 3],
  ['noorv', 3],
  ['nooos', 3],
  ['noosn', 1],
  ['noose', 1],
  ['noost', 1],
  ['not8n', 1],
  ['nooto', 1],
  ['noota', 1],
  ['nopar', 1],
  ['nopor', 3],
  ['noray', 1],
  ['noraf', 1],
  ['normb', 1],
  ['noram', 3],
  ['normv', 1],
  ['norna', 1],
  ['nordb', 1],
  ['norbv', 1],
  ['norsd', 3],
  ['norau', 1],
  ['nores', 1],
  ['noree', 1],
  ['norfj', 1],
  ['norek', 3],
  ['noren', 1],
  ['norep', 1],
  ['norpv', 3],
  ['noriv', 1],
  ['norss', 3],
  ['noris', 3],
  ['norsh', 3],
  ['noroa', 1],
  ['norog', 3],
  ['norov', 1],
  ['norub', 3],
  ['norun', 1],
  ['nortl', 1],
  ['noryg', 4],
  ['noryf', 3],
  ['norad', 1],
  ['nordy', 1],
  ['norrs', 4],
  ['noret', 5],
  ['nosal', 1],
  ['nosac', 1],
  ['nosbu', 1],
  ['nosdl', 1],
  ['nosat', 3],
  ['noslh', 1],
  ['nosam', 1],
  ['nosax', 1],
  ['nosaf', 1],
  ['nosas', 3],
  ['nosdi', 1],
  ['nosai', 1],
  ['nosay', 1],
  ['noann', 3],
  ['nospg', 3],
  ['nosau', 3],
  ['nosje', 1],
  ['nosej', 1],
  ['nosev', 1],
  ['nosjh', 3],
  ['nosrd', 3],
  ['nosih', 1],
  ['nosrv', 3],
  ['nosss', 1],
  ['nosju', 3],
  ['nosjo', 3],
  ['nosjv', 1],
  ['nosaa', 1],
  ['nosrh', 1],
  ['noskz', 1],
  ['nosan', 1],
  ['noskb', 3],
  ['noskf', 1],
  ['nospk', 1],
  ['nosip', 1],
  ['noski', 1],
  ['nosky', 1],
  ['nosln', 3],
  ['noskh', 1],
  ['nosko', 1],
  ['noskx', 3],
  ['nosgt', 1],
  ['nosrk', 1],
  ['nosro', 3],
  ['nosku', 3],
  ['noskv', 3],
  ['nosei', 1],
  ['noskl', 1],
  ['noslg', 3],
  ['noslm', 3],
  ['noslv', 1],
  ['nosmo', 1],
  ['noroy', 1],
  ['nosnf', 1],
  ['nosnv', 1],
  ['nosni', 1],
  ['nosog', 5],
  ['nosok', 1],
  ['nosla', 6],
  ['nosld', 1],
  ['noslx', 3],
  ['nosrx', 3],
  ['noder', 3],
  ['nospv', 3],
  ['nosdt', 1],
  ['nosak', 1],
  ['nosuz', 3],
  ['nosnl', 1],
  ['nosht', 1],
  ['nosta', 3],
  ['nostv', 3],
  ['nosvt', 1],
  ['nostg', 1],
  ['nosns', 1],
  ['noste', 3],
  ['nosth', 3],
  ['nosti', 1],
  ['nosty', 1],
  ['nostk', 1],
  ['noskk', 3],
  ['nosto', 1],
  ['nosrp', 5],
  ['nosba', 1],
  ['nosdz', 3],
  ['nostb', 1],
  ['nosrs', 1],
  ['nostf', 1],
  ['nosoy', 1],
  ['nostd', 1],
  ['nosrn', 3],
  ['nostz', 1],
  ['noume', 1],
  ['nosgj', 3],
  ['nosso', 1],
  ['nosrm', 3],
  ['nostr', 3],
  ['nostu', 1],
  ['nostx', 1],
  ['nosua', 1],
  ['nosul', 1],
  ['nosnd', 1],
  ['nound', 1],
  ['nosis', 3],
  ['nosun', 3],
  ['nosur', 3],
  ['nosus', 1],
  ['nosvb', 1],
  ['nosvo', 1],
  ['nosve', 3],
  ['nosvv', 3],
  ['nosos', 1],
  ['nosyk', 3],
  ['nosid', 1],
  ['nosvl', 1],
  ['nosov', 3],
  ['nosne', 1],
  ['nosmn', 1],
  ['nosor', 1],
  ['nosof', 1],
  ['nosra', 1],
  ['nosvr', 3],
  ['notaa', 1],
  ['notae', 3],
  ['notau', 3],
  ['notoy', 3],
  ['notin', 1],
  ['notbo', 1],
  ['notjs', 1],
  ['notjo', 1],
  ['notjr', 3],
  ['notjl', 1],
  ['notof', 3],
  ['notom', 3],
  ['notnn', 1],
  ['notav', 1],
  ['notju', 1],
  ['notor', 1],
  ['notva', 1],
  ['notvi', 1],
  ['notov', 3],
  ['notry', 1],
  ['notro', 3],
  ['nonzy', 3],
  ['notmv', 3],
  ['notrn', 1],
  ['notuf', 1],
  ['notus', 1],
  ['notve', 3],
  ['notyf', 1],
  ['notyn', 1],
  ['notsb', 1],
  ['notsx', 3],
  ['notyv', 1],
  ['notyt', 1],
  ['notal', 1],
  ['noton', 3],
  ['noull', 1],
  ['noule', 1],
  ['nouls', 3],
  ['nouvk', 1],
  ['noubo', 1],
  ['nousd', 3],
  ['noutg', 1],
  ['nouth', 3],
  ['noutn', 3],
  ['nouts', 1],
  ['noutv', 1],
  ['novhm', 1],
  ['novak', 3],
  ['novbe', 1],
  ['novag', 3],
  ['novad', 1],
  ['novls', 1],
  ['noval', 1],
  ['novae', 1],
  ['novnn', 1],
  ['novan', 3],
  ['novay', 1],
  ['novtl', 3],
  ['novts', 1],
  ['novmd', 3],
  ['novef', 1],
  ['noveg', 1],
  ['noveo', 1],
  ['nover', 3],
  ['novrr', 1],
  ['novst', 3],
  ['novvy', 1],
  ['novev', 1],
  ['novis', 3],
  ['noika', 1],
  ['novsm', 3],
  ['novil', 1],
  ['novhn', 1],
  ['novkk', 1],
  ['novkn', 1],
  ['novif', 1],
  ['novns', 1],
  ['novnj', 1],
  ['novsn', 1],
  ['novdl', 1],
  ['novda', 3],
  ['novll', 3],
  ['nosag', 3],
  ['novoh', 1],
  ['novgn', 1],
  ['novvg', 3],
  ['novgy', 1],
  ['novey', 1],
  ['noyto', 1],
  ['noaaa', 1],
  ['noaaf', 1],
  ['noago', 3],
  ['noagk', 1],
  ['noahm', 1],
  ['noakm', 1],
  ['noale', 1],
  ['noaav', 3],
  ['noand', 3],
  ['noaan', 3],
  ['noarh', 1],
  ['noard', 3],
  ['noata', 1],
  ['nookf', 3],
  ['nooln', 1],
  ['noolv', 1],
  ['noola', 1],
  ['nosvi', 3],
  ['noosg', 1],
  ['noors', 1],
  ['nooyg', 1],
  ['npbgl', 4],
  ['npbit', 4],
  ['npbjh', 4],
  ['npbju', 4],
  ['npbdp', 4],
  ['npbwa', 4],
  ['npbhr', 4],
  ['npbhp', 4],
  ['npbir', 6],
  ['nphrj', 4],
  ['npdnp', 4],
  ['npdap', 4],
  ['npdhi', 4],
  ['npdop', 4],
  ['npgkh', 4],
  ['npjkr', 4],
  ['npjir', 4],
  ['npjmo', 4],
  ['npjum', 4],
  ['npktm', 6],
  ['npldn', 4],
  ['npltg', 4],
  ['nplua', 4],
  ['npxmg', 4],
  ['npngx', 4],
  ['npmey', 4],
  ['npmwp', 4],
  ['npllu', 1],
  ['npkep', 4],
  ['npppl', 4],
  ['nppkr', 4],
  ['nprjb', 4],
  ['nprhp', 4],
  ['nprpa', 4],
  ['npruk', 4],
  ['nprum', 4],
  ['npfeb', 4],
  ['npsih', 4],
  ['npsif', 4],
  ['npimk', 4],
  ['npskh', 4],
  ['npsyh', 4],
  ['nptpj', 4],
  ['nptpu', 4],
  ['nptmi', 4],
  ['nrinu', 5],
  ['nualo', 1],
  ['nuiue', 5],
  ['nzaka', 1],
  ['nzalr', 4],
  ['nzamz', 4],
  ['nzasg', 6],
  ['nzbhe', 6],
  ['nzblu', 3],
  ['nzbuw', 1],
  ['nzcht', 4],
  ['nzcsa', 3],
  ['nzchc', 6],
  ['nzcmv', 4],
  ['nzdgr', 6],
  ['nzdpc', 1],
  ['nzdev', 1],
  ['nzdud', 6],
  ['nzfgl', 4],
  ['nzwho', 4],
  ['nzgle', 1],
  ['nzgbz', 4],
  ['nzhlz', 6],
  ['nzhkk', 6],
  ['nzivc', 6],
  ['nzkko', 4],
  ['nzkat', 4],
  ['nzkau', 3],
  ['nzkui', 4],
  ['nzkke', 4],
  ['nzlyt', 3],
  ['nzkwu', 4],
  ['nzmap', 3],
  ['nzmro', 4],
  ['nzmta', 6],
  ['nzmab', 1],
  ['nzmfn', 3],
  ['nzmzp', 6],
  ['nzmon', 4],
  ['nznpe', 5],
  ['nzoam', 6],
  ['nzoha', 4],
  ['nzone', 1],
  ['nzopx', 1],
  ['nzorr', 1],
  ['nzott', 1],
  ['nzpkl', 4],
  ['nzpmr', 4],
  ['nzppq', 4],
  ['nzpcn', 5],
  ['nzpoe', 1],
  ['nzgbs', 4],
  ['nzpon', 1],
  ['nzzqn', 4],
  ['nzrag', 6],
  ['nzrav', 3],
  ['nzolt', 1],
  ['nzrot', 6],
  ['nzrus', 3],
  ['nzsea', 3],
  ['nzshb', 3],
  ['nzszs', 4],
  ['nzwik', 4],
  ['nzktf', 6],
  ['nztak', 3],
  ['nzhmb', 1],
  ['nztkh', 1],
  ['nztuo', 4],
  ['nzteu', 4],
  ['nztmz', 6],
  ['nztwi', 3],
  ['nztkz', 4],
  ['nztry', 1],
  ['nzwke', 3],
  ['nzwir', 6],
  ['nzwtg', 1],
  ['nzwka', 4],
  ['nzwhk', 4],
  ['nzwhn', 1],
  ['nzwhr', 3],
  ['nzwnp', 4],
  ['nzwtz', 4],
  ['omstq', 3],
  ['omsuw', 1],
  ['omrmb', 4],
  ['ombyb', 4],
  ['omdqm', 3],
  ['omfah', 1],
  ['omkhs', 4],
  ['omomm', 4],
  ['ommsh', 4],
  ['ommfh', 1],
  ['ommct', 5],
  ['ommut', 1],
  ['omopq', 1],
  ['omsul', 1],
  ['omqal', 3],
  ['omquo', 1],
  ['omray', 1],
  ['omsll', 5],
  ['omshi', 1],
  ['omsoh', 1],
  ['omofc', 1],
  ['omsuh', 4],
  ['omtth', 6],
  ['paacu', 4],
  ['paagd', 1],
  ['paail', 4],
  ['papam', 1],
  ['paaco', 3],
  ['papbm', 1],
  ['pabfq', 4],
  ['pablb', 1],
  ['pabay', 3],
  ['pabcc', 3],
  ['pacde', 4],
  ['pacte', 4],
  ['paczz', 3],
  ['pachx', 6],
  ['pacha', 1],
  ['pachg', 3],
  ['pactd', 5],
  ['paotd', 4],
  ['paczj', 4],
  ['pactb', 1],
  ['padav', 4],
  ['papve', 4],
  ['paele', 5],
  ['pahow', 4],
  ['pagba', 1],
  ['paghe', 4],
  ['pagtn', 1],
  ['pag8l', 1],
  ['paher', 1],
  ['pajqe', 4],
  ['paplp', 5],
  ['pamnp', 1],
  ['pampi', 4],
  ['pamit', 3],
  ['papac', 4],
  ['pamel', 1],
  ['pamfs', 1],
  ['pampp', 4],
  ['pangn', 4],
  ['papai', 1],
  ['papay', 1],
  ['paped', 3],
  ['papes', 3],
  ['papyc', 4],
  ['papbe', 3],
  ['paaml', 5],
  ['papca', 3],
  ['papue', 4],
  ['parit', 4],
  ['parod', 1],
  ['pariz', 4],
  ['pagde', 3],
  ['parsi', 4],
  ['pasax', 4],
  ['panmg', 4],
  ['pascl', 3],
  ['pasfw', 4],
  ['pasyp', 4],
  ['parih', 4],
  ['patbg', 1],
  ['patjc', 4],
  ['patoc', 1],
  ['patuw', 4],
  ['patue', 4],
  ['pautu', 4],
  ['pavac', 3],
  ['papyv', 4],
  ['peald', 4],
  ['peanc', 1],
  ['peans', 4],
  ['peata', 4],
  ['peaqp', 4],
  ['peati', 1],
  ['peayp', 4],
  ['pepub', 1],
  ['peblp', 4],
  ['pecab', 1],
  ['pecja', 4],
  ['pecll', 1],
  ['pechh', 4],
  ['pechy', 1],
  ['pecix', 4],
  ['pechm', 5],
  ['pecon', 3],
  ['pecuz', 6],
  ['peeen', 1],
  ['pegsm', 1],
  ['pehco', 1],
  ['pehuu', 4],
  ['pehuy', 1],
  ['peibp', 4],
  ['peilq', 5],
  ['peiqt', 5],
  ['pejau', 4],
  ['pejji', 4],
  ['pejul', 4],
  ['pelpp', 1],
  ['pelag', 1],
  ['pelim', 5],
  ['pelob', 1],
  ['peloo', 1],
  ['pemft', 4],
  ['pemri', 1],
  ['pemlq', 5],
  ['pembp', 4],
  ['pengs', 1],
  ['pepac', 1],
  ['pepai', 1],
  ['pepmc', 1],
  ['pepam', 1],
  ['pepmt', 1],
  ['pepio', 5],
  ['pepiu', 4],
  ['pepcl', 5],
  ['pepch', 1],
  ['pepem', 5],
  ['pepun', 1],
  ['peplo', 1],
  ['peumi', 4],
  ['perij', 4],
  ['perim', 4],
  ['pesvy', 1],
  ['pebar', 1],
  ['pesja', 5],
  ['peape', 4],
  ['pesnx', 1],
  ['pesmg', 4],
  ['pesqu', 4],
  ['pesyc', 4],
  ['pesup', 1],
  ['petcq', 4],
  ['petyl', 5],
  ['petdm', 1],
  ['petag', 3],
  ['petpp', 4],
  ['petgi', 4],
  ['petru', 5],
  ['petbp', 4],
  ['peyms', 5],
  ['pfahe', 4],
  ['pfahu', 1],
  ['pfaaa', 4],
  ['pfapk', 4],
  ['pfaxr', 4],
  ['pfauq', 4],
  ['pfaut', 1],
  ['pfbob', 5],
  ['pffac', 4],
  ['pffhz', 4],
  ['pffav', 4],
  ['pffgu', 4],
  ['pffat', 1],
  ['pfhoi', 4],
  ['pfheu', 1],
  ['pfhht', 1],
  ['pfhhz', 4],
  ['pfhix', 4],
  ['pfhuh', 4],
  ['pfiki', 1],
  ['pfkkr', 4],
  ['pfmkp', 4],
  ['pfmvt', 4],
  ['pfmau', 4],
  ['pfmoz', 4],
  ['pfmoe', 1],
  ['pfxmh', 4],
  ['pfnau', 4],
  ['pftup', 1],
  ['pfnhv', 4],
  ['pfnuk', 4],
  ['pfotp', 1],
  ['pfppt', 5],
  ['pfpkp', 4],
  ['pfpuk', 4],
  ['pfrfp', 4],
  ['pfrvv', 1],
  ['pfrgi', 4],
  ['pfrea', 4],
  ['pfrur', 4],
  ['pffaa', 4],
  ['pftai', 1],
  ['pftkp', 4],
  ['pftkx', 4],
  ['pftjn', 4],
  ['pftkv', 4],
  ['pftti', 4],
  ['pftih', 4],
  ['pfgmr', 4],
  ['pftub', 4],
  ['pfzta', 4],
  ['pfuah', 4],
  ['pfuap', 4],
  ['pfutu', 1],
  ['pfvhz', 4],
  ['pfvai', 1],
  ['pglor', 1],
  ['pgabw', 5],
  ['pgafr', 4],
  ['pgaup', 4],
  ['pgaho', 1],
  ['pgaia', 1],
  ['pgaie', 4],
  ['pgatp', 4],
  ['pgayu', 4],
  ['pgaki', 1],
  ['pgalc', 1],
  ['pggur', 5],
  ['pgamf', 4],
  ['pgamu', 4],
  ['pgazb', 4],
  ['pgamg', 4],
  ['pgauj', 4],
  ['pgami', 1],
  ['pgadc', 4],
  ['pganb', 1],
  ['pgagg', 4],
  ['pgakg', 4],
  ['pgaob', 4],
  ['pgapr', 4],
  ['pgarp', 4],
  ['pgraw', 4],
  ['pgaoa', 4],
  ['pgaon', 4],
  ['pgapp', 4],
  ['pgaek', 4],
  ['pgasz', 4],
  ['pgabp', 4],
  ['pgaui', 4],
  ['pgauv', 4],
  ['pgawb', 4],
  ['pgawr', 4],
  ['pgbap', 4],
  ['pgvmu', 4],
  ['pgbdz', 4],
  ['pgbaj', 4],
  ['pgopu', 4],
  ['pgbcp', 4],
  ['pgbmz', 4],
  ['pgbnz', 4],
  ['pgbpd', 4],
  ['pgbwj', 4],
  ['pgbsp', 4],
  ['pgbea', 4],
  ['pgbwp', 4],
  ['pgbaa', 4],
  ['pgbpk', 4],
  ['pgbrp', 4],
  ['pgbij', 4],
  ['pgbiz', 4],
  ['pgxbn', 4],
  ['pgbnv', 4],
  ['pgbov', 4],
  ['pgbnm', 4],
  ['pgboi', 1],
  ['pgboq', 4],
  ['pgbvp', 4],
  ['pgbmh', 4],
  ['pgbpb', 4],
  ['pgbor', 1],
  ['pgbot', 4],
  ['pgbrh', 4],
  ['pgubi', 4],
  ['pgbua', 5],
  ['pgbul', 4],
  ['pgbna', 1],
  ['pgbnt', 4],
  ['pgbxz', 4],
  ['pgcgc', 4],
  ['pgcpi', 4],
  ['pgcpn', 4],
  ['pgcvl', 4],
  ['pgcau', 1],
  ['pgcvb', 4],
  ['pgcfg', 1],
  ['pgdao', 4],
  ['pgdlb', 4],
  ['pgdau', 5],
  ['pgdgg', 4],
  ['pgdaf', 4],
  ['pgdbp', 4],
  ['pgdbl', 1],
  ['pgder', 4],
  ['pgdnu', 4],
  ['pgdos', 4],
  ['pgddm', 4],
  ['pgdoi', 4],
  ['pgdoo', 4],
  ['pgdpu', 4],
  ['pgefg', 4],
  ['pgeia', 4],
  ['pgept', 4],
  ['pgems', 4],
  ['pgemi', 4],
  ['pgemo', 4],
  ['pgega', 4],
  ['pgere', 4],
  ['pgeru', 4],
  ['pgesa', 4],
  ['pgfne', 4],
  ['pgfrq', 4],
  ['pgfin', 5],
  ['pgfaq', 4],
  ['pgfub', 5],
  ['pgfum', 4],
  ['pggar', 4],
  ['pggrl', 4],
  ['pggrh', 4],
  ['pggmi', 5],
  ['pggbc', 4],
  ['pggaw', 1],
  ['pggew', 4],
  ['pggil', 1],
  ['pggwn', 4],
  ['pggoe', 4],
  ['pggoc', 4],
  ['pggka', 4],
  ['pggei', 5],
  ['pggvi', 4],
  ['pggug', 4],
  ['pggaz', 4],
  ['pgglp', 4],
  ['pggue', 4],
  ['pggap', 4],
  ['pghbd', 4],
  ['pgheo', 4],
  ['pghaz', 4],
  ['pghwa', 4],
  ['pghyf', 4],
  ['pghni', 4],
  ['pghit', 4],
  ['pghnn', 4],
  ['pghkn', 5],
  ['pgima', 4],
  ['pgiau', 4],
  ['pgibi', 4],
  ['pgihu', 4],
  ['pgilx', 4],
  ['pgimn', 4],
  ['pgimd', 4],
  ['pgidn', 4],
  ['pgius', 4],
  ['pgiok', 4],
  ['pgiop', 4],
  ['pgitk', 6],
  ['pgjaq', 4],
  ['pgjop', 4],
  ['pgkbm', 4],
  ['pgkgw', 4],
  ['pgagk', 4],
  ['pgkia', 4],
  ['pgkai', 1],
  ['pgkzf', 4],
  ['pgkrk', 3],
  ['pgkpp', 1],
  ['pgklo', 1],
  ['pgkdq', 4],
  ['pgkmf', 4],
  ['pgkju', 4],
  ['pgkaq', 4],
  ['pgkuy', 4],
  ['pgkex', 4],
  ['pgkne', 4],
  ['pgkdp', 4],
  ['pgkdr', 4],
  ['pgktk', 4],
  ['pgkpl', 4],
  ['pgkak', 4],
  ['pgkaf', 4],
  ['pgkrj', 4],
  ['pgkmr', 4],
  ['pgkxr', 4],
  ['pgksb', 4],
  ['pgkvg', 5],
  ['pgkwo', 4],
  ['pgkeg', 4],
  ['pgknl', 4],
  ['pgkru', 4],
  ['pgkma', 5],
  ['pgkii', 4],
  ['pgkie', 5],
  ['pgkiz', 4],
  ['pgkri', 4],
  ['pgkim', 1],
  ['pgkiq', 4],
  ['pgksg', 4],
  ['pgkve', 4],
  ['pgung', 5],
  ['pgkwx', 4],
  ['pgkmb', 4],
  ['pgkkd', 4],
  ['pgkor', 4],
  ['pgkql', 4],
  ['pgkcj', 4],
  ['pghoc', 4],
  ['pgkom', 4],
  ['pgkpm', 4],
  ['pgkpf', 4],
  ['pgkgb', 4],
  ['pgkop', 1],
  ['pgkpa', 4],
  ['pgkde', 4],
  ['pgksp', 4],
  ['pgkul', 1],
  ['pgcmu', 4],
  ['pgkgm', 4],
  ['pgkup', 4],
  ['pgkuq', 4],
  ['pgkwv', 4],
  ['pgkux', 4],
  ['pglab', 4],
  ['pglae', 5],
  ['pglgm', 4],
  ['pglmy', 4],
  ['pglak', 1],
  ['pglmg', 4],
  ['pglam', 1],
  ['pglnm', 4],
  ['pglla', 1],
  ['pglhp', 4],
  ['pgltf', 4],
  ['pglnc', 4],
  ['pglpn', 4],
  ['pglng', 4],
  ['pglnv', 5],
  ['pglgn', 4],
  ['pglnq', 4],
  ['pglol', 1],
  ['pglom', 1],
  ['pgldt', 3],
  ['pglsj', 4],
  ['pglsa', 4],
  ['pglwi', 4],
  ['pglmi', 4],
  ['pgmag', 5],
  ['pgmpg', 4],
  ['pgmmv', 4],
  ['pgmlq', 4],
  ['pgmqo', 4],
  ['pgmkn', 4],
  ['pgmap', 4],
  ['pgmam', 1],
  ['pgmrm', 4],
  ['pgman', 6],
  ['pgmvi', 4],
  ['pgmgp', 4],
  ['pgmfo', 4],
  ['pguuu', 4],
  ['pgmas', 4],
  ['pgmpf', 4],
  ['pgmpu', 4],
  ['pgmwi', 4],
  ['pgmwg', 4],
  ['pgmgg', 4],
  ['pgmnp', 4],
  ['pgmbv', 4],
  ['pgmrh', 4],
  ['pgmdu', 4],
  ['pgmyx', 4],
  ['pgmfz', 4],
  ['pgmie', 1],
  ['pgmxk', 4],
  ['pgmzn', 4],
  ['pgmis', 5],
  ['pgmpx', 4],
  ['pgmjj', 4],
  ['pgmhy', 4],
  ['pgmxh', 4],
  ['pgobm', 4],
  ['pgmtk', 1],
  ['pgguv', 4],
  ['pguae', 4],
  ['pghgu', 4],
  ['pglnf', 4],
  ['pgmdm', 4],
  ['pgmwu', 4],
  ['pgndn', 4],
  ['pgatn', 5],
  ['pgnba', 4],
  ['pgndi', 4],
  ['pgnkn', 4],
  ['pgnoo', 4],
  ['pgnap', 1],
  ['pggbf', 4],
  ['pgngr', 4],
  ['pgnpg', 4],
  ['pgiis', 4],
  ['pgnom', 4],
  ['pgnmn', 4],
  ['pgnwt', 4],
  ['pgnug', 4],
  ['pguku', 4],
  ['pgnut', 4],
  ['pgobx', 4],
  ['pgoge', 4],
  ['pgokv', 4],
  ['pgokp', 4],
  ['pgolq', 4],
  ['pgoml', 4],
  ['pgose', 4],
  ['pgonb', 4],
  ['pgopb', 4],
  ['pgrax', 4],
  ['pgoty', 4],
  ['pgror', 1],
  ['pgosg', 4],
  ['pgple', 4],
  ['pgpaw', 4],
  ['pgpgn', 4],
  ['pgpgb', 4],
  ['pgppx', 4],
  ['pgpmp', 4],
  ['pgpdi', 4],
  ['pgpdo', 1],
  ['pgpnp', 4],
  ['pgrge', 4],
  ['pgpom', 5],
  ['pgpua', 4],
  ['pgpmn', 4],
  ['pgpui', 4],
  ['pgrbp', 4],
  ['pgrab', 5],
  ['pgraa', 4],
  ['pgram', 1],
  ['pgrnr', 5],
  ['pgrmn', 4],
  ['pgrvi', 1],
  ['pgruu', 4],
  ['pgsbv', 4],
  ['pgsfu', 4],
  ['pgsgj', 4],
  ['pgsai', 1],
  ['pgsdi', 4],
  ['pgsmu', 1],
  ['pgsam', 4],
  ['pgsqt', 5],
  ['pgsgk', 4],
  ['pgsmh', 4],
  ['pgswg', 4],
  ['pgsxw', 4],
  ['pgsch', 1],
  ['pgsxh', 4],
  ['pgsbc', 4],
  ['pgspv', 4],
  ['pgsxa', 4],
  ['pgsss', 4],
  ['pgsil', 4],
  ['pgswr', 4],
  ['pgsmj', 4],
  ['pgsim', 4],
  ['pgnis', 4],
  ['pgsgb', 4],
  ['pgspl', 1],
  ['pgsiz', 4],
  ['pgswe', 4],
  ['pgsoi', 1],
  ['pgsph', 4],
  ['pgsmp', 4],
  ['pgsbe', 4],
  ['pgskc', 4],
  ['pgule', 4],
  ['pgsuz', 4],
  ['pgtba', 4],
  ['pgtbg', 4],
  ['pgtgl', 5],
  ['pgtlw', 5],
  ['pgtam', 1],
  ['pgtai', 1],
  ['pgtmi', 1],
  ['pgtpi', 4],
  ['pgtbq', 4],
  ['pgtrj', 4],
  ['pgtiz', 4],
  ['pgtsk', 4],
  ['pgtau', 1],
  ['pgtut', 4],
  ['pgtwy', 4],
  ['pgtkb', 4],
  ['pgtkw', 4],
  ['pgtfm', 4],
  ['pgtep', 4],
  ['pgteo', 4],
  ['pgtdb', 6],
  ['pgtfa', 4],
  ['pgtbe', 4],
  ['pgtck', 4],
  ['pgtig', 4],
  ['pgtlo', 4],
  ['pgtoi', 1],
  ['pgton', 4],
  ['pgtcj', 4],
  ['pgtok', 4],
  ['pgtsw', 4],
  ['pgtsi', 4],
  ['pgtfi', 5],
  ['pgtlp', 4],
  ['pgula', 1],
  ['pgumc', 4],
  ['pgupr', 4],
  ['pguru', 4],
  ['pguso', 4],
  ['pguvo', 4],
  ['pgvla', 3],
  ['pgvai', 5],
  ['pgviv', 4],
  ['pgwab', 4],
  ['pgwao', 4],
  ['pgwgu', 4],
  ['pgwkn', 4],
  ['pgagl', 4],
  ['pgwtt', 4],
  ['pgwnu', 4],
  ['pgwbm', 4],
  ['pgwbc', 4],
  ['pgwsu', 4],
  ['pgwsa', 4],
  ['pgwum', 4],
  ['pgwat', 3],
  ['pgwug', 4],
  ['pgwaj', 4],
  ['pgwep', 4],
  ['pgwed', 4],
  ['pgwwk', 5],
  ['pgwpm', 4],
  ['pgwiu', 4],
  ['pgwtp', 4],
  ['pgwoa', 4],
  ['pgwok', 1],
  ['pgwuv', 4],
  ['pgkyx', 4],
  ['pgkpe', 4],
  ['pgksx', 4],
  ['pgpge', 4],
  ['pgxyr', 4],
  ['pgyeq', 4],
  ['pgyen', 1],
  ['pgyvd', 4],
  ['pgkgh', 4],
  ['pgrku', 5],
  ['pgzen', 4],
  ['phapr', 1],
  ['phbcd', 5],
  ['phblc', 3],
  ['phbtn', 1],
  ['phbph', 5],
  ['phboa', 1],
  ['phbrp', 3],
  ['phbul', 1],
  ['phbur', 3],
  ['phcgy', 5],
  ['phcaj', 3],
  ['phcby', 3],
  ['phcyp', 4],
  ['phcug', 3],
  ['phcts', 1],
  ['phcve', 1],
  ['phcbo', 5],
  ['phdvo', 5],
  ['phggg', 3],
  ['phggm', 3],
  ['phhim', 3],
  ['phign', 5],
  ['philo', 5],
  ['phbct', 3],
  ['phjpm', 1],
  ['phlao', 5],
  ['phlpu', 3],
  ['phlin', 1],
  ['phmab', 3],
  ['phmlb', 3],
  ['phmht', 3],
  ['phmvs', 1],
  ['phmxi', 5],
  ['phnag', 6],
  ['phnug', 1],
  ['phozc', 5],
  ['phppp', 3],
  ['phpsl', 3],
  ['phpas', 1],
  ['phpdc', 3],
  ['phpll', 3],
  ['phpps', 5],
  ['phsfe', 5],
  ['phsin', 1],
  ['phsug', 5],
  ['phtac', 5],
  ['phtag', 5],
  ['phvcs', 1],
  ['phabu', 1],
  ['phadl', 1],
  ['phahe', 1],
  ['phaju', 1],
  ['phaav', 4],
  ['phala', 1],
  ['phalb', 1],
  ['phalc', 3],
  ['phale', 1],
  ['phamu', 1],
  ['phana', 1],
  ['phant', 1],
  ['pheuq', 4],
  ['phara', 1],
  ['phast', 1],
  ['phati', 1],
  ['phbab', 1],
  ['phbco', 1],
  ['phbac', 1],
  ['phbcu', 1],
  ['phbgc', 1],
  ['phbnq', 5],
  ['phbgi', 1],
  ['phbag', 5],
  ['phbai', 1],
  ['phbal', 1],
  ['phbla', 1],
  ['phbln', 1],
  ['phbqa', 5],
  ['phbgs', 3],
  ['phblt', 1],
  ['phblg', 1],
  ['phblu', 1],
  ['phbnw', 1],
  ['phbty', 1],
  ['phbrb', 1],
  ['phbrt', 1],
  ['phbso', 5],
  ['phbsy', 1],
  ['phbas', 1],
  ['phbtg', 3],
  ['phbts', 1],
  ['phbat', 1],
  ['phbau', 1],
  ['phbng', 3],
  ['phbys', 1],
  ['phbyp', 1],
  ['phbyw', 1],
  ['phbyb', 1],
  ['phbiy', 1],
  ['phbin', 3],
  ['phbog', 3],
  ['phblp', 1],
  ['phbor', 1],
  ['phbgv', 1],
  ['phbos', 1],
  ['phbgr', 1],
  ['phbud', 1],
  ['phbug', 1],
  ['phusu', 4],
  ['phbxu', 5],
  ['phcab', 1],
  ['phcaz', 1],
  ['phcdy', 4],
  ['phcdn', 1],
  ['phccg', 1],
  ['phcgt', 1],
  ['phclp', 1],
  ['phcdb', 1],
  ['phcwy', 1],
  ['phcgp', 1],
  ['phcml', 1],
  ['phcam', 1],
  ['phcgm', 5],
  ['phcny', 1],
  ['phcnt', 1],
  ['phcpl', 1],
  ['phcpc', 1],
  ['phcrg', 1],
  ['phcra', 1],
  ['phcgg', 5],
  ['phcst', 3],
  ['phcsb', 1],
  ['phctb', 1],
  ['phcun', 1],
  ['phcrm', 4],
  ['phmph', 4],
  ['phcyz', 4],
  ['phceb', 5],
  ['phcfa', 4],
  ['phclv', 1],
  ['phxcn', 4],
  ['phcgi', 1],
  ['phcsi', 3],
  ['phcuj', 1],
  ['phcmo', 1],
  ['phcur', 1],
  ['phcyu', 4],
  ['phdte', 4],
  ['phdcb', 1],
  ['phdno', 3],
  ['phdpt', 1],
  ['phdrb', 1],
  ['phdic', 1],
  ['phdid', 1],
  ['phdsg', 4],
  ['phddw', 1],
  ['phdng', 1],
  ['phdnl', 1],
  ['phdgl', 1],
  ['phdio', 1],
  ['phdpl', 5],
  ['phdir', 1],
  ['phdiv', 1],
  ['phdul', 1],
  ['phdgt', 5],
  ['phgac', 1],
  ['phghe', 1],
  ['phgas', 1],
  ['phgni', 1],
  ['phgin', 1],
  ['phghg', 1],
  ['phgms', 1],
  ['phgun', 1],
  ['phhyb', 1],
  ['phaqe', 3],
  ['phhij', 1],
  ['phhil', 1],
  ['phhnt', 1],
  ['phhng', 1],
  ['phhnb', 1],
  ['phhon', 1],
  ['phhoo', 1],
  ['phinp', 1],
  ['phipe', 5],
  ['phisl', 1],
  ['phjag', 1],
  ['phjas', 3],
  ['phjnz', 1],
  ['phjol', 5],
  ['phkas', 1],
  ['phklm', 3],
  ['phklo', 4],
  ['phkar', 1],
  ['phkat', 1],
  ['phkia', 1],
  ['phkil', 1],
  ['phkin', 1],
  ['phkip', 1],
  ['phkiw', 1],
  ['phkol', 1],
  ['phkum', 1],
  ['phlac', 1],
  ['phlpz', 1],
  ['phlrp', 1],
  ['phlab', 1],
  ['phlay', 1],
  ['phlag', 1],
  ['phlgu', 1],
  ['phlam', 1],
  ['phlan', 1],
  ['phlna', 1],
  ['phlzb', 3],
  ['phlbk', 1],
  ['phley', 1],
  ['phlia', 1],
  ['phlib', 1],
  ['phlim', 1],
  ['phlgg', 1],
  ['phlnk', 1],
  ['phlpa', 1],
  ['phlom', 1],
  ['phlop', 1],
  ['phloo', 1],
  ['phlbx', 4],
  ['phluc', 1],
  ['phlug', 3],
  ['phlum', 1],
  ['phlno', 3],
  ['phncp', 4],
  ['phlwa', 4],
  ['phmgd', 1],
  ['phmco', 1],
  ['phmcj', 1],
  ['phmad', 1],
  ['phmga', 1],
  ['phmls', 1],
  ['phmgb', 1],
  ['phmai', 1],
  ['phmak', 1],
  ['phmti', 3],
  ['phmkc', 1],
  ['phmlp', 4],
  ['phmll', 1],
  ['phmsb', 1],
  ['phmtp', 1],
  ['phmgi', 1],
  ['phmbo', 4],
  ['phmsy', 1],
  ['phmny', 1],
  ['phmgg', 1],
  ['phmni', 1],
  ['phmnl', 5],
  ['phmnn', 3],
  ['phmns', 3],
  ['phxma', 4],
  ['phmrq', 4],
  ['phmas', 1],
  ['phmbt', 5],
  ['phmsc', 1],
  ['phmac', 1],
  ['phmta', 1],
  ['phmdn', 3],
  ['phmcd', 1],
  ['phmil', 1],
  ['phmio', 1],
  ['phmic', 1],
  ['phmss', 1],
  ['phmon', 1],
  ['phmor', 1],
  ['phmul', 1],
  ['phmnt', 3],
  ['phnab', 1],
  ['phwnp', 5],
  ['phngh', 1],
  ['phnal', 1],
  ['phnap', 1],
  ['phnar', 1],
  ['phnas', 1],
  ['phnto', 1],
  ['phnid', 1],
  ['phnon', 1],
  ['phodi', 1],
  ['pholo', 1],
  ['phopo', 3],
  ['phors', 1],
  ['phora', 1],
  ['phorc', 5],
  ['phorl', 1],
  ['phote', 1],
  ['phpdr', 1],
  ['phpag', 5],
  ['phplb', 1],
  ['phplp', 1],
  ['phplw', 1],
  ['phpmt', 1],
  ['phpam', 3],
  ['phppl', 1],
  ['phpnb', 1],
  ['phpcn', 1],
  ['phpca', 1],
  ['phpgt', 1],
  ['phpnn', 1],
  ['phpra', 1],
  ['phpis', 1],
  ['phpin', 1],
  ['phpla', 1],
  ['phplc', 3],
  ['phpro', 1],
  ['phpba', 1],
  ['phphd', 1],
  ['phirn', 1],
  ['phpga', 1],
  ['phpug', 1],
  ['phpul', 1],
  ['phptl', 1],
  ['phpnt', 1],
  ['phque', 1],
  ['phqui', 1],
  ['phqbi', 1],
  ['phqbl', 1],
  ['phqpt', 1],
  ['phrea', 1],
  ['phrln', 1],
  ['phrom', 1],
  ['phrxs', 5],
  ['phsbg', 1],
  ['phsbb', 1],
  ['phsby', 1],
  ['phsag', 1],
  ['phslm', 1],
  ['phsca', 1],
  ['phsio', 1],
  ['phsis', 1],
  ['phspq', 1],
  ['phste', 1],
  ['phsgs', 4],
  ['phsng', 1],
  ['phnsp', 4],
  ['phsan', 1],
  ['phsct', 1],
  ['phscr', 1],
  ['phslu', 1],
  ['phsma', 1],
  ['phsni', 1],
  ['phsas', 1],
  ['phiao', 4],
  ['phsay', 1],
  ['phsmr', 3],
  ['phsir', 1],
  ['phssv', 4],
  ['phsiy', 1],
  ['phico', 4],
  ['phsig', 1],
  ['phsdg', 1],
  ['phxso', 5],
  ['phsgd', 1],
  ['phsor', 1],
  ['phsua', 1],
  ['phsfs', 5],
  ['phsku', 1],
  ['phtbc', 1],
  ['phtbg', 1],
  ['phtbh', 4],
  ['phtbu', 1],
  ['phtto', 1],
  ['phtgb', 4],
  ['phtkw', 1],
  ['phtgo', 3],
  ['phtgd', 1],
  ['phtgl', 1],
  ['phtgt', 1],
  ['phtal', 3],
  ['phtlm', 1],
  ['phtgn', 1],
  ['phtnu', 1],
  ['phtdg', 5],
  ['phtan', 3],
  ['phtdc', 1],
  ['phtwt', 4],
  ['phrzp', 4],
  ['phtay', 1],
  ['phtib', 1],
  ['phtin', 1],
  ['phtob', 1],
  ['phtld', 1],
  ['phtlg', 1],
  ['phtlp', 1],
  ['phtls', 1],
  ['phton', 1],
  ['phtug', 4],
  ['phtgi', 1],
  ['phtgw', 1],
  ['phuly', 1],
  ['phurr', 1],
  ['phvnv', 1],
  ['phvrc', 4],
  ['phvtl', 3],
  ['phvto', 1],
  ['phwas', 1],
  ['phwaw', 1],
  ['phzam', 5],
  ['pkaaw', 6],
  ['pkatg', 6],
  ['pkbdn', 6],
  ['pkwgb', 6],
  ['pkbhv', 6],
  ['pkbnp', 6],
  ['pkbhc', 6],
  ['pkcwp', 4],
  ['pkchb', 6],
  ['pkcjl', 6],
  ['pkddu', 6],
  ['pkdba', 6],
  ['pkdsk', 6],
  ['pklyp', 6],
  ['pkgil', 6],
  ['pkgrt', 6],
  ['pkhdd', 6],
  ['pkisb', 6],
  ['pkjag', 6],
  ['pkkcf', 4],
  ['pkkbh', 6],
  ['pkkct', 1],
  ['pkkba', 1],
  ['pkkbu', 3],
  ['pkqkh', 4],
  ['pkkdd', 6],
  ['pkkia', 1],
  ['pkoht', 6],
  ['pklhe', 6],
  ['pklrg', 4],
  ['pkhra', 6],
  ['pkmwd', 6],
  ['pkmpd', 6],
  ['pkmjd', 6],
  ['pkbqm', 1],
  ['pkmux', 6],
  ['pkmfg', 6],
  ['pkwns', 6],
  ['pknhs', 4],
  ['pkpct', 1],
  ['pkpjg', 6],
  ['pkpaj', 6],
  ['pkpew', 6],
  ['pkqct', 1],
  ['pkuet', 6],
  ['pkryk', 6],
  ['pkraz', 6],
  ['pkrwp', 6],
  ['pkreq', 4],
  ['pkswn', 6],
  ['pksdt', 6],
  ['pksgi', 6],
  ['pkzeo', 4],
  ['pksyw', 6],
  ['pkshi', 6],
  ['pkswv', 6],
  ['pkskt', 6],
  ['pksbq', 6],
  ['pkkdu', 6],
  ['pksul', 6],
  ['pkskz', 6],
  ['pktft', 6],
  ['pktlb', 6],
  ['pktuk', 6],
  ['pkwaf', 4],
  ['pkpzh', 6],
  ['plbms', 6],
  ['plbek', 1],
  ['plbrk', 1],
  ['plbzi', 3],
  ['plbyc', 1],
  ['plcpl', 3],
  ['plch3', 1],
  ['plzaa', 1],
  ['plcdb', 3],
  ['plczw', 4],
  ['pl6ma', 3],
  ['pldar', 3],
  ['pldcz', 3],
  ['plzic', 1],
  ['pldzn', 1],
  ['plelb', 3],
  ['plqkd', 4],
  ['plfbk', 3],
  ['plgdn', 5],
  ['plgdy', 3],
  ['plgki', 1],
  ['plzin', 1],
  ['plgkz', 1],
  ['plhel', 3],
  ['pliyc', 3],
  ['plluu', 3],
  ['pljdz', 3],
  ['plkao', 3],
  ['plkyk', 1],
  ['plsjk', 1],
  ['plktw', 6],
  ['plma9', 3],
  ['plkol', 3],
  ['plkom', 6],
  ['plmk9', 1],
  ['plkz4', 3],
  ['plkly', 1],
  ['plosz', 6],
  ['plkrk', 4],
  ['plkpw', 1],
  ['plkod', 3],
  ['plkmr', 3],
  ['plkrm', 3],
  ['pllea', 3],
  ['pllpn', 3],
  ['plkuy', 3],
  ['pllcj', 4],
  ['plmnn', 1],
  ['plmid', 3],
  ['plnxa', 3],
  ['plmkw', 3],
  ['plmre', 1],
  ['plnsw', 6],
  ['plnch', 1],
  ['plnwa', 3],
  ['plnow', 3],
  ['plola', 3],
  ['plosd', 1],
  ['plptn', 1],
  ['plit5', 3],
  ['plpit', 6],
  ['plblz', 1],
  ['plpnw', 1],
  ['plplc', 1],
  ['plpoz', 4],
  ['plpzn', 3],
  ['plrap', 6],
  ['plrwl', 1],
  ['plxxx', 1],
  ['plroi', 1],
  ['plruc', 1],
  ['plrze', 6],
  ['plsca', 3],
  ['plskn', 1],
  ['plskj', 1],
  ['plsop', 3],
  ['plsy2', 1],
  ['plqxq', 4],
  ['plspa', 3],
  ['plswi', 3],
  ['plszz', 5],
  ['plszy', 4],
  ['plqep', 4],
  ['pltbz', 3],
  ['plurd', 3],
  ['plust', 3],
  ['plums', 3],
  ['plwaw', 6],
  ['plwzo', 3],
  ['plwiu', 1],
  ['plwie', 6],
  ['plwla', 3],
  ['plwol', 3],
  ['plwli', 3],
  ['plwro', 4],
  ['plzgo', 1],
  ['plqaz', 4],
  ['plieg', 4],
  ['plzni', 3],
  ['pmmqc', 4],
  ['pmfsp', 5],
  ['pnpcn', 1],
  ['prbqn', 5],
  ['prare', 5],
  ['prarr', 3],
  ['prcpx', 4],
  ['prddp', 6],
  ['prfaj', 4],
  ['prgux', 1],
  ['prguy', 3],
  ['prhuc', 4],
  ['prlam', 1],
  ['prmaz', 5],
  ['prpse', 5],
  ['prpjo', 1],
  ['prpya', 1],
  ['prnrr', 4],
  ['prsal', 1],
  ['prsju', 5],
  ['prsbs', 1],
  ['prvqs', 4],
  ['ptcal', 3],
  ['ptagd', 1],
  ['ptabf', 3],
  ['ptagr', 1],
  ['ptadg', 1],
  ['ptang', 1],
  ['ptadh', 3],
  ['ptger', 3],
  ['ptarp', 3],
  ['ptave', 3],
  ['ptazm', 1],
  ['ptbtr', 1],
  ['ptban', 3],
  ['ptbao', 3],
  ['ptbei', 3],
  ['ptbng', 1],
  ['ptboc', 3],
  ['ptbgz', 6],
  ['ptbgc', 6],
  ['ptbrg', 1],
  ['ptbus', 1],
  ['ptcac', 3],
  ['ptcdp', 3],
  ['ptces', 1],
  ['ptcnl', 3],
  ['ptcpl', 3],
  ['ptcrc', 1],
  ['ptcrb', 1],
  ['ptcas', 3],
  ['ptcnv', 1],
  ['ptchv', 6],
  ['ptcbp', 6],
  ['ptcna', 3],
  ['ptcls', 1],
  ['ptcvu', 4],
  ['ptcap', 1],
  ['ptcov', 6],
  ['ptcml', 3],
  ['ptdou', 1],
  ['ptxzx', 3],
  ['ptest', 3],
  ['ptfdf', 3],
  ['ptflw', 4],
  ['ptfdt', 1],
  ['ptfoz', 1],
  ['ptfuz', 1],
  ['ptfae', 1],
  ['ptgrw', 4],
  ['ptgua', 3],
  ['ptgue', 1],
  ['ptgif', 3],
  ['ptbra', 3],
  ['ptlos', 3],
  ['ptldp', 3],
  ['ptlaj', 3],
  ['ptlns', 1],
  ['ptlei', 3],
  ['ptlep', 3],
  ['ptldr', 3],
  ['ptmac', 6],
  ['ptle2', 1],
  ['ptmch', 3],
  ['ptmad', 3],
  ['ptmdm', 1],
  ['ptmat', 3],
  ['ptmlr', 1],
  ['pttgo', 1],
  ['ptmus', 1],
  ['ptnav', 1],
  ['ptnrd', 3],
  ['ptoei', 3],
  ['ptola', 1],
  ['ptolh', 3],
  ['ptpvh', 1],
  ['ptior', 1],
  ['ptpen', 3],
  ['ptpft', 3],
  ['ptavp', 3],
  ['ptpom', 3],
  ['ptpds', 3],
  ['ptprm', 3],
  ['ptpcz', 3],
  ['ptpmz', 3],
  ['ptpxo', 5],
  ['ptpvc', 3],
  ['ptprg', 3],
  ['ptprv', 3],
  ['ptqrt', 3],
  ['ptrpx', 3],
  ['ptrqu', 1],
  ['ptqeg', 6],
  ['ptsag', 1],
  ['ptdfz', 3],
  ['ptscg', 3],
  ['ptscf', 3],
  ['ptslz', 1],
  ['ptsms', 3],
  ['ptsma', 4],
  ['ptsha', 3],
  ['ptssb', 3],
  ['ptset', 3],
  ['ptsjz', 4],
  ['ptsmt', 1],
  ['ptsmi', 1],
  ['ptafr', 1],
  ['ptter', 5],
  ['pttpo', 3],
  ['pttoa', 1],
  ['pttrf', 1],
  ['pttun', 1],
  ['ptvag', 1],
  ['ptvab', 1],
  ['ptngv', 3],
  ['ptvel', 3],
  ['ptvdv', 1],
  ['ptvdc', 3],
  ['ptvlc', 1],
  ['ptvfd', 1],
  ['ptvic', 3],
  ['ptvdp', 3],
  ['ptvfx', 3],
  ['ptvfc', 3],
  ['ptvnr', 1],
  ['ptvnc', 1],
  ['ptvrl', 6],
  ['ptvre', 3],
  ['ptvse', 6],
  ['ptvar', 3],
  ['ptzfm', 3],
  ['pwang', 1],
  ['pwror', 5],
  ['pyasu', 5],
  ['pyayo', 4],
  ['pybfa', 4],
  ['pybcm', 3],
  ['pycaa', 3],
  ['pycac', 1],
  ['pyagt', 5],
  ['pymra', 3],
  ['pycnp', 1],
  ['pyeno', 5],
  ['pyfnx', 1],
  ['pyflm', 4],
  ['pyfuo', 3],
  ['pyite', 1],
  ['pyesg', 4],
  ['pypjc', 4],
  ['pypil', 6],
  ['pypan', 1],
  ['pypgu', 1],
  ['pypcj', 3],
  ['pypbt', 4],
  ['pypsa', 1],
  ['pysan', 1],
  ['pyter', 3],
  ['pyvmi', 4],
  ['pyvll', 1],
  ['qarus', 1],
  ['qaasn', 1],
  ['qaalu', 6],
  ['qaslw', 1],
  ['qahmd', 1],
  ['qadoh', 5],
  ['qahal', 1],
  ['qahna', 1],
  ['qames', 1],
  ['qaqap', 1],
  ['qaqch', 1],
  ['qarlf', 1],
  ['qaums', 3],
  ['relpt', 3],
  ['repos', 1],
  ['rerun', 4],
  ['releu', 1],
  ['rezse', 4],
  ['ressz', 1],
  ['roagi', 1],
  ['roarw', 4],
  ['robcm', 4],
  ['robay', 4],
  ['robpu', 4],
  ['robab', 1],
  ['robzi', 3],
  ['robct', 3],
  ['robcn', 3],
  ['robez', 1],
  ['robuh', 4],
  ['robbu', 4],
  ['rocaf', 3],
  ['rocas', 1],
  ['rocsb', 4],
  ['rocev', 1],
  ['rochl', 1],
  ['rochn', 1],
  ['roclj', 4],
  ['rocnm', 1],
  ['rocob', 1],
  ['rocra', 4],
  ['rocts', 1],
  ['rodva', 4],
  ['rodcd', 6],
  ['rodts', 1],
  ['roefs', 1],
  ['rofet', 3],
  ['rofte', 1],
  ['rogal', 1],
  ['rogrg', 1],
  ['roguu', 1],
  ['rohau', 1],
  ['rohdc', 1],
  ['rorva', 3],
  ['roias', 6],
  ['roisc', 3],
  ['rojrt', 1],
  ['rozui', 1],
  ['ro5li', 1],
  ['rolum', 1],
  ['rolut', 1],
  ['romac', 1],
  ['romgu', 1],
  ['romah', 1],
  ['romag', 3],
  ['romed', 1],
  ['romid', 3],
  ['rondi', 3],
  ['roojd', 1],
  ['roolt', 1],
  ['roomr', 4],
  ['roorv', 1],
  ['rootp', 4],
  ['roovi', 1],
  ['ropau', 1],
  ['ropta', 1],
  ['ropes', 1],
  ['ropse', 1],
  ['ropal', 1],
  ['roppc', 1],
  ['rorde', 1],
  ['roxxx', 1],
  ['rornn', 1],
  ['rosuj', 4],
  ['rosbz', 4],
  ['roscv', 4],
  ['rosul', 3],
  ['rotsr', 4],
  ['rotce', 5],
  ['rotr5', 1],
  ['rotum', 1],
  ['rotut', 1],
  ['rotgm', 4],
  ['rovtr', 1],
  ['rozic', 1],
  ['rsapt', 3],
  ['rsbpa', 3],
  ['rsbeg', 6],
  ['rsbzd', 3],
  ['rs4sn', 3],
  ['rsdim', 3],
  ['rsdob', 1],
  ['rsgnj', 1],
  ['rskma', 3],
  ['rsmsd', 3],
  ['rsini', 6],
  ['rszzp', 6],
  ['rspho', 3],
  ['rsprn', 6],
  ['rsxxx', 1],
  ['rsska', 3],
  ['rsuzc', 6],
  ['rsvgs', 3],
  ['rsvs4', 1],
  ['rsvra', 3],
  ['ruber', 1],
  ['rumag', 1],
  ['ruoka', 1],
  ['ruolg', 1],
  ['ruspa', 1],
  ['ruvzb', 1],
  ['ruzao', 1],
  ['ruabk', 3],
  ['ruacs', 4],
  ['ruaks', 1],
  ['ruadh', 4],
  ['rusak', 3],
  ['ruabc', 1],
  ['ruamv', 5],
  ['ruamu', 1],
  ['rudyr', 5],
  ['ruaaq', 4],
  ['ruagk', 3],
  ['ruarh', 5],
  ['ruarm', 1],
  ['ruasf', 5],
  ['ruazo', 1],
  ['rubgv', 3],
  ['rublk', 1],
  ['rubln', 1],
  ['rubwo', 4],
  ['ruzca', 3],
  ['rublt', 1],
  ['rubax', 4],
  ['rubkh', 1],
  ['rubkt', 1],
  ['ruego', 4],
  ['rubem', 1],
  ['rubcx', 4],
  ['rueyk', 4],
  ['rubel', 1],
  ['ruezv', 4],
  ['rubqs', 6],
  ['rubkn', 1],
  ['rubor', 1],
  ['rubos', 1],
  ['rubtk', 4],
  ['rubnk', 3],
  ['ruuua', 4],
  ['ruolb', 1],
  ['rucha', 3],
  ['ruchb', 3],
  ['rucsy', 6],
  ['rucek', 4],
  ['ruchm', 1],
  ['rucee', 5],
  ['rucyx', 4],
  ['rucih', 1],
  ['ruhta', 4],
  ['ruckl', 4],
  ['ruckh', 4],
  ['rucnn', 4],
  ['ruchu', 1],
  ['rucpc', 1],
  ['rudka', 1],
  ['rudha', 3],
  ['rudks', 4],
  ['rudme', 4],
  ['rudub', 1],
  ['rudbk', 1],
  ['rudud', 1],
  ['ruegv', 1],
  ['rueka', 6],
  ['rueko', 3],
  ['ruesl', 4],
  ['rupok', 1],
  ['rueie', 4],
  ['rufpt', 1],
  ['rugvy', 3],
  ['rugdz', 4],
  ['rugsa', 3],
  ['rugrh', 1],
  ['rugrv', 4],
  ['ruhtg', 4],
  ['ruiaa', 5],
  ['ruirm', 4],
  ['ruioy', 3],
  ['ruidg', 1],
  ['ruina', 4],
  ['ruiok', 1],
  ['ruikt', 4],
  ['ruiwa', 4],
  ['ruivo', 3],
  ['ruijk', 4],
  ['rujok', 6],
  ['rukgd', 5],
  ['ruklf', 6],
  ['rukly', 1],
  ['rukmu', 5],
  ['rukan', 1],
  ['rukas', 3],
  ['ruksi', 3],
  ['rukzp', 3],
  ['rukzn', 6],
  ['rukem', 1],
  ['rukej', 6],
  ['ruker', 1],
  ['rukhv', 5],
  ['ruksa', 1],
  ['rukty', 1],
  ['rukho', 3],
  ['rukny', 3],
  ['rukdn', 1],
  ['rukim', 1],
  ['rukin', 1],
  ['rukik', 1],
  ['rukyk', 1],
  ['rukvx', 6],
  ['rukvk', 4],
  ['rukiy', 3],
  ['ruksl', 1],
  ['ruklr', 3],
  ['rukgp', 4],
  ['rukyi', 1],
  ['rukvo', 1],
  ['ruklt', 1],
  ['rukxk', 4],
  ['rukok', 3],
  ['rukpa', 3],
  ['ruknl', 1],
  ['rukrf', 1],
  ['rukor', 1],
  ['rukma', 3],
  ['rukmw', 4],
  ['ruxc2', 1],
  ['rukot', 1],
  ['ruksz', 4],
  ['rukzh', 1],
  ['rukzm', 1],
  ['rukrr', 5],
  ['rukja', 5],
  ['rukso', 3],
  ['ruryk', 3],
  ['ruknv', 1],
  ['rukra', 3],
  ['rukry', 1],
  ['rukyb', 3],
  ['rukri', 1],
  ['rukdt', 1],
  ['rukub', 1],
  ['ruklk', 1],
  ['rukur', 1],
  ['rukro', 6],
  ['rubvv', 5],
  ['ruurs', 6],
  ['rukyz', 4],
  ['rulvr', 3],
  ['rulaz', 1],
  ['ruldy', 3],
  ['rulnk', 1],
  ['ruleo', 1],
  ['ruldg', 4],
  ['rules', 1],
  ['ruleu', 1],
  ['rulnr', 1],
  ['rulpk', 6],
  ['rubrk', 3],
  ['rulug', 1],
  ['rulbu', 3],
  ['rugdx', 4],
  ['rugdg', 4],
  ['rumqf', 4],
  ['rumgo', 1],
  ['rumkr', 1],
  ['rukmm', 1],
  ['ruma2', 1],
  ['rumdy', 1],
  ['rumeg', 1],
  ['rumez', 1],
  ['rumas', 3],
  ['rumrv', 4],
  ['rumjz', 4],
  ['rumog', 1],
  ['rumon', 1],
  ['rubka', 4],
  ['rumvo', 1],
  ['rummk', 5],
  ['rumsh', 3],
  ['runac', 3],
  ['runym', 4],
  ['runjk', 1],
  ['runnm', 5],
  ['runar', 1],
  ['runef', 4],
  ['runlk', 1],
  ['runfg', 4],
  ['runer', 4],
  ['runev', 1],
  ['runia', 1],
  ['runjc', 5],
  ['rugoj', 5],
  ['runpe', 1],
  ['runog', 3],
  ['runsk', 4],
  ['runvr', 4],
  ['rundk', 3],
  ['runoz', 4],
  ['runmk', 3],
  ['runoi', 4],
  ['runvs', 3],
  ['ruovb', 6],
  ['runux', 4],
  ['runoj', 6],
  ['runya', 4],
  ['ruoho', 1],
  ['ruokz', 1],
  ['ruoye', 1],
  ['ruolp', 1],
  ['ruomk', 1],
  ['ruolo', 3],
  ['ruoya', 3],
  ['ruoms', 4],
  ['ruong', 1],
  ['ruoze', 3],
  ['ruhzo', 1],
  ['ruren', 6],
  ['ruosw', 4],
  ['ruosf', 4],
  ['ruozk', 3],
  ['ruozy', 3],
  ['rupce', 1],
  ['rupex', 5],
  ['rupez', 4],
  ['rupge', 1],
  ['rupee', 6],
  ['rupek', 1],
  ['rupkc', 5],
  ['rupes', 5],
  ['rupwe', 5],
  ['rupny', 3],
  ['ruptn', 1],
  ['rupnv', 1],
  ['rupyj', 4],
  ['ruprn', 1],
  ['rupse', 1],
  ['ruptp', 1],
  ['rupyo', 3],
  ['rupry', 3],
  ['rupgn', 3],
  ['rupri', 1],
  ['ruphs', 1],
  ['rupvx', 4],
  ['rupkv', 6],
  ['rupud', 1],
  ['rurat', 4],
  ['rurov', 5],
  ['rurnd', 3],
  ['rurub', 1],
  ['rurdp', 1],
  ['rurzn', 4],
  ['ruryb', 5],
  ['rurzv', 3],
  ['rusab', 5],
  ['rusly', 4],
  ['ruslh', 1],
  ['ruskx', 4],
  ['rurtw', 5],
  ['ruseg', 1],
  ['ruszz', 3],
  ['rusvk', 1],
  ['rusew', 1],
  ['rusrm', 1],
  ['rusha', 1],
  ['rub2l', 1],
  ['rusvo', 4],
  ['rusiz', 1],
  ['ruska', 1],
  ['rulnx', 4],
  ['rusng', 3],
  ['rucsh', 4],
  ['ruslk', 1],
  ['russk', 3],
  ['rusog', 1],
  ['rustw', 4],
  ['rustr', 1],
  ['ruswt', 4],
  ['rusgc', 4],
  ['rusve', 1],
  ['rusty', 1],
  ['ruscw', 4],
  ['rusyz', 1],
  ['rutag', 1],
  ['rutam', 1],
  ['rutbw', 4],
  ['rutau', 1],
  ['rutay', 1],
  ['rutaz', 1],
  ['ruiks', 5],
  ['rutvk', 3],
  ['rutox', 4],
  ['rutoi', 1],
  ['rutti', 1],
  ['rutof', 4],
  ['rutrr', 1],
  ['rutdo', 3],
  ['rutua', 1],
  ['rutya', 4],
  ['rutvr', 6],
  ['rutyd', 4],
  ['rutjm', 6],
  ['ruuel', 1],
  ['ruufa', 6],
  ['ruugl', 1],
  ['ruugc', 3],
  ['ruuct', 4],
  ['ruuud', 4],
  ['ruuly', 6],
  ['ruumb', 1],
  ['ruura', 1],
  ['ruurj', 4],
  ['ruusk', 4],
  ['ruuch', 1],
  ['ruulu', 3],
  ['ruuik', 4],
  ['ruukk', 1],
  ['ruukx', 4],
  ['ruvnn', 1],
  ['ruvkr', 1],
  ['ruvar', 3],
  ['ruvay', 1],
  ['rukmv', 1],
  ['ruvus', 4],
  ['ruvlu', 4],
  ['ruvny', 1],
  ['ruvye', 1],
  ['ruvtm', 1],
  ['ruvit', 1],
  ['ruvtz', 3],
  ['rufip', 1],
  ['ruvvo', 5],
  ['ruvko', 4],
  ['ruvlg', 1],
  ['ruvlk', 4],
  ['ruvog', 5],
  ['ruvgd', 4],
  ['ruvzh', 3],
  ['ruvkt', 4],
  ['ruvoz', 4],
  ['ruvyp', 1],
  ['ruvms', 1],
  ['ruvyg', 5],
  ['ruvyk', 3],
  ['ruvys', 1],
  ['ruyab', 1],
  ['ruyks', 4],
  ['ruyam', 1],
  ['ruiar', 5],
  ['ruyey', 1],
  ['rudee', 5],
  ['ruuus', 4],
  ['ruzar', 1],
  ['ruzem', 1],
  ['ruzdk', 3],
  ['rwbtq', 4],
  ['rwgyi', 4],
  ['rwkme', 4],
  ['rwkgl', 6],
  ['rwnsa', 3],
  ['rwrub', 1],
  ['rwrhg', 4],
  ['rwrw4', 1],
  ['saahb', 4],
  ['saabt', 4],
  ['saaha', 1],
  ['sajbi', 3],
  ['saakh', 6],
  ['saalk', 1],
  ['saaqk', 1],
  ['sakhu', 1],
  ['saamu', 1],
  ['saqah', 1],
  ['saqun', 1],
  ['sarae', 4],
  ['sabhh', 4],
  ['sabur', 1],
  ['sadha', 5],
  ['sadhu', 1],
  ['saelq', 4],
  ['saury', 4],
  ['sahbt', 6],
  ['sahas', 4],
  ['sahof', 4],
  ['sajed', 5],
  ['sagiz', 5],
  ['saajf', 4],
  ['sajut', 1],
  ['sajub', 1],
  ['sakmx', 6],
  ['sakac', 3],
  ['salit', 1],
  ['samed', 4],
  ['samjh', 4],
  ['samak', 1],
  ['saman', 1],
  ['samuf', 1],
  ['saeam', 4],
  ['saaqi', 4],
  ['saqal', 1],
  ['saqtf', 4],
  ['saqur', 1],
  ['sarab', 1],
  ['sarah', 4],
  ['sarar', 1],
  ['saram', 1],
  ['saraz', 3],
  ['sarta', 1],
  ['saruh', 4],
  ['sasuh', 1],
  ['sashw', 4],
  ['saslf', 4],
  ['satuu', 4],
  ['satif', 4],
  ['satui', 4],
  ['savla', 3],
  ['sauzh', 6],
  ['sawae', 6],
  ['saawi', 4],
  ['saejh', 5],
  ['saybi', 3],
  ['sazul', 4],
  ['sazuy', 1],
  ['sbalb', 1],
  ['sbaks', 5],
  ['sbavu', 4],
  ['sbhir', 5],
  ['sbira', 5],
  ['sbrus', 5],
  ['sbmbu', 4],
  ['sbmua', 4],
  ['sbnor', 1],
  ['sbone', 4],
  ['sbprs', 4],
  ['sbrin', 5],
  ['sbtlg', 5],
  ['sbaft', 4],
  ['sbanh', 4],
  ['sbaob', 1],
  ['sbrna', 4],
  ['sbatd', 4],
  ['sbbas', 4],
  ['sbvev', 4],
  ['sbrri', 4],
  ['sbbpf', 4],
  ['sbbny', 4],
  ['sbchy', 5],
  ['sbfre', 4],
  ['sbgef', 4],
  ['sbgzo', 5],
  ['sbgsi', 4],
  ['sbjja', 4],
  ['sbkge', 4],
  ['sbkue', 4],
  ['sbkwr', 4],
  ['sbkws', 4],
  ['sblev', 1],
  ['sblof', 1],
  ['sbmny', 4],
  ['sbnaz', 4],
  ['sbnem', 3],
  ['sbgta', 4],
  ['sbotv', 4],
  ['sbrbv', 4],
  ['sbrnl', 4],
  ['sbnnb', 4],
  ['sbscz', 5],
  ['sbsvy', 4],
  ['sbegm', 4],
  ['sbshh', 1],
  ['sbvao', 4],
  ['sbtaa', 4],
  ['sbviu', 5],
  ['scbdi', 4],
  ['scdei', 4],
  ['scfrk', 4],
  ['scdes', 4],
  ['scmaw', 1],
  ['scsez', 4],
  ['scpov', 1],
  ['scpri', 4],
  ['scvic', 3],
  ['sdaad', 4],
  ['sdatb', 4],
  ['sddnx', 4],
  ['sddog', 4],
  ['sdelf', 4],
  ['sdebd', 4],
  ['sdedb', 4],
  ['sdnud', 4],
  ['sdgsu', 4],
  ['sdegn', 4],
  ['sdjum', 4],
  ['sdkdx', 4],
  ['sdksl', 4],
  ['sdkrt', 4],
  ['sdgbu', 4],
  ['sdkst', 4],
  ['sdmak', 4],
  ['sdmbh', 1],
  ['sdmwe', 4],
  ['sdnhf', 4],
  ['sduyl', 4],
  ['sdpzu', 5],
  ['sdrss', 4],
  ['sdswa', 1],
  ['sddni', 4],
  ['sdwhf', 4],
  ['sdwuu', 4],
  ['sestr', 3],
  ['seagb', 3],
  ['seala', 1],
  ['sealf', 1],
  ['seank', 1],
  ['seaar', 1],
  ['seara', 3],
  ['searb', 3],
  ['seadg', 1],
  ['seasu', 1],
  ['searn', 4],
  ['seark', 1],
  ['searv', 3],
  ['seasd', 1],
  ['sebbh', 1],
  ['sebtf', 3],
  ['sebga', 1],
  ['sebea', 1],
  ['seber', 1],
  ['sebjr', 3],
  ['sebla', 1],
  ['seboh', 3],
  ['sebol', 3],
  ['sebog', 1],
  ['sebom', 1],
  ['seble', 4],
  ['sebld', 1],
  ['sebre', 1],
  ['sebro', 1],
  ['sebma', 4],
  ['sebrt', 1],
  ['sebru', 3],
  ['sebvk', 3],
  ['sebur', 1],
  ['sebuv', 1],
  ['sebyx', 1],
  ['sebac', 1],
  ['sebaa', 3],
  ['sebas', 1],
  ['sebat', 1],
  ['sebdq', 1],
  ['sedla', 1],
  ['sedeg', 1],
  ['sedjp', 1],
  ['sedju', 1],
  ['sedjn', 1],
  ['sedom', 1],
  ['sedon', 1],
  ['sedrt', 1],
  ['sedyn', 3],
  ['seell', 3],
  ['seelo', 3],
  ['seenk', 3],
  ['seekt', 4],
  ['seess', 3],
  ['sefak', 1],
  ['sefag', 3],
  ['sefkg', 3],
  ['sefsb', 1],
  ['sefig', 1],
  ['sefil', 3],
  ['sefis', 1],
  ['sefbk', 1],
  ['sefja', 1],
  ['sefli', 1],
  ['sefor', 1],
  ['sefra', 3],
  ['sefar', 3],
  ['sefsd', 1],
  ['segam', 3],
  ['seglm', 1],
  ['segor', 1],
  ['segkv', 3],
  ['segvn', 1],
  ['segre', 3],
  ['segrh', 1],
  ['segrd', 3],
  ['segru', 1],
  ['segnr', 1],
  ['segun', 1],
  ['segub', 1],
  ['segus', 3],
  ['segks', 1],
  ['segev', 4],
  ['segvx', 5],
  ['segoa', 3],
  ['sehac', 3],
  ['sehas', 1],
  ['sehak', 3],
  ['sehbg', 1],
  ['sehmr', 3],
  ['sehaa', 3],
  ['sehah', 1],
  ['sehan', 3],
  ['sehst', 1],
  ['sehel', 5],
  ['seagh', 4],
  ['sehmv', 4],
  ['seher', 1],
  ['sehld', 3],
  ['sehlf', 6],
  ['sehun', 3],
  ['sehus', 1],
  ['sehyp', 1],
  ['sehls', 3],
  ['sehnd', 3],
  ['sehbv', 1],
  ['sehog', 3],
  ['sehgs', 1],
  ['sehon', 3],
  ['sehnb', 1],
  ['sehnf', 3],
  ['seidb', 4],
  ['seigg', 3],
  ['sejoh', 1],
  ['sejar', 3],
  ['sejat', 1],
  ['sejkg', 5],
  ['sejna', 3],
  ['sekgg', 1],
  ['sekax', 3],
  ['sekal', 1],
  ['sekvk', 1],
  ['sekps', 3],
  ['sekph', 1],
  ['sekbk', 1],
  ['sekxv', 1],
  ['sekan', 3],
  ['seksk', 4],
  ['sekaa', 3],
  ['seksd', 5],
  ['sekak', 1],
  ['sekas', 1],
  ['sekat', 1],
  ['sekrn', 4],
  ['sekgm', 3],
  ['sekla', 3],
  ['sekli', 1],
  ['sekld', 3],
  ['sekvh', 1],
  ['sekhn', 3],
  ['sekub', 1],
  ['sekua', 3],
  ['sekun', 1],
  ['sekgr', 3],
  ['sekuv', 3],
  ['sekva', 1],
  ['sekyr', 1],
  ['sekks', 3],
  ['sekag', 1],
  ['sekrh', 1],
  ['sekog', 3],
  ['sekmh', 1],
  ['selaa', 3],
  ['selio', 3],
  ['seled', 3],
  ['selim', 3],
  ['selis', 3],
  ['selpi', 6],
  ['seljp', 3],
  ['selju', 3],
  ['selom', 3],
  ['selou', 1],
  ['selug', 1],
  ['sella', 5],
  ['selyc', 4],
  ['selys', 3],
  ['selod', 3],
  ['seldo', 3],
  ['selot', 1],
  ['selun', 1],
  ['selvr', 1],
  ['semmx', 4],
  ['semar', 1],
  ['semrf', 3],
  ['semad', 3],
  ['semsd', 3],
  ['seman', 1],
  ['semav', 1],
  ['semem', 3],
  ['semoo', 1],
  ['semlo', 3],
  ['semdm', 3],
  ['semun', 3],
  ['semsk', 1],
  ['semle', 3],
  ['semon', 3],
  ['semor', 1],
  ['senol', 3],
  ['senst', 1],
  ['senog', 3],
  ['senja', 1],
  ['senor', 1],
  ['senot', 3],
  ['senoe', 1],
  ['senhn', 1],
  ['senyn', 3],
  ['senas', 1],
  ['senav', 1],
  ['seoax', 1],
  ['seobb', 1],
  ['seort', 1],
  ['seosk', 3],
  ['seott', 3],
  ['seoxe', 3],
  ['sephm', 3],
  ['sepit', 3],
  ['sepas', 1],
  ['seram', 3],
  ['serin', 1],
  ['sernh', 1],
  ['serby', 3],
  ['seruv', 3],
  ['seraa', 1],
  ['seror', 1],
  ['serov', 1],
  ['serng', 3],
  ['sesms', 1],
  ['sestl', 3],
  ['sesbb', 1],
  ['sesbn', 3],
  ['sesae', 3],
  ['sesak', 1],
  ['seses', 1],
  ['sesgt', 3],
  ['sesik', 1],
  ['sespp', 1],
  ['sesim', 3],
  ['sesgm', 1],
  ['seskl', 1],
  ['sesnr', 3],
  ['seskt', 1],
  ['seske', 1],
  ['sesko', 3],
  ['seskr', 3],
  ['seskn', 1],
  ['sessr', 3],
  ['seskb', 3],
  ['seskm', 3],
  ['sesli', 1],
  ['sesmo', 3],
  ['sespr', 3],
  ['sestj', 1],
  ['seste', 3],
  ['sestg', 3],
  ['sesta', 1],
  ['sesud', 1],
  ['sestk', 3],
  ['sestv', 3],
  ['sesus', 3],
  ['sesmn', 3],
  ['sestq', 3],
  ['sekaf', 1],
  ['sesmd', 3],
  ['sesds', 1],
  ['sestu', 3],
  ['sesun', 1],
  ['sesug', 3],
  ['sesdk', 3],
  ['sesur', 3],
  ['sesnd', 3],
  ['sesvx', 1],
  ['sesvk', 1],
  ['sesea', 3],
  ['seevg', 6],
  ['sesaf', 3],
  ['sesog', 3],
  ['sesoe', 3],
  ['sesol', 3],
  ['sesor', 3],
  ['settd', 3],
  ['setov', 1],
  ['setyf', 6],
  ['setoa', 3],
  ['setrg', 1],
  ['setsl', 1],
  ['setun', 3],
  ['setoe', 1],
  ['seudd', 3],
  ['seugn', 3],
  ['seulf', 1],
  ['seund', 1],
  ['seupp', 3],
  ['seurs', 3],
  ['seuta', 1],
  ['sevak', 1],
  ['seval', 3],
  ['sevag', 3],
  ['sevgn', 3],
  ['sevxh', 3],
  ['sever', 1],
  ['sevhm', 4],
  ['seviv', 3],
  ['sevgo', 3],
  ['sevaj', 3],
  ['sevan', 3],
  ['sevxo', 4],
  ['sevaa', 3],
  ['sevrb', 1],
  ['sewal', 1],
  ['seyst', 3],
  ['sealn', 1],
  ['seagn', 3],
  ['seahu', 3],
  ['seama', 3],
  ['seage', 3],
  ['seann', 3],
  ['seoco', 1],
  ['seoen', 1],
  ['seorb', 4],
  ['seogr', 1],
  ['seorv', 1],
  ['seosd', 6],
  ['seost', 1],
  ['sgbkm', 3],
  ['sgjur', 3],
  ['sgkep', 1],
  ['sgppt', 3],
  ['sgpap', 1],
  ['sgqpg', 6],
  ['sgayc', 1],
  ['sgpub', 1],
  ['sgseb', 1],
  ['sgslt', 1],
  ['sgsem', 1],
  ['sgsin', 5],
  ['sgsct', 1],
  ['sgtam', 6],
  ['sgtpg', 1],
  ['sgtpn', 1],
  ['sgtay', 1],
  ['sgtga', 4],
  ['sgtua', 3],
  ['shasc', 1],
  ['shasi', 5],
  ['shshn', 1],
  ['shrpt', 1],
  ['shtdc', 1],
  ['sidbo', 1],
  ['siizo', 1],
  ['sijar', 1],
  ['sijce', 3],
  ['sikop', 1],
  ['silju', 4],
  ['sislv', 1],
  ['sisml', 3],
  ['simbx', 4],
  ['sipir', 1],
  ['sipow', 5],
  ['sirog', 1],
  ['sisec', 1],
  ['sis8l', 1],
  ['sitol', 1],
  ['silu6', 3],
  ['sivoj', 1],
  ['sivra', 1],
  ['sivb9', 1],
  ['sivuh', 1],
  ['sizab', 1],
  ['sizbu', 1],
  ['siz42', 1],
  ['sizag', 1],
  ['sizav', 1],
  ['sizdo', 1],
  ['sizet', 1],
  ['sizgb', 1],
  ['sizgk', 1],
  ['sizgl', 1],
  ['sizgp', 1],
  ['sizgv', 1],
  ['siz95', 1],
  ['sixza', 3],
  ['siz35', 1],
  ['sizid', 1],
  ['sizir', 1],
  ['sizuz', 1],
  ['sjbar', 1],
  ['sjnya', 1],
  ['sjsve', 1],
  ['skbby', 6],
  ['skbck', 1],
  ['skbts', 6],
  ['skbab', 1],
  ['skdvo', 1],
  ['skglt', 3],
  ['skhri', 4],
  ['skkao', 3],
  ['skknp', 1],
  ['skksc', 6],
  ['skfd3', 3],
  ['skkve', 1],
  ['sklue', 4],
  ['skxcv', 3],
  ['skmat', 3],
  ['skmod', 1],
  ['skhil', 3],
  ['skpzy', 4],
  ['skpop', 1],
  ['sktat', 4],
  ['skplb', 1],
  ['skpov', 4],
  ['skxxx', 1],
  ['skk87', 3],
  ['skslk', 1],
  ['sksld', 4],
  ['skdf4', 3],
  ['sktep', 1],
  ['sktlm', 3],
  ['sktbe', 3],
  ['sktna', 3],
  ['sktut', 3],
  ['skdxz', 3],
  ['skvzk', 1],
  ['skzia', 3],
  ['skilz', 4],
  ['slkbs', 4],
  ['slbte', 5],
  ['sldsl', 4],
  ['slfna', 5],
  ['slgbk', 4],
  ['slhgs', 6],
  ['slkba', 4],
  ['slken', 4],
  ['slnit', 1],
  ['slpep', 1],
  ['slsbo', 1],
  ['slsrk', 4],
  ['slwye', 6],
  ['smaq8', 3],
  ['smsai', 4],
  ['snbxe', 4],
  ['sndss', 6],
  ['sncsk', 4],
  ['sndkr', 5],
  ['snfou', 1],
  ['snklc', 5],
  ['snkgg', 4],
  ['snkda', 4],
  ['snlyn', 1],
  ['snmba', 1],
  ['snmax', 4],
  ['snnik', 4],
  ['snpod', 4],
  ['snrdt', 4],
  ['snxls', 5],
  ['snsmy', 4],
  ['sntud', 4],
  ['snzig', 5],
  ['soalu', 4],
  ['sobib', 4],
  ['sobsy', 4],
  ['sobbo', 5],
  ['sobxx', 4],
  ['sobsa', 4],
  ['sobuo', 4],
  ['socxn', 4],
  ['sohcm', 4],
  ['soelm', 3],
  ['soera', 4],
  ['soglk', 4],
  ['sogbm', 4],
  ['sogsr', 4],
  ['soggr', 4],
  ['soghr', 3],
  ['sohga', 4],
  ['sokmu', 5],
  ['solkr', 4],
  ['solgx', 4],
  ['somer', 1],
  ['somgq', 5],
  ['socmo', 4],
  ['socms', 4],
  ['srabn', 5],
  ['sraaj', 4],
  ['srbto', 4],
  ['srdoe', 4],
  ['srdrj', 4],
  ['srkcb', 4],
  ['srldo', 4],
  ['srllw', 1],
  ['srmoj', 5],
  ['srick', 5],
  ['sroem', 4],
  ['srpbm', 5],
  ['srorg', 4],
  ['srprm', 1],
  ['srsma', 1],
  ['srsmz', 4],
  ['srtot', 4],
  ['sragi', 5],
  ['srwso', 4],
  ['ssjub', 6],
  ['ssmak', 6],
  ['sswuu', 4],
  ['stpgp', 4],
  ['stpcp', 5],
  ['stsaa', 1],
  ['sttms', 5],
  ['svaqj', 3],
  ['svapo', 1],
  ['svsso', 1],
  ['svbel', 1],
  ['svman', 1],
  ['svlld', 3],
  ['svlun', 1],
  ['svmir', 1],
  ['svtue', 1],
  ['svnhz', 1],
  ['svncu', 1],
  ['svsal', 5],
  ['sxges', 3],
  ['sxmar', 1],
  ['sxphi', 3],
  ['sxsxm', 5],
  ['syald', 3],
  ['sysor', 4],
  ['syalp', 4],
  ['syarw', 3],
  ['syben', 3],
  ['syban', 3],
  ['sydam', 5],
  ['sydez', 4],
  ['syqdr', 4],
  ['sykac', 4],
  ['syltk', 5],
  ['sypms', 4],
  ['syqsw', 4],
  ['sytts', 3],
  ['sytao', 1],
  ['szsho', 4],
  ['szmal', 3],
  ['szmts', 4],
  ['szqmn', 4],
  ['sznhl', 3],
  ['sz7ds', 3],
  ['tcgdt', 5],
  ['tcmds', 4],
  ['tcnca', 5],
  ['tcpic', 4],
  ['tcpls', 5],
  ['tcslx', 5],
  ['tcxsc', 5],
  ['tdaod', 4],
  ['tdaeh', 4],
  ['tdamc', 4],
  ['tdatv', 4],
  ['tdbkr', 4],
  ['tdotc', 4],
  ['tdogr', 4],
  ['tdout', 4],
  ['tdfyt', 4],
  ['tdkmc', 6],
  ['tdltc', 4],
  ['tdamo', 4],
  ['tdmef', 4],
  ['tdmvo', 4],
  ['tdmqq', 4],
  ['tdndj', 4],
  ['tdoum', 4],
  ['tdplf', 4],
  ['tdsrh', 4],
  ['tdakm', 4],
  ['tfpfr', 1],
  ['tgane', 1],
  ['tgkpe', 1],
  ['tglfw', 5],
  ['tglrl', 4],
  ['thsht', 1],
  ['thbnk', 3],
  ['thmtp', 3],
  ['thbpg', 3],
  ['thbpk', 3],
  ['thbtp', 3],
  ['thbnd', 1],
  ['thbsp', 3],
  ['thbmt', 3],
  ['thbng', 1],
  ['thbtc', 1],
  ['thbai', 3],
  ['thbnl', 1],
  ['thben', 1],
  ['thbfv', 4],
  ['thcha', 1],
  ['thcnx', 4],
  ['thchs', 3],
  ['thcjm', 4],
  ['thchu', 1],
  ['thdmk', 4],
  ['thect', 1],
  ['thera', 1],
  ['thhdy', 4],
  ['thhop', 1],
  ['thhhq', 4],
  ['thkpt', 3],
  ['thkan', 3],
  ['thkha', 1],
  ['thkly', 3],
  ['thkkm', 4],
  ['thkkc', 6],
  ['thksm', 1],
  ['thkla', 1],
  ['thkno', 1],
  ['thksi', 1],
  ['thkyo', 1],
  ['thkrv', 1],
  ['thkbv', 4],
  ['thlch', 3],
  ['thlpt', 4],
  ['thlas', 1],
  ['thloe', 4],
  ['thmat', 1],
  ['thcei', 4],
  ['thhgn', 4],
  ['thmaq', 4],
  ['thmek', 1],
  ['thkop', 4],
  ['thnak', 4],
  ['thnkt', 1],
  ['thnst', 4],
  ['thnnt', 4],
  ['thnrw', 1],
  ['thnaw', 4],
  ['thnhp', 1],
  ['thnim', 1],
  ['thpyy', 4],
  ['thpkb', 1],
  ['thpkn', 1],
  ['thpkp', 1],
  ['thptn', 1],
  ['thpan', 4],
  ['thpyx', 6],
  ['thpyt', 1],
  ['thpha', 1],
  ['thphy', 4],
  ['thphs', 4],
  ['thptr', 3],
  ['thkru', 3],
  ['thppa', 3],
  ['thprh', 4],
  ['thhkt', 4],
  ['thpkt', 1],
  ['thpla', 1],
  ['thppc', 1],
  ['thrnn', 1],
  ['thunn', 4],
  ['throi', 4],
  ['thscs', 3],
  ['thsno', 4],
  ['thusm', 4],
  ['thsth', 3],
  ['thsat', 1],
  ['thsir', 3],
  ['thsbp', 3],
  ['thssw', 1],
  ['thsic', 1],
  ['thsgk', 3],
  ['thsri', 1],
  ['thths', 4],
  ['thurt', 4],
  ['thsia', 6],
  ['thi6c', 1],
  ['thtac', 1],
  ['thtkt', 4],
  ['thtab', 3],
  ['thtkh', 4],
  ['ththa', 1],
  ['thtrg', 1],
  ['thtst', 4],
  ['thtdx', 4],
  ['thtpp', 1],
  ['thutp', 4],
  ['thubp', 4],
  ['thuth', 4],
  ['thuct', 3],
  ['tjdyu', 4],
  ['tjlbd', 4],
  ['tkafu', 1],
  ['tkfko', 1],
  ['tknku', 1],
  ['tlaut', 4],
  ['tlbch', 4],
  ['tlkoe', 4],
  ['tlmpt', 4],
  ['tloec', 4],
  ['tlviq', 4],
  ['tmalt', 1],
  ['tmasb', 6],
  ['tmche', 3],
  ['tmhal', 3],
  ['tmkra', 3],
  ['tmmyp', 4],
  ['tmtaz', 4],
  ['tmcrz', 4],
  ['tmtmz', 1],
  ['tnhmm', 3],
  ['tntze', 3],
  ['tnari', 1],
  ['tnash', 1],
  ['tnbjc', 3],
  ['tnchb', 3],
  ['tndid', 1],
  ['tnebm', 4],
  ['tnnbe', 4],
  ['tngae', 5],
  ['tngaf', 4],
  ['tnjar', 1],
  ['tnqkn', 4],
  ['tnkeb', 1],
  ['tnkem', 1],
  ['tnlgn', 3],
  ['tnlsk', 3],
  ['tnmad', 3],
  ['tnmba', 3],
  ['tnmir', 6],
  ['tnoud', 1],
  ['tnrds', 3],
  ['tnsln', 3],
  ['tntbj', 6],
  ['tntat', 1],
  ['tntoe', 4],
  ['tnzrz', 3],
  ['toeua', 4],
  ['tohpa', 5],
  ['tonei', 1],
  ['tonfo', 4],
  ['tontt', 4],
  ['totbu', 5],
  ['topan', 1],
  ['tovav', 4],
  ['trtgt', 3],
  ['trada', 5],
  ['trafy', 6],
  ['traji', 4],
  ['trakc', 1],
  ['trakd', 1],
  ['trakb', 1],
  ['trgkv', 1],
  ['traky', 1],
  ['trala', 1],
  ['trali', 1],
  ['trayd', 1],
  ['trama', 1],
  ['tramr', 3],
  ['trana', 1],
  ['trank', 6],
  ['trard', 6],
  ['traya', 1],
  ['trayv', 1],
  ['trbbp', 1],
  ['trbzi', 5],
  ['trbal', 4],
  ['trbts', 3],
  ['trbey', 1],
  ['trbbi', 3],
  ['trbxn', 5],
  ['trbog', 1],
  ['trbol', 1],
  ['trbni', 6],
  ['trbot', 1],
  ['trbzb', 1],
  ['trbzc', 1],
  ['trbhn', 1],
  ['trbuy', 1],
  ['trszf', 4],
  ['trcid', 1],
  ['trcub', 1],
  ['trdlm', 4],
  ['trdar', 1],
  ['trdat', 1],
  ['trdrc', 3],
  ['trdik', 1],
  ['trdil', 1],
  ['trdiy', 6],
  ['trdyl', 1],
  ['tredi', 1],
  ['trezs', 4],
  ['trene', 1],
  ['trerk', 1],
  ['trere', 3],
  ['trerc', 6],
  ['trerz', 6],
  ['tresb', 4],
  ['tresk', 6],
  ['treyp', 3],
  ['trfas', 1],
  ['trfet', 1],
  ['trfin', 1],
  ['trfrz', 3],
  ['trfoc', 1],
  ['trgta', 1],
  ['trgzt', 6],
  ['trgeb', 3],
  ['trgel', 3],
  ['trgem', 1],
  ['trgze', 1],
  ['trgir', 1],
  ['trgck', 1],
  ['trgca', 1],
  ['trgor', 1],
  ['trgul', 1],
  ['trhar', 1],
  ['trhas', 1],
  ['trhat', 4],
  ['trhay', 3],
  ['trher', 1],
  ['trhis', 3],
  ['trhop', 3],
  ['trids', 1],
  ['trign', 1],
  ['trine', 1],
  ['tridm', 1],
  ['trisk', 3],
  ['trise', 4],
  ['trisl', 4],
  ['tradb', 4],
  ['trizt', 3],
  ['trkbs', 3],
  ['trkcm', 4],
  ['trkrb', 1],
  ['trkby', 1],
  ['trkky', 3],
  ['trkrs', 3],
  ['trkrt', 1],
  ['trksy', 4],
  ['trkar', 1],
  ['trkpe', 3],
  ['trkas', 1],
  ['trktm', 4],
  ['trasr', 6],
  ['trkfk', 1],
  ['trkmr', 3],
  ['trkco', 4],
  ['trkn2', 3],
  ['trkya', 6],
  ['trkmx', 3],
  ['trkus', 3],
  ['trkoc', 1],
  ['trkfz', 3],
  ['trlma', 1],
  ['trmlx', 4],
  ['trmal', 1],
  ['trmvg', 3],
  ['trmad', 3],
  ['trmra', 1],
  ['trmrm', 1],
  ['trmpt', 3],
  ['trmqj', 4],
  ['trmer', 3],
  ['trmfz', 3],
  ['trmzh', 4],
  ['trmil', 4],
  ['trmim', 1],
  ['trmud', 3],
  ['trmsr', 4],
  ['trnem', 1],
  ['trnev', 4],
  ['trm47', 3],
  ['trodu', 1],
  ['trord', 1],
  ['tr9ak', 3],
  ['trpzr', 1],
  ['trpen', 1],
  ['trplp', 1],
  ['trksp', 1],
  ['trriz', 1],
  ['trrzy', 1],
  ['trsaw', 4],
  ['trssx', 5],
  ['trsfq', 4],
  ['trsrl', 1],
  ['trsrs', 3],
  ['trser', 1],
  ['trsxz', 4],
  ['trsle', 1],
  ['trsil', 3],
  ['trsic', 5],
  ['trvas', 6],
  ['trsur', 1],
  ['trsut', 1],
  ['trtas', 1],
  ['trtir', 1],
  ['trtjk', 4],
  ['trtur', 1],
  ['trtut', 1],
  ['trtuz', 1],
  ['trurl', 1],
  ['trusa', 4],
  ['trvak', 1],
  ['trvan', 6],
  ['tryal', 3],
  ['tryar', 1],
  ['traut', 1],
  ['tryik', 1],
  ['trypo', 3],
  ['trady', 1],
  ['trzey', 1],
  ['trzon', 1],
  ['trcam', 1],
  ['trckz', 1],
  ['trcum', 6],
  ['trces', 1],
  ['truny', 1],
  ['trusk', 1],
  ['ttcrn', 3],
  ['ttcha', 1],
  ['ttchv', 3],
  ['ttcla', 3],
  ['ttcva', 3],
  ['ttgua', 3],
  ['ttlab', 1],
  ['ttply', 1],
  ['ttptf', 1],
  ['ttptg', 1],
  ['ttpts', 1],
  ['ttptp', 1],
  ['ttpos', 5],
  ['ttfpt', 3],
  ['ttsfe', 1],
  ['ttsca', 1],
  ['tttem', 5],
  ['tttab', 4],
  ['tvfun', 5],
  ['twapg', 1],
  ['twbal', 3],
  ['twcmj', 4],
  ['twcyi', 4],
  ['twgni', 4],
  ['twhcn', 4],
  ['twhop', 1],
  ['twhsz', 6],
  ['twhtc', 3],
  ['twhun', 5],
  ['twhuk', 1],
  ['twkhh', 5],
  ['twkel', 1],
  ['twknh', 4],
  ['twlhn', 4],
  ['twlgm', 1],
  ['twmal', 1],
  ['twmli', 1],
  ['twmzg', 4],
  ['twmfk', 4],
  ['twnan', 3],
  ['twkyd', 4],
  ['twpif', 4],
  ['twshl', 3],
  ['twsuo', 1],
  ['twsmt', 4],
  ['twtxg', 5],
  ['twtnn', 5],
  ['twtpe', 5],
  ['twtsa', 4],
  ['twttt', 5],
  ['twwot', 4],
  ['twwtu', 3],
  ['twyge', 1],
  ['twyln', 1],
  ['tzmko', 1],
  ['tzark', 4],
  ['tzbkz', 4],
  ['tzdod', 4],
  ['tzgit', 6],
  ['tzikw', 3],
  ['tziri', 4],
  ['tzkag', 1],
  ['tztkq', 5],
  ['tzjro', 4],
  ['tzkiy', 4],
  ['tzkik', 1],
  ['tzkim', 1],
  ['tzlky', 4],
  ['tzldi', 5],
  ['tzluy', 4],
  ['tzmfa', 4],
  ['tzxmi', 4],
  ['tzmbi', 4],
  ['tzmic', 1],
  ['tzmik', 1],
  ['tzmoh', 1],
  ['tzmpa', 6],
  ['tzmts', 1],
  ['tzmyw', 5],
  ['tzmuz', 4],
  ['tzmwn', 4],
  ['tzmwz', 5],
  ['tznch', 4],
  ['tzjom', 4],
  ['tzpan', 1],
  ['tzpma', 5],
  ['tzrij', 1],
  ['tzsam', 1],
  ['tzseu', 4],
  ['tzshy', 4],
  ['tzsgx', 4],
  ['tzsut', 4],
  ['tztbo', 4],
  ['tztgt', 5],
  ['tzznz', 5],
  ['uaerd', 1],
  ['uabgd', 1],
  ['uakbp', 4],
  ['uackc', 4],
  ['uacej', 4],
  ['uacwc', 6],
  ['uailk', 3],
  ['uadnk', 4],
  ['uadok', 6],
  ['uaifo', 6],
  ['uaizm', 1],
  ['uakcp', 4],
  ['uahrk', 4],
  ['uakhe', 5],
  ['uahmj', 4],
  ['uakia', 1],
  ['uakrq', 4],
  ['uakhu', 4],
  ['uakwg', 4],
  ['uakgo', 4],
  ['uaiev', 4],
  ['ualwo', 4],
  ['uavsg', 4],
  ['uauck', 4],
  ['uampw', 5],
  ['uamxr', 4],
  ['uaoct', 3],
  ['uayuz', 1],
  ['uaplv', 4],
  ['uarni', 1],
  ['uaxxx', 1],
  ['uarwn', 4],
  ['uasev', 4],
  ['uasip', 4],
  ['uaskd', 3],
  ['uaumy', 4],
  ['uadsk', 3],
  ['uatnl', 4],
  ['uaudy', 1],
  ['uavin', 4],
  ['uavyl', 1],
  ['uazpr', 5],
  ['uaozh', 4],
  ['uaztr', 4],
  ['ugrua', 4],
  ['ugebb', 4],
  ['ugmkn', 1],
  ['ugulu', 4],
  ['ugjin', 5],
  ['ugkbg', 4],
  ['ugkla', 4],
  ['ugkse', 6],
  ['ugkcu', 4],
  ['ugmbq', 6],
  ['ugoyg', 4],
  ['ugpaf', 4],
  ['ugsrt', 4],
  ['ugtry', 6],
  ['ugymb', 1],
  ['umjon', 5],
  ['ummdy', 5],
  ['umawk', 5],
  ['uscs6', 3],
  ['ususb', 3],
  ['usdog', 3],
  ['usfmw', 3],
  ['uszfi', 3],
  ['usgns', 3],
  ['ushkg', 1],
  ['usnrp', 3],
  ['uspm4', 3],
  ['usihv', 6],
  ['uspgy', 3],
  ['usxpg', 1],
  ['ustwm', 3],
  ['usnyi', 3],
  ['usyta', 3],
  ['usttv', 3],
  ['usafw', 3],
  ['uswgb', 3],
  ['usaqw', 1],
  ['usab3', 3],
  ['usabr', 6],
  ['usaek', 3],
  ['usapg', 6],
  ['usabi', 6],
  ['usa8c', 3],
  ['usadk', 4],
  ['usdst', 3],
  ['usiab', 3],
  ['usadg', 4],
  ['usdia', 3],
  ['usafk', 1],
  ['usaf5', 3],
  ['usafo', 4],
  ['usaik', 6],
  ['usaw2', 6],
  ['usakk', 4],
  ['uskki', 4],
  ['usaki', 4],
  ['usakh', 6],
  ['usako', 4],
  ['uskqa', 4],
  ['usaa2', 1],
  ['usahu', 3],
  ['usauk', 4],
  ['usngz', 5],
  ['usalm', 6],
  ['usals', 4],
  ['usyan', 3],
  ['usaby', 4],
  ['usalb', 5],
  ['uscvo', 4],
  ['usael', 6],
  ['usabq', 4],
  ['usal7', 1],
  ['uswkk', 4],
  ['usaed', 4],
  ['usalx', 4],
  ['usaxn', 6],
  ['usaxr', 3],
  ['usesf', 6],
  ['usaxg', 6],
  ['usagq', 3],
  ['usali', 4],
  ['usaiv', 6],
  ['usalz', 4],
  ['usaet', 4],
  ['usag3', 4],
  ['usedl', 3],
  ['usatx', 3],
  ['usaia', 6],
  ['usal3', 1],
  ['usamn', 6],
  ['usxam', 3],
  ['usapn', 4],
  ['usale', 4],
  ['usapy', 3],
  ['usafa', 3],
  ['ustaj', 1],
  ['usaln', 6],
  ['uslts', 6],
  ['usxak', 1],
  ['uszad', 1],
  ['usaqy', 6],
  ['usamf', 3],
  ['usama', 4],
  ['usabl', 4],
  ['usqhh', 1],
  ['usaht', 4],
  ['usalc', 3],
  ['usahh', 6],
  ['usamw', 6],
  ['usayz', 6],
  ['usaos', 4],
  ['usots', 6],
  ['usndv', 4],
  ['usnko', 1],
  ['usana', 6],
  ['usakp', 4],
  ['usanc', 5],
  ['usacq', 3],
  ['usaid', 6],
  ['usand', 6],
  ['usaxz', 1],
  ['usadr', 6],
  ['usubo', 6],
  ['usaxx', 4],
  ['usanq', 6],
  ['usagn', 4],
  ['usrfk', 4],
  ['usani', 4],
  ['usaib', 4],
  ['usarb', 5],
  ['usanp', 5],
  ['usann', 4],
  ['usnqh', 1],
  ['usanb', 6],
  ['usvfr', 1],
  ['usany', 4],
  ['usate', 4],
  ['usanv', 4],
  ['usaaf', 5],
  ['usapv', 6],
  ['usatw', 5],
  ['usyas', 3],
  ['usjar', 6],
  ['usacv', 4],
  ['usarc', 4],
  ['usadm', 6],
  ['usjyr', 1],
  ['usioa', 3],
  ['usjlh', 6],
  ['usaez', 3],
  ['usatm', 6],
  ['usats', 4],
  ['usarx', 4],
  ['usaev', 6],
  ['usasd', 6],
  ['usasx', 5],
  ['ushts', 4],
  ['usasy', 4],
  ['usasf', 3],
  ['usa22', 1],
  ['usoax', 3],
  ['usase', 4],
  ['usast', 5],
  ['usthn', 3],
  ['usahn', 6],
  ['usato', 6],
  ['usmmi', 6],
  ['usakb', 4],
  ['usatl', 4],
  ['usaio', 4],
  ['ustbh', 3],
  ['usaiy', 6],
  ['usahz', 3],
  ['usatt', 4],
  ['usatk', 4],
  ['usatr', 1],
  ['usatu', 4],
  ['usatz', 6],
  ['usaun', 6],
  ['usauo', 6],
  ['uslew', 6],
  ['usags', 6],
  ['usaug', 6],
  ['usvr9', 3],
  ['usvau', 1],
  ['usauz', 6],
  ['usyao', 3],
  ['usasq', 6],
  ['usaum', 6],
  ['usaus', 6],
  ['usagv', 3],
  ['usaou', 3],
  ['usav9', 1],
  ['usanw', 3],
  ['usavo', 6],
  ['usavd', 3],
  ['usazl', 3],
  ['usbgt', 6],
  ['usgaj', 3],
  ['usbge', 6],
  ['usbke', 6],
  ['usbar', 4],
  ['usbfl', 6],
  ['usfl8', 1],
  ['usldk', 3],
  ['us2by', 1],
  ['usba7', 1],
  ['usbal', 3],
  ['usbwi', 4],
  ['usbdy', 4],
  ['usbgr', 5],
  ['usbng', 4],
  ['usbhb', 5],
  ['usrga', 3],
  ['usbnf', 4],
  ['usnax', 6],
  ['usrux', 1],
  ['usbg7', 3],
  ['usbnl', 4],
  ['usmpv', 4],
  ['usbrw', 4],
  ['usbti', 4],
  ['usbvo', 6],
  ['usbsz', 4],
  ['usbow', 6],
  ['usytj', 1],
  ['uszbp', 3],
  ['ushlb', 6],
  ['usafh', 3],
  ['usbth', 3],
  ['usbtr', 5],
  ['usafv', 3],
  ['usbtl', 4],
  ['usbn9', 1],
  ['usbam', 4],
  ['usbde', 5],
  ['usbbc', 4],
  ['usbcy', 6],
  ['usybz', 3],
  ['usmbs', 6],
  ['usbay', 3],
  ['ushpy', 5],
  ['usbcc', 4],
  ['usbie', 6],
  ['usbty', 4],
  ['usbfo', 3],
  ['usbpt', 5],
  ['uswbq', 4],
  ['usbd3', 3],
  ['usbfp', 6],
  ['usbvd', 4],
  ['usbi2', 1],
  ['usbko', 3],
  ['usbkw', 6],
  ['usbed', 6],
  ['usbfr', 4],
  ['usnir', 6],
  ['uszgg', 1],
  ['usyyx', 1],
  ['usybd', 3],
  ['usba3', 3],
  ['usbel', 3],
  ['usbpv', 3],
  ['uskbe', 4],
  ['usacb', 4],
  ['usb44', 1],
  ['usbcs', 4],
  ['usbe8', 1],
  ['uszbf', 6],
  ['uspsb', 6],
  ['usblv', 6],
  ['usewo', 3],
  ['usblm', 6],
  ['usbvu', 4],
  ['usbji', 6],
  ['usbzo', 6],
  ['usrdm', 6],
  ['usbnc', 3],
  ['usbtn', 4],
  ['usbnv', 6],
  ['usbbb', 4],
  ['usijm', 1],
  ['usbeh', 6],
  ['usjbk', 5],
  ['usbci', 3],
  ['usbml', 6],
  ['useie', 3],
  ['us3br', 3],
  ['usbvv', 3],
  ['usrwk', 3],
  ['usbba', 3],
  ['usbet', 4],
  ['usbzj', 1],
  ['usehl', 3],
  ['usbpa', 4],
  ['usbtt', 4],
  ['usbcl', 3],
  ['usbvy', 6],
  ['usrbf', 4],
  ['usbic', 4],
  ['usbig', 6],
  ['usbgq', 4],
  ['usbmx', 4],
  ['usbpi', 4],
  ['uswbr', 4],
  ['usbsm', 3],
  ['ushca', 4],
  ['usigs', 1],
  ['usgqj', 3],
  ['usbwh', 3],
  ['usbil', 6],
  ['usbix', 4],
  ['usafm', 3],
  ['usbio', 6],
  ['usbgm', 4],
  ['uskbc', 4],
  ['usiwo', 3],
  ['usbhm', 6],
  ['usxy2', 1],
  ['uswo4', 1],
  ['usbsq', 4],
  ['usbds', 3],
  ['usbih', 4],
  ['usbis', 6],
  ['ushwj', 1],
  ['usbzz', 3],
  ['usbcb', 4],
  ['usbkt', 6],
  ['usbwl', 4],
  ['usbws', 6],
  ['usbv6', 1],
  ['usbsi', 4],
  ['usbyw', 4],
  ['usbdg', 4],
  ['uslxw', 3],
  ['usbid', 4],
  ['usoom', 3],
  ['usbmg', 6],
  ['usbmi', 4],
  ['usbi8', 3],
  ['usbbx', 4],
  ['usblu', 4],
  ['usluf', 3],
  ['usbfb', 4],
  ['usblh', 4],
  ['usbyh', 4],
  ['usbct', 4],
  ['usdhb', 3],
  ['usbf3', 6],
  ['usbxa', 4],
  ['uszzb', 3],
  ['usboi', 6],
  ['usb27', 3],
  ['usbnk', 6],
  ['uszbi', 3],
  ['usybg', 3],
  ['usyby', 3],
  ['usbf6', 3],
  ['usbnw', 4],
  ['usoyh', 3],
  ['usrlu', 4],
  ['usbxs', 4],
  ['usbos', 5],
  ['usbsw', 4],
  ['usyth', 3],
  ['uswbu', 4],
  ['usbld', 4],
  ['usbya', 4],
  ['usbtf', 4],
  ['ususa', 3],
  ['ustub', 3],
  ['usaph', 4],
  ['usbwg', 6],
  ['usbwm', 4],
  ['usybn', 3],
  ['usbyz', 3],
  ['usbzn', 4],
  ['ussrq', 6],
  ['usbdf', 4],
  ['usbfd', 6],
  ['usbra', 1],
  ['usbbd', 4],
  ['usbrd', 4],
  ['usbhf', 3],
  ['usplk', 4],
  ['usbwc', 6],
  ['usbnp', 3],
  ['usbzt', 4],
  ['usbkd', 6],
  ['uszwb', 1],
  ['uspwt', 5],
  ['usbm4', 1],
  ['usbcw', 3],
  ['usbfj', 3],
  ['usbhc', 3],
  ['usbdb', 3],
  ['usbdr', 5],
  ['usb7s', 3],
  ['usrdn', 6],
  ['usbmc', 4],
  ['ustjb', 1],
  ['usb2n', 1],
  ['usbfs', 6],
  ['usbso', 6],
  ['usbzl', 6],
  ['ustri', 6],
  ['ustto', 4],
  ['usbb8', 3],
  ['usbdx', 4],
  ['usbbw', 4],
  ['usbm3', 1],
  ['ustsz', 3],
  ['uszbh', 6],
  ['usbkx', 4],
  ['usbok', 4],
  ['usboc', 3],
  ['usboy', 3],
  ['usbkf', 4],
  ['usrbh', 4],
  ['usbkq', 3],
  ['usbjc', 4],
  ['usqxy', 3],
  ['usbv8', 3],
  ['uszvb', 3],
  ['usbwd', 4],
  ['usbhi', 3],
  ['usbcm', 3],
  ['usnhz', 6],
  ['usssi', 5],
  ['usbch', 3],
  ['uscfd', 6],
  ['usbce', 4],
  ['usbxk', 6],
  ['usbfy', 3],
  ['usbkc', 4],
  ['usbup', 3],
  ['usb2v', 1],
  ['usbuf', 5],
  ['usbyg', 4],
  ['usffo', 3],
  ['usbfg', 4],
  ['usifp', 4],
  ['uszba', 1],
  ['usulo', 3],
  ['usbur', 4],
  ['uszbu', 6],
  ['usbyi', 4],
  ['usbbf', 4],
  ['usbhj', 3],
  ['usbrl', 4],
  ['usbtv', 5],
  ['usqri', 3],
  ['usbnh', 3],
  ['usbno', 4],
  ['usbnb', 3],
  ['usbni', 3],
  ['usbs9', 1],
  ['usbtp', 6],
  ['usbui', 3],
  ['usbuj', 3],
  ['usbum', 4],
  ['usbtm', 6],
  ['usbu2', 1],
  ['usxyb', 3],
  ['uscbz', 4],
  ['uscad', 4],
  ['usycz', 3],
  ['uscir', 4],
  ['uslbq', 3],
  ['usmi3', 1],
  ['usawe', 6],
  ['uscdw', 4],
  ['uscdz', 3],
  ['uscxl', 6],
  ['usclr', 4],
  ['uscwg', 4],
  ['uscct', 1],
  ['usaeo', 3],
  ['uscto', 4],
  ['usamg', 3],
  ['uscbd', 3],
  ['usjhy', 6],
  ['usaqq', 1],
  ['uscdd', 3],
  ['uscde', 3],
  ['uscdh', 6],
  ['uscdn', 4],
  ['usxae', 3],
  ['uscmu', 3],
  ['usvok', 4],
  ['usadw', 4],
  ['usczz', 4],
  ['uscum', 3],
  ['uscnd', 6],
  ['uscdl', 4],
  ['uscdf', 3],
  ['uscuq', 3],
  ['uscuu', 3],
  ['uscne', 4],
  ['usctk', 4],
  ['uspb7', 1],
  ['ustpc', 3],
  ['usccj', 3],
  ['uscc2', 3],
  ['uscgi', 6],
  ['usxpy', 1],
  ['uslur', 4],
  ['uscgf', 3],
  ['usehm', 4],
  ['usczp', 4],
  ['usczf', 4],
  ['uscsp', 4],
  ['uscvi', 3],
  ['uscap', 3],
  ['uscgp', 3],
  ['us4cp', 1],
  ['usmdh', 6],
  ['uscar', 4],
  ['uscsj', 3],
  ['uscuz', 3],
  ['uscld', 6],
  ['usmry', 6],
  ['usngi', 6],
  ['uscjo', 3],
  ['uscpt', 3],
  ['uscrb', 3],
  ['uszrr', 3],
  ['uscm9', 1],
  ['usczt', 4],
  ['uscin', 6],
  ['uscwu', 3],
  ['uscsn', 4],
  ['uscsf', 3],
  ['uscgx', 3],
  ['usqch', 3],
  ['usyce', 3],
  ['uscgz', 6],
  ['usczk', 4],
  ['uscpr', 6],
  ['uscib', 3],
  ['usrwx', 1],
  ['usczi', 3],
  ['usxss', 3],
  ['usomw', 3],
  ['usc4h', 1],
  ['usxsd', 1],
  ['uscki', 5],
  ['usumo', 3],
  ['usycd', 3],
  ['usqac', 3],
  ['usccs', 3],
  ['usyu5', 1],
  ['uszcz', 3],
  ['usc3b', 1],
  ['uscdc', 6],
  ['usxza', 1],
  ['usycg', 3],
  ['uscdk', 6],
  ['uscdu', 3],
  ['uscid', 6],
  ['usdiv', 3],
  ['uszrh', 3],
  ['uscws', 4],
  ['uscwq', 3],
  ['uscbs', 3],
  ['uscwf', 3],
  ['useti', 3],
  ['usghm', 4],
  ['usl99', 1],
  ['uscem', 4],
  ['usycp', 3],
  ['usenl', 6],
  ['uszct', 3],
  ['usvvz', 3],
  ['usw6t', 3],
  ['uscgj', 3],
  ['uscdr', 4],
  ['uscik', 4],
  ['uschl', 4],
  ['usclq', 3],
  ['uscmi', 6],
  ['usnmh', 3],
  ['uswcr', 4],
  ['uschd', 4],
  ['uscnu', 4],
  ['us9cp', 1],
  ['usp3n', 3],
  ['uszoi', 3],
  ['usccy', 4],
  ['uschs', 5],
  ['uscrw', 5],
  ['uszgt', 3],
  ['usclt', 4],
  ['uscho', 6],
  ['usca3', 1],
  ['uscym', 4],
  ['usts4', 1],
  ['uscha', 6],
  ['usqcq', 3],
  ['uscyf', 4],
  ['uscls', 4],
  ['usysc', 1],
  ['uscex', 4],
  ['usdge', 3],
  ['ushcw', 4],
  ['uskcn', 4],
  ['uscka', 4],
  ['usckg', 3],
  ['usckk', 4],
  ['uscff', 3],
  ['uscc8', 3],
  ['uskqc', 3],
  ['uscht', 3],
  ['uscr5', 3],
  ['uscfg', 3],
  ['usxtk', 3],
  ['usvak', 4],
  ['usqcc', 3],
  ['uscys', 4],
  ['uszyn', 3],
  ['uschi', 5],
  ['uscgw', 3],
  ['uschk', 6],
  ['usckx', 4],
  ['uscic', 6],
  ['uscef', 4],
  ['uskcl', 4],
  ['uszcg', 3],
  ['uscds', 6],
  ['uschz', 4],
  ['usci6', 1],
  ['uswal', 4],
  ['uscno', 4],
  ['uscjc', 3],
  ['usczn', 4],
  ['ushib', 4],
  ['usczo', 4],
  ['uscxc', 4],
  ['usciv', 4],
  ['uschu', 4],
  ['uszcb', 3],
  ['usirc', 4],
  ['uschp', 4],
  ['uszlp', 3],
  ['usis3', 3],
  ['usxmy', 1],
  ['uszcy', 3],
  ['uszcc', 3],
  ['usqai', 3],
  ['uscnh', 4],
  ['usco3', 1],
  ['usrnn', 3],
  ['uszdc', 3],
  ['usicl', 4],
  ['uscww', 3],
  ['usclp', 4],
  ['uscg4', 3],
  ['usckb', 4],
  ['usckm', 4],
  ['usckv', 4],
  ['usyck', 3],
  ['usvxj', 1],
  ['uszcs', 3],
  ['uscla', 3],
  ['usqcb', 3],
  ['uscao', 4],
  ['uscly', 3],
  ['uscn3', 1],
  ['uszcl', 6],
  ['uspsb', 6],
  ['usclc', 6],
  ['usrxo', 3],
  ['us2pa', 3],
  ['usclw', 4],
  ['usewg', 3],
  ['usceu', 6],
  ['usiuh', 1],
  ['usern', 3],
  ['uscwx', 3],
  ['us2to', 3],
  ['uscft', 4],
  ['usift', 3],
  ['usjmx', 1],
  ['uszqc', 3],
  ['usc4n', 1],
  ['uscbt', 3],
  ['uscsm', 6],
  ['usctz', 4],
  ['uscwi', 4],
  ['uscwz', 3],
  ['uscli', 4],
  ['uscxv', 3],
  ['usxvr', 3],
  ['uscyi', 3],
  ['uscvn', 6],
  ['uscu2', 3],
  ['usqcn', 3],
  ['uszcv', 3],
  ['uszqz', 3],
  ['usclg', 4],
  ['uscth', 6],
  ['usck2', 1],
  ['usc55', 3],
  ['uscoj', 3],
  ['usck9', 1],
  ['uscoi', 4],
  ['usnvv', 3],
  ['uscoe', 4],
  ['uscfa', 4],
  ['uskcc', 4],
  ['usyww', 1],
  ['uscbk', 6],
  ['uscdb', 4],
  ['ushq2', 1],
  ['uscxf', 4],
  ['usqim', 3],
  ['uscom', 4],
  ['uscu6', 1],
  ['usofx', 3],
  ['uscgs', 4],
  ['uscll', 4],
  ['usl55', 3],
  ['usycl', 6],
  ['uskcr', 4],
  ['uscos', 6],
  ['usrto', 6],
  ['uscae', 4],
  ['uscoa', 4],
  ['uscou', 6],
  ['usmrc', 6],
  ['usynj', 3],
  ['usycu', 3],
  ['usclu', 6],
  ['uscmh', 4],
  ['uscsg', 6],
  ['uscus', 6],
  ['usolu', 6],
  ['usubs', 6],
  ['usvda', 4],
  ['usomc', 6],
  ['uszec', 3],
  ['usomm', 3],
  ['uscpm', 4],
  ['usccr', 4],
  ['uscon', 4],
  ['usjqq', 1],
  ['uscnk', 4],
  ['uscdy', 3],
  ['usc25', 1],
  ['uscev', 4],
  ['uscxo', 6],
  ['usgcl', 3],
  ['usjla', 4],
  ['uscop', 4],
  ['uscob', 3],
  ['usopb', 3],
  ['usco9', 3],
  ['usczc', 4],
  ['usqce', 4],
  ['uscro', 4],
  ['uscdv', 5],
  ['uscrx', 6],
  ['uscba', 4],
  ['usoig', 6],
  ['usc35', 3],
  ['uszzc', 3],
  ['uscd4', 3],
  ['uscrp', 5],
  ['usto3', 3],
  ['uscrs', 6],
  ['uscez', 4],
  ['uscj8', 3],
  ['usctx', 4],
  ['uscvo', 4],
  ['usctw', 4],
  ['us2tt', 1],
  ['uscot', 6],
  ['uscil', 4],
  ['usouc', 6],
  ['uscbf', 4],
  ['usuge', 3],
  ['uscp6', 1],
  ['usyhe', 3],
  ['usqcz', 3],
  ['uscga', 4],
  ['uscig', 4],
  ['uszrc', 3],
  ['usccg', 4],
  ['usckr', 4],
  ['uszce', 3],
  ['usqap', 3],
  ['uscse', 4],
  ['usc8t', 1],
  ['uscsq', 6],
  ['uscew', 4],
  ['usc74', 1],
  ['uscrm', 3],
  ['uszcw', 3],
  ['usckd', 4],
  ['usckn', 4],
  ['uscty', 4],
  ['uscrt', 4],
  ['uscsv', 6],
  ['usthu', 3],
  ['usnrc', 4],
  ['usv7x', 3],
  ['uscye', 4],
  ['uscuw', 4],
  ['uscvr', 4],
  ['uscbe', 4],
  ['ushxd', 1],
  ['usudc', 3],
  ['uscuh', 4],
  ['usctb', 4],
  ['usyxb', 1],
  ['usdag', 4],
  ['usd5w', 3],
  ['usdck', 4],
  ['usdgn', 4],
  ['usyde', 3],
  ['usdht', 4],
  ['usdal', 6],
  ['usdc6', 3],
  ['usdfw', 4],
  ['usdnn', 4],
  ['usar5', 1],
  ['usdxr', 4],
  ['usdne', 3],
  ['usdgb', 4],
  ['usdsv', 4],
  ['usdan', 6],
  ['usdvi', 6],
  ['usdha', 3],
  ['usddr', 3],
  ['usda8', 1],
  ['usdvt', 3],
  ['usdav', 3],
  ['usdfb', 6],
  ['usdvv', 3],
  ['usday', 4],
  ['usdy2', 3],
  ['usdab', 4],
  ['usdu5', 1],
  ['usdrw', 3],
  ['usdri', 4],
  ['usdt4', 3],
  ['usdwx', 3],
  ['usdeo', 4],
  ['usdth', 4],
  ['usdcr', 4],
  ['usdcu', 4],
  ['usdec', 5],
  ['usdeu', 6],
  ['usdtr', 4],
  ['usdeh', 6],
  ['uswdb', 4],
  ['use5p', 3],
  ['usdp5', 3],
  ['usdpk', 4],
  ['usfjy', 1],
  ['usdrg', 4],
  ['usdwd', 3],
  ['usdfi', 4],
  ['usdcb', 3],
  ['usdn2', 3],
  ['usdrt', 4],
  ['usdci', 3],
  ['usdva', 3],
  ['usdhx', 6],
  ['usdij', 1],
  ['usydp', 3],
  ['usdvq', 3],
  ['usal5', 1],
  ['usdta', 4],
  ['usdtc', 1],
  ['usdjn', 4],
  ['usdmn', 4],
  ['useyy', 6],
  ['usdns', 4],
  ['usden', 4],
  ['usde5', 1],
  ['usqdq', 3],
  ['usde3', 1],
  ['usdsm', 5],
  ['usdsh', 3],
  ['usdsr', 3],
  ['usdsi', 4],
  ['usnew', 3],
  ['us7al', 3],
  ['usdtt', 3],
  ['usdtl', 6],
  ['usqdv', 3],
  ['usdvl', 4],
  ['usdm4', 1],
  ['usdik', 4],
  ['uszkn', 3],
  ['usdcy', 1],
  ['usdlg', 4],
  ['usdll', 6],
  ['usdln', 4],
  ['usdio', 4],
  ['usjho', 1],
  ['usddc', 6],
  ['usqdy', 3],
  ['usdlo', 4],
  ['uszde', 3],
  ['usdsl', 3],
  ['usdph', 3],
  ['usdof', 4],
  ['usdzm', 3],
  ['usdhn', 6],
  ['usdug', 4],
  ['usdgw', 4],
  ['usdov', 4],
  ['usjdy', 6],
  ['usdwq', 1],
  ['usdyl', 4],
  ['usdrf', 4],
  ['usdru', 4],
  ['usdre', 4],
  ['usdr3', 3],
  ['usdbn', 4],
  ['uspsk', 4],
  ['usdbs', 6],
  ['usduj', 6],
  ['usduf', 4],
  ['usdpg', 4],
  ['usiad', 4],
  ['usdlh', 5],
  ['usduc', 6],
  ['usdu3', 1],
  ['usdfu', 3],
  ['usdup', 3],
  ['uszdq', 3],
  ['usdro', 6],
  ['usdua', 6],
  ['usrdu', 4],
  ['usdut', 5],
  ['uszdz', 3],
  ['usduh', 3],
  ['useaa', 4],
  ['usela', 4],
  ['usegv', 4],
  ['usqey', 3],
  ['usere', 3],
  ['usepa', 3],
  ['usqeo', 3],
  ['usqev', 3],
  ['usztb', 3],
  ['usech', 3],
  ['useqe', 3],
  ['us4he', 1],
  ['uszef', 3],
  ['usefo', 4],
  ['usefn', 3],
  ['ushto', 4],
  ['useht', 6],
  ['usekb', 1],
  ['use2l', 1],
  ['useq4', 1],
  ['usxxy', 1],
  ['usem7', 3],
  ['useog', 3],
  ['usyep', 3],
  ['usepy', 3],
  ['usqet', 3],
  ['usesp', 4],
  ['useca', 4],
  ['ustyy', 3],
  ['uszep', 3],
  ['us99w', 1],
  ['usetn', 4],
  ['usabe', 4],
  ['usesn', 6],
  ['usesw', 4],
  ['usepm', 1],
  ['usesd', 4],
  ['useau', 6],
  ['use2d', 1],
  ['usedv', 3],
  ['usev6', 1],
  ['uszvy', 3],
  ['usede', 4],
  ['usetw', 3],
  ['usgme', 1],
  ['usedg', 4],
  ['usqeb', 3],
  ['useow', 3],
  ['useda', 4],
  ['uszyl', 1],
  ['useek', 4],
  ['usegh', 3],
  ['usegx', 4],
  ['usef2', 6],
  ['usefb', 4],
  ['uskku', 4],
  ['uskek', 4],
  ['uscjn', 4],
  ['usecp', 3],
  ['usedk', 4],
  ['useld', 4],
  ['usehi', 3],
  ['usemt', 4],
  ['uselp', 6],
  ['usels', 3],
  ['useb4', 1],
  ['usero', 4],
  ['usilw', 3],
  ['uskhw', 3],
  ['uselv', 4],
  ['useli', 4],
  ['usecg', 4],
  ['usekx', 6],
  ['uselk', 4],
  ['use3k', 3],
  ['user2', 3],
  ['useki', 5],
  ['usekn', 4],
  ['uszem', 3],
  ['useko', 6],
  ['usekt', 3],
  ['usekz', 3],
  ['usebz', 1],
  ['uselw', 4],
  ['uszev', 3],
  ['usyeb', 3],
  ['useln', 4],
  ['uszew', 3],
  ['uszen', 3],
  ['uszet', 3],
  ['useqz', 3],
  ['usel3', 1],
  ['usel4', 1],
  ['uselm', 4],
  ['usqdm', 3],
  ['useoy', 3],
  ['usery', 3],
  ['useqw', 1],
  ['usely', 6],
  ['uslyu', 6],
  ['usemb', 4],
  ['uszzx', 1],
  ['usemy', 3],
  ['usjem', 4],
  ['usem4', 1],
  ['usemg', 3],
  ['usemk', 4],
  ['usyem', 3],
  ['usepe', 3],
  ['usemp', 4],
  ['usenc', 6],
  ['usbgm', 4],
  ['usqts', 4],
  ['uskeb', 4],
  ['uswdg', 6],
  ['usen5', 3],
  ['usets', 4],
  ['useph', 4],
  ['usqrl', 3],
  ['useri', 5],
  ['usxeh', 3],
  ['userr', 4],
  ['usqew', 3],
  ['usesc', 5],
  ['useso', 4],
  ['usesg', 3],
  ['usest', 6],
  ['usefs', 1],
  ['use2t', 1],
  ['usett', 3],
  ['useud', 3],
  ['useuf', 4],
  ['useug', 6],
  ['usuce', 4],
  ['useup', 6],
  ['useka', 5],
  ['useue', 4],
  ['useva', 4],
  ['usevw', 4],
  ['usevm', 4],
  ['usev7', 1],
  ['usqei', 3],
  ['uszeo', 3],
  ['usexi', 4],
  ['usfh2', 3],
  ['usfpl', 3],
  ['usfai', 5],
  ['usfby', 4],
  ['usfbs', 6],
  ['usfdf', 1],
  ['usffl', 4],
  ['usfgx', 1],
  ['ussuu', 4],
  ['usfrm', 4],
  ['usqfc', 3],
  ['usqfy', 3],
  ['usfmu', 3],
  ['usfpt', 3],
  ['usnjf', 3],
  ['ustx3', 1],
  ['usfvp', 1],
  ['usewb', 5],
  ['usnfl', 4],
  ['usflj', 4],
  ['usfsy', 3],
  ['usfmh', 4],
  ['usfak', 4],
  ['uskfp', 4],
  ['usfwl', 4],
  ['usfar', 6],
  ['usfbl', 6],
  ['usfrg', 4],
  ['usfa3', 3],
  ['usfam', 4],
  ['usfmn', 6],
  ['usqfm', 3],
  ['usfgq', 1],
  ['usf2s', 1],
  ['usfay', 6],
  ['usfym', 4],
  ['usfyv', 6],
  ['usjjw', 6],
  ['usfa2', 3],
  ['usfel', 3],
  ['uszgu', 3],
  ['usfeb', 3],
  ['usqfi', 3],
  ['usfi3', 3],
  ['usfil', 4],
  ['usfdy', 4],
  ['usfnk', 4],
  ['usfic', 4],
  ['usfid', 4],
  ['usfik', 3],
  ['usfiv', 4],
  ['usfmc', 4],
  ['usfbf', 3],
  ['usflg', 4],
  ['usyfd', 6],
  ['usflt', 4],
  ['usfxm', 4],
  ['usfi7', 1],
  ['usfnt', 6],
  ['usfw2', 3],
  ['usflo', 6],
  ['usflz', 3],
  ['usfne', 3],
  ['usmsl', 4],
  ['usyfa', 3],
  ['usflu', 4],
  ['usnhx', 4],
  ['usfld', 4],
  ['usfcc', 3],
  ['usfxy', 4],
  ['usfsq', 3],
  ['usfop', 4],
  ['usfta', 1],
  ['usra3', 3],
  ['usfo9', 3],
  ['usfcy', 4],
  ['uszyf', 3],
  ['usxfo', 3],
  ['usdaa', 4],
  ['uslsf', 4],
  ['usfbg', 4],
  ['usfob', 5],
  ['usfbr', 4],
  ['usftc', 3],
  ['uscca', 4],
  ['usfnl', 4],
  ['usqfa', 3],
  ['usaye', 4],
  ['uswri', 4],
  ['usfod', 6],
  ['usfaf', 4],
  ['usfhu', 4],
  ['usmui', 4],
  ['usbys', 4],
  ['usrbn', 4],
  ['usftk', 6],
  ['usflv', 4],
  ['ustbn', 4],
  ['usqfu', 3],
  ['usfms', 4],
  ['usfme', 6],
  ['usftm', 3],
  ['usfmy', 4],
  ['usfpr', 5],
  ['uspoe', 6],
  ['usfvr', 3],
  ['usfrn', 4],
  ['usfri', 4],
  ['usfsn', 4],
  ['usfsi', 6],
  ['usfsm', 6],
  ['ustsn', 3],
  ['usfst', 6],
  ['usfsu', 6],
  ['usqfw', 4],
  ['usfwa', 6],
  ['usfwt', 6],
  ['usfyu', 4],
  ['usftl', 4],
  ['usqwi', 1],
  ['usfr6', 1],
  ['usfox', 4],
  ['usyfx', 3],
  ['usfcv', 3],
  ['uszfo', 4],
  ['usfft', 6],
  ['usfnf', 3],
  ['usfkl', 4],
  ['usfkn', 4],
  ['usfau', 3],
  ['usyfz', 3],
  ['usfdk', 6],
  ['usfdr', 4],
  ['usxzd', 1],
  ['usfde', 1],
  ['usfzz', 3],
  ['usfnb', 3],
  ['usee8', 1],
  ['usfep', 4],
  ['usfpo', 3],
  ['usxas', 1],
  ['usfet', 4],
  ['usfrh', 4],
  ['usjwy', 3],
  ['uswfk', 4],
  ['usfrp', 4],
  ['usfat', 4],
  ['usfrd', 4],
  ['usfsp', 3],
  ['usfrr', 4],
  ['usrxr', 1],
  ['uszfm', 3],
  ['usfry', 4],
  ['usful', 6],
  ['usfnr', 4],
  ['usgab', 4],
  ['usgad', 6],
  ['usgag', 4],
  ['usgle', 6],
  ['usgnv', 6],
  ['usgvl', 6],
  ['usgai', 4],
  ['usgak', 4],
  ['usgbh', 4],
  ['usgal', 4],
  ['usgsf', 3],
  ['usgbg', 6],
  ['uszgq', 3],
  ['usgg2', 3],
  ['usawy', 3],
  ['usgup', 6],
  ['usgls', 5],
  ['usgam', 4],
  ['usgek', 4],
  ['usgvr', 3],
  ['usgbv', 3],
  ['usgck', 4],
  ['usjhc', 4],
  ['ustxu', 1],
  ['usg44', 1],
  ['usgt9', 3],
  ['us8ga', 6],
  ['usgdr', 3],
  ['usnxr', 3],
  ['usgdm', 4],
  ['usgnc', 3],
  ['usgnr', 3],
  ['usqgt', 3],
  ['usga7', 1],
  ['usgt4', 1],
  ['usgyy', 5],
  ['usgsq', 1],
  ['usgkt', 4],
  ['uszgz', 1],
  ['usia9', 4],
  ['usged', 4],
  ['usgge', 5],
  ['ustuv', 1],
  ['usgty', 4],
  ['usghe', 3],
  ['usgbc', 3],
  ['usgid', 3],
  ['usgez', 3],
  ['usgcc', 6],
  ['uskgz', 4],
  ['ustxg', 6],
  ['usggg', 4],
  ['usgdw', 4],
  ['usglw', 4],
  ['uszlo', 1],
  ['usgjq', 1],
  ['usgwv', 4],
  ['usjgx', 4],
  ['usor8', 3],
  ['usg2h', 1],
  ['usgdv', 4],
  ['usglq', 4],
  ['usynn', 3],
  ['usygl', 3],
  ['usgfl', 4],
  ['usnbu', 6],
  ['usgkx', 1],
  ['usgws', 4],
  ['uszge', 3],
  ['usglo', 3],
  ['usglc', 3],
  ['usnea', 4],
  ['usn3e', 3],
  ['usgca', 3],
  ['usgol', 6],
  ['usgxr', 1],
  ['usgdh', 4],
  ['usgla', 3],
  ['usgsb', 6],
  ['usqgx', 3],
  ['usglv', 4],
  ['usvzz', 1],
  ['usgop', 1],
  ['usgng', 4],
  ['usgld', 4],
  ['usgnu', 4],
  ['usgyr', 4],
  ['usgve', 4],
  ['usgvz', 3],
  ['usllj', 3],
  ['usgva', 3],
  ['usgsh', 4],
  ['usoen', 3],
  ['usgp2', 3],
  ['usgzb', 3],
  ['usgry', 3],
  ['usqgb', 3],
  ['usgcn', 4],
  ['usgcw', 4],
  ['usgfk', 6],
  ['usghn', 3],
  ['usgri', 6],
  ['usgjt', 6],
  ['usgrm', 4],
  ['usgra', 3],
  ['usgpz', 4],
  ['usgrr', 5],
  ['usgvw', 4],
  ['usgzi', 3],
  ['usgmt', 4],
  ['usgnt', 4],
  ['uskgx', 4],
  ['usghc', 3],
  ['usyyg', 1],
  ['usgbr', 4],
  ['usgbd', 4],
  ['usgtf', 4],
  ['usgxy', 4],
  ['usrvr', 4],
  ['usfwz', 3],
  ['usgeb', 3],
  ['usgfd', 4],
  ['usgp4', 3],
  ['usgso', 4],
  ['uspa9', 3],
  ['usgcy', 6],
  ['usglh', 5],
  ['usgre', 4],
  ['usgvt', 6],
  ['uspgv', 6],
  ['usgsp', 6],
  ['usgrd', 6],
  ['usgwo', 4],
  ['usgsp', 6],
  ['usgey', 4],
  ['usqr5', 3],
  ['usg77', 1],
  ['uszgp', 1],
  ['usgpw', 1],
  ['usgoo', 6],
  ['uszgo', 6],
  ['usgon', 6],
  ['usgvb', 3],
  ['usygs', 3],
  ['usgtg', 1],
  ['usgdj', 3],
  ['usguf', 4],
  ['usgum', 1],
  ['usgpt', 5],
  ['usgkn', 4],
  ['usguc', 4],
  ['usgst', 4],
  ['usgok', 4],
  ['usgug', 3],
  ['usg8y', 1],
  ['usguy', 4],
  ['usgm2', 3],
  ['ushs2', 3],
  ['ushgr', 4],
  ['ushwn', 3],
  ['ushah', 3],
  ['ushxk', 1],
  ['ussun', 4],
  ['ushns', 5],
  ['usha7', 1],
  ['ushaf', 4],
  ['ushlj', 3],
  ['ushs6', 3],
  ['ushmr', 3],
  ['ushab', 6],
  ['ushao', 6],
  ['ushm7', 3],
  ['ushpt', 4],
  ['ushr3', 3],
  ['usphf', 4],
  ['ushnm', 4],
  ['uspak', 4],
  ['uscmx', 4],
  ['ush2n', 3],
  ['ushve', 4],
  ['ushnx', 4],
  ['usleb', 6],
  ['ushbc', 4],
  ['ushb4', 3],
  ['ushjs', 1],
  ['ushs3', 3],
  ['ushxg', 1],
  ['us5mn', 3],
  ['ushrl', 6],
  ['ush9r', 3],
  ['ushar', 4],
  ['ushb3', 3],
  ['ushsb', 6],
  ['ushro', 6],
  ['ushfd', 4],
  ['ushvs', 6],
  ['uszhw', 3],
  ['ushht', 3],
  ['uszhu', 3],
  ['ushg2', 3],
  ['ushsi', 6],
  ['ushnc', 4],
  ['ushbg', 4],
  ['usqha', 3],
  ['ushae', 4],
  ['ushl5', 3],
  ['ushvr', 6],
  ['ushzz', 3],
  ['ushwi', 4],
  ['ushhr', 4],
  ['ushth', 4],
  ['usxvb', 1],
  ['ushay', 4],
  ['ushdn', 4],
  ['ushys', 6],
  ['ushwd', 6],
  ['ushyr', 4],
  ['ushzl', 4],
  ['ushkb', 4],
  ['usyhr', 3],
  ['ushr4', 1],
  ['uszeh', 3],
  ['ushee', 6],
  ['ushln', 6],
  ['ushmt', 4],
  ['ush99', 1],
  ['ushev', 6],
  ['ushm4', 3],
  ['ushxu', 3],
  ['ushed', 4],
  ['usahc', 4],
  ['usrma', 3],
  ['ushes', 4],
  ['ushwx', 3],
  ['usyhb', 3],
  ['ushib', 4],
  ['ush4m', 4],
  ['ushm3', 3],
  ['ushky', 6],
  ['ushco', 1],
  ['ushid', 3],
  ['ushda', 4],
  ['usgso', 4],
  ['ushrq', 3],
  ['ushvg', 1],
  ['ushr5', 1],
  ['ushlc', 4],
  ['ushio', 4],
  ['uszhm', 3],
  ['usito', 5],
  ['ustlq', 1],
  ['ushhh', 6],
  ['usliy', 4],
  ['ushhk', 3],
  ['ushbr', 4],
  ['ushbh', 4],
  ['ushob', 4],
  ['ushbk', 3],
  ['ushff', 4],
  ['ushgz', 4],
  ['uszht', 3],
  ['ushde', 4],
  ['ushol', 4],
  ['ushyl', 4],
  ['ushli', 4],
  ['ushrm', 3],
  ['usomn', 6],
  ['uszpp', 3],
  ['ushwo', 6],
  ['usyhm', 3],
  ['ushcr', 4],
  ['ushom', 5],
  ['ushor', 3],
  ['ushms', 4],
  ['ushst', 4],
  ['us9wa', 3],
  ['usqhe', 3],
  ['usksh', 3],
  ['usmkk', 4],
  ['ushnh', 4],
  ['usho7', 1],
  ['ushpb', 4],
  ['ushop', 4],
  ['usqhk', 3],
  ['ushqm', 4],
  ['ushot', 6],
  ['ushsp', 4],
  ['ushtl', 4],
  ['ushul', 6],
  ['ushum', 4],
  ['ushu8', 3],
  ['ush4h', 1],
  ['usykl', 3],
  ['usqht', 3],
  ['ush8n', 1],
  ['ushcc', 4],
  ['ushsf', 3],
  ['usqhg', 3],
  ['ushus', 4],
  ['ushv2', 3],
  ['ushgu', 3],
  ['ushud', 4],
  ['ushxf', 3],
  ['ushnb', 4],
  ['ushgw', 3],
  ['ushts', 4],
  ['ushsv', 4],
  ['ushtv', 4],
  ['ushn9', 1],
  ['ushon', 4],
  ['ushsl', 4],
  ['ushya', 4],
  ['uswhd', 4],
  ['usicy', 4],
  ['usidg', 4],
  ['usqil', 3],
  ['usida', 4],
  ['usigg', 4],
  ['usili', 4],
  ['usimm', 4],
  ['usido', 6],
  ['usiml', 4],
  ['usnrs', 4],
  ['usivg', 3],
  ['usidp', 6],
  ['usyil', 3],
  ['ustgb', 1],
  ['usio2', 3],
  ['usir2', 3],
  ['usins', 4],
  ['usidi', 4],
  ['usind', 6],
  ['usieg', 1],
  ['usyig', 3],
  ['uszik', 3],
  ['usinl', 5],
  ['usqis', 3],
  ['usiyk', 4],
  ['usiow', 6],
  ['usifa', 4],
  ['usirb', 4],
  ['usimt', 6],
  ['usiwd', 4],
  ['usivk', 1],
  ['usisl', 4],
  ['usifl', 3],
  ['usiva', 3],
  ['usisp', 4],
  ['usith', 4],
  ['usiuk', 3],
  ['uskib', 4],
  ['usivh', 4],
  ['usjam', 3],
  ['uskpt', 4],
  ['usjqk', 3],
  ['usjac', 4],
  ['usjan', 6],
  ['usjs3', 3],
  ['usjxn', 6],
  ['usmjq', 4],
  ['usmkl', 6],
  ['usijx', 4],
  ['usj3c', 3],
  ['usjax', 5],
  ['usjkv', 4],
  ['uslrf', 4],
  ['usoaj', 6],
  ['usafn', 4],
  ['usja2', 1],
  ['usjmb', 3],
  ['usjpy', 3],
  ['usjhw', 6],
  ['usjms', 4],
  ['usapt', 4],
  ['usjas', 4],
  ['usjay', 3],
  ['usefw', 4],
  ['usjfn', 4],
  ['usjfz', 1],
  ['usjef', 4],
  ['usje2', 1],
  ['uszj9', 1],
  ['usjna', 6],
  ['usjuk', 3],
  ['usjec', 3],
  ['usqjb', 3],
  ['usjda', 4],
  ['uskdc', 3],
  ['usjfk', 4],
  ['usjbg', 1],
  ['usjoa', 3],
  ['usjcy', 6],
  ['usjoy', 6],
  ['usbgm', 4],
  ['ustri', 6],
  ['usjst', 6],
  ['usjot', 6],
  ['ushgt', 4],
  ['usjbr', 6],
  ['usjon', 1],
  ['usjln', 6],
  ['usjdn', 4],
  ['usjsa', 3],
  ['usjct', 4],
  ['usjnu', 5],
  ['usunu', 4],
  ['uskag', 3],
  ['uskkf', 4],
  ['uskl9', 1],
  ['usogg', 6],
  ['uska7', 1],
  ['uskhh', 6],
  ['usaiz', 4],
  ['usknk', 4],
  ['uskkk', 4],
  ['usazo', 6],
  ['uslup', 4],
  ['usfca', 6],
  ['usklg', 4],
  ['uskal', 4],
  ['usmue', 4],
  ['usknb', 4],
  ['usikk', 6],
  ['uskck', 5],
  ['usmci', 4],
  ['usjhm', 4],
  ['uskyk', 4],
  ['uskkl', 4],
  ['uskxa', 4],
  ['uskuk', 4],
  ['uslih', 4],
  ['usvik', 4],
  ['uskw7', 1],
  ['usmvm', 4],
  ['uskay', 3],
  ['usear', 4],
  ['usxhe', 6],
  ['uskhb', 3],
  ['useen', 4],
  ['usbkh', 4],
  ['uskeu', 4],
  ['usklp', 4],
  ['uskls', 5],
  ['usemm', 4],
  ['usena', 4],
  ['usku4', 1],
  ['uskmr', 3],
  ['uskeh', 4],
  ['usknt', 6],
  ['usc3a', 3],
  ['uskkt', 4],
  ['uske7', 1],
  ['useok', 4],
  ['usktz', 1],
  ['userv', 4],
  ['usqkk', 3],
  ['usktn', 5],
  ['usqkm', 3],
  ['uskwu', 3],
  ['uskyl', 4],
  ['useyw', 5],
  ['usyej', 1],
  ['uskyp', 3],
  ['uszyk', 3],
  ['usian', 4],
  ['uskih', 3],
  ['usggg', 4],
  ['usffa', 4],
  ['usile', 4],
  ['usqkc', 3],
  ['uskic', 4],
  ['uskvc', 4],
  ['uskpd', 6],
  ['usakn', 4],
  ['uskwm', 3],
  ['uskif', 6],
  ['usigm', 6],
  ['uskgm', 3],
  ['ustri', 6],
  ['usnqi', 4],
  ['usiso', 6],
  ['uskpn', 4],
  ['usirk', 6],
  ['usxkw', 3],
  ['uskab', 1],
  ['uskis', 1],
  ['usism', 4],
  ['uskkb', 4],
  ['uskxk', 3],
  ['usktt', 3],
  ['uskvl', 4],
  ['uskzh', 4],
  ['uskbk', 4],
  ['uslmt', 6],
  ['usklw', 6],
  ['usk99', 1],
  ['ustys', 4],
  ['usobu', 4],
  ['usadq', 4],
  ['usokk', 4],
  ['uskw2', 3],
  ['uskkh', 4],
  ['uskpp', 3],
  ['usosx', 4],
  ['uskot', 4],
  ['usotz', 4],
  ['uskka', 4],
  ['uskyu', 4],
  ['uskuw', 4],
  ['uslkk', 4],
  ['uskub', 1],
  ['usuuk', 4],
  ['uskwt', 4],
  ['uskwk', 4],
  ['uslse', 4],
  ['uslgd', 6],
  ['uslgk', 3],
  ['uslgz', 1],
  ['uslga', 6],
  ['uspoc', 4],
  ['uswlb', 4],
  ['uslci', 4],
  ['usxxl', 1],
  ['uszyt', 3],
  ['uslaf', 4],
  ['uslft', 4],
  ['usyfq', 3],
  ['usl9g', 1],
  ['uslgc', 6],
  ['usl5a', 3],
  ['usla2', 3],
  ['uslch', 5],
  ['usle3', 3],
  ['uszlg', 3],
  ['usxes', 4],
  ['uslg2', 3],
  ['ushii', 4],
  ['uslu7', 1],
  ['usljn', 4],
  ['uslma', 4],
  ['uslrp', 3],
  ['uslpq', 3],
  ['usqjc', 1],
  ['uslkp', 4],
  ['usylp', 3],
  ['us6er', 3],
  ['uswcw', 3],
  ['usnel', 4],
  ['uslal', 6],
  ['usls4', 1],
  ['uslks', 4],
  ['uslkv', 4],
  ['uslaa', 4],
  ['uslny', 4],
  ['uslns', 6],
  ['uslzc', 3],
  ['uswjf', 4],
  ['uskaj', 1],
  ['uslnd', 4],
  ['uslzv', 3],
  ['usl54', 1],
  ['usylt', 3],
  ['uslb9', 1],
  ['uslan', 6],
  ['uslpo', 4],
  ['uslar', 6],
  ['uslrd', 6],
  ['uskln', 4],
  ['uslru', 6],
  ['uslas', 4],
  ['uslvs', 4],
  ['uslro', 6],
  ['uslth', 4],
  ['uslbe', 4],
  ['uslfb', 6],
  ['uslul', 4],
  ['usqlr', 3],
  ['usua7', 1],
  ['usqlh', 3],
  ['us5bl', 1],
  ['uslwc', 4],
  ['uslwe', 3],
  ['uslvu', 3],
  ['uslvl', 4],
  ['uslwv', 4],
  ['uslaw', 6],
  ['uslw5', 1],
  ['usay5', 1],
  ['usluu', 3],
  ['uslxv', 4],
  ['uszlt', 3],
  ['usleb', 4],
  ['usylb', 3],
  ['uslee', 4],
  ['uslcf', 3],
  ['usllc', 3],
  ['uslrj', 4],
  ['uslmy', 3],
  ['uslem', 4],
  ['usnlc', 4],
  ['uslh7', 1],
  ['usltw', 4],
  ['usqlo', 3],
  ['useu3', 1],
  ['uskll', 4],
  ['usleq', 3],
  ['uslwb', 6],
  ['uslwd', 3],
  ['uslws', 6],
  ['uslew', 6],
  ['uslwt', 4],
  ['uslex', 6],
  ['uslxn', 4],
  ['usqdu', 1],
  ['uslbl', 6],
  ['uslib', 3],
  ['usl2p', 3],
  ['uslia', 6],
  ['uslvd', 4],
  ['uslic', 4],
  ['uslnk', 6],
  ['usyoc', 1],
  ['uslrk', 4],
  ['usyld', 3],
  ['usldj', 6],
  ['usdd6', 3],
  ['usqlf', 3],
  ['usln5', 1],
  ['usllb', 3],
  ['usl2d', 1],
  ['usltg', 3],
  ['uslhk', 3],
  ['uswln', 4],
  ['uslpw', 4],
  ['uslr3', 3],
  ['uslit', 4],
  ['usylr', 3],
  ['usliv', 4],
  ['uslvk', 4],
  ['uslvm', 4],
  ['uslxq', 1],
  ['uslhv', 6],
  ['uslot', 4],
  ['usxlt', 3],
  ['uslo7', 1],
  ['uslgu', 6],
  ['usylo', 3],
  ['usoit', 3],
  ['uslpc', 4],
  ['usloz', 4],
  ['uslnr', 4],
  ['uslni', 4],
  ['uslcj', 3],
  ['uslij', 4],
  ['uslrc', 3],
  ['usl9k', 3],
  ['uszlw', 3],
  ['uslog', 5],
  ['usggg', 4],
  ['uslo9', 1],
  ['uslps', 4],
  ['uslor', 3],
  ['uslsb', 4],
  ['usqlt', 3],
  ['uswlr', 4],
  ['uslat', 3],
  ['uslam', 6],
  ['uslax', 5],
  ['uslsn', 4],
  ['usllu', 3],
  ['uslhb', 4],
  ['ustso', 3],
  ['uslsr', 4],
  ['usqyl', 3],
  ['uslow', 4],
  ['uslfn', 4],
  ['uslo8', 1],
  ['uslms', 4],
  ['uslc2', 3],
  ['uspoy', 4],
  ['uslol', 4],
  ['usqli', 3],
  ['usvtt', 1],
  ['ustoz', 1],
  ['us7sd', 3],
  ['uslwy', 1],
  ['uslbb', 4],
  ['uslb2', 3],
  ['usldm', 5],
  ['uslfk', 6],
  ['usylu', 3],
  ['uslbt', 6],
  ['uslp2', 1],
  ['uslsk', 4],
  ['usltz', 3],
  ['uslyr', 3],
  ['us3wa', 3],
  ['usqly', 3],
  ['uslyh', 6],
  ['usllx', 4],
  ['uslym', 3],
  ['us7yt', 3],
  ['usyvo', 1],
  ['uslyo', 4],
  ['us3sd', 3],
  ['uszan', 3],
  ['usaf3', 6],
  ['usmp5', 3],
  ['usmcd', 5],
  ['usejk', 1],
  ['usmqb', 4],
  ['usmcn', 6],
  ['useif', 1],
  ['usmae', 4],
  ['usmdn', 4],
  ['usmpe', 6],
  ['usmsn', 4],
  ['usxmd', 4],
  ['usymd', 3],
  ['usmdj', 4],
  ['usinv', 3],
  ['usmdj', 4],
  ['usago', 4],
  ['usmld', 4],
  ['usmg5', 3],
  ['usmaw', 4],
  ['usmm6', 6],
  ['usmlk', 4],
  ['usaqj', 1],
  ['usmmh', 4],
  ['uszmq', 3],
  ['usmnz', 4],
  ['usmcr', 1],
  ['usmht', 4],
  ['usmh8', 3],
  ['usmhk', 4],
  ['usmnh', 3],
  ['uszmy', 3],
  ['usmxa', 4],
  ['usmbl', 4],
  ['usisq', 4],
  ['usmtw', 5],
  ['usmkt', 4],
  ['usmly', 4],
  ['usia2', 1],
  ['usnsv', 3],
  ['uskmo', 4],
  ['usmfd', 4],
  ['usmeo', 4],
  ['usntj', 4],
  ['usqmu', 3],
  ['usjvi', 4],
  ['uszpu', 3],
  ['usxuy', 3],
  ['usmzj', 4],
  ['usmth', 4],
  ['usmyh', 4],
  ['usmh2', 3],
  ['usmrk', 4],
  ['usmah', 3],
  ['uszay', 3],
  ['usmrf', 4],
  ['usrte', 4],
  ['usmge', 6],
  ['usrtt', 6],
  ['usmnn', 6],
  ['usmwa', 4],
  ['usmzz', 6],
  ['usqmk', 3],
  ['usmms', 4],
  ['usmxg', 6],
  ['usqmm', 3],
  ['usmqt', 4],
  ['usmr2', 3],
  ['usasl', 6],
  ['usmhl', 4],
  ['usmml', 4],
  ['usnma', 3],
  ['usmiw', 4],
  ['usmfi', 4],
  ['usmvy', 4],
  ['usmtn', 4],
  ['usmrb', 6],
  ['usmyv', 6],
  ['usmcw', 6],
  ['usmss', 6],
  ['usmgi', 4],
  ['ustaw', 3],
  ['usmto', 6],
  ['usmp2', 3],
  ['ustsx', 1],
  ['usmyk', 4],
  ['usmy6', 1],
  ['usnrb', 4],
  ['usay7', 1],
  ['usmlc', 6],
  ['ustxc', 6],
  ['usmfe', 4],
  ['usmyl', 4],
  ['usmxy', 4],
  ['usmfs', 6],
  ['usqmw', 3],
  ['usmc2', 6],
  ['usmcb', 6],
  ['usmck', 4],
  ['uszwm', 3],
  ['usmf6', 3],
  ['usmcg', 4],
  ['usmkj', 3],
  ['usqmc', 3],
  ['usrnc', 4],
  ['usmpr', 4],
  ['usmqw', 4],
  ['usjjq', 1],
  ['usmej', 4],
  ['useaz', 1],
  ['usumq', 1],
  ['usmdf', 4],
  ['usmfr', 6],
  ['usmdr', 4],
  ['usmp7', 1],
  ['usmyu', 4],
  ['usmlb', 4],
  ['usmfv', 4],
  ['uszmv', 4],
  ['usmgf', 3],
  ['usm8n', 3],
  ['usmn5', 3],
  ['usmnm', 6],
  ['usmt6', 1],
  ['uszxe', 3],
  ['usmxp', 3],
  ['usmce', 4],
  ['usdra', 4],
  ['usmd5', 3],
  ['usois', 3],
  ['usmei', 6],
  ['usrrl', 4],
  ['usmsc', 6],
  ['usmtm', 5],
  ['usttr', 6],
  ['usxxx', 1],
  ['uswmk', 4],
  ['usmia', 5],
  ['usmio', 4],
  ['usiyh', 3],
  ['usjdm', 4],
  ['uszmg', 3],
  ['uskkv', 1],
  ['usm4i', 1],
  ['usiam', 3],
  ['usmgc', 4],
  ['uszix', 3],
  ['usmdo', 4],
  ['usm44', 1],
  ['usmwo', 4],
  ['usild', 6],
  ['usmaf', 6],
  ['usmdd', 6],
  ['usmbs', 6],
  ['usdbd', 4],
  ['usm2p', 1],
  ['usm2b', 1],
  ['usmls', 4],
  ['usmlf', 6],
  ['usmrq', 3],
  ['usmkw', 3],
  ['usmlj', 4],
  ['ust9x', 3],
  ['usmlt', 4],
  ['usipr', 3],
  ['usmiv', 6],
  ['usmw4', 3],
  ['usmt5', 3],
  ['usnse', 4],
  ['usmke', 5],
  ['usms6', 3],
  ['usmhm', 4],
  ['usmev', 4],
  ['usmwl', 4],
  ['usmsp', 4],
  ['usarv', 4],
  ['usmot', 6],
  ['usmnt', 4],
  ['usmfe', 4],
  ['usmso', 4],
  ['usmhe', 6],
  ['uscny', 4],
  ['usmby', 4],
  ['usmob', 5],
  ['usmod', 4],
  ['uszoh', 3],
  ['usm47', 1],
  ['usmli', 6],
  ['usmif', 4],
  ['usoo9', 1],
  ['usm9o', 3],
  ['usm4x', 3],
  ['usmlu', 6],
  ['usmvc', 4],
  ['ussiy', 4],
  ['usmtp', 4],
  ['usmy3', 3],
  ['usmry', 6],
  ['usmve', 4],
  ['usmgj', 4],
  ['usmgm', 4],
  ['usmsv', 4],
  ['usmxc', 4],
  ['usmxo', 4],
  ['usmpv', 4],
  ['usmtj', 6],
  ['usqmv', 4],
  ['usgmv', 4],
  ['usqor', 3],
  ['usmrh', 3],
  ['usyre', 3],
  ['us9oh', 3],
  ['usptn', 4],
  ['usmpp', 3],
  ['usmrn', 6],
  ['usmgw', 4],
  ['usmpj', 4],
  ['usmox', 4],
  ['usm5v', 1],
  ['usmmu', 4],
  ['usmor', 4],
  ['usmrt', 1],
  ['usmw3', 3],
  ['uskmy', 4],
  ['usmwh', 4],
  ['usmos', 4],
  ['usymr', 3],
  ['usmgr', 4],
  ['usxzs', 1],
  ['usmtk', 3],
  ['usmtc', 4],
  ['usmgq', 3],
  ['uslly', 6],
  ['usqhs', 3],
  ['usmcl', 4],
  ['usmop', 4],
  ['usmps', 6],
  ['usmpz', 6],
  ['usmsd', 4],
  ['usmpo', 4],
  ['usmhs', 4],
  ['usmuu', 4],
  ['usmvn', 6],
  ['usmvw', 6],
  ['usymv', 3],
  ['usmws', 4],
  ['usmu4', 1],
  ['usvmv', 6],
  ['usmuo', 4],
  ['uswmh', 4],
  ['usnuq', 4],
  ['usuip', 6],
  ['usmou', 4],
  ['usmki', 3],
  ['usmkz', 3],
  ['uszmw', 3],
  ['usmhn', 4],
  ['usmie', 4],
  ['usymk', 3],
  ['uszmu', 3],
  ['uscey', 6],
  ['usxys', 3],
  ['usmut', 4],
  ['usmsl', 4],
  ['usmko', 6],
  ['usmyr', 6],
  ['usmyg', 3],
  ['usmyc', 3],
  ['usyna', 3],
  ['usoch', 4],
  ['usnnk', 4],
  ['usnol', 4],
  ['usack', 4],
  ['usapc', 4],
  ['uswna', 4],
  ['uspka', 4],
  ['usapf', 6],
  ['usyud', 1],
  ['usash', 6],
  ['usbna', 5],
  ['usvqv', 1],
  ['usnyu', 3],
  ['ushez', 5],
  ['usnak', 3],
  ['usdca', 4],
  ['usnki', 4],
  ['usnva', 3],
  ['usnij', 3],
  ['usnej', 3],
  ['useed', 6],
  ['usnzq', 3],
  ['usnb4', 3],
  ['usnlg', 4],
  ['usnoq', 1],
  ['usenn', 4],
  ['usnda', 3],
  ['useos', 4],
  ['usnph', 4],
  ['usn8p', 1],
  ['usnpc', 3],
  ['usnxz', 3],
  ['usnvd', 4],
  ['usnbd', 3],
  ['usewb', 5],
  ['usewn', 6],
  ['usnbl', 3],
  ['usbw5', 1],
  ['usncy', 3],
  ['usnsx', 1],
  ['usnxc', 6],
  ['usncn', 4],
  ['uszwc', 3],
  ['usnp7', 1],
  ['us4nj', 3],
  ['usnfr', 3],
  ['ushvn', 5],
  ['usnh2', 3],
  ['usnhn', 3],
  ['usara', 4],
  ['usqnn', 3],
  ['uskgk', 4],
  ['usnli', 3],
  ['usned', 3],
  ['usnqv', 1],
  ['usgon', 6],
  ['usxnw', 3],
  ['usnm2', 3],
  ['usphd', 4],
  ['usqzn', 3],
  ['usnpx', 3],
  ['usrnh', 4],
  ['usnsb', 3],
  ['usknw', 4],
  ['usn5t', 3],
  ['usulm', 4],
  ['usnwo', 6],
  ['usswf', 4],
  ['uszmp', 3],
  ['usaws', 3],
  ['usecs', 4],
  ['usnfe', 3],
  ['usefk', 4],
  ['usnoz', 3],
  ['usnpo', 3],
  ['usnpt', 5],
  ['usnwh', 4],
  ['usjnp', 3],
  ['usnns', 3],
  ['usphf', 4],
  ['usqne', 3],
  ['uswwt', 4],
  ['usewk', 4],
  ['ustnu', 4],
  ['usiag', 6],
  ['usnie', 4],
  ['usnkv', 4],
  ['usich', 3],
  ['usnme', 4],
  ['usnib', 4],
  ['usiko', 4],
  ['usnle', 4],
  ['usnin', 4],
  ['usn5b', 3],
  ['usnix', 3],
  ['usnk3', 1],
  ['uswtk', 4],
  ['usols', 5],
  ['usqnv', 3],
  ['usome', 5],
  ['usnnl', 4],
  ['usorv', 4],
  ['usncm', 3],
  ['usncx', 3],
  ['usofk', 6],
  ['usni2', 1],
  ['usoun', 4],
  ['usowk', 4],
  ['usnau', 3],
  ['usn99', 3],
  ['usn4b', 1],
  ['usnts', 3],
  ['usndb', 1],
  ['uszgn', 3],
  ['usnhq', 3],
  ['usn2r', 3],
  ['usqnm', 3],
  ['usuho', 3],
  ['uslbf', 6],
  ['usnqz', 3],
  ['usznr', 3],
  ['usny5', 1],
  ['usnsc', 3],
  ['usnw2', 3],
  ['usyno', 3],
  ['usnhm', 3],
  ['usobk', 6],
  ['usohc', 4],
  ['uszzh', 3],
  ['usort', 4],
  ['usntr', 1],
  ['usorq', 6],
  ['usnow', 3],
  ['usoic', 4],
  ['usnxl', 1],
  ['usowd', 4],
  ['ustxq', 1],
  ['usnot', 4],
  ['usnui', 4],
  ['usnul', 4],
  ['usnup', 4],
  ['usznc', 4],
  ['usnyx', 3],
  ['usord', 4],
  ['usonl', 4],
  ['usob2', 3],
  ['usakj', 3],
  ['usodw', 6],
  ['usqop', 3],
  ['usyob', 3],
  ['usoak', 5],
  ['usobt', 6],
  ['usotn', 4],
  ['usokv', 3],
  ['usxor', 3],
  ['usobi', 3],
  ['usocf', 4],
  ['usoce', 4],
  ['usoam', 3],
  ['usoca', 4],
  ['usocx', 3],
  ['usntu', 4],
  ['usoci', 4],
  ['usocp', 3],
  ['usocn', 4],
  ['usoc7', 1],
  ['uso82', 1],
  ['usoga', 4],
  ['usogs', 5],
  ['usogy', 3],
  ['usyoq', 3],
  ['usoil', 6],
  ['uszhe', 3],
  ['usokb', 6],
  ['usomj', 6],
  ['usokx', 3],
  ['usokc', 6],
  ['usokm', 4],
  ['usdxx', 3],
  ['usolh', 4],
  ['usdkh', 1],
  ['usold', 6],
  ['usole', 4],
  ['usol2', 1],
  ['uskoy', 4],
  ['usolv', 4],
  ['uszot', 3],
  ['usoly', 4],
  ['usony', 4],
  ['usqot', 3],
  ['usolm', 5],
  ['usqoa', 3],
  ['usoma', 5],
  ['usomk', 4],
  ['usia3', 3],
  ['usonh', 4],
  ['usonn', 4],
  ['usono', 4],
  ['usont', 4],
  ['usot5', 1],
  ['usong', 3],
  ['uszow', 3],
  ['usopl', 4],
  ['usjor', 6],
  ['usobc', 3],
  ['usogb', 6],
  ['usosz', 3],
  ['us8mn', 3],
  ['usoeg', 3],
  ['usorl', 4],
  ['usydo', 3],
  ['usove', 4],
  ['usosb', 4],
  ['usm2s', 1],
  ['usoeo', 4],
  ['usosc', 4],
  ['usoks', 4],
  ['usosh', 6],
  ['usooa', 4],
  ['usos3', 1],
  ['usov3', 3],
  ['usosw', 3],
  ['usotl', 3],
  ['usoto', 4],
  ['usosv', 3],
  ['us3vc', 3],
  ['uskoz', 4],
  ['uszod', 3],
  ['usowa', 4],
  ['usoxc', 4],
  ['usoxd', 4],
  ['usuox', 6],
  ['usoxn', 6],
  ['usozr', 4],
  ['usoza', 4],
  ['uspfc', 4],
  ['uspbk', 4],
  ['uspfa', 4],
  ['uspga', 4],
  ['uspge', 3],
  ['uspgo', 4],
  ['usphk', 4],
  ['uspmu', 4],
  ['uspvz', 4],
  ['uspce', 4],
  ['uspy6', 1],
  ['uspsx', 4],
  ['uspbe', 3],
  ['uspsn', 6],
  ['uspqm', 3],
  ['uspsp', 4],
  ['uspaq', 4],
  ['uspmx', 4],
  ['uspmz', 3],
  ['usp64', 1],
  ['uspao', 4],
  ['uspgz', 1],
  ['usppa', 4],
  ['usqpm', 3],
  ['uspfn', 5],
  ['uspnu', 4],
  ['usp77', 1],
  ['uswpo', 4],
  ['uspgr', 4],
  ['uspht', 4],
  ['usprx', 4],
  ['uspkf', 4],
  ['uspkd', 4],
  ['usaaz', 6],
  ['uspkb', 6],
  ['uskpk', 4],
  ['uspk3', 1],
  ['uspkt', 3],
  ['usppf', 4],
  ['uspas', 3],
  ['uspsd', 6],
  ['uspgl', 5],
  ['uspsc', 4],
  ['usprb', 4],
  ['uspcp', 3],
  ['uspwg', 3],
  ['usptn', 4],
  ['usnhk', 4],
  ['uskph', 4],
  ['uspjb', 6],
  ['usyl2', 1],
  ['uspgs', 4],
  ['uspea', 3],
  ['usps6', 1],
  ['usqpn', 3],
  ['uspeq', 6],
  ['uspdb', 4],
  ['uspec', 4],
  ['usplr', 4],
  ['uspxn', 6],
  ['uspmb', 6],
  ['usjuy', 3],
  ['uszpb', 3],
  ['uspdt', 4],
  ['us7ca', 3],
  ['us9dk', 3],
  ['uspps', 3],
  ['uspns', 5],
  ['uspb4', 3],
  ['uspia', 4],
  ['uspqn', 3],
  ['usxpe', 3],
  ['usfpy', 4],
  ['uspro', 4],
  ['uspyl', 4],
  ['uspyb', 3],
  ['uskpv', 4],
  ['uspay', 3],
  ['usgus', 6],
  ['usvys', 6],
  ['usypb', 3],
  ['uspgc', 4],
  ['uspsg', 4],
  ['usptb', 6],
  ['uspnf', 4],
  ['usphl', 5],
  ['uspne', 4],
  ['usphp', 4],
  ['usyph', 3],
  ['usphx', 6],
  ['uspcu', 4],
  ['uslqk', 6],
  ['uspir', 6],
  ['usypf', 3],
  ['uspgx', 3],
  ['uspm8', 1],
  ['uspip', 4],
  ['uspqs', 4],
  ['uspiw', 3],
  ['uspaa', 3],
  ['uspbf', 6],
  ['usqps', 3],
  ['usypn', 3],
  ['uspim', 4],
  ['usqpy', 3],
  ['usxpr', 4],
  ['uspts', 4],
  ['uspit', 5],
  ['uspsf', 4],
  ['usitv', 3],
  ['uspvf', 4],
  ['useuu', 3],
  ['uspvw', 4],
  ['usptu', 4],
  ['ustjj', 1],
  ['usplb', 4],
  ['usptr', 4],
  ['uspi7', 1],
  ['usp2l', 1],
  ['usplp', 3],
  ['usjbs', 6],
  ['uspwd', 4],
  ['usply', 4],
  ['uspym', 6],
  ['uspoh', 4],
  ['usqph', 3],
  ['uspih', 6],
  ['usbsf', 4],
  ['uspa2', 1],
  ['uskpb', 4],
  ['ustbw', 1],
  ['uspp5', 1],
  ['uspho', 4],
  ['uspiz', 4],
  ['uspp3', 3],
  ['usprt', 4],
  ['us9iu', 3],
  ['usprs', 3],
  ['usow4', 1],
  ['uspxl', 4],
  ['usqpd', 3],
  ['uspoq', 4],
  ['usppm', 6],
  ['uspo8', 1],
  ['uspnc', 6],
  ['us4nm', 3],
  ['uspo7', 1],
  ['usptk', 4],
  ['uspvy', 4],
  ['uspof', 6],
  ['uspck', 4],
  ['usptd', 4],
  ['usptc', 4],
  ['uspal', 3],
  ['uspll', 3],
  ['uspta', 4],
  ['usclm', 5],
  ['usptl', 4],
  ['uspoa', 3],
  ['uskpy', 4],
  ['uspry', 1],
  ['uspob', 1],
  ['uspcv', 1],
  ['usuxz', 3],
  ['uspzh', 1],
  ['uskpc', 4],
  ['usqpr', 1],
  ['uspeb', 3],
  ['uspe2', 3],
  ['usfoc', 3],
  ['uspfd', 4],
  ['uspgh', 1],
  ['uspgm', 4],
  ['usabc', 1],
  ['uspth', 4],
  ['usphn', 5],
  ['uspis', 3],
  ['usptj', 3],
  ['usprf', 4],
  ['usjdt', 1],
  ['usplv', 3],
  ['usori', 4],
  ['usplu', 1],
  ['uspmv', 3],
  ['uspdi', 3],
  ['uspme', 3],
  ['uspml', 4],
  ['uspm3', 3],
  ['usurr', 3],
  ['uspnj', 3],
  ['usprl', 4],
  ['uslu8', 1],
  ['usqoc', 1],
  ['uspo2', 3],
  ['usppv', 4],
  ['usypr', 3],
  ['usri4', 3],
  ['uspsj', 3],
  ['uspjs', 4],
  ['uspsl', 1],
  ['ussul', 3],
  ['ustwd', 5],
  ['uspwr', 4],
  ['uspow', 3],
  ['uspoj', 3],
  ['uspca', 4],
  ['uszpt', 3],
  ['uspod', 3],
  ['usptv', 4],
  ['usdtp', 3],
  ['uspdx', 5],
  ['uspwm', 5],
  ['uspwq', 1],
  ['usxpt', 3],
  ['uspmh', 4],
  ['uspsm', 5],
  ['usptm', 3],
  ['usvtp', 1],
  ['usrkr', 6],
  ['usptw', 4],
  ['uspou', 4],
  ['uspul', 4],
  ['uspg8', 1],
  ['ustxx', 1],
  ['uspoy', 4],
  ['uspp4', 1],
  ['uszpw', 3],
  ['uspcd', 4],
  ['usps3', 3],
  ['usptt', 4],
  ['uszwz', 1],
  ['usprw', 4],
  ['usprc', 6],
  ['uspqi', 6],
  ['usps9', 1],
  ['uspuc', 4],
  ['usp43', 3],
  ['uspt7', 3],
  ['usblf', 4],
  ['uspct', 4],
  ['uspnn', 4],
  ['usprz', 4],
  ['usprg', 6],
  ['uspp9', 1],
  ['usppc', 4],
  ['usqpx', 3],
  ['uspvd', 5],
  ['uspvc', 5],
  ['uspvu', 6],
  ['usscc', 6],
  ['uspub', 6],
  ['uspgq', 1],
  ['uspuw', 4],
  ['uspgd', 4],
  ['usyp5', 1],
  ['usukt', 4],
  ['usnyg', 4],
  ['uszqe', 3],
  ['usuqe', 4],
  ['uszqu', 3],
  ['usuil', 4],
  ['usmqi', 6],
  ['usqui', 1],
  ['usuin', 6],
  ['uskwn', 4],
  ['usnco', 4],
  ['usyrg', 3],
  ['usxrc', 3],
  ['usrai', 3],
  ['usrdu', 4],
  ['usrmp', 4],
  ['usrbk', 4],
  ['usrbo', 3],
  ['usrhg', 3],
  ['us8dd', 3],
  ['usrng', 4],
  ['usrgr', 4],
  ['usrap', 4],
  ['usyrp', 3],
  ['usrsp', 4],
  ['usxra', 3],
  ['usrwl', 6],
  ['usacc', 3],
  ['usyro', 3],
  ['usrdg', 6],
  ['usrbl', 4],
  ['usrdv', 4],
  ['usrdb', 4],
  ['usrf5', 1],
  ['usrhy', 3],
  ['usrdk', 3],
  ['usrdr', 4],
  ['usrdd', 6],
  ['usrmd', 6],
  ['usrdm', 6],
  ['usrwc', 3],
  ['usrwf', 4],
  ['usrr6', 3],
  ['usrct', 4],
  ['usrfg', 4],
  ['usreh', 4],
  ['usizk', 1],
  ['usrno', 6],
  ['usrnz', 4],
  ['usrnt', 4],
  ['usje7', 1],
  ['usrxf', 3],
  ['usyry', 3],
  ['uszbk', 3],
  ['usrhi', 4],
  ['usrie', 4],
  ['usrif', 4],
  ['usrld', 4],
  ['usrch', 3],
  ['usrid', 6],
  ['usrib', 6],
  ['uszic', 3],
  ['usril', 4],
  ['usrim', 3],
  ['usraf', 3],
  ['usr88', 1],
  ['usrg6', 3],
  ['usqqn', 3],
  ['uszrb', 3],
  ['usrv5', 1],
  ['usrgj', 3],
  ['usral', 4],
  ['usvrd', 3],
  ['usvse', 3],
  ['usriw', 6],
  ['usroa', 6],
  ['usrzz', 6],
  ['usrce', 4],
  ['usr55', 3],
  ['usrcr', 4],
  ['usroc', 5],
  ['usrst', 4],
  ['usrc6', 1],
  ['usrkh', 6],
  ['usrks', 6],
  ['usxrt', 3],
  ['usryk', 1],
  ['usrck', 4],
  ['usrfd', 6],
  ['usr63', 1],
  ['usrkp', 4],
  ['usrp3', 3],
  ['usr2v', 1],
  ['usrkw', 6],
  ['usryf', 1],
  ['usrwi', 6],
  ['usrky', 3],
  ['usrd4', 1],
  ['usrgs', 3],
  ['usrog', 4],
  ['usrcy', 3],
  ['usrh2', 3],
  ['usrjq', 1],
  ['usrla', 6],
  ['usr3o', 3],
  ['usfal', 4],
  ['usreo', 4],
  ['usrme', 4],
  ['usrmg', 6],
  ['usomd', 3],
  ['usroj', 1],
  ['usrlt', 3],
  ['usrol', 4],
  ['usr34', 1],
  ['usrsj', 4],
  ['usrbg', 4],
  ['usrsg', 3],
  ['usrjt', 1],
  ['usrv9', 1],
  ['usrsk', 3],
  ['usryn', 1],
  ['usrow', 6],
  ['usraj', 3],
  ['usrtd', 4],
  ['usrpx', 4],
  ['usrsx', 6],
  ['usrwb', 4],
  ['usr5w', 1],
  ['uszre', 3],
  ['usrby', 4],
  ['usyrf', 3],
  ['usrui', 4],
  ['usyrd', 3],
  ['usbyi', 4],
  ['usrsl', 4],
  ['usrsh', 4],
  ['usrsn', 6],
  ['usrut', 6],
  ['uszry', 3],
  ['uszhb', 3],
  ['ussbb', 3],
  ['ussab', 3],
  ['usqsk', 3],
  ['ussac', 5],
  ['usrv4', 1],
  ['ussad', 4],
  ['usgxh', 1],
  ['ussp2', 3],
  ['ussgw', 4],
  ['usmbs', 6],
  ['usxsg', 3],
  ['ussag', 4],
  ['ussa3', 3],
  ['usust', 6],
  ['uss7t', 1],
  ['usskz', 3],
  ['uscs4', 3],
  ['usstc', 4],
  ['ussgu', 4],
  ['usst9', 1],
  ['usstg', 5],
  ['ussth', 3],
  ['ushza', 3],
  ['usjsy', 3],
  ['usjo4', 1],
  ['ussjn', 4],
  ['ustou', 3],
  ['usstj', 6],
  ['usysj', 3],
  ['usok9', 1],
  ['usuts', 3],
  ['ussl2', 6],
  ['usksm', 4],
  ['usstq', 4],
  ['usxsm', 4],
  ['ussmk', 4],
  ['ussnp', 4],
  ['uss67', 3],
  ['us6tt', 3],
  ['ussip', 6],
  ['ussre', 3],
  ['usxtm', 3],
  ['ussle', 6],
  ['usslm', 3],
  ['usslo', 6],
  ['uss2b', 1],
  ['usslt', 6],
  ['ussbo', 4],
  ['ussln', 6],
  ['ussns', 6],
  ['ussby', 6],
  ['ussrw', 6],
  ['ussmn', 4],
  ['usslc', 4],
  ['ussas', 4],
  ['usior', 1],
  ['uszsi', 3],
  ['ussjt', 4],
  ['ussat', 4],
  ['ussbt', 4],
  ['ussql', 4],
  ['ussfr', 4],
  ['ussfo', 5],
  ['ussjc', 5],
  ['uswsj', 4],
  ['uszxz', 3],
  ['uscsl', 6],
  ['ussxf', 3],
  ['ussyl', 4],
  ['usspq', 5],
  ['ussrf', 6],
  ['ussdp', 4],
  ['usbmh', 3],
  ['uszds', 3],
  ['ussyp', 3],
  ['usksr', 4],
  ['usysg', 3],
  ['usfu7', 1],
  ['ussfb', 4],
  ['ussfm', 4],
  ['ussna', 6],
  ['usscz', 4],
  ['ussru', 5],
  ['ussaf', 6],
  ['ussmx', 6],
  ['ussmo', 4],
  ['usszp', 4],
  ['ussts', 6],
  ['usjqj', 1],
  ['usro2', 1],
  ['ussqa', 4],
  ['ussa6', 1],
  ['ussrn', 3],
  ['usslk', 6],
  ['uszor', 3],
  ['ussrq', 6],
  ['ussaa', 4],
  ['uswsf', 4],
  ['uss4y', 3],
  ['uszop', 3],
  ['usssm', 5],
  ['ussav', 5],
  ['ustsa', 3],
  ['ussva', 4],
  ['usxna', 3],
  ['usqjz', 1],
  ['usscm', 4],
  ['usjmh', 6],
  ['ussch', 4],
  ['uss44', 1],
  ['usxot', 3],
  ['ussb3', 6],
  ['usbff', 4],
  ['usssz', 6],
  ['usstz', 3],
  ['ustts', 3],
  ['usscr', 6],
  ['usscb', 4],
  ['us7ol', 3],
  ['ussyb', 4],
  ['ussrc', 4],
  ['ussrp', 3],
  ['ussea', 5],
  ['usysb', 3],
  ['ussef', 4],
  ['usdmo', 4],
  ['usse4', 1],
  ['ussdx', 4],
  ['uswlk', 4],
  ['ussov', 4],
  ['uss66', 6],
  ['usseg', 6],
  ['usosm', 3],
  ['usses', 6],
  ['ussqv', 4],
  ['usuos', 4],
  ['usswd', 4],
  ['usser', 4],
  ['usmit', 4],
  ['usshx', 4],
  ['usxsk', 3],
  ['usskk', 4],
  ['ussr4', 3],
  ['usysh', 3],
  ['usnri', 4],
  ['ussnl', 6],
  ['ussbm', 6],
  ['usysd', 3],
  ['ussmu', 4],
  ['usshf', 6],
  ['usne5', 3],
  ['ussbx', 4],
  ['ussyi', 4],
  ['usz4x', 3],
  ['ussxp', 4],
  ['usxsi', 3],
  ['usshn', 4],
  ['ussya', 4],
  ['uszso', 3],
  ['usshr', 6],
  ['uspnx', 4],
  ['usxsh', 3],
  ['ussbq', 1],
  ['usipx', 1],
  ['uswsh', 4],
  ['usshh', 4],
  ['ushcb', 4],
  ['usxsy', 3],
  ['ussow', 6],
  ['usshv', 6],
  ['usshg', 4],
  ['uss7i', 1],
  ['ussdy', 4],
  ['ussny', 4],
  ['ussxy', 6],
  ['ussi7', 1],
  ['usfhu', 4],
  ['ussik', 6],
  ['usslg', 4],
  ['uszva', 1],
  ['ussib', 3],
  ['ussvc', 4],
  ['usvky', 3],
  ['usxvn', 3],
  ['usszu', 6],
  ['us2si', 3],
  ['ussux', 5],
  ['usfsd', 4],
  ['usyer', 3],
  ['ussit', 4],
  ['usskj', 4],
  ['ussgy', 5],
  ['usskw', 6],
  ['ussi2', 3],
  ['usslq', 4],
  ['ussk4', 1],
  ['usysv', 3],
  ['usscj', 4],
  ['ussfz', 4],
  ['usitn', 3],
  ['usysk', 3],
  ['usmqy', 4],
  ['uss7y', 1],
  ['ussnk', 4],
  ['usse3', 3],
  ['usonm', 6],
  ['usyss', 3],
  ['ussod', 3],
  ['ussxq', 4],
  ['ussol', 4],
  ['usomu', 3],
  ['ussme', 4],
  ['usss3', 3],
  ['usacj', 3],
  ['ussbn', 6],
  ['usodt', 3],
  ['usqpz', 1],
  ['usysy', 1],
  ['uszyx', 1],
  ['ussoh', 3],
  ['usxsj', 3],
  ['uss7k', 1],
  ['ustvl', 4],
  ['usaa9', 3],
  ['uswsn', 4],
  ['us2og', 1],
  ['usozn', 1],
  ['uspxd', 3],
  ['uss8k', 3],
  ['usqsw', 3],
  ['usnzw', 4],
  ['usioh', 3],
  ['usuwh', 3],
  ['ussop', 4],
  ['ushh7', 3],
  ['ussvw', 4],
  ['uscmy', 4],
  ['ussar', 4],
  ['usspa', 6],
  ['usspf', 4],
  ['us7ba', 3],
  ['usecr', 3],
  ['usrwq', 1],
  ['usspw', 6],
  ['uslx8', 1],
  ['usrtl', 6],
  ['ussus', 4],
  ['usgeg', 4],
  ['usxxo', 1],
  ['usqsg', 3],
  ['uss3g', 1],
  ['ussg3', 3],
  ['uszsg', 3],
  ['usspz', 4],
  ['ussfy', 3],
  ['ussgf', 6],
  ['ussgh', 6],
  ['usspi', 6],
  ['usvsf', 4],
  ['usysp', 3],
  ['ussr9', 1],
  ['ussyn', 4],
  ['usyrx', 1],
  ['usrl5', 1],
  ['usxar', 3],
  ['ussce', 4],
  ['usuyu', 3],
  ['ustbr', 4],
  ['ussvh', 6],
  ['usshd', 6],
  ['uswsb', 4],
  ['ussbs', 4],
  ['uswbb', 4],
  ['usqqx', 1],
  ['ustep', 3],
  ['ussep', 4],
  ['ussgi', 3],
  ['usstk', 4],
  ['ussqi', 4],
  ['usste', 4],
  ['ussvs', 4],
  ['uszsb', 3],
  ['usskt', 3],
  ['usllm', 3],
  ['usswo', 6],
  ['ustis', 3],
  ['ustk9', 1],
  ['ussgn', 3],
  ['uss8t', 1],
  ['ussrv', 4],
  ['usso9', 1],
  ['usslb', 4],
  ['uszst', 1],
  ['usmmn', 6],
  ['usvow', 3],
  ['usmvl', 4],
  ['usqst', 3],
  ['usjsd', 6],
  ['ustfo', 3],
  ['ussud', 4],
  ['ussua', 6],
  ['usssw', 4],
  ['ussue', 4],
  ['usirs', 4],
  ['ustug', 6],
  ['ussgt', 4],
  ['ushjd', 3],
  ['ussgr', 6],
  ['uszsu', 3],
  ['ussiv', 4],
  ['usslr', 4],
  ['usasz', 3],
  ['ussk3', 3],
  ['usumm', 4],
  ['usssc', 6],
  ['ussuo', 4],
  ['ussun', 4],
  ['ussuc', 4],
  ['ussut', 1],
  ['ussuw', 5],
  ['ussve', 4],
  ['usuit', 1],
  ['uszsd', 3],
  ['uszyy', 3],
  ['ussww', 4],
  ['uszs2', 1],
  ['usyl9', 1],
  ['ussyv', 6],
  ['ussyr', 5],
  ['ustb3', 1],
  ['usqtb', 3],
  ['ustiw', 5],
  ['ustch', 3],
  ['ustft', 3],
  ['ushne', 4],
  ['usta3', 1],
  ['uszao', 3],
  ['ustct', 4],
  ['ustkl', 4],
  ['usytl', 3],
  ['usta2', 1],
  ['ustka', 4],
  ['usasn', 6],
  ['ustlh', 6],
  ['ustrk', 3],
  ['ustmm', 3],
  ['ustpa', 5],
  ['usxts', 3],
  ['ustsg', 4],
  ['ustbj', 1],
  ['ustpo', 4],
  ['ustal', 4],
  ['usztg', 3],
  ['ustsm', 6],
  ['uszkl', 1],
  ['ustwz', 3],
  ['ustlj', 4],
  ['ustek', 4],
  ['ustdi', 1],
  ['ustne', 3],
  ['ustwe', 4],
  ['ustyz', 4],
  ['usuta', 3],
  ['ustmz', 3],
  ['ustsp', 4],
  ['ustlf', 4],
  ['ustla', 4],
  ['uskts', 4],
  ['usqtr', 6],
  ['ustpl', 6],
  ['ustsl', 3],
  ['ustke', 4],
  ['usznh', 3],
  ['usvvc', 3],
  ['ushuf', 6],
  ['usnjq', 1],
  ['ustrl', 6],
  ['usqty', 3],
  ['usteb', 4],
  ['usteh', 4],
  ['ustxk', 4],
  ['ustxt', 3],
  ['uscy2', 3],
  ['usdls', 6],
  ['usojl', 3],
  ['ustrm', 4],
  ['usthp', 4],
  ['ustvf', 4],
  ['ustvi', 6],
  ['ustps', 3],
  ['usthf', 3],
  ['usthm', 4],
  ['ustpw', 3],
  ['usktb', 4],
  ['usjto', 4],
  ['usqtf', 3],
  ['usoi7', 3],
  ['ushai', 4],
  ['usxtn', 3],
  ['ustma', 6],
  ['uskth', 4],
  ['usztr', 3],
  ['ustl8', 1],
  ['usxte', 3],
  ['ustnc', 4],
  ['usytb', 6],
  ['usvex', 4],
  ['ustqt', 1],
  ['ustsi', 3],
  ['ustix', 4],
  ['ustoc', 4],
  ['usgfb', 4],
  ['ustog', 4],
  ['ustkj', 4],
  ['ustki', 4],
  ['usook', 4],
  ['ustdo', 4],
  ['ustol', 5],
  ['usqth', 3],
  ['usyto', 3],
  ['ustkn', 1],
  ['usmjx', 6],
  ['usqto', 3],
  ['ustph', 6],
  ['ustop', 6],
  ['usqtn', 3],
  ['ustoa', 4],
  ['ustor', 4],
  ['usztu', 3],
  ['ust5w', 3],
  ['ustc4', 1],
  ['ustfg', 3],
  ['ustfr', 3],
  ['ustvc', 4],
  ['ustrp', 4],
  ['ustrt', 4],
  ['ust2r', 1],
  ['ustrx', 4],
  ['usttn', 6],
  ['us8yi', 3],
  ['usytc', 3],
  ['ustri', 4],
  ['ustnv', 3],
  ['ustad', 4],
  ['usqti', 3],
  ['ustrh', 4],
  ['usttd', 4],
  ['usoyy', 3],
  ['ustoi', 4],
  ['ustry', 3],
  ['ustkf', 4],
  ['ustmn', 3],
  ['ustcs', 6],
  ['usznt', 3],
  ['ustbc', 4],
  ['ustbz', 1],
  ['usxty', 3],
  ['usdda', 6],
  ['ustus', 6],
  ['ustcc', 6],
  ['usuqq', 3],
  ['ustlr', 4],
  ['ustha', 6],
  ['ustul', 4],
  ['ustlt', 4],
  ['ustni', 3],
  ['uswtl', 4],
  ['ustnk', 4],
  ['ustup', 6],
  ['ustp2', 1],
  ['usm4u', 3],
  ['ustuo', 6],
  ['ustcl', 6],
  ['ustt9', 1],
  ['ustge', 4],
  ['uswnc', 4],
  ['ustnp', 4],
  ['ustwf', 6],
  ['ustwa', 4],
  ['ustw2', 1],
  ['uszyi', 3],
  ['uste8', 1],
  ['ustyr', 6],
  ['ustjf', 6],
  ['usugi', 4],
  ['usugs', 6],
  ['usuki', 4],
  ['us4tp', 3],
  ['us3dv', 3],
  ['usumt', 4],
  ['usumb', 4],
  ['us9qa', 3],
  ['usunk', 4],
  ['usund', 6],
  ['uszuc', 3],
  ['usucy', 4],
  ['usyug', 3],
  ['ususc', 4],
  ['usut2', 3],
  ['usuhi', 3],
  ['usukk', 1],
  ['usccb', 4],
  ['usupp', 4],
  ['usyup', 3],
  ['usqus', 3],
  ['usucr', 3],
  ['usuca', 4],
  ['usuiz', 4],
  ['usuto', 4],
  ['usuva', 4],
  ['usuwc', 3],
  ['usuxx', 3],
  ['uskuy', 4],
  ['usvac', 4],
  ['usvdz', 5],
  ['usvld', 6],
  ['usvtn', 6],
  ['usvle', 4],
  ['us8qa', 3],
  ['usvlo', 6],
  ['usvps', 4],
  ['usvpz', 6],
  ['usvls', 3],
  ['usvbe', 3],
  ['usvhn', 4],
  ['usvny', 6],
  ['usvgs', 3],
  ['usvan', 3],
  ['usvla', 4],
  ['usva3', 4],
  ['usyvr', 3],
  ['usyvi', 3],
  ['usvee', 4],
  ['usvn3', 1],
  ['usvnc', 4],
  ['usvnt', 3],
  ['usve9', 3],
  ['usvel', 4],
  ['usvrb', 6],
  ['usvrs', 4],
  ['usvih', 4],
  ['usvkb', 1],
  ['usvks', 5],
  ['us3du', 3],
  ['usvct', 6],
  ['usvcv', 4],
  ['usvdi', 4],
  ['usvmd', 1],
  ['usvj2', 3],
  ['usoea', 4],
  ['us3tr', 3],
  ['usved', 3],
  ['usvia', 6],
  ['usvix', 1],
  ['usvot', 3],
  ['usvir', 3],
  ['usvis', 4],
  ['usact', 6],
  ['uswr4', 1],
  ['uswy2', 3],
  ['usww9', 3],
  ['usqwd', 3],
  ['uswg2', 1],
  ['ushhi', 4],
  ['uswah', 4],
  ['us9ai', 3],
  ['uswh7', 1],
  ['uswkl', 4],
  ['usblw', 4],
  ['usain', 4],
  ['uswa7', 1],
  ['us8uy', 3],
  ['uswdn', 4],
  ['uswaa', 4],
  ['usalw', 4],
  ['uswu7', 1],
  ['usarg', 4],
  ['ush9s', 3],
  ['uswa4', 1],
  ['usrbw', 6],
  ['uswlm', 4],
  ['usaxv', 4],
  ['usywd', 3],
  ['usuwa', 4],
  ['usass', 3],
  ['usvwr', 3],
  ['usjcq', 1],
  ['usszl', 4],
  ['usrrt', 5],
  ['uswzs', 3],
  ['uswtu', 3],
  ['ushqj', 1],
  ['uskjh', 1],
  ['usocw', 6],
  ['uswab', 3],
  ['uswas', 6],
  ['uswsg', 4],
  ['usw9a', 1],
  ['usbwi', 4],
  ['uswwa', 4],
  ['uswva', 3],
  ['uskwf', 4],
  ['usalo', 6],
  ['uswpf', 3],
  ['usart', 4],
  ['usaty', 6],
  ['us999', 3],
  ['uswvl', 6],
  ['uswvi', 6],
  ['usues', 4],
  ['usukn', 4],
  ['uswqu', 1],
  ['uswup', 3],
  ['usauw', 6],
  ['uszal', 3],
  ['usays', 6],
  ['usdtw', 4],
  ['usway', 4],
  ['usywz', 3],
  ['uswea', 4],
  ['usxbw', 3],
  ['usebs', 6],
  ['usepg', 4],
  ['useom', 3],
  ['uswco', 3],
  ['us3ol', 3],
  ['uslwl', 4],
  ['uselz', 4],
  ['useat', 6],
  ['usenv', 6],
  ['uswnh', 3],
  ['usweq', 3],
  ['usetb', 4],
  ['uswbm', 3],
  ['uswfg', 6],
  ['us4wr', 3],
  ['usl3y', 3],
  ['usywe', 3],
  ['usvkw', 4],
  ['usxpu', 4],
  ['uswlq', 3],
  ['usawm', 4],
  ['usjwq', 1],
  ['us3tu', 3],
  ['uspbi', 5],
  ['uskwp', 4],
  ['uswot', 3],
  ['uswr3', 1],
  ['uszdg', 3],
  ['usxtr', 3],
  ['usyly', 1],
  ['uswsx', 4],
  ['uswmu', 3],
  ['uswys', 4],
  ['uswep', 3],
  ['ushpn', 4],
  ['uswe2', 1],
  ['uswst', 4],
  ['usbaf', 6],
  ['usfok', 4],
  ['uswy7', 3],
  ['uszxq', 3],
  ['uswpt', 3],
  ['us3dc', 3],
  ['uswwi', 3],
  ['usu3v', 3],
  ['uswwp', 4],
  ['uswht', 4],
  ['usywh', 3],
  ['usean', 4],
  ['ushlg', 4],
  ['usnuw', 4],
  ['uswkt', 3],
  ['usxwe', 3],
  ['uswmo', 4],
  ['uswxb', 3],
  ['usztp', 3],
  ['uswtr', 4],
  ['usleb', 4],
  ['uswsd', 4],
  ['usssu', 4],
  ['ushie', 4],
  ['usnen', 4],
  ['usbrg', 4],
  ['usxwt', 3],
  ['uswqa', 3],
  ['uswmp', 3],
  ['usict', 4],
  ['uswcp', 3],
  ['uswc4', 3],
  ['usywi', 3],
  ['usvtd', 3],
  ['uswwd', 4],
  ['usavp', 4],
  ['usikb', 6],
  ['usywa', 3],
  ['usiiw', 3],
  ['uswus', 3],
  ['usphf', 4],
  ['usipt', 6],
  ['usiim', 3],
  ['usxzw', 3],
  ['usqwl', 3],
  ['usisn', 4],
  ['usill', 4],
  ['uslnn', 4],
  ['uswow', 4],
  ['usnxx', 4],
  ['usw26', 1],
  ['usyip', 4],
  ['uswlw', 4],
  ['uswpb', 3],
  ['uswma', 3],
  ['usilg', 5],
  ['usilm', 5],
  ['usiln', 6],
  ['uswtn', 3],
  ['usqcw', 4],
  ['uswgo', 6],
  ['usyst', 3],
  ['uswdr', 4],
  ['uswmj', 3],
  ['usmwm', 4],
  ['usqzj', 1],
  ['uswlc', 6],
  ['usbdl', 4],
  ['usinf', 6],
  ['uswld', 4],
  ['uswgx', 3],
  ['usink', 4],
  ['usyec', 3],
  ['uswmc', 4],
  ['usztk', 3],
  ['uswjj', 6],
  ['usona', 5],
  ['uswnk', 3],
  ['usinw', 4],
  ['uswns', 3],
  ['usint', 4],
  ['usgif', 4],
  ['usziw', 1],
  ['uswqz', 3],
  ['uswrp', 3],
  ['usiss', 6],
  ['uswnf', 3],
  ['usisw', 4],
  ['uslnp', 4],
  ['uswbn', 6],
  ['usxwo', 3],
  ['ustie', 3],
  ['usolf', 4],
  ['uswfb', 3],
  ['us8dc', 3],
  ['us8tt', 3],
  ['usuo4', 3],
  ['uswo7', 1],
  ['us8xc', 3],
  ['us8xd', 3],
  ['uswdz', 3],
  ['uswov', 3],
  ['uswwr', 6],
  ['usywr', 3],
  ['usbjj', 6],
  ['usorh', 5],
  ['uswrl', 6],
  ['usotg', 4],
  ['usrw5', 6],
  ['uswxs', 3],
  ['uswur', 3],
  ['usxyt', 3],
  ['uszwk', 3],
  ['usqww', 3],
  ['usykm', 6],
  ['usykn', 4],
  ['usyaz', 3],
  ['useyr', 6],
  ['usyok', 1],
  ['usthv', 4],
  ['usykb', 3],
  ['uszyh', 3],
  ['usoys', 4],
  ['usyng', 4],
  ['usytw', 3],
  ['usrkc', 4],
  ['usucc', 4],
  ['usyum', 6],
  ['uskzb', 6],
  ['us8pr', 3],
  ['uszzv', 4],
  ['uszph', 6],
  ['uszr9', 1],
  ['uszum', 3],
  ['uyati', 4],
  ['uybuv', 4],
  ['uycar', 1],
  ['uycyr', 5],
  ['uycpp', 1],
  ['uydzo', 5],
  ['uyfzb', 1],
  ['uyjit', 1],
  ['uyjlc', 1],
  ['uylap', 1],
  ['uymlz', 4],
  ['uymer', 1],
  ['uymvd', 5],
  ['uynvp', 1],
  ['uypdu', 5],
  ['uypir', 1],
  ['uypdp', 5],
  ['uyrvy', 4],
  ['uysty', 5],
  ['uytaw', 4],
  ['uytyt', 4],
  ['uyvch', 4],
  ['uzakt', 3],
  ['uzazn', 4],
  ['uzasa', 3],
  ['uzbhk', 4],
  ['uzfeg', 4],
  ['uzksq', 6],
  ['uznma', 4],
  ['uznvi', 4],
  ['uzncu', 4],
  ['uzskd', 4],
  ['uztas', 4],
  ['uzuch', 4],
  ['uzugc', 6],
  ['uzzer', 4],
  ['vccrp', 3],
  ['vccan', 1],
  ['vcgrg', 3],
  ['vcktn', 5],
  ['vcmqs', 5],
  ['vcpli', 4],
  ['vcsvd', 5],
  ['vcuni', 5],
  ['veagv', 4],
  ['veamy', 1],
  ['veaao', 4],
  ['veara', 1],
  ['vebav', 1],
  ['vebjv', 1],
  ['vebla', 4],
  ['vebns', 4],
  ['vebrm', 4],
  ['vecbs', 5],
  ['vecxa', 5],
  ['veclz', 4],
  ['vecaj', 4],
  ['veccs', 4],
  ['vecar', 3],
  ['vevcr', 4],
  ['vecup', 5],
  ['vecuv', 4],
  ['vechv', 1],
  ['vechq', 3],
  ['vecbl', 5],
  ['vecgu', 5],
  ['vecze', 5],
  ['vecum', 5],
  ['veelc', 1],
  ['veeor', 4],
  ['veegu', 1],
  ['veelp', 1],
  ['veetv', 1],
  ['veelx', 4],
  ['vevig', 4],
  ['veeoz', 4],
  ['veguq', 4],
  ['vegut', 1],
  ['vegua', 3],
  ['vegub', 1],
  ['vegdo', 4],
  ['vegui', 5],
  ['veica', 4],
  ['vejot', 1],
  ['vektv', 4],
  ['vekav', 4],
  ['velcv', 1],
  ['velec', 1],
  ['velfr', 4],
  ['velag', 5],
  ['velsv', 1],
  ['velvl', 1],
  ['velgy', 5],
  ['velsp', 5],
  ['velpj', 4],
  ['velrv', 4],
  ['vemar', 5],
  ['vemyc', 4],
  ['vemtv', 1],
  ['vemun', 4],
  ['vemrd', 4],
  ['vepmr', 1],
  ['veptm', 4],
  ['vepla', 5],
  ['vepmp', 1],
  ['vepct', 1],
  ['vepar', 1],
  ['veprc', 1],
  ['vepph', 4],
  ['vepdz', 4],
  ['veprg', 1],
  ['veptz', 1],
  ['vepmv', 4],
  ['vepyh', 5],
  ['vepbl', 5],
  ['vepco', 3],
  ['vecmr', 3],
  ['vepho', 1],
  ['vepcz', 1],
  ['vemiv', 1],
  ['vepzo', 5],
  ['veppz', 5],
  ['vepsu', 1],
  ['vepca', 1],
  ['vepcn', 1],
  ['vepcu', 1],
  ['veplv', 1],
  ['veppd', 1],
  ['vepfi', 3],
  ['vesvz', 4],
  ['vesci', 4],
  ['vesnf', 4],
  ['vesfd', 5],
  ['vesfx', 1],
  ['veslv', 1],
  ['vesvv', 4],
  ['vesom', 4],
  ['vesbb', 4],
  ['vestb', 6],
  ['vesnv', 4],
  ['vestd', 4],
  ['vetjv', 1],
  ['vetuv', 4],
  ['vetmo', 4],
  ['vetur', 1],
  ['veyur', 3],
  ['veurm', 4],
  ['vevln', 4],
  ['vevlv', 4],
  ['vevdp', 4],
  ['vewok', 4],
  ['vgeis', 5],
  ['vgrad', 5],
  ['vgngd', 4],
  ['vgjvd', 1],
  ['vgnsx', 4],
  ['vgnis', 1],
  ['vgpur', 1],
  ['vgsho', 1],
  ['vgtov', 5],
  ['vgvij', 4],
  ['vicha', 1],
  ['victd', 1],
  ['viczb', 3],
  ['vifrd', 1],
  ['vienp', 1],
  ['vihoc', 1],
  ['vilib', 1],
  ['vipax', 3],
  ['vistx', 6],
  ['vistt', 5],
  ['vnvic', 1],
  ['vnotw', 1],
  ['vnbno', 3],
  ['vnbho', 1],
  ['vnbmv', 4],
  ['vnbao', 1],
  ['vnbcd', 1],
  ['vntre', 1],
  ['vnben', 1],
  ['vnbdu', 3],
  ['vnbdg', 3],
  ['vnblg', 1],
  ['vnbmh', 3],
  ['vnhnh', 1],
  ['vnbng', 3],
  ['vnbse', 3],
  ['vncah', 4],
  ['vnccu', 3],
  ['vncln', 3],
  ['vntot', 1],
  ['vncmt', 3],
  ['vnban', 3],
  ['vncph', 1],
  ['vncrb', 1],
  ['vncsj', 4],
  ['vnocl', 3],
  ['vncmy', 3],
  ['vncve', 3],
  ['vncca', 3],
  ['vncuv', 3],
  ['vnclo', 3],
  ['vncli', 3],
  ['vndad', 5],
  ['vndli', 4],
  ['vndmo', 1],
  ['vndvu', 3],
  ['vndxa', 3],
  ['vndoh', 1],
  ['vndna', 3],
  ['vndth', 3],
  ['vndxn', 3],
  ['vndn3', 3],
  ['vngda', 3],
  ['vngdb', 3],
  ['vnhat', 1],
  ['vnhdg', 3],
  ['vnhsn', 3],
  ['vnhph', 1],
  ['vnhan', 5],
  ['vnspc', 3],
  ['vnhaa', 1],
  ['vnhia', 1],
  ['vnhch', 1],
  ['vnhlp', 1],
  ['vnhon', 1],
  ['vnhui', 5],
  ['vnhyn', 3],
  ['vnstr', 3],
  ['vnitx', 3],
  ['vnkhu', 1],
  ['vnkon', 4],
  ['vnkyq', 3],
  ['vnkyh', 3],
  ['vnlap', 1],
  ['vnlbn', 3],
  ['vnlbt', 3],
  ['vnlth', 1],
  ['vnxlo', 4],
  ['vnlpo', 3],
  ['vnmoc', 1],
  ['vnmut', 1],
  ['vnmth', 3],
  ['vnncn', 3],
  ['vnnmd', 1],
  ['vnnhd', 3],
  ['vnngt', 3],
  ['vnngh', 3],
  ['vnnvt', 3],
  ['vnonb', 3],
  ['vnnha', 5],
  ['vnnht', 3],
  ['vnnih', 3],
  ['vnpha', 5],
  ['vnphh', 5],
  ['vnphu', 1],
  ['vnpqc', 4],
  ['vnhbn', 4],
  ['vniph', 3],
  ['vnphl', 1],
  ['vnphg', 3],
  ['vnphb', 3],
  ['vnpxu', 6],
  ['vnhho', 4],
  ['vnqul', 1],
  ['vnxng', 5],
  ['vnqnh', 3],
  ['vnqut', 1],
  ['vnqbh', 3],
  ['vnvkg', 4],
  ['vnrdg', 1],
  ['vnrqu', 3],
  ['vnsad', 3],
  ['vncsg', 3],
  ['vnsit', 3],
  ['vnosp', 3],
  ['vnsoa', 6],
  ['vnsdp', 1],
  ['vnsqh', 6],
  ['vnson', 1],
  ['vnsh9', 3],
  ['vnstd', 1],
  ['vntmk', 4],
  ['vntcg', 1],
  ['vntmt', 3],
  ['vnhpp', 1],
  ['vnqnt', 3],
  ['vntcn', 3],
  ['vntsn', 6],
  ['vntam', 3],
  ['vntbi', 1],
  ['vntho', 1],
  ['vntpp', 1],
  ['vntna', 3],
  ['vnthd', 3],
  ['vnthu', 3],
  ['vndts', 3],
  ['vntno', 3],
  ['vntvn', 3],
  ['vntbb', 5],
  ['vnttd', 3],
  ['vnvph', 1],
  ['vnvch', 1],
  ['vnlmp', 1],
  ['vnvhg', 1],
  ['vnvss', 1],
  ['vnvnh', 5],
  ['vnvlo', 1],
  ['vnvlg', 3],
  ['vnvro', 3],
  ['vnvag', 1],
  ['vuauy', 4],
  ['vuawd', 4],
  ['vugba', 4],
  ['vuccv', 4],
  ['vudly', 4],
  ['vuson', 4],
  ['vufta', 4],
  ['vuzgu', 4],
  ['vuipa', 4],
  ['vulpm', 4],
  ['vulnb', 4],
  ['vulod', 4],
  ['vulne', 4],
  ['vulug', 3],
  ['vumwf', 4],
  ['vumtv', 4],
  ['vunus', 4],
  ['vuolj', 4],
  ['vupbj', 4],
  ['vupsa', 1],
  ['vuvli', 5],
  ['vuuiq', 4],
  ['vurcl', 4],
  ['vusan', 1],
  ['vussr', 4],
  ['vuslh', 4],
  ['vuswj', 4],
  ['vutah', 4],
  ['vutgh', 4],
  ['vutoh', 4],
  ['vuulb', 4],
  ['vuvls', 4],
  ['vuwlh', 4],
  ['vueae', 4],
  ['vuepi', 4],
  ['wfmau', 3],
  ['wfsig', 3],
  ['wffut', 4],
  ['wfwls', 4],
  ['wsapw', 5],
  ['wsaau', 5],
  ['wsfgi', 4],
  ['wslav', 4],
  ['wsmxs', 4],
  ['xzoca', 1],
  ['xzaad', 1],
  ['xzsha', 1],
  ['xzanu', 1],
  ['xzant', 1],
  ['xzbuv', 1],
  ['xzcmt', 1],
  ['xzdhs', 1],
  ['xzdht', 1],
  ['xzdra', 1],
  ['xzdtl', 1],
  ['xzhei', 1],
  ['xzkmt', 1],
  ['xzlam', 1],
  ['xzlgt', 1],
  ['xznjo', 1],
  ['xznne', 1],
  ['xznte', 1],
  ['xzsby', 1],
  ['xzstp', 1],
  ['xzsuu', 5],
  ['xztgr', 1],
  ['xzwrt', 1],
  ['xzyet', 1],
  ['yeeab', 4],
  ['yeade', 5],
  ['yeahw', 1],
  ['yeaay', 4],
  ['yebuk', 4],
  ['yeajo', 4],
  ['yeaxk', 4],
  ['yebyd', 4],
  ['yebhn', 4],
  ['yedah', 4],
  ['yedhl', 4],
  ['yedmr', 4],
  ['yeelk', 1],
  ['yehar', 1],
  ['yehau', 1],
  ['yehod', 5],
  ['yekam', 4],
  ['yekho', 1],
  ['yelad', 3],
  ['yeldr', 4],
  ['yemfy', 4],
  ['yemok', 1],
  ['yemkx', 5],
  ['yeukr', 4],
  ['yeprm', 1],
  ['yeihn', 4],
  ['yerak', 1],
  ['yerai', 1],
  ['yerxa', 4],
  ['yeriy', 4],
  ['yesal', 1],
  ['yesah', 4],
  ['yegxf', 4],
  ['yesct', 5],
  ['yetai', 4],
  ['yewda', 4],
  ['ytdza', 5],
  ['ytlon', 3],
  ['ytmam', 1],
  ['zaagz', 4],
  ['zaalj', 4],
  ['zaaob', 1],
  ['zaady', 4],
  ['zathr', 1],
  ['zahbl', 4],
  ['zabiy', 4],
  ['zabfn', 6],
  ['zabwa', 3],
  ['za8gt', 1],
  ['zaute', 4],
  ['zacdo', 4],
  ['zadwk', 1],
  ['zaduk', 4],
  ['zactp', 1],
  ['zaeby', 1],
  ['zaell', 4],
  ['zaemg', 4],
  ['zafcb', 4],
  ['zafhk', 3],
  ['zagsf', 1],
  ['zagty', 1],
  ['zagrj', 4],
  ['zagiy', 4],
  ['za3wc', 1],
  ['zagrh', 1],
  ['zahrs', 4],
  ['zahzv', 4],
  ['zahlw', 4],
  ['zahds', 4],
  ['zainy', 4],
  ['zaivi', 1],
  ['zarss', 1],
  ['zajtp', 1],
  ['zajnb', 6],
  ['zakuu', 1],
  ['zakos', 1],
  ['zakho', 4],
  ['zakim', 6],
  ['zabgr', 1],
  ['zaklz', 4],
  ['zakxe', 4],
  ['za2wc', 1],
  ['zakig', 6],
  ['zakof', 6],
  ['zakkn', 1],
  ['zakom', 3],
  ['zakmh', 4],
  ['zakya', 3],
  ['zalay', 4],
  ['zakoo', 1],
  ['zahla', 4],
  ['zaler', 3],
  ['zalmr', 4],
  ['zaldz', 4],
  ['zalcd', 4],
  ['zaluj', 4],
  ['zamd3', 3],
  ['zamfk', 6],
  ['zaaam', 4],
  ['zalle', 4],
  ['zamgh', 4],
  ['zamwt', 3],
  ['zamde', 3],
  ['zamez', 6],
  ['zambm', 4],
  ['zamzq', 4],
  ['zambd', 6],
  ['zamzy', 5],
  ['zamwr', 4],
  ['zaedg', 1],
  ['zamzf', 4],
  ['zanlp', 4],
  ['zangg', 3],
  ['zancs', 4],
  ['zangl', 4],
  ['zannl', 1],
  ['zanuf', 1],
  ['zaouh', 4],
  ['zapnl', 3],
  ['zapen', 1],
  ['zaphw', 4],
  ['zaphx', 1],
  ['zapzb', 4],
  ['zaptg', 4],
  ['zapbz', 4],
  ['zapit', 1],
  ['zaafd', 4],
  ['zaped', 3],
  ['zaplz', 5],
  ['zanol', 1],
  ['zangq', 1],
  ['zapry', 6],
  ['zaprk', 4],
  ['zautw', 4],
  ['zarvo', 4],
  ['zarcb', 5],
  ['zarwc', 1],
  ['za7wc', 1],
  ['zashm', 3],
  ['zarod', 4],
  ['zagss', 4],
  ['zasdb', 5],
  ['zazec', 4],
  ['zaze4', 3],
  ['zasid', 3],
  ['zasmn', 3],
  ['zasis', 4],
  ['zaszk', 4],
  ['zasnl', 1],
  ['zasbu', 4],
  ['zastd', 3],
  ['zastr', 1],
  ['zanty', 4],
  ['zatdt', 4],
  ['zatcu', 4],
  ['zathy', 4],
  ['zatsd', 4],
  ['zalta', 4],
  ['zauld', 4],
  ['zaulx', 4],
  ['zautt', 4],
  ['zautn', 4],
  ['zavac', 3],
  ['zavre', 4],
  ['zavru', 4],
  ['zavyd', 4],
  ['zawat', 1],
  ['zawel', 4],
  ['zawwc', 1],
  ['zawtn', 1],
  ['zmcgj', 4],
  ['zmcip', 4],
  ['zmqke', 4],
  ['zmklb', 4],
  ['zmkmz', 4],
  ['zmzkb', 4],
  ['zmkaa', 4],
  ['zmzkp', 4],
  ['zmkiw', 4],
  ['zmlvi', 4],
  ['zmlxu', 4],
  ['zmlun', 4],
  ['zmmns', 4],
  ['zmmmq', 4],
  ['zmmfu', 4],
  ['zmmnr', 4],
  ['zmnla', 4],
  ['zmzgm', 4],
  ['zmsxg', 4],
  ['zmsjq', 4],
  ['zmsia', 1],
  ['zmsli', 4],
  ['zmbbz', 4],
  ['zwbfo', 4],
  ['zwbuq', 4],
  ['zwbzh', 4],
  ['zwche', 1],
  ['zwchj', 4],
  ['zwflu', 1],
  ['zwgwa', 1],
  ['zwgwe', 6],
  ['zwhre', 4],
  ['zwwki', 4],
  ['zwhwn', 4],
  ['zwkab', 4],
  ['zwmjw', 4],
  ['zwmvz', 4],
  ['zwuta', 4],
  ['zwrsp', 1],
  ['zwvfa', 4],
  ['zwzmz', 3],
]
